import React from "react";

const IconMedia = () => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.5 6C6.67157 6 6 6.67157 6 7.5V28.5C6 29.3284 6.67157 30 7.5 30H28.5C29.3284 30 30 29.3284 30 28.5V7.5C30 6.67157 29.3284 6 28.5 6H7.5ZM3 7.5C3 5.01472 5.01472 3 7.5 3H28.5C30.9853 3 33 5.01472 33 7.5V28.5C33 30.9853 30.9853 33 28.5 33H7.5C5.01472 33 3 30.9853 3 28.5V7.5Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.75 12C12.3358 12 12 12.3358 12 12.75C12 13.1642 12.3358 13.5 12.75 13.5C13.1642 13.5 13.5 13.1642 13.5 12.75C13.5 12.3358 13.1642 12 12.75 12ZM9 12.75C9 10.6789 10.6789 9 12.75 9C14.8211 9 16.5 10.6789 16.5 12.75C16.5 14.8211 14.8211 16.5 12.75 16.5C10.6789 16.5 9 14.8211 9 12.75Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M22.9393 13.9393C23.5251 13.3536 24.4749 13.3536 25.0607 13.9393L32.5607 21.4393C33.1464 22.0251 33.1464 22.9749 32.5607 23.5607C31.9749 24.1464 31.0251 24.1464 30.4393 23.5607L24 17.1213L8.56066 32.5607C7.97487 33.1464 7.02513 33.1464 6.43934 32.5607C5.85355 31.9749 5.85355 31.0251 6.43934 30.4393L22.9393 13.9393Z"
      fill="currentColor"
    />
  </svg>
);

export default IconMedia;
