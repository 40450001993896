import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { getCategories, getMediaCategories } from "../api";

export const fetchCategories = createAsyncThunk(
  "categories/fetchCategories",
  async ({ language }, { dispatch, getState }) => {
    const { token } = getState().auth;
    const response = await getCategories({
      token,
      language,
    });
    return response;
  }
);

const initialState = {
  fetching: false,
  fetched: false,
  items: [],
  currentRequestId: undefined,
  error: null,
};

export const categoriesSlice = createSlice({
  name: "categories",
  initialState,
  extraReducers: {
    [fetchCategories.pending]: (state, action) => {
      state.fetching = true;
      state.currentRequestId = action.meta.requestId;
    },
    [fetchCategories.fulfilled]: (state, { payload }) => {
      state.fetching = false;
      state.items = payload.data.data;
      state.currentRequestId = undefined;
    },
    [fetchCategories.rejected]: (state, { payload }) => {
      state.error = payload;
      state.fetching = false;
      state.items = [];
      state.currentRequestId = undefined;
    },
  },
});

export default categoriesSlice.reducer;
