import { SortableElement } from "react-sortable-hoc";
import Skeleton from "react-loading-skeleton";
import classNames from "classnames";

import styles from "../Pages.module.scss";
import Media from "../../common/Media";
import Question from "../../common/Question";
import Article from "../../common/Article";
import Formation from "../../common/Formation";

export default SortableElement(
  ({
    item,
    showAllStatus,
    // showFiduciaryStatus,
    fetchedDataById,
    language,
    index,
    // hiddenFaqs,
    rubric,
  }) => {
    if (item.objectType === "QUESTION") {
      return (
        <div className={classNames("cell small-12")}>
          <div className={styles.qCardCont}>
            <Question
              key={item.id}
              rubric={rubric}
              item={item}
              index={index}
              edit={true}
            />
          </div>
        </div>
      );
    }
    if (item.objectType === "ARTICLE") {
      return (
        <div className={classNames("cell small-12")}>
          <div className={styles.cardCont}>
            {fetchedDataById.length > 0 &&
            fetchedDataById.filter((el) => el.id === item.id).length > 0 &&
            fetchedDataById.filter((el) => el.id === item.id)[0].object ? (
              <Article
                article={
                  fetchedDataById.length > 0 &&
                  fetchedDataById.filter((el) => el.id === item.id).length >
                    0 &&
                  fetchedDataById.filter((el) => el.id === item.id)[0].object
                }
                language={language}
                type="TYPE2"
                edit={true}
                showAllStatus={showAllStatus}
                question={item}
              />
            ) : (
              <Skeleton height={302} />
            )}
          </div>
        </div>
      );
    }
    if (item.objectType === "EVENT") {
      return (
        <div className={classNames("cell small-12")}>
          <div className={styles.cardCont}>
            {fetchedDataById.length > 0 &&
            fetchedDataById.filter((el) => el.id === item.id).length > 0 &&
            fetchedDataById.filter((el) => el.id === item.id)[0].object ? (
              <Formation
                formation={
                  fetchedDataById.length > 0 &&
                  fetchedDataById.filter((el) => el.id === item.id).length >
                    0 &&
                  fetchedDataById.filter((el) => el.id === item.id)[0].object
                }
                language={language}
                type={"TYPE2"}
                edit={true}
                showAllStatus={showAllStatus}
                question={item}
              />
            ) : (
              <Skeleton height={302} />
            )}
          </div>
        </div>
      );
    }
    if (item.objectType === "MEDIA") {
      return (
        <div
          className={
            fetchedDataById.length > 0 &&
            fetchedDataById.filter((el) => el.id === item.id).length > 0 &&
            fetchedDataById.filter((el) => el.id === item.id)[0].object &&
            (fetchedDataById.filter((el) => el.id === item.id)[0].object
              .docType === "PDF" ||
              fetchedDataById.filter((el) => el.id === item.id)[0].object
                .docType === "PPT")
              ? "cell small-12 medium-6"
              : "cell small-12"
          }
        >
          <div className={styles.cardCont}>
            {fetchedDataById.length > 0 &&
            fetchedDataById.filter((el) => el.id === item.id).length > 0 &&
            fetchedDataById.filter((el) => el.id === item.id)[0].object ? (
              <Media
                media={
                  fetchedDataById.filter((el) => el.id === item.id)[0].object
                }
                language={language}
                size={"lg"}
                type="TYPE2"
                edit={true}
                showAllStatus={showAllStatus}
                question={item}
              />
            ) : (
              <Skeleton height={302} />
            )}
          </div>
        </div>
      );
    }
  }
);
