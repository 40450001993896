import React from "react";

const IconBackArrow = () => (
  <svg
    width="14"
    height="13"
    viewBox="0 0 14 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.259444 6.71442L5.55299 11.9383C5.89429 12.2761 6.44627 12.2717 6.78408 11.9296C7.1219 11.5874 7.11842 11.0363 6.77536 10.6985L2.99241 6.96518L13.1294 6.96518C13.6108 6.96518 14 6.57601 14 6.09455C14 5.61309 13.6108 5.22388 13.1294 5.22388L2.99241 5.22388L6.77536 1.49056C7.11838 1.15274 7.121 0.601627 6.78408 0.259469C6.61345 0.0870791 6.38881 2.45072e-05 6.16418 2.45269e-05C5.94302 2.45462e-05 5.7219 0.0835982 5.55299 0.250788L0.259444 5.47468C0.0940152 5.63835 -5.53185e-07 5.86127 -5.32787e-07 6.09459C-5.12397e-07 6.32783 0.0931554 6.54985 0.259444 6.71442Z"
      fill="#29394D"
    />
  </svg>
);

export default IconBackArrow;
