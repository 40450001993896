import React from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";

import styles from "../Search.module.scss";
import Card from "./Card";

const Domains = ({ setCurrentDomain }) => {
  const domains = useSelector((state) => state.domains.items);
  return (
    <div className={`${styles.cardsList} grid-x `}>
      {domains.map((domain) => (
        <div
          key={domain.id}
          className={classNames(
            styles.cardCont,
            "cell small-12 medium-6 large-4"
          )}
        >
          <Card
            type={"domain"}
            setCurrentDomain={setCurrentDomain}
            data={domain}
          />
        </div>
      ))}
    </div>
  );
};

export default Domains;
