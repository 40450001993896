import React from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";

import Question from "../../common/Question";
import PageLoader from "../../common/PageLoader";
import { getRubric } from "../../../api";

const Questions = ({ rubricID }) => {
  const hiddenFaqs = useSelector((state) => state.faq.hiddenFaqs);
  const auth = useSelector((state) => state.auth);
  const { token } = auth;

  let { isLoading, data } = useQuery(
    ["getRubric", token, rubricID],
    async () => {
      if (token && rubricID) {
        try {
          const response = await getRubric({
            token,
            id: rubricID,
            community: auth.navCommunity ? auth.navCommunity.id : 9,
          });

          return response.data.data[0];
        } catch (e) {
          return null;
        }
      }
    }
  );

  if (isLoading) {
    return (
      <div className="cell medium-12 large-9 small-12">
        <PageLoader />
      </div>
    );
  }

  return (
    <div>
      {data &&
        data.questions &&
        data.questions.map((item, i) => {
          if (item.status !== "PUBLISHED" || hiddenFaqs.Q.includes(item.id)) {
            return null;
          }

          return (
            <Question
              key={item.id}
              rubric={data}
              item={item}
              index={i}
              search={true}
            />
          );
        })}
    </div>
  );
};

export default Questions;
