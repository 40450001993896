import React from "react";
import styles from "../Authors.module.scss";
import _ from "../../../i18n";
const AuthorCard = ({ data, lng, nbr }) => {
  return (
    <div className={styles.author}>
      <div className={styles.avatar}>
        <div
          className={styles.img}
          style={{ backgroundImage: `url(${data.user.avatar})` }}
        />
      </div>
      <div className={styles.info}>
        <h2>{`${data.user.firstName} ${data.user.lastName}`}</h2>
        <h4>
          {lng.toLowerCase() === "fr"
            ? data.headlineFr.title
            : lng.toLowerCase() === "en"
            ? data.headlineEn.title
            : data.headlineNl.title}
        </h4>

        <div className={styles.nbPages}>
          <span>
            <strong>{nbr}&nbsp;</strong> {_("solutions")}
          </span>
        </div>
      </div>
    </div>
  );
};

export default AuthorCard;
