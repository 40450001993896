import React, { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { ModalConfirm } from "tamtam-components";

import _ from "../../i18n";
import PagePopOver from "./PagePopOver";
import styles from "./Themes.module.scss";
import { removePage, removeTheme } from "../../api";

const ThemeItem = ({
  theme,
  afterPageSave,
  afterPageDelete,
  showSidebar,
  afterThemeDelete,
}) => {
  const language = useSelector((state) => state.params.language);
  const token = useSelector((state) => state.auth.token);
  const [themePopOver, setThemePopOver] = useState(false);
  const [pagePopOver, setPagePopOver] = useState(null);
  const [addPagePopOver, setAddPagePopOver] = useState(null);
  const [currentPage, setCurrentPage] = useState(null);
  const [isOpenThemeDeleteModal, setIsOpenThemeDeleteModal] = useState(false);
  const [isOpenPageDeleteModal, setIsOpenPageDeleteModal] = useState(false);
  const [deletingPage, setDeletingPage] = useState(false);
  const [deletingTheme, setDeletingTheme] = useState(false);

  const toggleEditThemePopOver = () => {
    setThemePopOver(!themePopOver);
  };

  const toggleEditPagePopOver = (pageId) => {
    pagePopOver === pageId ? setPagePopOver(null) : setPagePopOver(pageId);
  };
  const toggleAddPagePopOver = () => {
    setAddPagePopOver(!addPagePopOver);
  };

  const handleThemeDelete = async () => {
    try {
      setDeletingTheme(true);
      await removeTheme(token, theme.id);
      afterThemeDelete({ id: theme.id });
      setDeletingTheme(false);
      setIsOpenThemeDeleteModal(false);
    } catch (error) {
      setDeletingTheme(false);
      console.error(error);
    }
  };

  const handlePageDelete = async () => {
    try {
      setDeletingPage(true);
      await removePage(token, currentPage);
      afterPageDelete({ id: currentPage, themeId: theme.id });
      setDeletingPage(false);
      setIsOpenPageDeleteModal(false);
      setCurrentPage(null);
    } catch (error) {
      setDeletingPage(false);
      console.error(error);
    }
  };

  const titleAttr = `title${
    language.charAt(0).toUpperCase() + language.slice(1)
  }`;

  const renderPages = () => {
    return (
      <ul className="grid-x">
        {theme.pages &&
          theme.pages.map((page) => {
            return (
              <li
                key={`page-${page.id}`}
                className={`cell small-12 medium-6 large-4 ${styles.itemPage}`}
              >
                <div
                  className={`${styles.itemPage_header} ${
                    pagePopOver === page.id ? styles.active : ""
                  }`}
                >
                  {page[titleAttr]}
                  <div className={styles.itemPage_actions}>
                    <div
                      className={styles.actions_page_delete}
                      onClick={() => {
                        setCurrentPage(page.id);
                        setIsOpenPageDeleteModal(true);
                      }}
                    >
                      <i className="icon-ttp-trash"></i>
                    </div>
                    <div
                      className={`${styles.actions_page_show}`}
                      onClick={() => toggleEditPagePopOver(page.id)}
                    >
                      <i className="icon-ttp-chevron-down"></i>
                    </div>

                    <ModalConfirm
                      type="delete"
                      isOpen={isOpenPageDeleteModal}
                      onCancel={() => setIsOpenPageDeleteModal(false)}
                      onConfirm={() => handlePageDelete()}
                      inProcess={deletingPage}
                      title={_("modal_delete_title")}
                      text={_("modal_delete_text")}
                      labelNo={_("no")}
                      labelYes={_("yes")}
                      labelError={_("error")}
                    />
                  </div>

                  {pagePopOver === page.id && (
                    <div className={styles.itemPage_form}>
                      <PagePopOver
                        {...page}
                        themeId={theme.id}
                        closePopOver={() => setPagePopOver(null)}
                        afterPageSave={afterPageSave}
                      />
                    </div>
                  )}
                </div>
              </li>
            );
          })}
        <li
          className={`cell small-12 medium-6 large-4 ${styles.addPage_item} `}
        >
          <div
            className={`${styles.addPage_header}`}
            onClick={() => toggleAddPagePopOver()}
          >
            <i className="icon-ttp-plus"></i>
            <span>{_("add_page")}...</span>
            {addPagePopOver && (
              <div className={styles.addPage_form}>
                <PagePopOver
                  language={language}
                  themeId={theme.id}
                  closePopOver={() => setAddPagePopOver(null)}
                  afterPageSave={afterPageSave}
                />
              </div>
            )}
          </div>
        </li>
      </ul>
    );
  };

  return (
    <div className={`${styles.item} ${themePopOver ? styles.active : ""} `}>
      <div className={`${styles.item_header} `}>
        <div className={styles.item_header_left}>
          {theme[titleAttr]}
          {theme.isDefault === true || theme.isDefault === 1 ? (
            <span className={styles.default_tag}>Default</span>
          ) : null}
        </div>
        <div className={styles.item_actions}>
          <div className={styles.actions_edit} onClick={() => showSidebar()}>
            <i className="icon-ttp-edit"></i>
          </div>

          <div
            className={styles.actions_delete}
            onClick={() => setIsOpenThemeDeleteModal(true)}
          >
            <i className="icon-ttp-trash"></i>
          </div>
          <div
            className={`${styles.actions_show}`}
            onClick={() => toggleEditThemePopOver()}
          >
            <i className="icon-ttp-chevron-down"></i>
          </div>
          <ModalConfirm
            type="delete"
            isOpen={isOpenThemeDeleteModal}
            onCancel={() => setIsOpenThemeDeleteModal(false)}
            onConfirm={() => handleThemeDelete()}
            inProcess={deletingTheme}
            title={_("modal_delete_title")}
            text={_("modal_delete_text")}
            labelNo={_("no")}
            labelYes={_("yes")}
            labelError={_("error")}
          />
        </div>
      </div>
      {themePopOver && (
        <div className={styles.item_details}>
          <label className={styles.addPage_label}>{_("linked_pages")}</label>
          {renderPages()}
        </div>
      )}
    </div>
  );
};

export default ThemeItem;
