import React from "react";
import styles from "../Authors.module.scss";
import Question from "../../common/Question";
import _ from "../../../i18n";
const AuthorSolutions = ({ lng, data }) => {
  const cvAttr = `cv${lng.charAt(0).toUpperCase() + lng.slice(1)}`;
  return (
    <div className={`${styles.authorQuestions} grid-x`}>
      <div className={`${styles.authorBanner} cell medium-4 large-3 small-12`}>
        <div className={styles.avatar}>
          <div
            style={{
              backgroundImage: `url(${data.author.user.avatar})`,
            }}
            className={styles.main}
          />
          <div
            style={{
              backgroundImage: `url(${data.author.user.roles[0].organization.avatarUrl})`,
            }}
            className={styles.logo}
          ></div>
        </div>
        <h1>{`${data.author.user.firstName} ${data.author.user.lastName}`}</h1>
        <h2>
          {lng.toLowerCase() === "fr"
            ? data.author.headlineFr.title
            : lng.toLowerCase() === "en"
            ? data.author.headlineEn.title
            : data.author.headlineNl.title}
        </h2>
        <p>{data.author[cvAttr]}</p>
      </div>
      <div className={`${styles.solutionsList} cell medium-8 large-9 small-12`}>
        <h3>
          {data.questions.length} {_("solutions_published_by")}{" "}
          {`${data.author.user.firstName} ${data.author.user.lastName}`}
        </h3>
        <div className={styles.list}>
          {data.questions.map((item, index) => (
            <Question
              key={item.id}
              rubric={item.rubric}
              item={item}
              index={index}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default AuthorSolutions;
