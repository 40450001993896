import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import { toast } from "react-toastify";
import { ModalConfirm } from "tamtam-components";
import { arrayMoveImmutable } from "array-move";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";

import { APP_ENV, SELECT_STYLES } from "../../../config";
import { saveRubric, removeRubric, getTTPUser, getGroups } from "../../../api";
import {
  deleteRubric,
  fetchThemes,
  setAllowCreateTags,
  setBannerThemes,
  fetchBannerThemes,
} from "../../../store";
import {
  getTagName,
  getAuthorAllHeadlines,
  getAuthorHeadlines,
  convertDateToUTC,
} from "../../../utils";

import Button from "../../common/Button";
import Tabs from "../../common/Tabs";
import Loader from "../../common/Loader";
import Switch from "../../common/Switch/Switch";
import { IconTrash } from "../../common/Icons";
import Category from "./Category";
import Scope from "./Scope";
import Tag from "./Tag";
import AuthorSuggestion from "./AuthorSuggestion";
import ChainSuggestion from "./ChainSuggestion";
import SortableAuthorList from "./SortableAuthorList";
import Chain from "./Chain";
import GroupsSelect from "./GroupsSelect";
import Domain from "./Domain";
import Theme from "./Theme";
import Page from "./Page";
import Status from "./Status";
import _ from "../../../i18n";
import styles from "./FAQ.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import { useQueryClient } from "react-query";

const languages = [
  { value: "fr", label: _("french") },
  { value: "nl", label: _("dutch") },
  { value: "en", label: _("english") },
];

const DATE_FORMAT = "DD-MM-YYYY HH:mm";
const API_DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";

export default function RubricForm(props) {
  const { themeID, pageID } = useParams();

  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const themes = useSelector((state) => state.themes.items);
  const currentDomain = useSelector((state) => state.domains.currentDomain);
  const currentRubric = useSelector((state) => state.faq.currentRubric);
  const mediaCategories = useSelector((state) => state.categories.media_items);
  const faqs = useSelector((state) => state.faq.items);
  const auth = useSelector((state) => state.auth);
  const { token, user, navCommunity } = auth;
  const language = useSelector((state) => state.params.language);
  const [displayedLanguage, setDisplayedLanguage] = useState(language);
  const [title, setTitle] = useState({ en: "", fr: "", nl: "" });
  const [introduction, setIntroduction] = useState({ en: "", fr: "", nl: "" });
  const [introductionFr, setIntroductionFr] = useState("");
  const [introductionNl, setIntroductionNl] = useState("");
  const [introductionEn, setIntroductionEn] = useState("");
  const [community, setCommunity] = useState(null);
  const [parent, setParent] = useState(null);
  const [category, setCategory] = useState(null);
  const [scope, setScope] = useState(["PUBLIC"]);
  const [status, setStatus] = useState("DRAFT");
  const [publishedAt, setPublishedAt] = useState(null);
  const [groups, setGroups] = useState([]);
  const [theme, setTheme] = useState(null);
  const [domain, setDomain] = useState(null);
  const [pages, setPages] = useState([]);
  const [tags, setTags] = useState([]);
  const [source, setSource] = useState({
    fr: { title: "", url: "" },
    nl: { title: "", url: "" },
    en: { title: "", url: "" },
  });
  // const [icon, setIcon] = useState(null);
  const [isPrivate, setIsPrivate] = useState(false);
  const [privateGroups, setPrivateGroups] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  // const [isOpenIconList, setIsOpenIconList] = useState(false);
  const [parentOptions, setParentOptions] = useState([]);
  const [authors, setAuthors] = useState([]);
  const [chain, setChain] = useState(null);
  const urlAttr = `url${language.charAt(0).toUpperCase() + language.slice(1)}`;
  const dispatch = useDispatch();

  const titleAttr = `title${
    language.charAt(0).toUpperCase() + language.slice(1)
  }`;
  const nameAttr = `name${
    language.charAt(0).toUpperCase() + language.slice(1)
  }`;

  languages.forEach(function (item, i) {
    if (item.value === language) {
      languages.splice(i, 1);
      languages.unshift(item);
    }
  });

  const communityOptions = [];
  if (user && user.communities) {
    user.communities.map((com) => {
      communityOptions.push({
        label: com.name,
        value: com.id,
      });
    });
  }

  useEffect(() => {
    if (navCommunity && !community) {
      setCommunity({
        value: navCommunity.id,
        label: navCommunity.name,
      });
      if (themes.length === 0) {
        dispatch(fetchThemes({ communityId: navCommunity.id }));
      }
    }
  }, [navCommunity]);

  useEffect(() => {
    if (currentRubric && !props.isNew) {
      resetRubric();
      setTitle({
        fr: currentRubric.titleFr,
        en: currentRubric.titleEn,
        nl: currentRubric.titleNl,
      });
      setIntroduction({
        fr: currentRubric.introductionFr,
        en: currentRubric.introductionEn,
        nl: currentRubric.introductionNl,
      });
      setIntroductionFr(currentRubric.introductionFr);
      setIntroductionNl(currentRubric.introductionNl);
      setIntroductionEn(currentRubric.introductionEn);
      if (currentRubric.organization) {
        setCommunity({
          value: currentRubric.organization.id,
          label: currentRubric.organization.name,
        });
      }
      if (currentRubric.category) {
        setCategory({
          id: currentRubric.category.id,
          name: currentRubric.category[nameAttr],
        });
      } else {
        setCategory(null);
      }
      if (currentRubric.isPrivate) {
        setIsPrivate(true);
        let customFilter = [
          {
            property: "id",
            value: currentRubric.privateGroups,
            operator: "in",
          },
        ];
        getGroups({
          token: auth.token,
          clientId: currentRubric.organization
            ? currentRubric.organization.id
            : null,
          customFilter,
        }).then((result) => {
          setPrivateGroups(result.data.data);
        });
      } else {
        setIsPrivate(false);
        setPrivateGroups([]);
      }
      if (currentRubric.domains && currentRubric.domains.length > 0) {
        setDomain(
          currentRubric.domains.map((item) => {
            return {
              value: item.id,
              label: item[titleAttr],
            };
          })
        );
      } else {
        setDomain(null);
      }
      if (currentRubric.theme) {
        setTheme({
          id: currentRubric.theme.id,
          title: currentRubric.theme[titleAttr],
        });
      } else {
        setTheme(null);
      }
      if (currentRubric.pages && currentRubric.pages.length > 0) {
        setPages(
          currentRubric.pages.map((page) => {
            const pageTitle =
              page[titleAttr] ||
              page["titleFr"] ||
              page["titleEn"] ||
              page["titleNl"];
            return {
              id: page.id,
              title: pageTitle,
            };
          })
        );
      } else {
        setPages([]);
      }
      if (currentRubric.parent) {
        setParent({
          value: currentRubric.parent.id,
          label: currentRubric.parent[titleAttr],
        });
      } else {
        setParent(null);
      }
      setScope(currentRubric.csScope);
      setStatus(currentRubric.status);
      if (currentRubric.publishedAt) {
        setPublishedAt(
          convertDateToUTC(
            currentRubric.publishedAt,
            API_DATE_FORMAT,
            DATE_FORMAT
          )
        );
      }
      if (currentRubric.tags) {
        let tabTags = currentRubric.tags.map((tag) => {
          let label = getTagName(tag, language);
          let tmp = {
            label,
            name: tag[nameAttr],
            value: tag.id,
            tag: {
              id: tag.id,
              nameEn: tag.nameEn,
              nameFr: tag.nameFr,
              nameNl: tag.nameNl,
            },
          };
          return tmp;
        });
        setTags(tabTags);
      }
      let sortedAuthors = currentRubric.author
        ? [...currentRubric.author].sort((a, b) => a.priority - b.priority)
        : [];
      if (currentRubric.chain && currentRubric.chain.length === 1) {
        let chain = currentRubric.chain[0];
        let avatar = null,
          avatarUrl = null;
        if (chain.mediaChain) {
          let media = chain.mediaChain.filter(
            (item) => item.language === language
          );
          if (media && media.length === 1) {
            avatar = media[0].avatar;
            avatarUrl = media[0].avatarUrl;
          }
        }
        setChain({ ...chain, avatar, avatarUrl });
      } else {
        setChain(null);
      }
      sortedAuthors = sortedAuthors.map((author) => {
        return { ...author, isAuthor: true };
      });
      sortedAuthors = [...sortedAuthors];
      setAuthors(sortedAuthors);
      if (currentRubric.source) {
        setSource(currentRubric.source);
      } else {
        setSource({
          fr: { title: "", url: "" },
          nl: { title: "", url: "" },
          en: { title: "", url: "" },
        });
      }
    } else {
      if (auth.navCommunity) {
        setCommunity({
          value: auth.navCommunity.id,
          label: auth.navCommunity.name,
        });
      }
    }
  }, [currentRubric]);

  // let parentOptions = [];
  useEffect(() => {
    if (faqs) {
      setParentOptions(
        faqs.map((faq) => {
          return { value: faq.id, label: faq[titleAttr] };
        })
      );
    }
  }, [faqs]);

  const handlePaste = (event, cleanData, maxCharCount, core) => {
    let content = cleanData.replace(/\s+style="[^"]*"/gi, "");
    return content.replace(/(<\/?h)([0-1])/gi, "$12");
  };

  const handleCancel = () => {
    props.closeSidebar();
  };

  const onSortAuthorsEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex === newIndex) {
      return;
    }

    let oldPriority = authors[oldIndex].priority;
    let newPriority = authors[newIndex].priority;

    let newAuthorsOrder = arrayMoveImmutable(authors, oldIndex, newIndex);
    newAuthorsOrder[newIndex] = {
      ...newAuthorsOrder[newIndex],
      priority: newPriority,
    };
    newAuthorsOrder[oldIndex] = {
      ...newAuthorsOrder[oldIndex],
      priority: oldPriority,
    };

    setAuthors(newAuthorsOrder);
  };

  const validate = () => {
    let errors = [];

    if (title[language].trim().length === 0) {
      errors.push(_("validate_title"));
    }

    if (!community) {
      errors.push(_("validate_community"));
    }

    if (!category) {
      errors.push(_("validate_category"));
    }

    if (!theme) {
      errors.push(_("validate_theme"));
    }

    if (pages.length === 0) {
      errors.push(_("validate_page"));
    }

    if (!domain) {
      errors.push(_("validate_domain"));
    }

    if (tags.length > 0) {
      let emptyTagName = false;
      tags.forEach((tag) => {
        if (
          !tag["__isNew__"] &&
          (tag.tag.nameFr.length === 0 ||
            tag.tag.nameNl.length === 0 ||
            tag.tag.nameEn.length === 0)
        ) {
          emptyTagName = true;
        }
      });
      if (emptyTagName) {
        errors.push(_("validate_tag_name"));
      }
    }

    if (isPrivate && privateGroups.length === 0) {
      errors.push(_("validate_private_groups"));
    }

    return errors;
  };

  const handleSave = async () => {
    let errors = validate();

    if (errors && errors.length > 0) {
      let ErrorsContainer = ({ errors }) => (
        <div>
          <span>{_("errors") + " :"}</span>
          <ul>
            {errors.map((e, i) => (
              <li key={i}>{e}</li>
            ))}
          </ul>
        </div>
      );
      toast.error(<ErrorsContainer errors={errors} />);
      return;
    }

    const formatedTags = [];
    const nameAttr = `name${
      language.charAt(0).toUpperCase() + language.slice(1)
    }`;
    tags.forEach((tag) => {
      if (tag["__isNew__"]) {
        let newTag = {};
        newTag[nameAttr] = tag.value;
        formatedTags.push(newTag);
      } else {
        formatedTags.push({ id: tag.value });
      }
    });

    let selectedGroups = groups.map((item) => {
      return item.id;
    });

    let tabPrivateGroups = privateGroups.map((item) => {
      return item.id;
    });

    let data = {
      title,
      community: community.value,
      csScope: scope,
      groups: selectedGroups,
      tags: formatedTags,
      parent: parent ? parent.value : "",
      authors,
      chains: chain ? [chain] : [],
      categoryId: category.id,
      themeId: theme.id,
      domain,
      pages,
      status,
      publishedAt: publishedAt
        ? convertDateToUTC(publishedAt, DATE_FORMAT, API_DATE_FORMAT)
        : "",
      isPrivate,
      privateGroups: tabPrivateGroups,
      introduction: {
        fr: introductionFr,
        nl: introductionNl,
        en: introductionEn,
      },
      source: JSON.stringify(source),
    };

    if (currentRubric) {
      data.id = currentRubric.id;
    }

    try {
      setIsSaving(true);
      const response = await saveRubric(token, data);
      setIsSaving(false);
      resetAfterSaveRubric();

      const urlAttr = `url${
        language.charAt(0).toUpperCase() + language.slice(1)
      }`;
      const saveData = {
        id: response.data.data.id,
        domainUrl: response.data.data.domains[0][urlAttr],
        themeId: theme.id,
        pageId: pages[0].id,
      };
      if (props.afterSave) {
        props.afterSave(saveData);
      }
      props.closeSidebar();
    } catch (e) {
      setIsSaving(false);
    }
  };

  const resetAfterSaveRubric = () => {
    setTitle({ en: "", fr: "", nl: "" });
    setIntroduction({ en: "", fr: "", nl: "" });
    setIntroductionFr("");
    setIntroductionNl("");
    setIntroductionEn("");
    setIsPrivate(false);
    setPrivateGroups([]);
  };

  const resetRubric = () => {
    setTitle({ en: "", fr: "", nl: "" });
    setIntroduction({ en: "", fr: "", nl: "" });
    setIntroductionFr("");
    setIntroductionNl("");
    setIntroductionEn("");
    setScope(["PUBLIC"]);
    setStatus("DRAFT");
    setGroups([]);
    setTags([]);
    setCommunity(null);
    setParent(null);
    setPublishedAt(null);
    setCategory(null);
    setSource({
      fr: { title: "", url: "" },
      nl: { title: "", url: "" },
      en: { title: "", url: "" },
    });
    setIsPrivate(false);
    setPrivateGroups([]);
  };

  const handleDelete = () => {
    setIsDeleting(true);
    removeRubric(
      token,
      currentRubric.id,
      currentRubric.chain ? currentRubric.chain[0] : null
    )
      .then(() => {
        dispatch(deleteRubric(currentRubric.id));
        setIsDeleting(false);
        setIsOpenDeleteModal(false);
        if (themeID && pageID) {
          dispatch(setBannerThemes([]));
          dispatch(
            fetchBannerThemes({
              domain: currentDomain.id,
              isEditable: currentDomain.isEditable,
              rubrics: true,
            })
          );
          queryClient.invalidateQueries("getPageById");
          navigate(
            `/${language}/${currentDomain[urlAttr]}/themes/${themeID}/pages/${pageID}`
          );
          props.closeSidebar();
        } else {
          window.location.reload();
        }
      })
      .catch((err) => {
        console.error(err);
        setIsDeleting(false);
        setIsOpenDeleteModal(false);
        toast.error(_("rubric_delete_error"));
      });
  };

  useEffect(() => {
    if (
      !currentRubric &&
      auth.user &&
      authors.length === 0 &&
      community &&
      props.isOpened
    ) {
      handleEmptyAuthors();
    }
  }, [community, props.isOpened]);

  const handleEmptyAuthors = async () => {
    const authorTitle =
      auth.user.defaultSignature && auth.user.defaultSignature.title
        ? auth.user.defaultSignature.title
        : auth.user.firstName + " " + auth.user.lastName;
    let head = getAuthorHeadlines(auth.user, language);

    if (community) {
      const userResponse = await getTTPUser({
        userId: auth.user.id,
        token: auth.token,
      });
      const user = userResponse.data ? userResponse.data[0] : null;
      const headlineAttr = `headline${
        language.charAt(0).toUpperCase() + language.slice(1)
      }`;

      const blogRole = user.blogRole
        ? user.blogRole.filter(
            (item) => item.organizationId === community?.value
          )[0]
        : null;
      if (blogRole && blogRole[headlineAttr]) {
        head = blogRole[headlineAttr].title ? blogRole[headlineAttr].title : "";
      }
      if (user && user.communities) {
        let selectedCommunity = user.communities.filter(
          (com) => com.id === community?.value
        );
        if (selectedCommunity.length > 0) {
          if (
            selectedCommunity[0] &&
            selectedCommunity[0].blogs &&
            selectedCommunity[0].blogs.length > 0 &&
            selectedCommunity[0].blogs[0].preferences &&
            selectedCommunity[0].blogs[0].preferences.allowCreateTags == 1
          ) {
            dispatch(setAllowCreateTags(true));
          }
        }
      }
    }
    let author = {
      id: auth.user.id,
      signature: {
        title: authorTitle,
        head: head,
      },
      avatar: auth.user.avatarUrl || null,
      avatarUrl: auth.user.avatarUrl || null,
      enableAvatar: true,
      priority: 0,
      firstName: auth.user.firstName,
      lastName: auth.user.lastName,
      headlines: getAuthorAllHeadlines(auth.user),
      isAuthor: true,
    };
    setAuthors([author]);
  };

  const handleDeleteAuthor = (author) => {
    let index = -1;
    for (let i = 0; i < authors.length; i++) {
      if (authors[i].id === author.id) {
        index = i;
        break;
      }
    }

    let newAuthors = authors.concat(); // For immutability
    // author.status = "DELETED";
    let newAuthor = Object.assign({}, author, { status: "DELETED" });

    if (index !== -1) {
      newAuthors[index] = newAuthor;
    }

    setAuthors(newAuthors);
  };

  const handleChangeAuthor = (newAuthor) => {
    let newAuthors = [...authors];

    let index = -1;
    for (let i = 0; i < newAuthors.length; i++) {
      if (
        newAuthors[i].id === newAuthor.id &&
        newAuthors[i].isAuthor === newAuthor.isAuthor
      ) {
        index = i;
        break;
      }
    }

    if (index === -1) {
      newAuthors.push(newAuthor);
    } else {
      newAuthors[index] = newAuthor;
    }
    setAuthors(newAuthors);
  };

  const handleChangeIntro = (c, lang) => {
    eval("setIntroduction" + lang.charAt(0).toUpperCase() + lang.slice(1))(c);
  };

  return (
    <div className={styles.container}>
      <div className={styles.authorsContainer}>
        <div>
          <p className={styles.authorTitle}>{_("chain")}</p>
          <div className={styles.chainContainer}>
            {chain ? (
              <Chain
                language={language}
                author={chain}
                onDelete={() => setChain(null)}
              />
            ) : (
              <ChainSuggestion
                selectedCommunity={community}
                selectedLanguage={language}
                chain={chain}
                onChainChange={(e) => setChain(e)}
              />
            )}
          </div>
        </div>

        <p className={styles.authorTitle}>{_("authors")}</p>
        <SortableAuthorList
          language={props.selectedLanguage}
          auth={auth}
          onChangeAuthor={(newAuthor) => handleChangeAuthor(newAuthor)}
          onDeleteAuthor={(author) => handleDeleteAuthor(author)}
          onSortEnd={onSortAuthorsEnd}
          helperClass={styles.sortableHelper}
          lockAxis="y"
          pressDelay={200}
          authors={authors}
        />

        <div className={styles.addAuthor}>
          <AuthorSuggestion
            selectedCommunity={community}
            selectedLanguage={language}
            authors={authors}
            onAuthorChange={handleChangeAuthor}
          />
        </div>
      </div>

      <div className={styles.rightContainer}>
        <Tabs
          theme="wtheme"
          onClickTab={(e) => setDisplayedLanguage(languages[e].value)}
        >
          {languages.map((lng) => (
            <div
              key={`langtab${lng.value}`}
              label={lng.label}
              icon={`https://tamtam.s3-eu-west-1.amazonaws.com/cdn/widget/${APP_ENV}/img/flags/${lng.value.toUpperCase()}.jpg`}
            >
              <div className={styles.langTab}>
                <div className={styles.formRow}>
                  <label className={styles.configLabel}>
                    {_("rubric_form_title")} {lng.value}
                  </label>
                  <input
                    className={styles.formInput}
                    value={title[lng.value]}
                    onChange={(e) => {
                      setTitle({
                        ...title,
                        [lng.value]: e.target.value,
                      });
                    }}
                  />
                </div>

                <label className={styles.configLabel}>
                  {_("source")} {lng.value}
                </label>
                <div className={styles.configRow}>
                  <div className={styles.configColumn}>
                    <input
                      className={styles.formInput}
                      value={source[lng.value].title}
                      placeholder={_("source_title")}
                      onChange={(e) => {
                        setSource({
                          ...source,
                          [lng.value]: {
                            ...source[lng.value],
                            title: e.target.value,
                          },
                        });
                      }}
                    />
                  </div>
                  <div className={styles.configColumn}>
                    <input
                      className={styles.formInput}
                      value={source[lng.value].url}
                      placeholder={_("source_url")}
                      onChange={(e) => {
                        setSource({
                          ...source,
                          [lng.value]: {
                            ...source[lng.value],
                            url: e.target.value,
                          },
                        });
                      }}
                    />
                  </div>
                </div>

                <label className={styles.configLabel}>
                  {_("rubric_form_intro")} {lng.value}
                </label>
                <div className={`ttp-helpeditor ${styles.editorContainer} `}>
                  <SunEditor
                    setOptions={{
                      attributesWhitelist: {
                        // all: "style",
                        input: "checked",
                      },
                      stickyToolbar: false,
                      height: "auto",
                      minHeight: "200px",
                      showPathLabel: false,
                      resizingBar: false,
                      imageUrlInput: false,
                      imageWidth: "75%",
                      formats: [
                        "p",
                        "blockquote",
                        "h2",
                        "h3",
                        "h4",
                        "h5",
                        "h6",
                      ],
                      buttonList: [
                        [
                          "undo",
                          "redo",
                          "formatBlock",
                          "bold",
                          "underline",
                          "italic",
                          "strike",
                          "outdent",
                          "indent",
                          "align",
                          "list",
                          "table",
                          "link",
                          "image",
                          "video",
                          "fullScreen",
                          "showBlocks",
                        ],
                      ],
                    }}
                    setContents={introduction[lng.value]}
                    onChange={(c) => handleChangeIntro(c, lng.value)}
                    onPaste={handlePaste}
                  />
                </div>
              </div>
            </div>
          ))}
        </Tabs>

        <div className={styles.configRow}>
          <div className={styles.configColumn}>
            <label className={styles.configLabel}>{_("category")}</label>
            <Category
              setCategory={setCategory}
              category={category}
              selectStyles={SELECT_STYLES}
              language={language}
            />
          </div>

          <div className={styles.configColumn}>
            <label className={styles.configLabel}>{_("community")}</label>
            <Select
              isClearable
              styles={SELECT_STYLES}
              options={communityOptions}
              isSearchable={false}
              value={community}
              onChange={(e) => {
                setCommunity(e);
                dispatch(fetchThemes({ communityId: e.value }));
              }}
            />
          </div>
        </div>

        <div className={styles.configRow}>
          <div className={styles.configColumn}>
            <label className={styles.configLabel}>{_("theme")}</label>
            <Theme
              selectStyles={SELECT_STYLES}
              language={language}
              theme={theme}
              setTheme={setTheme}
              setPages={setPages}
              setDomain={setDomain}
              community={community}
            />
          </div>

          <div className={styles.configColumn}>
            <label className={styles.configLabel}>{_("page")}</label>
            <Page
              theme={theme}
              setPages={setPages}
              pages={pages}
              selectStyles={SELECT_STYLES}
              language={language}
              community={community}
            />
          </div>
        </div>

        <div className={styles.configRow}>
          <div className={styles.configColumn}>
            <label className={styles.configLabel}>{_("domain")}</label>
            <Domain
              theme={theme}
              setDomain={setDomain}
              domain={domain}
              selectStyles={SELECT_STYLES}
              language={language}
            />
          </div>

          <div className={styles.configColumn}>
            <label className={styles.configLabel}>{_("tag")}</label>
            <Tag
              selectStyles={SELECT_STYLES}
              language={language}
              displayedLng={displayedLanguage}
              tags={tags}
              setTags={setTags}
            />
          </div>
        </div>

        <div className={styles.configRow}>
          <div className={styles.configColumn}>
            <div className={styles.switchRow}>
              <label className={styles.configLabel}>{_("is_private")}</label>
              <Switch isChecked={isPrivate} onChange={(e) => setIsPrivate(e)} />
            </div>
          </div>
          {isPrivate && (
            <div className={styles.configColumn}>
              <label className={styles.configLabel}>
                {_("subscribers_groups")}
              </label>
              <GroupsSelect
                isMulti={true}
                selectedGroups={privateGroups}
                onChange={(groups) => setPrivateGroups(groups)}
                auth={auth}
                community={community}
                selectStyles={SELECT_STYLES}
              />
            </div>
          )}
        </div>

        <div className={styles.configRow}>
          <div className={styles.configColumn}>
            <label className={styles.configLabel}>{_("status")}</label>
            <Status
              status={status}
              setStatus={setStatus}
              publishedAt={publishedAt}
              setPublishedAt={setPublishedAt}
            />
          </div>

          <div className={styles.configColumn}>
            <label className={styles.configLabel}>{_("scope")}</label>
            <Scope
              scope={scope}
              setScope={setScope}
              setGroups={setGroups}
              groups={groups}
              auth={auth}
              community={community}
              selectStyles={SELECT_STYLES}
              parent={parent}
            />
          </div>
        </div>

        <div className={styles.formControls}>
          <Button onClick={() => handleCancel()} variant="default">
            {_("cancel")}
          </Button>

          <div className={styles.actions}>
            {currentRubric && (
              <span onClick={() => setIsOpenDeleteModal(true)}>
                <IconTrash size={18} />
              </span>
            )}
            {isSaving ? (
              <Button
                variant="primary"
                style={{ paddingTop: "15px", paddingBottom: "15px" }}
                className={styles.okBtn}
              >
                <Loader
                  style={{
                    height: "10px",
                  }}
                  color={"#fff"}
                />
              </Button>
            ) : (
              <Button
                onClick={() => handleSave()}
                variant="primary"
                className={styles.okBtn}
              >
                {_("save")}
              </Button>
            )}
          </div>
        </div>
      </div>
      <ModalConfirm
        type="delete"
        isOpen={isOpenDeleteModal}
        inProcess={isDeleting}
        onCancel={() => setIsOpenDeleteModal(false)}
        onConfirm={handleDelete}
        title={_("modal_delete_title")}
        text={_("modal_delete_text")}
        labelNo={_("no")}
        labelYes={_("yes")}
      />
    </div>
  );
}
