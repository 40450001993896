const faqEn = {
  save: "Save",
  cancel: "Cancel",
  english: "English",
  french: "French",
  dutch: "Dutch",
  title: "Frequently Asked Questions",
  subtitle: "Welcome to Tamtam Help Center",
  add_title: "Add a question",
  rubric: "Rubric",
  rubrics: "Rubrics",
  rubric_add_headline: "Add rubric",
  rubric_edit_headline: "Edit rubric",
  rubric_form_title: "Title",
  rubric_form_intro: "Introduction",
  rubric_parent: "Parent",
  appName: "Application",
  faq_add_headline: "Add a question related to the rubric",
  faq_edit_headline: "Edit a question related to the rubric",
  faq_status: "Status",
  faq_form_anwser: "Answer",
  helpful: "Was this helpful?",
  yes: "Yes",
  no: "No",
  add_question: "Add question",
  edit_question: "Edit question",
  question: "Question",
  modal_delete_title: "Delete confirmation",
  modal_delete_text: "Are you sure you want to delete this item?",
  rubric_delete_error: "An error occured!",
  save_order: "Save order",
  home_title: "Help center",
  home_sub_title: "How can we help you today?",
  home_need_help: "Need help?",
  home: "Home",
  loading: "Loading ...",
  type_author: "Type author name ...",
  loading_suggestions: "Loading suggestions ...",
  avatars: "Avatars",
  authors: "Authors",
  errors: "Errors",
  validate_title: "You must provide a title.",
  validate_category: "You must select a category",
  validate_community: "You must select a community",
  validate_icon: "You must select an icon",
  library_page_title: "Library",
  library_page_subtitle: "List of shared rubrics",
  type_of_sharing: "Type of sharing",
  shared_on: "Shared on",
  shared_with: "Shared with",
  share_with: "Share with",
  rubrics_to_share: "Rubrics to share",
  add_chain: "Add chain",
  chain: "Chain",
  home_form_button: "Follow the guide",
  search_placeholder: "Search ...",
  back: "Back",
  select_rubric: "Select a rubric",
  need_help: "Need help",
  follow_guide: "Follow the guide to find answers to all your questions",
  searching_results: "Searching for results...",
  no_results_found: "No results found",
  suggest_answer: "Suggest Answer",
  suggestion_success:
    "Thank you for your suggestion we will take it into consideration",
  suggestion_exists:
    "You already suggested an answer to this question (Click to see it)",
  update_suggestion: "Update suggestion",
  individuals: "Individuals",
  companies: "Companies",
  independent_liberal: "Self-employed and Liberal Professions",
  experts_partners: "Experts & Partners",
  ASBL: "ASBL",
  slug_individuals: "individuals",
  slug_companies: "companies",
  slug_independent_liberal: "self-employed-and-liberal-professions",
  slug_experts_partners: "experts-partners",
  slug_asbl: "asbl",
  chains: "Chains",
  "Event brings together the best content.":
    "Event brings together the best content.",
  "solution rubrics published by this channel":
    "solution rubrics published by this channel",
  show_full_description: "Read more",
  see_chain: "see the channel",
  solutions_published_by: "Solutions published by",
  solutions: "solutions",
  source_title: "Source title",
  source_url: "Source url",
  select_community: "Select a community ...",
  category: "Category",
  select_category: "Select a category ...",
  theme: "Theme",
  select_theme: "Default theme ...",
  page: "Page",
  select_page: "Select a page ...",
  tag_modal_title: "Tag translation",
  tag_name: "Name",
  validate_tag_name: "You must provide the translation of the tags",
  filter_results: "Filter results",
  clear_filter: "Clear Filter",
  search_results: "Search results",
  clear_search: "Clear Search",
  add_content: "Add content",
  add_content_sub:
    "Principium autem unde latius se funditabat, emersit ex negotio tali.",
  modal_save_order: "Confirmation of changes",
  modal_save_order_text: "Are you sure you want to save these changes?",
  fff_publications: "FFF News",
  selected_medias: "Selected Medias",
  disponible_medias: "Available medias",
  selected_articles: "Selected Articles",
  disponible_articles: "Available items",
  selected_formations: "Selected formations",
  disponible_formations: "Available trainings",
  read_article: "Read article",
  read_more: "READ MORE",
  read_more_details: "More details",
  download: "Download",
  privacy: "Privacy",
  privacy_rubrics: "Privacy Rubrics :",
  published_at: "Published at",
  visible_in_footer: "Visible in footer",
  add_rubric: "Add a rubric",
  activated: "Activated",
  deactivated: "Disabled",
  description: "Description",
  functional_cookies: "Functional Cookies",
  optional_cookies: "Optional Cookies",
  terms_of_use: "Terms of Use",
  nothing_found: "Nothing found...",
  library: "Library",
  share: "Share",
  ALL_COLLABORATORS: "All collaborators",
  ALL_CLIENTS: "All clients",
  ALL_CONTACTS: "All contacts",
  SPEC_COLLABORATOR: "Specific collaborators",
  SPEC_CLIENT: "Specific clients",
  SPEC_CONTACT: "Specific contacts",
  page_has_no_rubrics: "This page does not have any published rubrics",
  domain_has_no_themes: "This domain does not have any published themes",
  theme_has_no_pages: "This theme does not have any published pages",
  themes_and_pages: "Themes & Pages",
  themes_and_pages_list: "LIST OF THEMES AND PAGES",
  themes_and_pages_list_subtitle: "ADD OR CONFIGURE THEMES AND PAGES",
  add: "Add",
  linked_pages: "Pages :",
  change_image: "Change image",
  dropzone_text:
    "Drag and drop files here, or click to select the files to download.",
  invalid_theme_title: "Invalid theme title",
  invalid_private_groups: "You must specify groups for subscribers",
  select_icon: "Select icon",
  title_fr: "Title Fr",
  title_en: "Title En",
  title_nl: "Title Nl",
  is_private: "For subscribers only",
  subscribers_groups: "Subscriber groups",
  invalid_domain_title: "Invalid domain title",
  invalid_theme_domain: "Select theme domain",
  change_domain: "Change the domain",
  add_domain: "Add a domain",
  change_theme: "Change the theme",
  add_theme: "Add a theme",
  theme_isdefault: "Default theme",
  domain: "Domain",
  is_editable: "Is Editable",
  add_page: "Add Page",
  themes: "Themes",
  domains: "Domains",
  domain_title: "LIST OF DOMAINS",
  domain_sub_title: "ADD OR CONFIGURE DOMAINS",
};
export default faqEn;
