import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import { toast } from "react-toastify";
import { useQueryClient } from "react-query";

import Button from "../../common/Button";
import Loader from "../../common/Loader";
import Tabs from "../../common/Tabs";
import Switch from "../../common/Switch/Switch";
import { savePage } from "../../../store";
import _ from "../../../i18n";

import GroupsSelect from "./GroupsSelect";
import SidebarIcon from "./SidebarIcon";
import styles from "./FAQ.module.scss";

const languages = [
  { value: "fr", label: _("french") },
  { value: "nl", label: _("dutch") },
  { value: "en", label: _("english") },
];

const selectStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: state.isDisabled ? "#e6e6e6" : "#fff",
    boxShadow: "none",
    border: state.isFocused ? "1px solid #2495E1" : "1px solid #CED4DB",
    "&:hover": {
      borderColor: state.isFocused ? "#18A0FB" : "#DFE2E6",
    },
    padding: 0,
  }),
  placeholder: (provided, state) => ({
    ...provided,
    fontSize: "12px",
    lineHeight: "14px",
    color: "#6D7F92",
    fontWeight: 400,
  }),
  menuList: (provided, state) => ({
    ...provided,
    paddingTop: "0",
    paddingBottom: "0",
  }),
  menu: (provided, state) => ({
    ...provided,
    borderRadius: "5px",
    backgroundColor: "#FFFFFF",
    overflow: "hidden",
  }),
  option: (provided, state) => ({
    ...provided,
    textAlign: "left",
    fontSize: "12px",
    lineHeight: "14px",
    color: state.isSelected ? "#FFFFFF" : "#6D7F92",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
  }),
  singleValue: (provided, state) => ({
    ...provided,
    fontSize: "14px",
    lineHeight: "16px",
    color: "#29394D",
  }),
};

export default function ThemePopOver(props) {
  const { community, theme, selectedPages, language } = props;
  const dispatch = useDispatch();
  const [title, setTitle] = useState({ en: "", fr: "", nl: "" });
  const [pageCommunity, setPageCommunity] = useState(community);
  const [icon, setIcon] = useState(null);
  const [isOpenIconList, setIsOpenIconList] = useState(false);
  let themes = useSelector((state) => state.themes.items);
  const [pageTheme, setPageTheme] = useState(theme);
  const [isPrivate, setIsPrivate] = useState(false);
  const [privateGroups, setPrivateGroups] = useState([]);
  const pageIsSaving = useSelector((state) => state.themes.pageIsSaving);
  const auth = useSelector((state) => state.auth);
  const { user } = auth;
  const { communities } = user;
  const queryClient = useQueryClient();
  useEffect(() => {
    setPageTheme(theme);
  }, [theme]);

  const handleThemeChange = (e) => {
    setPageTheme(e);
  };

  const titleAttr = `title${
    language.charAt(0).toUpperCase() + language.slice(1)
  }`;

  const customFilter = (option, searchText) => {
    return option.data.title.toLowerCase().includes(searchText.toLowerCase());
  };
  let themeOptions = [];
  themes.map((theme, index) => {
    const themeTitle =
      theme[titleAttr] ||
      theme["titleFr"] ||
      theme["titleEn"] ||
      theme["titleNl"];
    themeOptions.push({
      id: theme.id,
      title: themeTitle,
    });
  });

  const communityOptions = [];
  communities.map((com) => {
    communityOptions.push({
      value: com.id,
      label: com.name,
    });
  });

  const handleTitleChange = (e, lng) => {
    let tmp = { ...title };
    tmp[lng] = e.target.value;
    setTitle(tmp);
  };

  const validate = () => {
    let errors = [];

    if (
      title.fr.trim().length === 0 ||
      title.en.trim().length === 0 ||
      title.nl.trim().length === 0
    ) {
      errors.push(_("validate_page_title"));
    }

    if (!pageCommunity) {
      errors.push(_("validate_community"));
    }

    if (isPrivate && privateGroups.length === 0) {
      errors.push(_("validate_private_groups"));
    }

    return errors;
  };

  const handleClose = () => {
    props.closePopOver();
    setTitle({ en: "", fr: "", nl: "" });
    setIcon(null);
  };

  const save = () => {
    let errors = validate();
    if (errors && errors.length > 0) {
      let ErrorsContainer = ({ errors }) => (
        <div>
          <span>{_("errors") + " :"}</span>
          <ul>
            {errors.map((e, i) => (
              <li key={i}>{e}</li>
            ))}
          </ul>
        </div>
      );
      toast.error(<ErrorsContainer errors={errors} />, { autoClose: true });
      return;
    }

    let tabPrivateGroups = privateGroups.map((item) => {
      return item.id;
    });

    let data = {
      titleFr: title.fr,
      titleNl: title.nl,
      titleEn: title.en,
      organization: pageCommunity.value,
      theme: pageTheme.id,
      icon: icon ? icon.id : null,
      isPrivate,
      privateGroups: tabPrivateGroups,
    };

    const titleAttr = `title${
      props.language.charAt(0).toUpperCase() + props.language.slice(1)
    }`;

    dispatch(savePage(data)).then(({ payload }) => {
      if (payload.statusCode && payload.statusCode === 400) {
        toast.error(payload.title, { autoClose: true });
      } else {
        const data = payload.data.data;
        let pages = selectedPages.map((item) => {
          return item;
        });
        pages.push({ id: data.id, title: data[titleAttr] });
        props.onSave(pages);
        toast.success("Success");
        queryClient.invalidateQueries("getWebtoolIcons");
        handleClose();
      }
    });
  };

  return (
    <>
      <div className={styles.popOver} onClick={(e) => e.stopPropagation()}>
        <Tabs theme="wtheme">
          {languages.map((lng) => (
            <div
              key={`langtab${lng.value}`}
              label={lng.label}
              icon={`https://tamtam.s3-eu-west-1.amazonaws.com/cdn/img/flags/${lng.value}.png`}
            >
              <div className={styles.langTab}>
                <label className={styles.configLabel}>
                  {_("page_title")} {lng.value}
                </label>
                <input
                  className={styles.formInput}
                  value={title[lng.value]}
                  onChange={(e) => {
                    handleTitleChange(e, lng.value);
                  }}
                />
              </div>
            </div>
          ))}
        </Tabs>

        <div className={styles.selectIconArea}>
          <div
            id="select-icon"
            className={`${styles.selectIcon} ${icon ? "icon-selected" : ""}`}
            onClick={() => setIsOpenIconList(true)}
          >
            {icon ? (
              <div className="icon-area">
                <i className={`icon webtool-${icon.label}`} />
                <p>{icon.label}</p>
              </div>
            ) : null}
            <span className={icon ? "hide" : ""}>{_("select_icon")}</span>
          </div>
        </div>

        <div className={styles.formRow}>
          <label className={styles.configLabel}>{_("community")}</label>
          <Select
            styles={selectStyles}
            options={communityOptions}
            isSearchable={false}
            placeholder={_("select_community")}
            value={pageCommunity}
            onChange={(e) => setPageCommunity(e)}
            isDisabled
          />
        </div>

        <div className={styles.formRow}>
          <label className={styles.configLabel}>{_("theme")}</label>
          <Select
            styles={selectStyles}
            options={themeOptions}
            className={styles.input}
            filterOption={customFilter}
            value={pageTheme}
            getOptionLabel={(option) => option.title}
            getOptionValue={(option) => option.id}
            onChange={(e) => handleThemeChange(e)}
          />
        </div>

        <div className={styles.formRow}>
          <div className={styles.switchRow}>
            <label className={styles.configLabel}>{_("is_private")}</label>
            <Switch isChecked={isPrivate} onChange={(e) => setIsPrivate(e)} />
          </div>
        </div>
        {isPrivate && (
          <div className={styles.formRow}>
            <label className={styles.configLabel}>
              {_("subscribers_groups")}
            </label>
            <GroupsSelect
              isMulti={true}
              selectedGroups={privateGroups}
              onChange={(groups) => setPrivateGroups(groups)}
              auth={auth}
              community={props.community}
              selectStyles={selectStyles}
            />
          </div>
        )}

        <Button block onClick={() => save()}>
          {pageIsSaving ? (
            <Loader
              style={{
                height: "10px",
              }}
              color={"#fff"}
            />
          ) : (
            _("add_page")
          )}
        </Button>
      </div>
      <SidebarIcon
        isOpened={isOpenIconList}
        hideSidebar={(e) => {
          e.stopPropagation();
          setIsOpenIconList(false);
        }}
        setIcon={(item) => setIcon(item)}
        sidebarClass="sidebar"
      />
    </>
  );
}
