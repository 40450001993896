import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { sortableContainer, sortableElement } from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";

import { setSortedItems } from "../../store";
import SortableFaqTreeItem from "./SortableFaqTreeItem";
import _ from "../../i18n";
import styles from "./FaqTree.module.scss";

const SortableContainer = sortableContainer(({ children }) => {
  return <ul className={styles.itemsList}>{children}</ul>;
});

export default function SortableFaqTree(props) {
  const isFetching = useSelector((state) => state.faq.fetching);
  let faqs = useSelector((state) => state.faq.items);
  const language = useSelector((state) => state.params.language);

  const titleAttr = `title${
    language.charAt(0).toUpperCase() + language.slice(1)
  }`;

  const dispatch = useDispatch();

  const onSortEnd = ({ oldIndex, newIndex }) => {
    dispatch(setSortedItems(arrayMoveImmutable(faqs, oldIndex, newIndex)));
    props.showSortSave();
  };

  const SortableItem = sortableElement(({ item, changeView }) => (
    <SortableFaqTreeItem
      item={item}
      changeView={(e) => changeView(e)}
      showSortSave={props.showSortSave}
    />
  ));

  return isFetching ? (
    [...Array(4).keys()].map((i) => (
      <div key={`faqfetch-${i}`} className={styles.fetchItem}>
        <Skeleton height={40} />
      </div>
    ))
  ) : (
    <SortableContainer onSortEnd={onSortEnd} useDragHandle>
      {faqs.map((item, index) => (
        <SortableItem
          key={`item-${index}`}
          index={index}
          item={item}
          changeView={props.changeView}
          titleAttr={titleAttr}
        />
      ))}
    </SortableContainer>
  );
}
