import { useSelector, useDispatch } from "react-redux";

import styles from "./AddRubric.module.scss";

import { setShowFiduciaryStatus } from "../../store";

const AdminStatusFilter = () => {
  const dispatch = useDispatch();
  const showFiduciaryStatus = useSelector(
    (state) => state.faq.showFiduciaryStatus
  );

  return (
    <div
      className={`${styles.status_trigger} ${
        showFiduciaryStatus && styles.active
      } `}
      onClick={() => dispatch(setShowFiduciaryStatus(!showFiduciaryStatus))}
    >
      <i className="icon-ttp-eye"></i>
    </div>
  );
};

export default AdminStatusFilter;
