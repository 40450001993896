import React from "react";

const IconSearch = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.8421 17.082L13.4678 12.7078C14.6412 11.3588 15.3516 9.59867 15.3516 7.67494C15.3516 3.44035 11.9073 0 7.67665 0C3.44206 0 0.00170898 3.44435 0.00170898 7.67494C0.00170898 11.9055 3.44605 15.3499 7.67665 15.3499C9.60038 15.3499 11.3605 14.6395 12.7095 13.4661L17.0837 17.8404C17.1875 17.9441 17.3272 18 17.4629 18C17.5986 18 17.7383 17.9481 17.8421 17.8404C18.0496 17.6328 18.0496 17.2896 17.8421 17.082ZM1.07532 7.67494C1.07532 4.03503 4.03674 1.07761 7.67266 1.07761C11.3126 1.07761 14.27 4.03902 14.27 7.67494C14.27 11.3109 11.3126 14.2763 7.67266 14.2763C4.03674 14.2763 1.07532 11.3149 1.07532 7.67494Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default IconSearch;
