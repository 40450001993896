import React from "react";

const IconArticle = () => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.5 4.5C1.5 3.67157 2.17157 3 3 3H12C13.9891 3 15.8968 3.79018 17.3033 5.1967C18.7098 6.60322 19.5 8.51088 19.5 10.5V31.5C19.5 32.3284 18.8284 33 18 33C17.1716 33 16.5 32.3284 16.5 31.5C16.5 30.7043 16.1839 29.9413 15.6213 29.3787C15.0587 28.8161 14.2957 28.5 13.5 28.5H3C2.17157 28.5 1.5 27.8284 1.5 27V4.5ZM16.5 26.3038V10.5C16.5 9.30653 16.0259 8.16193 15.182 7.31802C14.3381 6.47411 13.1935 6 12 6H4.5V25.5H13.5C14.5623 25.5 15.5956 25.7817 16.5 26.3038Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.6967 5.1967C20.1032 3.79018 22.0109 3 24 3H33C33.8284 3 34.5 3.67157 34.5 4.5V27C34.5 27.8284 33.8284 28.5 33 28.5H22.5C21.7043 28.5 20.9413 28.8161 20.3787 29.3787C19.8161 29.9413 19.5 30.7043 19.5 31.5C19.5 32.3284 18.8284 33 18 33C17.1716 33 16.5 32.3284 16.5 31.5V10.5C16.5 8.51088 17.2902 6.60322 18.6967 5.1967ZM19.5 26.3038C20.4044 25.7817 21.4377 25.5 22.5 25.5H31.5V6H24C22.8065 6 21.6619 6.47411 20.818 7.31802C19.9741 8.16193 19.5 9.30653 19.5 10.5V26.3038Z"
      fill="currentColor"
    />
  </svg>
);

export default IconArticle;
