import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { useQuery } from "react-query";
import { useSelector, useDispatch } from "react-redux";
import { ModalConfirm } from "tamtam-components";
import { toast } from "react-toastify";
import { arrayMoveImmutable } from "array-move";

import styles from "./MediaForm.module.scss";
import _ from "../../../i18n";
import IconSearch from "../../common/Icons/IconSearch";
import { getMedias, removeQuestion, saveQuestion } from "../../../api";
import Loader from "../../common/Loader";
import Media from "../../common/Media";
import AddMedia from "../../common/AddMedia";
import Button from "../../common/Button";
import { convertDateToUTC, validateUrl } from "../../../utils";
import { IconTrash } from "../../common/Icons";
import { deleteQuestion } from "../../../store";

const tabsVals = ["IMAGE", "VIDEO", "PDF", "PPT"];

const DATE_FORMAT = "DD-MM-YYYY HH:mm";
const API_DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";

const MediaForm = ({
  auth,
  addMediaModalOpened,
  setAddMediaModalOpened,
  onCancel,
  afterSave,
  status,
  publishedAt,
  isPrivate,
  privateGroups,
  currentRubric,
  currentQuestion,
  setShowEmbedInput,
  showEmbedInput,
}) => {
  const language = useSelector((state) => state.params.language);
  const [selectedMedias, setSelectedMedias] = useState([]);
  const [tab, setTab] = useState("IMAGE");
  const [newFile, setNewFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [search, setSearch] = useState("");
  const [embedUrl, setEmbedUrl] = useState("");
  const [embedUrlError, setEmbedUrlError] = useState("");

  const [start, setStart] = useState(0);
  const [medias, setMedias] = useState([]);
  const [loadingMedias, setLoadingMedias] = useState(false);
  const [scrollEnd, setScrollEnd] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const { navCommunity, token } = auth;
  const dispatch = useDispatch();
  useEffect(() => {
    if (scrollEnd) {
      setStart(start + 10);
    }
  }, [scrollEnd]);

  let { isLoading } = useQuery(
    ["listMedias", navCommunity, token, tab, search, start],
    async () => {
      if (token) {
        try {
          setLoadingMedias(true);
          const response = await getMedias({
            token,
            communityId: navCommunity.id,
            language,
            docType: tab,
            search,
            start,
          });
          setLoadingMedias(false);
          const fetchedData = response.data.data
            .map((item) => {
              if (item.id) {
                return item;
              }
            })
            .filter(Boolean);
          if (start > 0 && medias.length > 0) {
            setMedias([...medias, ...fetchedData]);
          } else {
            setMedias(fetchedData);
          }
        } catch (error) {
          if (error.response.data && error.response.data.statusCode === 404)
            setLoadingMedias(false);
        }
      }
    }
  );

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }
  };

  const sortMedias = ({ oldIndex, newIndex }) => {
    setSelectedMedias(arrayMoveImmutable(selectedMedias, oldIndex, newIndex));
  };

  const handleSave = async () => {
    let tabPrivateGroups = privateGroups.map((item) => {
      return item.id;
    });

    let data = {
      community: navCommunity ? navCommunity.id : 9,
      status: status.value,
      rubric: currentRubric && currentRubric.id,
      isPrivate,
      privateGroups: tabPrivateGroups,
      publishedAt: publishedAt
        ? convertDateToUTC(publishedAt, DATE_FORMAT, API_DATE_FORMAT)
        : "",
      objectType: "MEDIA",
      objectIds: selectedMedias.map((item) => item.id).filter(Boolean),
      embedUrls: selectedMedias.map((item) => item.youtube_url).filter(Boolean),
    };

    if (currentQuestion) {
      data.id = currentQuestion.id;
    }

    setIsSaving(true);
    try {
      await saveQuestion(token, data);
      setIsSaving(false);
      afterSave();
    } catch (e) {
      setIsSaving(false);
    }
  };

  const handleDelete = () => {
    setIsDeleting(true);
    removeQuestion(token, currentQuestion.id)
      .then(() => {
        dispatch(deleteQuestion(currentQuestion.id));
        setIsDeleting(false);
        afterSave();
        setIsOpenDeleteModal(false);
        onCancel();
      })
      .catch((err) => {
        setIsDeleting(false);
        setIsOpenDeleteModal(false);
        toast.error(_("rubric_delete_error"));
      });
  };

  return (
    <div className={styles.content}>
      {currentQuestion ? (
        <div style={{ width: "302px" }}>
          <Media
            media={currentQuestion.object}
            language={language}
            size={"md"}
          />
        </div>
      ) : (
        <>
          {showEmbedInput && (
            <div className={styles.inputCont}>
              <div className={classNames(styles.search, styles.embed_input)}>
                <input
                  type="text"
                  placeholder={_("embed_placeholder")}
                  value={embedUrl}
                  onKeyPress={(e) => {
                    if (e.key === "Enter" && embedUrlError.length === 0) {
                      let youtube_id;

                      if (embedUrl.includes("youtu.be")) {
                        youtube_id = embedUrl.split("youtu.be/")[1];
                      } else if (embedUrl.includes("www.youtube.com")) {
                        youtube_id = embedUrl.split("v=")[1].split("&")[0];
                      }
                      if (
                        selectedMedias.filter((med) => {
                          if (med.youtube_id && med.youtube_id === youtube_id)
                            return med;
                        }).length === 0
                      ) {
                        setSelectedMedias([
                          { youtube_url: embedUrl, youtube_id },
                          ...selectedMedias,
                        ]);
                        setShowEmbedInput(false);
                        setEmbedUrl("");
                        setEmbedUrlError("");
                      } else {
                        setEmbedUrlError("already_exists");
                      }
                    }
                  }}
                  onChange={(e) => {
                    setEmbedUrl(e.target.value);
                    if (e.target.value.length > 0)
                      if (!validateUrl(e.target.value)) {
                        setEmbedUrlError("incorrect_url");
                      } else if (
                        validateUrl(e.target.value) &&
                        !e.target.value.includes("https://youtu.be") &&
                        !e.target.value.includes("https://www.youtube.com")
                      ) {
                        setEmbedUrlError("incorrect_youtube_url");
                      } else {
                        setEmbedUrlError("");
                      }
                    else setEmbedUrlError("");
                  }}
                />
              </div>
              <label>{embedUrlError}</label>
            </div>
          )}
          {(selectedMedias.length > 0 || uploading) && (
            <>
              <div className={styles.section_title}>{_("selected_medias")}</div>
              <div className={styles.selected_media_container}>
                <div className={classNames(styles.media_list, "grid-x")}>
                  {uploading && (
                    <div
                      className={classNames(
                        styles.cardCont,
                        "cell small-12 medium-6 large-3"
                      )}
                    >
                      <div
                        className={classNames(
                          styles.uploadingMedia,
                          "cell small-12 medium-6 large-3"
                        )}
                        style={{
                          backgroundImage: newFile
                            ? `url("${URL.createObjectURL(newFile)}")`
                            : "",
                        }}
                      >
                        <div className={styles.uploadingMediaOverlay}>
                          <Loader
                            color={"#fff"}
                            style={{
                              width: "100%",
                              height: "0.5rem",
                              margin: "auto",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {selectedMedias.map((item) => (
                    <div
                      className={classNames(
                        styles.cardCont,
                        "cell small-12 medium-6 large-3"
                      )}
                    >
                      <Media
                        media={item}
                        medias={selectedMedias}
                        setMedias={setSelectedMedias}
                        language={language}
                      />
                    </div>
                  ))}
                </div>
              </div>
              <div className={styles.section_title}>
                {_("disponible_medias")}
              </div>
            </>
          )}
          <div className={classNames(styles.header, styles.header_margin)}>
            <ul className={styles.tabs}>
              {tabsVals.map((v) => (
                <li
                  key={v}
                  className={tab === v ? styles.selected : ""}
                  onClick={() => {
                    setMedias([]);
                    setStart(0);
                    setTab(v);
                  }}
                >
                  {_(`${v}`)}
                </li>
              ))}
            </ul>
            <div className={styles.search}>
              <IconSearch />
              <input
                type="text"
                placeholder={_("search_placeholder")}
                value={search}
                onChange={(e) => {
                  setMedias([]);
                  setStart(0);
                  setSearch(e.target.value);
                }}
              />
            </div>
          </div>
          <div
            className={styles.listContainer}
            onScroll={(e) => {
              if (
                e.target.offsetHeight + e.target.scrollTop + 0.5 >=
                e.target.scrollHeight
              ) {
                setScrollEnd(true);
              } else {
                setScrollEnd(false);
              }
            }}
          >
            {loadingMedias && start === 0 ? (
              <Loader
                style={{
                  width: "100%",
                  height: "1rem",
                  margin: "auto",
                }}
                color={"#18a0fb"}
              />
            ) : (
              medias.length > 0 && (
                <div className={classNames(styles.media_list, "grid-x")}>
                  {medias
                    .filter(
                      (item) =>
                        !currentRubric.questions
                          .filter((el) => el.objectType === "MEDIA")
                          .map((el) => el.objectId)
                          .includes(item.id)
                    )
                    .filter(
                      (item) =>
                        !selectedMedias.map((el) => el.id).includes(item.id)
                    )
                    .map((item) => (
                      <div
                        className={classNames(
                          styles.cardCont,
                          "cell small-12 medium-6 large-3"
                        )}
                      >
                        <Media
                          media={item}
                          medias={selectedMedias}
                          setMedias={setSelectedMedias}
                          language={language}
                        />
                      </div>
                    ))}
                </div>
              )
            )}
            {loadingMedias && start > 0 && (
              <Loader
                style={{
                  width: "100%",
                  height: "1rem",
                  margin: "auto",
                  marginTop: "1rem",
                }}
                color={"#18a0fb"}
              />
            )}
          </div>
        </>
      )}
      <div className={styles.formControls}>
        <Button onClick={() => handleCancel()} variant="default">
          {_("cancel")}
        </Button>

        <div className={styles.actions}>
          {currentQuestion && (
            <span onClick={() => setIsOpenDeleteModal(true)}>
              <IconTrash size={18} />
            </span>
          )}
          {isSaving ? (
            <Button
              variant="primary"
              style={{ paddingTop: "15px", paddingBottom: "15px" }}
              className={styles.okBtn}
            >
              <Loader
                style={{
                  height: "10px",
                }}
                color={"#fff"}
              />
            </Button>
          ) : (
            <Button
              onClick={() => handleSave()}
              variant="primary"
              className={styles.okBtn}
              disabled={selectedMedias.length === 0 && !currentQuestion}
            >
              {_("save")}
            </Button>
          )}
        </div>
      </div>
      <AddMedia
        isOpen={addMediaModalOpened}
        close={() => {
          setAddMediaModalOpened(false);
        }}
        setUploading={setUploading}
        setNewFile={setNewFile}
        token={auth.token}
        community={auth.navCommunity}
        medias={selectedMedias}
        setMedias={setSelectedMedias}
      />
      <ModalConfirm
        type="delete"
        isOpen={isOpenDeleteModal}
        inProcess={isDeleting}
        onCancel={() => setIsOpenDeleteModal(false)}
        onConfirm={handleDelete}
        title={_("modal_delete_title")}
        text={_("modal_delete_text")}
        labelNo={_("no")}
        labelYes={_("yes")}
      />
    </div>
  );
};

export default MediaForm;
