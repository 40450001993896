import {
  TTP_FFF_BLOG,
  TTP_DEG_BLOG,
  API_DATE_FORMAT,
  APP_ENV,
} from "../config";

import { getCookie } from "./auth";

import moment from "moment-timezone";
import "moment/locale/fr";
import "moment/locale/nl";

import _ from "../i18n";

const APP_LANG = getAppLanguage();

export function getRubricFullUrl(rubric, appLanguage, privateOrganizationId) {
  const { url, id, organization, language, isExternal, externalUrl } = rubric;

  if (isExternal) {
    return externalUrl;
  }

  let urlLang = language ? language : appLanguage;

  let fullUrl = `/${urlLang}/rubric/${url}/${id}`;

  if (
    organization &&
    [9, 4].includes(organization.id) &&
    organization.id != privateOrganizationId
  ) {
    if (organization.id == 9) {
      return `${TTP_FFF_BLOG}${fullUrl}`;
    } else if (organization.id == 4) {
      return `${TTP_DEG_BLOG}${fullUrl}`;
    }
  }

  return fullUrl;
}

export const getRubricDateByLang = (date, shortMonth = false) => {
  if (!date) {
    return "";
  }

  let datePartFormat = shortMonth ? "ll" : "LL";

  let datetime = moment(date, API_DATE_FORMAT).locale(APP_LANG);

  let datePart = datetime.format(datePartFormat);
  let timePart = datetime.format("LT");

  let dateStr = datePart + " " + _("DATE_INDEX") + " " + timePart;

  return dateStr;
};

export function getAppLanguage() {
  let lng = "fr";

  if (typeof window !== "undefined") {
    let langCookieName = "ttp_lang_" + APP_ENV;
    lng = getCookie(langCookieName);
  }

  return lng || "fr";
}

/**
 * Assert if an attachment is a PDF
 *
 * @param attachment object
 *
 * @return bool
 */
export function attachmentIsPDF(attachment) {
  if (attachment && attachment.fullMediaUrl) {
    return (
      attachment &&
      attachment.fullMediaUrl &&
      attachment.fullMediaUrl.split(".").pop().toLowerCase() === "pdf"
    );
  }

  return (
    attachment &&
    attachment.webPath &&
    attachment.webPath.split(".").pop().toLowerCase() === "pdf"
  );
}
