import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  fetchFaqData,
  selectFirstQuestion,
  setCurrentRubric,
  setCurrentQuestion,
  cancelSortedItems,
  resetOpenedRubrics,
  fetchCategories,
  fetchTypes,
  fetchThemes,
} from "../../store";
import { saveOrder } from "../../api";
import { IconPlus } from "../common/Icons";
import Button from "../common/Button";
import Loader from "../common/Loader";
import FaqAnswers from "./faq/FaqAnswers";
import FaqForm from "./faq/FaqForm";
import RubricForm from "./faq/RubricForm";
import SortableFaqTree from "./SortableFaqTree";
import styles from "./Settings.module.scss";
import _ from "../../i18n";

const Settings = ({ children }) => {
  const auth = useSelector((state) => state.auth);
  const language = useSelector((state) => state.params.language);
  const dispatch = useDispatch();
  const currentRubric = useSelector((state) => state.faq.currentRubric);
  const currentQuestion = useSelector((state) => state.faq.current);
  const categories = useSelector((state) => state.categories.items);
  const mediaCategories = useSelector((state) => state.categories.media_items);
  const types = useSelector((state) => state.types.items);
  const faqs = useSelector((state) => state.faq.items);
  const isFetching = useSelector((state) => state.faq.fetching);
  const [currentView, setCurrentView] = useState("ANSWER"); // ANSWER / RUBRIC_FORM / FAQ_ADD / FAQ_FORM
  const [showSortSave, setShowSortSave] = useState(false);
  const [isOrderSaving, setIsOrderSaving] = useState(false);

  const { token, navCommunity } = auth;

  // if (!auth.user) {
  //   return null;
  // }

  useEffect(() => {
    if (token && navCommunity) {
      dispatch(fetchFaqData(navCommunity.id));
    }
    if (token) {
      if (categories.length === 0) {
        console.log("settings");
        dispatch(fetchCategories({ language }));
      }
      if (types.length === 0) {
        dispatch(fetchTypes({}));
      }
      if (navCommunity) {
        const communityId = navCommunity ? navCommunity.id : null;
        dispatch(fetchThemes({ communityId }));
      }
    }
  }, [token, navCommunity]);

  const titleAttr = `title${
    language.charAt(0).toUpperCase() + language.slice(1)
  }`;

  const isRubricHasQuestion = (rubric) => {
    if (!rubric.children || (rubric.children && rubric.children.length === 0)) {
      return false;
    }

    let hasQuestion = false;
    rubric.children.forEach((child) => {
      if (child.type === "QUESTION") {
        hasQuestion = true;
      } else {
        if (child.detail.questions && child.detail.questions.length > 0) {
          hasQuestion = true;
        }
      }
    });
    return hasQuestion;
  };

  const handleAfterSaveRubric = (action, rub) => {
    dispatch(resetOpenedRubrics());
    dispatch(fetchFaqData(navCommunity.id));
    setCurrentView("ANSWER");

    // if (action === "EDIT") {
    //   if (!isRubricHasQuestion(currentRubric)) {
    //     setCurrentView("FAQ_ADD");
    //   } else {
    //     setCurrentView("ANSWER");
    //     dispatch(selectFirstQuestion());
    //   }
    // } else {
    //   setCurrentView("FAQ_ADD");
    // }
  };

  const handleSaveOrder = async () => {
    try {
      setIsOrderSaving(true);
      const response = await saveOrder(auth.token, faqs);
      setIsOrderSaving(false);
      setShowSortSave(false);
    } catch (e) {
      setIsOrderSaving(false);
    }
  };

  const handleCancelSaveOrder = () => {
    dispatch(cancelSortedItems());
    setShowSortSave(false);
  };

  let label = _("question");
  switch (currentView) {
    case "RUBRIC_FORM":
      label = currentRubric
        ? _("rubric_edit_headline")
        : _("rubric_add_headline");
      break;
    case "FAQ_ADD":
      label = `${_("faq_add_headline")} : <span>${
        currentRubric[titleAttr]
      }</span>`;
      break;
    case "FAQ_FORM":
      label = `${
        currentQuestion ? _("faq_edit_headline") : _("faq_add_headline")
      } : <span>${currentRubric[titleAttr]}</span>`;
      break;
  }

  return (
    <div className={styles.page}>
      <h1 className={styles.pageTitle}>Help center - Settings</h1>

      {/* <TmpSortableFaqTree showSidebar={() => setSidebarOpened(true)} /> */}

      <div className={styles.content}>
        <div className={styles.left}>
          <h3>{_("rubrics")}</h3>
          <SortableFaqTree
            changeView={(e) => setCurrentView(e)}
            showSortSave={() => setShowSortSave(true)}
          />
          {!isFetching && (
            <div
              className={`${styles.control} ${
                showSortSave && styles.control__row
              } `}
            >
              {!showSortSave ? (
                <>
                  <i
                    className={styles.plus}
                    onClick={() => {
                      dispatch(setCurrentRubric(null));
                      setCurrentView("RUBRIC_FORM");
                    }}
                  />
                  <span>{_("rubric_add_headline")}</span>
                </>
              ) : (
                <>
                  <Button
                    onClick={() => handleCancelSaveOrder()}
                    variant="default"
                    className={styles.okBtn}
                  >
                    {_("cancel")}
                  </Button>
                  {isOrderSaving ? (
                    <Button
                      variant="primary"
                      style={{ paddingTop: "15px", paddingBottom: "15px" }}
                      className={styles.okBtn}
                    >
                      <Loader
                        style={{
                          height: "10px",
                        }}
                        color={"#fff"}
                      />
                    </Button>
                  ) : (
                    <Button
                      onClick={() => handleSaveOrder()}
                      variant="primary"
                      className={styles.okBtn}
                    >
                      {_("save_order")}
                    </Button>
                  )}
                </>
              )}
            </div>
          )}
        </div>

        <div className={styles.right}>
          <h3 dangerouslySetInnerHTML={{ __html: label }}></h3>
          <div className={styles.rightContent}>
            {currentView === "ANSWER" && (
              <FaqAnswers
                changeView={(e) => setCurrentView(e)}
                onCancel={() => {
                  setCurrentView("ANSWER");
                  dispatch(selectFirstQuestion());
                }}
              />
            )}
            {currentView === "RUBRIC_FORM" && (
              <RubricForm
                onCancel={() => {
                  setCurrentView("ANSWER");
                  dispatch(selectFirstQuestion());
                }}
                afterSave={handleAfterSaveRubric}
              />
            )}
            {currentView === "FAQ_ADD" && currentRubric && (
              <div>
                <h3 className={styles.form_headline}>
                  {currentRubric[titleAttr]}
                </h3>
                <div className={styles.addFaqContainer}>
                  <div
                    className={styles.addFaq}
                    onClick={() => {
                      dispatch(setCurrentQuestion(null));
                      setCurrentView("FAQ_FORM");
                    }}
                  >
                    <span className={styles.addFaq_icon}>
                      <IconPlus />
                    </span>
                    <span>{_("add_question")}</span>
                  </div>
                </div>
              </div>
            )}
            {currentView === "FAQ_FORM" && (
              <FaqForm
                onCancel={() => {
                  setCurrentView("ANSWER");
                  dispatch(selectFirstQuestion());
                }}
                afterSave={() => setCurrentView("ANSWER")}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
