import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useQuery } from "react-query";
import { ModalConfirm } from "tamtam-components";
import Skeleton from "react-loading-skeleton";
import classNames from "classnames";

import styles from "../Pages.module.scss";
import Question from "../../common/Question";
import PageLoader from "../../common/PageLoader";
import {
  getArticles,
  getEvents,
  getMedias,
  getRubric,
  saveOrder,
} from "../../../api";
import { setCurrentRubric } from "../../../store";
import Media from "../../common/Media";
import SortableQuestionList from "./SortableQuestionList";
import { arrayMoveImmutable } from "array-move";
import _ from "../../../i18n";
import Article from "../../common/Article";
import Formation from "../../common/Formation";

const QuestionList = ({
  rubricID,
  isLibrary = false,
  libraryQuestions = null,
}) => {
  const language = useSelector((state) => state.params.language);
  const showAllStatus = useSelector((state) => state.faq.showAllStatus);
  const showFiduciaryStatus = useSelector(
    (state) => state.faq.showFiduciaryStatus
  );
  const hiddenFaqs = useSelector((state) => state.faq.hiddenFaqs);
  const auth = useSelector((state) => state.auth);
  const { token } = auth;
  const [fetchedDataById, setFetchedDataById] = useState([]);
  const [questionsList, setQuestionsList] = useState([]);
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const dispatch = useDispatch();
  const getYoutubeId = (url) => {
    if (url.includes("youtu.be")) {
      return url.split("youtu.be/")[1];
    } else if (url.includes("www.youtube.com")) {
      return url.split("v=")[1].split("&")[0];
    }
  };

  let { isLoading, data } = useQuery(
    ["getRubric", token, rubricID, libraryQuestions],
    async () => {
      if (token && rubricID) {
        const response = await getRubric({
          token,
          id: parseInt(rubricID),
          community: auth.navCommunity ? auth.navCommunity.id : 9,
        });

        if (response.data.data && response.data.data[0]) {
          dispatch(setCurrentRubric(response.data.data[0]));
          if (response.data.data[0].questions) {
            setQuestionsList(response.data.data[0].questions);
          }
          return response.data.data[0];
        } else {
          return null;
        }
      } else if (libraryQuestions && isLibrary) {
        setQuestionsList(libraryQuestions);
        return { questions: libraryQuestions };
      }
    }
  );

  const fetchData = async (fetchedList, mediaIds, articleIds, eventIds) => {
    let list = fetchedList;

    if (mediaIds.length > 0) {
      try {
        const res = await getMedias({
          token,
          limit: 60,
          ids: mediaIds,
        });

        if (res.data.data) {
          res.data.data.map((item) => {
            list = [
              ...list.filter((el) => el.objectId !== item.id),
              {
                ...list.filter((el) => el.objectId === item.id)[0],
                object: item,
              },
            ];
          });
        }
      } catch (error) {}
    }
    if (articleIds.length > 0) {
      try {
        const res = await getArticles({
          token,
          limit: 60,
          ids: articleIds,
        });

        if (res.data.data) {
          res.data.data.map((item) => {
            list = [
              ...list.filter((el) => el.objectId !== item.id),
              {
                ...list.filter((el) => el.objectId === item.id)[0],
                object: item,
              },
            ];
          });
        }
      } catch (error) {}
    }
    if (eventIds.length > 0) {
      try {
        const res = await getEvents({
          token,
          limit: 60,
          ids: eventIds,
        });

        if (res.data.data) {
          res.data.data.map((item) => {
            list = [
              ...list.filter((el) => el.objectId !== item.id),
              {
                ...list.filter((el) => el.objectId === item.id)[0],
                object: item,
              },
            ];
          });
        }
      } catch (error) {}
    }

    setFetchedDataById(list);
  };

  useEffect(() => {
    if (data && data.questions && data.questions.length > 0) {
      const list = [];
      data.questions.map((item) => {
        list.push({
          id: item.id,
          objectId: item.objectId,
          objectType: item.objectType,
          embedUrl: item.embedUrl,
          object: item.embedUrl
            ? {
                youtube_url: item.embedUrl,
                youtube_id: getYoutubeId(item.embedUrl),
              }
            : null,
          sharingDate: item.sharingDate ? item.sharingDate.date : null,
        });
      });

      const mediaIds = list
        .filter((el) => el.objectType === "MEDIA")
        .map((el) => el.objectId)
        .filter(Boolean);

      const articleIds = list
        .filter((el) => el.objectType === "ARTICLE")
        .map((el) => el.objectId);

      const eventIds = list
        .filter((el) => el.objectType === "EVENT")
        .map((el) => el.objectId);

      fetchData(list, mediaIds, articleIds, eventIds);
    }
  }, [data]);

  const sortQuestions = ({ oldIndex, newIndex }) => {
    setQuestionsList(arrayMoveImmutable(questionsList, oldIndex, newIndex));
  };

  useEffect(() => {
    if (
      !showAllStatus &&
      data &&
      data.questions &&
      questionsList.length === data.questions.length &&
      data.questions.map((el) => el.id) !== questionsList.map((el) => el.id)
    ) {
      setIsOpenConfirmModal(true);
    }
  }, [showAllStatus]);

  const handleSave = async () => {
    try {
      const rub = {
        id: data && data.id,
        children: questionsList,
      };
      setIsSaving(true);

      await saveOrder(token, [rub]);
      setIsSaving(false);
      setIsOpenConfirmModal(false);
    } catch (error) {
      setIsSaving(false);
      setIsOpenConfirmModal(false);
    }
  };

  if (isLoading) {
    return (
      <div className="cell medium-12 large-9 small-12">
        <PageLoader />
      </div>
    );
  }
  return (
    <div className={`${styles.rubricList}`}>
      {!isLibrary && showAllStatus && data && data.questions ? (
        <SortableQuestionList
          onSortEnd={(e) => sortQuestions(e)}
          questions={questionsList}
          showAllStatus={showAllStatus}
          showFiduciaryStatus={showFiduciaryStatus}
          fetchedDataById={fetchedDataById}
          language={language}
          hiddenFaqs={hiddenFaqs}
          rubric={data}
          distance={1}
          axis={"xy"}
        />
      ) : (
        <div
          className={classNames(
            styles.listContent,
            styles.questionsList,
            "grid-x"
          )}
        >
          {data && (
            <>
              {data.questions &&
                questionsList.map((item, index) => {
                  if (!showAllStatus && item.status !== "PUBLISHED") {
                    return null;
                  }
                  if (!showFiduciaryStatus && hiddenFaqs.Q.includes(item.id)) {
                    return null;
                  }
                  return item.objectType === "QUESTION" ? (
                    <div key={item.id} className={classNames("cell small-12")}>
                      <div className={styles.qCardCont}>
                        <Question
                          key={item.id}
                          rubric={data}
                          item={item}
                          index={index}
                          sharingDate={
                            fetchedDataById.length > 0 &&
                            fetchedDataById.filter((el) => el.id === item.id)
                              .length > 0 &&
                            fetchedDataById.filter((el) => el.id === item.id)[0]
                              .sharingDate
                          }
                        />
                      </div>
                    </div>
                  ) : item.objectType === "ARTICLE" ? (
                    <div className={classNames("cell small-12")} key={item.id}>
                      <div className={styles.cardCont}>
                        {fetchedDataById.length > 0 &&
                        fetchedDataById.filter((el) => el.id === item.id)
                          .length > 0 &&
                        fetchedDataById.filter((el) => el.id === item.id)[0]
                          .object ? (
                          <Article
                            article={
                              fetchedDataById.length > 0 &&
                              fetchedDataById.filter((el) => el.id === item.id)
                                .length > 0 &&
                              fetchedDataById.filter(
                                (el) => el.id === item.id
                              )[0].object
                            }
                            sharingDate={
                              fetchedDataById.length > 0 &&
                              fetchedDataById.filter((el) => el.id === item.id)
                                .length > 0 &&
                              fetchedDataById.filter(
                                (el) => el.id === item.id
                              )[0].sharingDate
                            }
                            language={language}
                            type="TYPE2"
                            question={item}
                          />
                        ) : (
                          <Skeleton height={302} />
                        )}
                      </div>
                    </div>
                  ) : item.objectType === "EVENT" ? (
                    <div className={classNames("cell small-12")} key={item.id}>
                      <div className={styles.cardCont}>
                        {fetchedDataById.length > 0 &&
                        fetchedDataById.filter((el) => el.id === item.id)
                          .length > 0 &&
                        fetchedDataById.filter((el) => el.id === item.id)[0]
                          .object ? (
                          <Formation
                            formation={
                              fetchedDataById.length > 0 &&
                              fetchedDataById.filter((el) => el.id === item.id)
                                .length > 0 &&
                              fetchedDataById.filter(
                                (el) => el.id === item.id
                              )[0].object
                            }
                            sharingDate={
                              fetchedDataById.length > 0 &&
                              fetchedDataById.filter((el) => el.id === item.id)
                                .length > 0 &&
                              fetchedDataById.filter(
                                (el) => el.id === item.id
                              )[0].sharingDate
                            }
                            language={language}
                            type={"TYPE2"}
                            question={item}
                          />
                        ) : (
                          <Skeleton height={302} />
                        )}
                      </div>
                    </div>
                  ) : item.objectType === "MEDIA" ? (
                    <div
                      className={
                        fetchedDataById.length > 0 &&
                        fetchedDataById.filter((el) => el.id === item.id)
                          .length > 0 &&
                        fetchedDataById.filter((el) => el.id === item.id)[0]
                          .object &&
                        (fetchedDataById.filter((el) => el.id === item.id)[0]
                          .object.docType === "PDF" ||
                          fetchedDataById.filter((el) => el.id === item.id)[0]
                            .object.docType === "PPT")
                          ? "cell small-12 medium-6"
                          : "cell small-12"
                      }
                      key={item.id}
                    >
                      <div className={styles.cardCont}>
                        {fetchedDataById.length > 0 &&
                        fetchedDataById.filter((el) => el.id === item.id)
                          .length > 0 &&
                        fetchedDataById.filter((el) => el.id === item.id)[0]
                          .object ? (
                          <Media
                            media={
                              fetchedDataById.filter(
                                (el) => el.id === item.id
                              )[0].object
                            }
                            sharingDate={
                              fetchedDataById.length > 0 &&
                              fetchedDataById.filter((el) => el.id === item.id)
                                .length > 0 &&
                              fetchedDataById.filter(
                                (el) => el.id === item.id
                              )[0].sharingDate
                            }
                            language={language}
                            size={"lg"}
                            type="TYPE2"
                            question={item}
                          />
                        ) : (
                          <Skeleton height={302} />
                        )}
                      </div>
                    </div>
                  ) : null;
                })}
            </>
          )}
        </div>
      )}
      <ModalConfirm
        type="send"
        isOpen={isOpenConfirmModal}
        inProcess={isSaving}
        onCancel={() => {
          if (data.questions) setQuestionsList(data.questions);
          setIsOpenConfirmModal(false);
        }}
        onConfirm={() => handleSave()}
        title={_("modal_save_order")}
        text={_("modal_save_order_text")}
        labelNo={_("no")}
        labelYes={_("yes")}
      />
    </div>
  );
};

export default QuestionList;
