import { useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import SunEditor from "suneditor-react";
import Select from "react-select";
import { toast } from "react-toastify";
import classNames from "classnames";
import { useSearchParams } from "react-router-dom";
import { arrayMoveImmutable } from "array-move";
import moment from "moment";
import { ModalConfirm } from "tamtam-components";

import styles from "./Privacy.module.scss";
import _ from "../../i18n";
import Tabs from "../common/Tabs";
import {
  API_DATE_FORMAT,
  APP_ENV,
  SELECT_STYLES,
  TTP_API_URL,
} from "../../config";
import Button from "../common/Button";
import {
  removePrivacyRubric,
  saveCookie,
  savePrivacyRubricsOrder,
  saveVersion,
  updateCookieSetting,
  updateVersionStatus,
  uploadTmpMedia,
} from "../../api";
import Switch from "../common/Switch/Switch";
import { setAuthUser } from "../../store/auth";
import { convertDateToUTC, setLocalStorageAuth } from "../../utils";
import RubricCard from "./RubricCard";
import SortableRubricList from "./SortableRubricList";
import { default as DotLoader } from "../common/Loader";

const languages = [
  { value: "fr", label: _("french") },
  { value: "en", label: _("english") },
  { value: "nl", label: _("dutch") },
];

const statusOptions = [
  { value: "DRAFT", label: _("draft") },
  { value: "PUBLISHED", label: _("published") },
];

const Cookies = ({ data, isUaAdmin, lng, token, navCommunity, user }) => {
  const dispatch = useDispatch();
  const [showForm, setShowForm] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [functionalFr, setFunctionalFr] = useState("");
  const [functionalEn, setFunctionalEn] = useState("");
  const [functionalNl, setFunctionalNl] = useState("");
  const [optionalFr, setOptionalFr] = useState("");
  const [optionalEn, setOptionalEn] = useState("");
  const [optionalNl, setOptionalNl] = useState("");
  const [cookieRubric, setCookieRubric] = useState(null);
  const [rubricsList, setRubricsList] = useState([]);
  const [sortableRubrics, setSortableRubrics] = useState([]);
  const [acceptCookies, setAcceptCookies] = useState(null);
  const [selectedRubric, setSelectedRubric] = useState(null);
  const [versionLanguage, setVersionLanguage] = useState(lng);
  const [title, setTitle] = useState({ en: "", fr: "", nl: "" });
  const [introductionFr, setIntroductionFr] = useState("");
  const [introductionEn, setIntroductionEn] = useState("");
  const [introductionNl, setIntroductionNl] = useState("");
  const [status, setStatus] = useState(statusOptions[0]);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [showSaveOrder, setShowSaveOrder] = useState(false);
  const [savingOrder, setSavingOrder] = useState(false);
  const [searchParams] = useSearchParams();
  const rubParam = searchParams.get("rub");

  useEffect(() => {
    if (rubricsList.length > 0 && rubParam && user && user.isUaAdmin) {
      setSelectedRubric(
        rubricsList.filter((el) => el.id.toString() === rubParam)[0]
      );
    } else if (rubricsList.length > 0 && rubParam) {
      setSelectedRubric(
        rubricsList
          .filter(
            (item) =>
              item.showInFooter && item.versions[0].status === "PUBLISHED"
          )
          .filter((el) => el.id.toString() === rubParam)[0]
      );
    }
  }, [rubParam, rubricsList]);

  const handleCookiesSetting = async (value) => {
    if (user) {
      setIsSaving(true);
      try {
        await updateCookieSetting({
          token,
          acceptCookies: value ? value.val : !acceptCookies,
          id: user.id,
        });
        let dtExpire = new Date();
        dtExpire.setTime(dtExpire.getTime() - 3600000 * 1000);
        localStorage.setItem(
          "ttp_accept_cookies",
          !acceptCookies ? "true" : "false"
        );
        setAcceptCookies(value ? value.val : !acceptCookies);
        dispatch(
          setAuthUser({
            ...user,
            acceptCookies: value ? value.val : !acceptCookies,
          })
        );
        setLocalStorageAuth({
          ...user,
          acceptCookies: value ? value.val : !acceptCookies,
        });
        setIsSaving(false);
      } catch (error) {
        toast.error(_("saving_error"));
        setIsSaving(false);
      }
    } else {
      setAcceptCookies(!acceptCookies);
      let dtExpire = new Date();
      dtExpire.setTime(dtExpire.getTime() - 3600000 * 1000);
      localStorage.setItem(
        "ttp_accept_cookies",
        !acceptCookies ? "true" : "false"
      );
    }
  };

  useEffect(() => {
    if (user) {
      if (localStorage.getItem("ttp_accept_cookies")) {
        setAcceptCookies(
          localStorage.getItem("ttp_accept_cookies") === "true" ? true : false
        );
        if (
          (user.acceptCookies &&
            localStorage.getItem("ttp_accept_cookies") === "false") ||
          (!user.acceptCookies &&
            localStorage.getItem("ttp_accept_cookies") === "true")
        ) {
          handleCookiesSetting(
            localStorage.getItem("ttp_accept_cookies") === "true"
              ? { val: true }
              : { val: false }
          );
        }
      } else {
        setAcceptCookies(user.acceptCookies);
        localStorage.setItem(
          "ttp_accept_cookies",
          user.acceptCookies ? "true" : "false"
        );
      }
    } else {
      if (localStorage.getItem("ttp_accept_cookies")) {
        setAcceptCookies(
          localStorage.getItem("ttp_accept_cookies") === "true" ? true : false
        );
      } else {
        setAcceptCookies(true);
      }
    }
  }, [user]);

  const functionalAttr = `functionalCookies${
    versionLanguage.charAt(0).toUpperCase() + versionLanguage.slice(1)
  }`;

  const optionalAttr = `optionalCookies${
    versionLanguage.charAt(0).toUpperCase() + versionLanguage.slice(1)
  }`;

  const introductionAttr = `introduction${
    versionLanguage.charAt(0).toUpperCase() + versionLanguage.slice(1)
  }`;

  const titleAttr = `title${
    versionLanguage.charAt(0).toUpperCase() + versionLanguage.slice(1)
  }`;

  const getChapterTitles = (lastVer) => {
    let parser = new DOMParser();
    const docFr = parser.parseFromString(lastVer.introductionFr, "text/html");
    const docEn = parser.parseFromString(lastVer.introductionEn, "text/html");
    const docNl = parser.parseFromString(lastVer.introductionNl, "text/html");

    const titlesFr = [];
    const titlesEn = [];
    const titlesNl = [];

    [...docFr.getElementsByTagName("h2")].forEach((el, i) => {
      el.setAttribute("id", `ch_fr_${i}`);
      titlesFr.push({ id: `ch_fr_${i}`, title: el.innerText });
    });
    [...docEn.getElementsByTagName("h2")].forEach((el, i) => {
      el.setAttribute("id", `ch_en_${i}`);
      titlesEn.push({ id: `ch_en_${i}`, title: el.innerText });
    });
    [...docNl.getElementsByTagName("h2")].forEach((el, i) => {
      el.setAttribute("id", `ch_nl_${i}`);
      titlesNl.push({ id: `ch_nl_${i}`, title: el.innerText });
    });

    return {
      titlesFr,
      titlesEn,
      titlesNl,
      version: {
        ...lastVer,
        introductionFr: docFr.body.innerHTML,
        introductionEn: docEn.body.innerHTML,
        introductionNl: docNl.body.innerHTML,
      },
    };
  };

  useEffect(() => {
    if (data) {
      setCookieRubric(data.filter((el) => !el.versions)[0]);
      setRubricsList(
        data
          .filter((el) => el.versions && el.versions.length > 0)
          .map((rub) => {
            return {
              ...rub,
              titlesFr: getChapterTitles(rub.versions[0]).titlesFr,
              titlesEn: getChapterTitles(rub.versions[0]).titlesEn,
              titlesNl: getChapterTitles(rub.versions[0]).titlesNl,
              versions: [getChapterTitles(rub.versions[0]).version],
            };
          })
      );
      setSortableRubrics(
        data
          .filter((el) => el.versions && el.versions.length > 0)
          .map((rub) => {
            return {
              ...rub,
              titlesFr: getChapterTitles(rub.versions[0]).titlesFr,
              titlesEn: getChapterTitles(rub.versions[0]).titlesEn,
              titlesNl: getChapterTitles(rub.versions[0]).titlesNl,
              versions: [getChapterTitles(rub.versions[0]).version],
            };
          })
      );
      setSelectedRubric(data.filter((el) => !el.versions)[0]);
    }
    if ((!data || !data.filter((el) => !el.versions)[0]) && isUaAdmin) {
      setShowForm(true);
    }
  }, [data, isUaAdmin]);

  const handlePaste = (event, cleanData, maxCharCount, core) => {
    let content = cleanData.replace(/\s+style="[^"]*"/gi, "");
    return content.replace(/(<\/?h)([0-1])/gi, "$12");
  };

  const resetForm = () => {
    setTitle({ en: "", fr: "", nl: "" });
    setIntroductionFr("");
    setIntroductionEn("");
    setIntroductionNl("");
    setStatus(statusOptions[0]);
    setFunctionalFr("");
    setFunctionalEn("");
    setFunctionalNl("");
    setOptionalFr("");
    setOptionalEn("");
    setOptionalNl("");
  };
  useEffect(() => {
    if (selectedRubric) {
      setShowForm(false);
      resetForm();
    }
  }, [selectedRubric]);

  const changeVersionStatus = async () => {
    const data = {
      id: selectedRubric.versions[0].id,
      status:
        selectedRubric.versions[0].status === "DRAFT" ? "PUBLISHED" : "DRAFT",
      publishedAt:
        status.value === "DRAFT"
          ? convertDateToUTC(new Date(), API_DATE_FORMAT, API_DATE_FORMAT)
          : "",
    };
    setIsSaving(true);

    try {
      const res = await updateVersionStatus({
        token,
        data,
      });
      if (res.data.data) {
        setRubricsList(
          rubricsList.map((rub) => {
            if (rub.id === selectedRubric.id) {
              return {
                ...rub,
                versions: rub.versions.map((ver) => {
                  if (ver.id === res.data.data.id) {
                    return {
                      ...ver,
                      status: res.data.data.status,
                      publishedAt: res.data.data.publishedAt,
                    };
                  }
                  return ver;
                }),
              };
            }
            return rub;
          })
        );

        setSortableRubrics(
          sortableRubrics.map((rub) => {
            if (rub.id === selectedRubric.id) {
              return {
                ...rub,
                versions: rub.versions.map((ver) => {
                  if (ver.id === res.data.data.id) {
                    return {
                      ...ver,
                      status: res.data.data.status,
                      publishedAt: res.data.data.publishedAt,
                    };
                  }
                  return ver;
                }),
              };
            }
            return rub;
          })
        );

        setSelectedRubric({
          ...selectedRubric,
          versions: selectedRubric.versions.map((ver) => {
            if (ver.id === res.data.data.id) {
              return {
                ...ver,
                status: res.data.data.status,
                publishedAt: res.data.data.publishedAt,
              };
            }
            return ver;
          }),
        });
      }
      setIsSaving(false);
    } catch (error) {
      toast.error(_("saving_error"));
      setIsSaving(false);
    }
  };

  const validate = (newRub) => {
    let errors = [];
    if (newRub) {
      if (title[lng].trim().length === 0) {
        errors.push(_("validate_title"));
      }
    } else {
      if (
        (lng === "fr" &&
          functionalFr.trim().length === 0 &&
          optionalFr.trim().length === 0) ||
        (lng === "en" &&
          functionalEn.trim().length === 0 &&
          optionalEn.trim().length === 0) ||
        (lng === "nl" &&
          functionalNl.trim().length === 0 &&
          optionalNl.trim().length === 0)
      ) {
        errors.push(_("validate_cookies"));
      }
    }

    return errors;
  };

  const handleSave = async (newRub) => {
    let errors = validate(newRub);

    if (errors && errors.length > 0) {
      let ErrorsContainer = ({ errors }) => (
        <div>
          <span>{_("errors") + " :"}</span>
          <ul>
            {errors.map((e, i) => (
              <li key={i}>{e}</li>
            ))}
          </ul>
        </div>
      );
      toast.error(<ErrorsContainer errors={errors} />);
      return;
    }

    if (newRub) {
      const reqData = {
        name: "1.0",
        title,
        introduction: {
          en: introductionEn,
          fr: introductionFr,
          nl: introductionNl,
        },
        status: status.value,
        publishedAt:
          status.value === "PUBLISHED"
            ? convertDateToUTC(new Date(), API_DATE_FORMAT, API_DATE_FORMAT)
            : "",
      };

      if (
        selectedRubric &&
        selectedRubric.versions &&
        selectedRubric.versions.length > 0
      ) {
        reqData.id = selectedRubric.versions[0].id;
      }
      setIsSaving(true);
      try {
        const res = await saveVersion({
          token,
          rubricId: reqData.id ? selectedRubric.id : null,
          communityId: navCommunity ? navCommunity.id : 9,
          isCookie: true,
          data: reqData,
        });

        if (!selectedRubric && res.resRub.data.data && res.resVer.data.data) {
          setSelectedRubric({
            ...res.resRub.data.data,
            titlesFr: getChapterTitles(res.resVer.data.data).titlesFr,
            titlesEn: getChapterTitles(res.resVer.data.data).titlesEn,
            titlesNl: getChapterTitles(res.resVer.data.data).titlesNl,
            versions: [getChapterTitles(res.resVer.data.data).version],
          });
          setRubricsList([
            {
              ...res.resRub.data.data,
              titlesFr: getChapterTitles(res.resVer.data.data).titlesFr,
              titlesEn: getChapterTitles(res.resVer.data.data).titlesEn,
              titlesNl: getChapterTitles(res.resVer.data.data).titlesNl,
              versions: [getChapterTitles(res.resVer.data.data).version],
            },
            ...rubricsList,
          ]);
          setSortableRubrics([
            {
              ...res.resRub.data.data,
              titlesFr: getChapterTitles(res.resVer.data.data).titlesFr,
              titlesEn: getChapterTitles(res.resVer.data.data).titlesEn,
              titlesNl: getChapterTitles(res.resVer.data.data).titlesNl,
              versions: [getChapterTitles(res.resVer.data.data).version],
            },
            ...sortableRubrics,
          ]);
        } else if (res.data.data) {
          setRubricsList(
            rubricsList.map((rub) => {
              if (rub.id === selectedRubric.id) {
                return {
                  ...rub,
                  titlesFr: getChapterTitles(res.data.data).titlesFr,
                  titlesEn: getChapterTitles(res.data.data).titlesEn,
                  titlesNl: getChapterTitles(res.data.data).titlesNl,
                  versions: rub.versions.map((ver) => {
                    if (ver.id === res.data.data.id) {
                      return {
                        ...getChapterTitles(res.data.data).version,
                      };
                    }
                    return ver;
                  }),
                };
              }
              return rub;
            })
          );

          setSortableRubrics(
            sortableRubrics.map((rub) => {
              if (rub.id === selectedRubric.id) {
                return {
                  ...rub,
                  titlesFr: getChapterTitles(res.data.data).titlesFr,
                  titlesEn: getChapterTitles(res.data.data).titlesEn,
                  titlesNl: getChapterTitles(res.data.data).titlesNl,
                  versions: rub.versions.map((ver) => {
                    if (ver.id === res.data.data.id) {
                      return {
                        ...getChapterTitles(res.data.data).version,
                      };
                    }
                    return ver;
                  }),
                };
              }
              return rub;
            })
          );

          setSelectedRubric({
            ...selectedRubric,
            titlesFr: getChapterTitles(res.data.data).titlesFr,
            titlesEn: getChapterTitles(res.data.data).titlesEn,
            titlesNl: getChapterTitles(res.data.data).titlesNl,
            versions: selectedRubric.versions.map((ver) => {
              if (ver.id === res.data.data.id) {
                return {
                  ...getChapterTitles(res.data.data).version,
                };
              }
              return ver;
            }),
          });
        }

        setIsSaving(false);
        setShowForm(false);
        resetForm();
      } catch (error) {
        console.error(error);
        toast.error(_("saving_error"));
        setIsSaving(false);
        setShowForm(false);
        resetForm();
      }
    } else {
      const reqData = {
        functionalFr,
        functionalEn,
        functionalNl,
        optionalFr,
        optionalEn,
        optionalNl,
      };

      if (cookieRubric) {
        reqData.id = cookieRubric.id;
      }

      setIsSaving(true);
      try {
        const res = await saveCookie({
          token,
          data: reqData,
          communityId: navCommunity ? navCommunity.id : 9,
        });
        if (res.data.data) setCookieRubric(res.data.data);
        setIsSaving(false);
        resetForm();
        setShowForm(false);
      } catch (error) {
        toast.error(_("saving_error"));
        setIsSaving(false);
        if (cookieRubric) {
          setShowForm(false);
        }
      }
    }
  };

  const deleteRubric = async () => {
    setIsSaving(true);
    try {
      const res = await removePrivacyRubric({
        token,
        rubricId: selectedRubric.id,
      });
      setRubricsList(rubricsList.filter((el) => el.id !== res.data.deletedId));
      setSortableRubrics(
        sortableRubrics.filter((el) => el.id !== res.data.deletedId)
      );

      setSelectedRubric(
        sortableRubrics.filter((el) => el.id !== res.data.deletedId)[0]
      );

      setIsSaving(false);
      setIsOpenDeleteModal(false);
    } catch (error) {
      console.error(error);
      toast.error(_("deleting_error"));
      setIsSaving(false);
    }
  };

  const handleSaveOrder = async () => {
    try {
      setSavingOrder(true);
      await savePrivacyRubricsOrder(token, sortableRubrics);
      setRubricsList(sortableRubrics);
      setSavingOrder(false);
      setShowSaveOrder(false);
    } catch (error) {
      setSavingOrder(false);
      setShowSaveOrder(false);
    }
  };

  const sortRubrics = ({ oldIndex, newIndex }) => {
    setShowSaveOrder(true);
    setSortableRubrics(arrayMoveImmutable(sortableRubrics, oldIndex, newIndex));
  };

  const handleImageUploadBefore = (files, info, uploadHandler) => {
    uploadTmpMedia({ token, data: files[0] }).then((resp) => {
      const url = resp.data.data.url;
      const startsWithHttp = url.lastIndexOf("http://", 0) === 0;
      const startsWithHttps = url.lastIndexOf("https://", 0) === 0;
      const isAbsolute = startsWithHttp || startsWithHttps;
      const imgUrl = isAbsolute ? url : `${TTP_API_URL}/${url}`;
      uploadHandler({
        result: [
          {
            url: imgUrl,
            name: files[0].name,
            size: files[0].size,
          },
        ],
      });
    });
  };

  const renderForm = () => {
    return (
      <div className={styles.cookiesForm}>
        {isSaving && <div className={styles.saving}></div>}
        <div className={styles.formBody}>
          <Tabs theme="wtheme">
            {languages.map((lng) => (
              <div
                key={`langtab${lng.value}`}
                label={lng.label}
                icon={`https://tamtam.s3-eu-west-1.amazonaws.com/cdn/widget/${APP_ENV}/img/flags/${lng.value.toUpperCase()}.jpg`}
              >
                <div className={styles.langTab}>
                  <label className={styles.configLabel}>
                    {_("functional_cookies")} {lng.value}
                  </label>
                  <div className={`ttp-helpeditor ${styles.editorContainer} `}>
                    <SunEditor
                      setOptions={{
                        attributesWhitelist: {
                          // all: "style",
                          input: "checked",
                        },
                        stickyToolbar: false,
                        height: "auto",
                        minHeight: "200px",
                        showPathLabel: false,
                        resizingBar: false,
                        imageUrlInput: false,
                        imageWidth: "75%",
                        formats: [
                          "p",
                          "blockquote",
                          "h2",
                          "h3",
                          "h4",
                          "h5",
                          "h6",
                        ],
                        buttonList: [
                          [
                            "undo",
                            "redo",
                            "formatBlock",
                            "bold",
                            "underline",
                            "italic",
                            "strike",
                            "outdent",
                            "indent",
                            "align",
                            "list",
                            "table",
                            "link",
                            "image",
                            "video",
                            // "fullScreen",
                            "showBlocks",
                          ],
                        ],
                      }}
                      setContents={
                        lng.value === "fr"
                          ? functionalFr
                          : lng.value === "en"
                          ? functionalEn
                          : functionalNl
                      }
                      onChange={(c) => {
                        lng.value === "fr"
                          ? setFunctionalFr(c)
                          : lng.value === "en"
                          ? setFunctionalEn(c)
                          : setFunctionalNl(c);
                      }}
                      onPaste={handlePaste}
                      onImageUploadBefore={handleImageUploadBefore}
                    />
                  </div>
                  <label
                    className={classNames(styles.configLabel, styles.marginTop)}
                  >
                    {_("optional_cookies")} {lng.value}
                  </label>
                  <div className={`ttp-helpeditor ${styles.editorContainer} `}>
                    <SunEditor
                      setOptions={{
                        attributesWhitelist: {
                          // all: "style",
                          input: "checked",
                        },
                        stickyToolbar: false,
                        height: "auto",
                        minHeight: "200px",
                        showPathLabel: false,
                        resizingBar: false,
                        imageUrlInput: false,
                        imageWidth: "75%",
                        formats: [
                          "p",
                          "blockquote",
                          "h2",
                          "h3",
                          "h4",
                          "h5",
                          "h6",
                        ],
                        buttonList: [
                          [
                            "undo",
                            "redo",
                            "formatBlock",
                            "bold",
                            "underline",
                            "italic",
                            "strike",
                            "outdent",
                            "indent",
                            "align",
                            "list",
                            "table",
                            "link",
                            "image",
                            "video",
                            // "fullScreen",
                            "showBlocks",
                          ],
                        ],
                      }}
                      setContents={
                        lng.value === "fr"
                          ? optionalFr
                          : lng.value === "en"
                          ? optionalEn
                          : optionalNl
                      }
                      onChange={(c) => {
                        lng.value === "fr"
                          ? setOptionalFr(c)
                          : lng.value === "en"
                          ? setOptionalEn(c)
                          : setOptionalNl(c);
                      }}
                      onPaste={handlePaste}
                      onImageUploadBefore={handleImageUploadBefore}
                    />
                  </div>
                </div>
              </div>
            ))}
          </Tabs>
        </div>
        <div className={styles.formFooter}>
          <Button
            variant="default"
            onClick={() => {
              resetForm();
              if (cookieRubric) {
                setShowForm(false);
              }
            }}
          >
            {_("cancel")}
          </Button>
          <Button variant="primary" onClick={() => handleSave(false)}>
            {_("save")}
          </Button>
        </div>
      </div>
    );
  };

  const renderRubricForm = () => {
    return (
      <div className={styles.rubric_form}>
        {isSaving && <div className={styles.saving}></div>}
        <div className={styles.formBody}>
          <div className={styles.statusRow}>
            <div className={styles.statusSelect}>
              <label>{_("faq_status")}</label>
              <Select
                styles={SELECT_STYLES}
                options={statusOptions}
                isSearchable={false}
                value={status}
                onChange={(e) => setStatus(e)}
              />
            </div>
          </div>
          <Tabs theme="wtheme">
            {languages.map((lng) => (
              <div
                key={`langtab${lng.value}`}
                label={lng.label}
                icon={`https://tamtam.s3-eu-west-1.amazonaws.com/cdn/widget/${APP_ENV}/img/flags/${lng.value.toUpperCase()}.jpg`}
              >
                <div className={styles.langTab}>
                  <div className={styles.formRow}>
                    <label className={styles.configLabel}>
                      {_("rubric")} {lng.value}
                    </label>
                    <input
                      className={styles.formInput}
                      value={title[lng.value]}
                      onChange={(e) => {
                        setTitle({
                          ...title,
                          [lng.value]: e.target.value,
                        });
                      }}
                    />
                  </div>

                  <label className={styles.configLabel}>
                    {_("description")} {lng.value}
                  </label>
                  <div className={`ttp-helpeditor ${styles.editorContainer} `}>
                    <SunEditor
                      setOptions={{
                        attributesWhitelist: {
                          // all: "style",
                          input: "checked",
                        },
                        stickyToolbar: false,
                        height: "auto",
                        minHeight: "200px",
                        showPathLabel: false,
                        resizingBar: false,
                        imageUrlInput: false,
                        imageWidth: "75%",
                        formats: [
                          "p",
                          "blockquote",
                          "h2",
                          "h3",
                          "h4",
                          "h5",
                          "h6",
                        ],
                        buttonList: [
                          [
                            "undo",
                            "redo",
                            "formatBlock",
                            "bold",
                            "underline",
                            "italic",
                            "strike",
                            "outdent",
                            "indent",
                            "align",
                            "list",
                            "table",
                            "link",
                            "image",
                            "video",
                            // "fullScreen",
                            "showBlocks",
                          ],
                        ],
                      }}
                      setContents={
                        lng.value === "fr"
                          ? introductionFr
                          : lng.value === "en"
                          ? introductionEn
                          : introductionNl
                      }
                      onChange={(c) => {
                        lng.value === "fr"
                          ? setIntroductionFr(c)
                          : lng.value === "en"
                          ? setIntroductionEn(c)
                          : setIntroductionNl(c);
                      }}
                      onPaste={handlePaste}
                      onImageUploadBefore={handleImageUploadBefore}
                    />
                  </div>
                </div>
              </div>
            ))}
          </Tabs>
        </div>
        <div className={styles.formFooter}>
          <Button
            variant="default"
            onClick={() => {
              resetForm();
              setShowForm(false);
              setSelectedRubric(cookieRubric);
            }}
          >
            {_("cancel")}
          </Button>
          <Button variant="primary" onClick={() => handleSave(true)}>
            {_("save")}
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.pageContent}>
      {showForm && !cookieRubric ? (
        <div className={styles.createTermsRubric}>{renderForm()}</div>
      ) : (
        <div className="grid-x">
          <div className={classNames(" cell medium-12 large-4")}>
            <div className={styles.left}>
              <h1>{_("cookies")}</h1>
              {cookieRubric && (
                <>
                  <div
                    className={styles.item}
                    onClick={() => setSelectedRubric(cookieRubric)}
                  >
                    <div
                      className={classNames(
                        styles.rubric,
                        selectedRubric && selectedRubric.id === cookieRubric.id
                          ? styles.rubric_active
                          : ""
                      )}
                    >
                      <p className={styles.chapterTitle}>
                        {_("manage_cookies")}
                      </p>
                      {selectedRubric &&
                        selectedRubric.id === cookieRubric.id && (
                          <div className={styles.arrow}>
                            <svg
                              width="14"
                              height="14"
                              viewBox="0 0 14 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clip-path="url(#clip0_50815_37489)">
                                <path
                                  d="M10.6482 6.50031L4.3525 0.206666C4.07624 -0.0688887 3.62867 -0.0688887 3.35172 0.206666C3.07547 0.482221 3.07547 0.929795 3.35172 1.20535L9.14809 6.99962L3.35242 12.7939C3.07617 13.0695 3.07616 13.517 3.35242 13.7933C3.62867 14.0688 4.07694 14.0688 4.35319 13.7933L10.6489 7.49963C10.921 7.22693 10.921 6.77232 10.6482 6.50031Z"
                                  fill="#18A0FB"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_50815_37489">
                                  <rect width="14" height="14" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                          </div>
                        )}
                    </div>
                  </div>
                  {user && user.isUaAdmin ? (
                    <>
                      <SortableRubricList
                        onSortEnd={(e) => sortRubrics(e)}
                        sortableRubrics={sortableRubrics}
                        setSortableRubrics={setSortableRubrics}
                        language={lng}
                        setSelectedRubric={setSelectedRubric}
                        selectedRubric={selectedRubric}
                        distance={1}
                      />
                      {showSaveOrder ? (
                        <div className={styles.saveOrder}>
                          <Button
                            variant="default"
                            onClick={() => {
                              setShowSaveOrder(false);
                              setSortableRubrics(rubricsList);
                            }}
                          >
                            {_("cancel")}
                          </Button>
                          <Button
                            variant="primary"
                            disabled={savingOrder}
                            onClick={() => handleSaveOrder()}
                            style={{
                              paddingTop: savingOrder ? "15px" : "default",
                              paddingBottom: savingOrder ? "15px" : "default",
                            }}
                          >
                            {savingOrder ? (
                              <DotLoader
                                style={{
                                  height: "10px",
                                }}
                                color={"#fff"}
                              />
                            ) : (
                              _("save_order")
                            )}
                          </Button>
                        </div>
                      ) : (
                        <div className={styles.addRubric}>
                          <button
                            onClick={() => {
                              setSelectedRubric(null);
                              setShowForm(true);
                            }}
                            disabled={showForm}
                          >
                            <svg
                              width="14"
                              height="14"
                              viewBox="0 0 14 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M13.2402 6.0242H7.97672V0.760665C7.97672 0.520889 7.65113 0.000976562 6.99997 0.000976562C6.34881 0.000976562 6.02322 0.520917 6.02322 0.760665V6.02423H0.759688C0.519941 6.0242 0 6.34979 0 7.00092C0 7.65205 0.519941 7.97767 0.759688 7.97767H6.02325V13.2412C6.02325 13.481 6.34881 14.0009 7 14.0009C7.65119 14.0009 7.97675 13.481 7.97675 13.2412V7.97767H13.2403C13.48 7.97767 14 7.65211 14 7.00092C14 6.34974 13.48 6.0242 13.2402 6.0242Z"
                                fill="#18A0FB"
                              />
                            </svg>
                          </button>
                          <label>{_("add_rubric")}</label>
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      {rubricsList.length > 0 &&
                        rubricsList.map((item) => (
                          <>
                            <RubricCard
                              item={item}
                              language={lng}
                              selectedRubric={selectedRubric}
                              setSelectedRubric={setSelectedRubric}
                              setSortableRubrics={setRubricsList}
                              sortableRubrics={rubricsList}
                              edit={false}
                              setVersionLanguage={setVersionLanguage}
                            />
                          </>
                        ))}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
          <div className={classNames(styles.right, " cell medium-12 large-8")}>
            {showForm ? (
              <>
                <div className={styles.fakeTop}></div>
                {selectedRubric && !selectedRubric.versions
                  ? renderForm()
                  : renderRubricForm()}
              </>
            ) : (
              <>
                {selectedRubric && selectedRubric.versions ? (
                  <>
                    {user && user.isUaAdmin ? (
                      <div className={styles.cookieActions}>
                        <div
                          className={styles.edit_cookie}
                          onClick={() => {
                            setShowForm(true);
                            setTitle({
                              fr: selectedRubric.versions[0].titleFr,
                              en: selectedRubric.versions[0].titleEn,
                              nl: selectedRubric.versions[0].titleNl,
                            });
                            setIntroductionEn(
                              selectedRubric.versions[0].introductionEn
                            );
                            setIntroductionFr(
                              selectedRubric.versions[0].introductionFr
                            );
                            setIntroductionNl(
                              selectedRubric.versions[0].introductionNl
                            );
                            setStatus(
                              statusOptions.filter(
                                (el) =>
                                  el.value === selectedRubric.versions[0].status
                              )[0]
                            );
                          }}
                        >
                          <svg
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_50853_38838)">
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M11.082 1.84977C10.942 1.84977 10.8033 1.87736 10.6739 1.93096C10.5445 1.98456 10.4269 2.06313 10.3279 2.16217L2.5624 9.92763L1.99676 12.0016L4.07077 11.436L11.8362 3.67054C11.9353 3.5715 12.0138 3.45392 12.0674 3.32452C12.121 3.19512 12.1486 3.05642 12.1486 2.91635C12.1486 2.77629 12.121 2.63759 12.0674 2.50819C12.0138 2.37879 11.9353 2.26121 11.8362 2.16217C11.7372 2.06313 11.6196 1.98456 11.4902 1.93096C11.3608 1.87736 11.2221 1.84977 11.082 1.84977ZM10.2274 0.853101C10.4984 0.74087 10.7888 0.683105 11.082 0.683105C11.3753 0.683105 11.6657 0.74087 11.9367 0.853101C12.2076 0.965333 12.4538 1.12983 12.6612 1.33721C12.8686 1.54458 13.0331 1.79078 13.1453 2.06173C13.2575 2.33268 13.3153 2.62308 13.3153 2.91635C13.3153 3.20963 13.2575 3.50003 13.1453 3.77098C13.0331 4.04193 12.8686 4.28812 12.6612 4.4955L4.78619 12.3705C4.71441 12.4423 4.62514 12.4941 4.5272 12.5208L1.31886 13.3958C1.11691 13.4509 0.90092 13.3935 0.752899 13.2455C0.604878 13.0975 0.54752 12.8815 0.602599 12.6795L1.4776 9.4712C1.50431 9.37326 1.55612 9.28399 1.6279 9.21221L9.5029 1.33721C9.71028 1.12983 9.95647 0.965333 10.2274 0.853101Z"
                                fill="#6D7F92"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_50853_38838">
                                <rect width="14" height="14" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </div>
                        <div
                          className={styles.delete}
                          onClick={() => setIsOpenDeleteModal(true)}
                        >
                          <svg
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_50853_38831)">
                              <path
                                d="M12.8363 2.0148H9.92352V1.53989C9.92352 0.69079 9.23273 0 8.38363 0H5.61472C4.76562 0 4.07484 0.69079 4.07484 1.53989V2.0148H1.16201C0.946135 2.0148 0.773438 2.1875 0.773438 2.40337C0.773438 2.61924 0.946135 2.79194 1.16201 2.79194H1.86431V11.9219C1.86431 13.0674 2.79687 14 3.94243 14H10.0559C11.2015 14 12.134 13.0674 12.134 11.9219V2.79194H12.8363C13.0522 2.79194 13.2249 2.61924 13.2249 2.40337C13.2249 2.1875 13.0522 2.0148 12.8363 2.0148ZM4.85197 1.53989C4.85197 1.11965 5.19449 0.777138 5.61472 0.777138H8.38363C8.80386 0.777138 9.14638 1.11965 9.14638 1.53989V2.0148H4.85197V1.53989ZM11.3569 11.9219C11.3569 12.6386 10.7726 13.2229 10.0559 13.2229H3.94243C3.22574 13.2229 2.64145 12.6386 2.64145 11.9219V2.79194H11.3598V11.9219H11.3569Z"
                                fill="#F9626D"
                              />
                              <path
                                d="M6.99794 11.8298C7.21381 11.8298 7.3865 11.6571 7.3865 11.4412V4.57363C7.3865 4.35776 7.21381 4.18506 6.99794 4.18506C6.78207 4.18506 6.60938 4.35776 6.60938 4.57363V11.4383C6.60938 11.6542 6.78207 11.8298 6.99794 11.8298Z"
                                fill="#F9626D"
                              />
                              <path
                                d="M4.46279 11.4007C4.67866 11.4007 4.85136 11.228 4.85136 11.0122V4.99941C4.85136 4.78354 4.67866 4.61084 4.46279 4.61084C4.24692 4.61084 4.07422 4.78354 4.07422 4.99941V11.0122C4.07422 11.228 4.24979 11.4007 4.46279 11.4007Z"
                                fill="#F9626D"
                              />
                              <path
                                d="M9.5331 11.4007C9.74897 11.4007 9.92167 11.228 9.92167 11.0122V4.99941C9.92167 4.78354 9.74897 4.61084 9.5331 4.61084C9.31723 4.61084 9.14453 4.78354 9.14453 4.99941V11.0122C9.14453 11.228 9.31723 11.4007 9.5331 11.4007Z"
                                fill="#F9626D"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_50853_38831">
                                <rect width="14" height="14" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </div>
                      </div>
                    ) : (
                      <div className={styles.fakeTop}></div>
                    )}
                    <div
                      className={classNames(
                        styles.rubric_display
                        // user && user.isUaAdmin ? "" : styles.hideOnIpad
                      )}
                    >
                      {isSaving && <div className={styles.saving}></div>}
                      <div className={styles.languageTabs}>
                        {selectedRubric.versions[0] && (
                          <ul>
                            {selectedRubric.versions[0].titleFr.length > 0 && (
                              <li onClick={() => setVersionLanguage("fr")}>
                                <div
                                  className={styles.flag}
                                  style={{
                                    backgroundImage: `url(${`https://tamtam.s3-eu-west-1.amazonaws.com/cdn/widget/${APP_ENV}/img/flags/FR.jpg`})`,
                                  }}
                                ></div>
                                <div
                                  style={{
                                    backgroundColor:
                                      versionLanguage === "fr" && "#18A0FB",
                                  }}
                                  className={styles.border}
                                ></div>
                              </li>
                            )}
                            {selectedRubric.versions[0].titleEn.length > 0 && (
                              <li onClick={() => setVersionLanguage("en")}>
                                <div
                                  className={styles.flag}
                                  style={{
                                    backgroundImage: `url(${`https://tamtam.s3-eu-west-1.amazonaws.com/cdn/widget/${APP_ENV}/img/flags/EN.jpg`})`,
                                  }}
                                ></div>
                                <div
                                  style={{
                                    backgroundColor:
                                      versionLanguage === "en" && "#18A0FB",
                                  }}
                                  className={styles.border}
                                ></div>
                              </li>
                            )}
                            {selectedRubric.versions[0].titleNl.length > 0 && (
                              <li onClick={() => setVersionLanguage("nl")}>
                                <div
                                  className={styles.flag}
                                  style={{
                                    backgroundImage: `url(${`https://tamtam.s3-eu-west-1.amazonaws.com/cdn/widget/${APP_ENV}/img/flags/NL.jpg`})`,
                                  }}
                                ></div>
                                <div
                                  style={{
                                    backgroundColor:
                                      versionLanguage === "nl" && "#18A0FB",
                                  }}
                                  className={styles.border}
                                ></div>
                              </li>
                            )}
                          </ul>
                        )}
                        {user && user.isUaAdmin && (
                          <div className={styles.isActive}>
                            <label
                              className={classNames(
                                styles.label,
                                selectedRubric.versions[0].status ===
                                  "PUBLISHED"
                                  ? styles.active_label
                                  : ""
                              )}
                            >
                              {selectedRubric.versions[0].status === "PUBLISHED"
                                ? _("activated")
                                : _("deactivated")}
                            </label>
                            <Switch
                              isChecked={
                                selectedRubric.versions[0].status ===
                                "PUBLISHED"
                              }
                              onChange={(e) => {
                                changeVersionStatus();
                              }}
                            />
                          </div>
                        )}
                      </div>
                      <p className={styles.title}>
                        {selectedRubric.versions[0][titleAttr]}
                      </p>
                      <div className={styles.details}>
                        <span className={styles.publishedAt}>
                          {" "}
                          {selectedRubric.versions[0].status === "DRAFT"
                            ? _("draft")
                            : _("published_at") +
                              " " +
                              moment(
                                selectedRubric.versions[0].publishedAt,
                                API_DATE_FORMAT
                              )
                                .locale(versionLanguage)
                                .format("DD MMM YYYY")}
                        </span>
                        <label className={styles.dot}></label>
                        <span className={styles.version}>
                          version {selectedRubric.versions[0].name}{" "}
                        </span>
                      </div>
                      <div
                        className="sun-editor-editable"
                        dangerouslySetInnerHTML={{
                          __html: selectedRubric.versions[0][introductionAttr],
                        }}
                      ></div>
                    </div>
                  </>
                ) : (
                  <>
                    {user && user.isUaAdmin ? (
                      <div className={styles.cookieActions}>
                        <div
                          className={styles.edit_cookie}
                          onClick={() => {
                            setShowForm(true);
                            setFunctionalFr(cookieRubric.functionalCookiesFr);
                            setFunctionalEn(cookieRubric.functionalCookiesEn);
                            setFunctionalNl(cookieRubric.functionalCookiesNl);
                            setOptionalFr(cookieRubric.optionalCookiesFr);
                            setOptionalEn(cookieRubric.optionalCookiesEn);
                            setOptionalNl(cookieRubric.optionalCookiesNl);
                          }}
                        >
                          <svg
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_50853_38838)">
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M11.082 1.84977C10.942 1.84977 10.8033 1.87736 10.6739 1.93096C10.5445 1.98456 10.4269 2.06313 10.3279 2.16217L2.5624 9.92763L1.99676 12.0016L4.07077 11.436L11.8362 3.67054C11.9353 3.5715 12.0138 3.45392 12.0674 3.32452C12.121 3.19512 12.1486 3.05642 12.1486 2.91635C12.1486 2.77629 12.121 2.63759 12.0674 2.50819C12.0138 2.37879 11.9353 2.26121 11.8362 2.16217C11.7372 2.06313 11.6196 1.98456 11.4902 1.93096C11.3608 1.87736 11.2221 1.84977 11.082 1.84977ZM10.2274 0.853101C10.4984 0.74087 10.7888 0.683105 11.082 0.683105C11.3753 0.683105 11.6657 0.74087 11.9367 0.853101C12.2076 0.965333 12.4538 1.12983 12.6612 1.33721C12.8686 1.54458 13.0331 1.79078 13.1453 2.06173C13.2575 2.33268 13.3153 2.62308 13.3153 2.91635C13.3153 3.20963 13.2575 3.50003 13.1453 3.77098C13.0331 4.04193 12.8686 4.28812 12.6612 4.4955L4.78619 12.3705C4.71441 12.4423 4.62514 12.4941 4.5272 12.5208L1.31886 13.3958C1.11691 13.4509 0.90092 13.3935 0.752899 13.2455C0.604878 13.0975 0.54752 12.8815 0.602599 12.6795L1.4776 9.4712C1.50431 9.37326 1.55612 9.28399 1.6279 9.21221L9.5029 1.33721C9.71028 1.12983 9.95647 0.965333 10.2274 0.853101Z"
                                fill="#6D7F92"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_50853_38838">
                                <rect width="14" height="14" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </div>
                      </div>
                    ) : (
                      <div className={styles.fakeTop}></div>
                    )}
                    <div className={styles.cookiesDisplay}>
                      <div className={styles.languageTabs}>
                        {cookieRubric && (
                          <ul>
                            {cookieRubric.functionalCookiesFr.length > 0 && (
                              <li onClick={() => setVersionLanguage("fr")}>
                                <div
                                  className={styles.flag}
                                  style={{
                                    backgroundImage: `url(${`https://tamtam.s3-eu-west-1.amazonaws.com/cdn/widget/${APP_ENV}/img/flags/FR.jpg`})`,
                                  }}
                                ></div>
                                <div
                                  style={{
                                    backgroundColor:
                                      versionLanguage === "fr" && "#18A0FB",
                                  }}
                                  className={styles.border}
                                ></div>
                              </li>
                            )}
                            {cookieRubric.functionalCookiesEn.length > 0 && (
                              <li onClick={() => setVersionLanguage("en")}>
                                <div
                                  className={styles.flag}
                                  style={{
                                    backgroundImage: `url(${`https://tamtam.s3-eu-west-1.amazonaws.com/cdn/widget/${APP_ENV}/img/flags/EN.jpg`})`,
                                  }}
                                ></div>
                                <div
                                  style={{
                                    backgroundColor:
                                      versionLanguage === "en" && "#18A0FB",
                                  }}
                                  className={styles.border}
                                ></div>
                              </li>
                            )}
                            {cookieRubric.functionalCookiesNl.length > 0 && (
                              <li onClick={() => setVersionLanguage("nl")}>
                                <div
                                  className={styles.flag}
                                  style={{
                                    backgroundImage: `url(${`https://tamtam.s3-eu-west-1.amazonaws.com/cdn/widget/${APP_ENV}/img/flags/NL.jpg`})`,
                                  }}
                                ></div>
                                <div
                                  style={{
                                    backgroundColor:
                                      versionLanguage === "nl" && "#18A0FB",
                                  }}
                                  className={styles.border}
                                ></div>
                              </li>
                            )}
                          </ul>
                        )}
                      </div>
                      <div className={styles.section}>
                        <div className={styles.section_header}>
                          <h2>{_("functional_cookies")}</h2>
                          <Switch isChecked={true} />
                          <div className={styles.disable}></div>
                        </div>
                        <p
                          className="sun-editor-editable"
                          dangerouslySetInnerHTML={{
                            __html: cookieRubric
                              ? cookieRubric[functionalAttr]
                              : "",
                          }}
                        ></p>
                      </div>
                      <div className={styles.section}>
                        <div className={styles.section_header}>
                          <h2>{_("optional_cookies")}</h2>
                          <Switch
                            isChecked={acceptCookies}
                            onChange={() => handleCookiesSetting()}
                          />
                          {isSaving && <div className={styles.disable}></div>}
                        </div>
                        <p
                          className="sun-editor-editable"
                          dangerouslySetInnerHTML={{
                            __html: cookieRubric
                              ? cookieRubric[optionalAttr]
                              : "",
                          }}
                        ></p>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      )}
      <ModalConfirm
        type="delete"
        isOpen={isOpenDeleteModal}
        inProcess={isSaving}
        onCancel={() => setIsOpenDeleteModal(false)}
        onConfirm={deleteRubric}
        title={_("modal_delete_title")}
        text={_("modal_delete_text")}
        labelNo={_("no")}
        labelYes={_("yes")}
      />
    </div>
  );
};

export default Cookies;
