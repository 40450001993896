import React from "react";
import styles from "./Languages.module.scss";
const Languages = ({ localLanguage, setLocalLanguage }) => {
  return (
    <ul className={styles.languages}>
      <li
        className={localLanguage === "fr" ? styles.active : ""}
        onClick={() => setLocalLanguage("fr")}
      >
        FR
      </li>
      <li
        className={localLanguage === "en" ? styles.active : ""}
        onClick={() => setLocalLanguage("en")}
      >
        EN
      </li>
      <li
        className={localLanguage === "nl" ? styles.active : ""}
        onClick={() => setLocalLanguage("nl")}
      >
        NL
      </li>
    </ul>
  );
};

export default Languages;
