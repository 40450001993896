import moment from "moment-timezone";
import "moment/locale/fr";
import "moment/locale/nl";

export const getDefaultLanguage = () => {
  let lng = navigator.language || navigator.userLanguage;
  lng = lng.split("-")[0];
  return ["fr", "en", "nl"].includes(lng) ? lng : "en";
};

export const isServer = !(
  typeof window !== "undefined" &&
  window.document &&
  window.document.createElement
);

export function getTagName(tag, currentLanguage) {
  if (tag[currentLanguage]) {
    return tag[currentLanguage];
  }
  let languages = ["nameFr", "nameNl", "nameEn"].filter(
    (e) => e !== currentLanguage
  );

  for (let i = 0; i < languages.length; i++) {
    let lng = languages[i];

    if (tag[lng] != null && tag[lng].trim() !== "") {
      return tag[lng];
    }
  }
  return "";
}

export function addLandaSize(img, width = 0, height = 0) {
  let result = img;
  let found = false;

  const splt = img.split(".");
  const ext = splt[splt.length - 1];

  if (width > 0) {
    result += `/w${width}`;
    found = true;
  }
  if (height > 0) {
    const sep = width > 0 ? "-" : "/";
    result += `${sep}h${height}`;
    found = true;
  }
  result += found ? "-noEnlarge" : "/noEnlarge";

  return `${result}.${ext}`.replace(
    "https://s3.eu-west-1.amazonaws.com/tamtam",
    "https://s3.tamtam.pro"
  );
}

export function getAuthorAllHeadlines(newAuthor) {
  let headlines =
    newAuthor.blogRoleInOrganization &&
    newAuthor.blogRoleInOrganization[0] &&
    newAuthor.blogRoleInOrganization[0].headlines &&
    typeof newAuthor.blogRoleInOrganization[0].headlines === "object"
      ? newAuthor.blogRoleInOrganization[0].headlines
      : null;

  if (!headlines) {
    headlines = newAuthor.author;
  }
  return headlines;
}

export function getAuthorHeadlines(newAuthor, selectedLanguage) {
  const headlineAttr = `headline${
    selectedLanguage.charAt(0).toUpperCase() + selectedLanguage.slice(1)
  }`;

  let headlines =
    newAuthor.blogRoleInOrganization &&
    newAuthor.blogRoleInOrganization[0] &&
    newAuthor.blogRoleInOrganization[0][headlineAttr] &&
    newAuthor.blogRoleInOrganization[0][headlineAttr].title
      ? newAuthor.blogRoleInOrganization[0][headlineAttr].title
      : "";
  if (
    !headlines &&
    newAuthor.author &&
    newAuthor.author[headlineAttr] &&
    newAuthor.author[headlineAttr].title
  ) {
    headlines = newAuthor.author[headlineAttr].title;
  }

  return headlines;
}

export const getUserNameForAvatar = (firstName, lastName) => {
  let fName = firstName.split(" ");
  if (fName.length >= 3) {
    return extractFirstLettre(fName, 3);
  } else {
    let lName = lastName.split(" ");
    return extractFirstLettre(fName.concat(lName), 3);
  }
};

function extractFirstLettre(arrayStr, length) {
  let result = "";
  for (let i = 0; i < arrayStr.length; i++) {
    if (arrayStr[i]) {
      result += arrayStr[i].substring(0, 1);
    }
  }
  return result.toUpperCase();
}

export function sortCategoriesAlphabetically(items, key) {
  return items.slice().sort((a, b) => {
    return a[key].toLowerCase().localeCompare(b[key]);
  });
}

export function extractCommunitiesId(communities) {
  let ids = [];
  for (let community of communities) {
    ids.push(community.id);
  }
  return ids;
}

const API_DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";
/**
 * Convert a date from client Timezone to UTC
 *
 * @param date string
 * @param srcFormat string
 * @param destFormat string
 *
 * @return string formatted UTC date (in destFormat format)
 */
export function convertDateToUTC(
  date,
  srcFormat = API_DATE_FORMAT,
  destFormat = API_DATE_FORMAT
) {
  if (!date) {
    return "";
  }

  var offsetMinutes = new Date().getTimezoneOffset();
  return moment(date, [srcFormat])
    .add(offsetMinutes, "minutes")
    .format(destFormat);
}

export function validateUrl(url) {
  var re =
    /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
  return re.test(String(url).toLowerCase());
}

export const getDateLabel = (date) => {
  const d = new Date(date);

  const result = d.toDateString().split(" ");

  const hours =
    parseInt(d.getHours(), 10) < 10 ? "0" + d.getHours() : d.getHours();
  const minutes =
    parseInt(d.getMinutes(), 10) < 10 ? "0" + d.getMinutes() : d.getMinutes();

  return (
    result[2] +
    " " +
    result[1] +
    " " +
    result[3] +
    ", at " +
    hours +
    ":" +
    minutes
  );
};
