import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import classnames from "classnames";
import { useSelector, useDispatch } from "react-redux";

import styles from "../Home.module.scss";
import { getSearchResult, getFilteredRubrics } from "../../../api";
import _ from "../../../i18n";
import { ReactComponent as ArrowDown } from "../assets/ArrowDown.svg";
import { ReactComponent as ArrowRight } from "../assets/ArrowRight.svg";
import Languages from "../../common/Languages";
import { setBannerThemes } from "../../../store";
import domains from "../../../store/domains";

function PageHeader({
  data,
  language,
  token,
  navCommunity,
  domainList,
  currentDomain,
  categoryFilter,
  setCategoryFilter,
  setFilteredRubrics,
  setFetchingRubrics,
  setSearching,
  setRubricList,
  search,
  setSearch,
  localLanguage,
  setLocalLanguage,
}) {
  const dispatch = useDispatch();
  const categories = useSelector((state) => state.categories.items);
  const navigate = useNavigate();
  const [scroll, setScroll] = useState("RIGHT");

  const contentAttr = `content${
    localLanguage.charAt(0).toUpperCase() + localLanguage.slice(1)
  }`;
  const titleAttr = `title${
    localLanguage.charAt(0).toUpperCase() + localLanguage.slice(1)
  }`;
  const urlAttr = `url${
    localLanguage.charAt(0).toUpperCase() + localLanguage.slice(1)
  }`;
  const nameAttr = `name${
    localLanguage.charAt(0).toUpperCase() + localLanguage.slice(1)
  }`;
  const answerAttr = `answer${
    language.charAt(0).toUpperCase() + language.slice(1)
  }`;

  const formatContent = (text) => {
    let textBefore = text.split("<em>")[0];
    let textAfter = text
      .split("<em>")
      .slice(2, text.split("<em>").length)
      .toString();
    textBefore =
      textBefore.length > 150
        ? `... ${textBefore.substring(0, 150)}`
        : textBefore;
    textAfter =
      textAfter.length > 170 ? `${textAfter.substring(0, 170)} ...` : textAfter;

    return `${textBefore} <em>${
      text.split("<em>")[1].split("</em>")[0]
    }</em> ${textAfter}`;
  };

  const validateResponse = (result) => {
    if (result.length > 0) {
      const list = [];
      result.forEach((element) => {
        switch (element.entity) {
          case "theme":
            if (
              element.organization === navCommunity.id &&
              element.domains &&
              JSON.parse(element.domains).length > 0
            ) {
              list.push({
                ...element,
                [titleAttr]: element.highlights[titleAttr][0],
                children: element.pagesList
                  ? JSON.parse(element.pagesList)
                  : null,
                url: `/${localLanguage}/${
                  JSON.parse(element.domains)[0][urlAttr]
                }/themes/${element.id}`,
              });
            }
            break;
          case "page":
            if (
              element.organization === navCommunity.id &&
              element.domains &&
              JSON.parse(element.domains).length > 0 &&
              element.theme
            ) {
              list.push({
                ...element,
                [titleAttr]: element.highlights[titleAttr][0],
                children: element.rubricsList
                  ? JSON.parse(element.rubricsList)
                  : null,
                url: `/${localLanguage}/${
                  JSON.parse(element.domains)[0][urlAttr]
                }/themes/${element.theme}/pages/${element.id}`,
              });
            }
            break;
          case "faq_rubric":
            if (
              element.organization === navCommunity.id &&
              element.domains &&
              JSON.parse(element.domains).length > 0 &&
              element.theme &&
              element.pages &&
              element.pages.split(",").length > 0 &&
              element.status === "PUBLISHED"
            ) {
              list.push({
                ...element,
                [titleAttr]: element.highlights[titleAttr][0],
                children: element.questionsList
                  ? JSON.parse(element.questionsList)
                  : null,
                url: `/${localLanguage}/${
                  JSON.parse(element.domains)[0][urlAttr]
                }/themes/${element.theme}/pages/${
                  element.pages.split(",")[0]
                }/rubrics/${element.id}`,
              });
            }
            break;
          case "faq_question":
            if (
              element.domains &&
              JSON.parse(element.domains).length > 0 &&
              element.theme &&
              element.pages &&
              element.pages.split(",").length > 0 &&
              element.rubric &&
              element.status === "PUBLISHED"
            ) {
              list.push({
                ...element,
                [titleAttr]: element.highlights[titleAttr][0],
                [answerAttr]:
                  element[answerAttr] && element[answerAttr].length > 350
                    ? element[answerAttr].substring(0, 350) + "..."
                    : element[answerAttr],
                url: `/${localLanguage}/${
                  JSON.parse(element.domains)[0][urlAttr]
                }/themes/${element.theme}/pages/${
                  element.pages.split(",")[0]
                }/rubrics/${element.rubric}?q=${element.id}`,
              });
            }
            break;
          case "faq_answer":
            if (
              element.domains &&
              JSON.parse(element.domains).length > 0 &&
              element.theme &&
              element.pages &&
              element.pages.split(",").length > 0 &&
              element.rubric &&
              element.question &&
              element.questionStatus === "PUBLISHED"
            ) {
              list.push({
                ...element,
                [contentAttr]: formatContent(
                  element.highlights[contentAttr][0]
                ),
                url: `/${localLanguage}/${
                  JSON.parse(element.domains)[0][urlAttr]
                }/themes/${element.theme}/pages/${
                  element.pages.split(",")[0]
                }/rubrics/${element.rubric}?q=${element.question}`,
              });
            }
            break;

          default:
            break;
        }
      });
      setRubricList(list);
    }
  };

  const searchValue = async (e) => {
    setSearch(e.target.value);
    if (e.target.value.trim().length < 3) {
      setRubricList(null);
    } else {
      setSearching(true);
      try {
        const response = await getSearchResult({
          value: e.target.value,
          language: localLanguage,
        });
        validateResponse(response.data.data);
        setSearching(false);
      } catch (error) {
        if (error.response && error.response.status === 404) {
          setRubricList(null);
          setSearching(false);
        }
      }

      // if (response && response.length > 0) {
      //   const list = response.map((item) => {
      //     if (item.questions && item.rubric) {
      //       const ids = item.questions.map((item) => item.id);
      //       const rub = item.rubric;
      //       rub.questions = [
      //         ...rub.questions
      //           .filter((item) => ids.includes(item.id))
      //           .map((q) => {
      //             if (q) {
      //               q.search = true;
      //             }
      //             return q;
      //           }),
      //         ...rub.questions.filter((item) => !ids.includes(item.id)),
      //       ];
      //       return rub;
      //     } else {
      //       return item;
      //     }
      //   });
      //   const empty = [];
      //   list.forEach((element) => {
      //     if (!empty.map((item) => item.id).includes(element.id))
      //       empty.push(element);
      //   });
      //   setRubricList(empty);
      // } else {
      //   setRubricList(null);
      // }
      // if (response !== "canceled") {
      //   setSearching(false);
      // }
    }
  };

  const filterRubrics = async () => {
    try {
      setFetchingRubrics(true);
      const res = await getFilteredRubrics({
        token,
        domain: currentDomain.id,
        category: categoryFilter.id,
        communityId: navCommunity.id,
      });
      if (res) {
        setFilteredRubrics(res.data.data);
      } else {
        setFilteredRubrics(null);
      }
      setFetchingRubrics(false);
    } catch (error) {
      setFetchingRubrics(false);
      setFilteredRubrics(null);
    }
  };

  useEffect(() => {
    filterRubrics();
    setSearch("");
  }, [categoryFilter, currentDomain]);
  useEffect(() => {
    if (search.length > 0) setCategoryFilter(null);
  }, [search]);

  return (
    <div className={styles.header}>
      <div className={styles.needHelp}>
        <h3>{_("home_title2")}</h3>
        <span>{_("home_sub_title2")}</span>
      </div>
      <div className={classnames(styles.search, "grid-x")}>
        <div
          className={classnames(
            styles.domaine,
            "cell small-12 medium-4 large-2"
          )}
        >
          <span
            onClick={() => {
              const el = document.getElementById("domain_list");
              el.classList.toggle(`${styles.activeList}`);
            }}
          >
            {currentDomain[titleAttr].length > 12
              ? currentDomain[titleAttr].substring(0, 12) + "..."
              : currentDomain[titleAttr]}
            <ArrowDown />
          </span>

          <ul id="domain_list">
            {domainList &&
              domainList.map((item) => (
                <li
                  key={item.id}
                  onClick={() => {
                    navigate(`/${language}/${item[urlAttr]}`);
                    const el = document.getElementById("domain_list");
                    el.classList.toggle(`${styles.activeList}`);
                    dispatch(setBannerThemes([]));
                  }}
                  style={{
                    display: item.id === currentDomain.id ? "none" : "block",
                  }}
                >
                  {item[titleAttr].length > 20
                    ? item[titleAttr].substring(0, 20) + "..."
                    : item[titleAttr]}
                </li>
              ))}
          </ul>
        </div>
        <div
          className={classnames(
            styles.inputSearch,
            "cell small-12 medium-6 large-9"
          )}
        >
          <div className={styles.inputContent}>
            <input
              type="text"
              placeholder={_("search_placeholder")}
              value={search}
              id={"search_input"}
              autoFocus={false}
              onChange={(e) => searchValue(e)}
              autoComplete={"off"}
            />
          </div>
        </div>
        <div className={"cell small-12 medium-2 large-1"}>
          <Languages
            localLanguage={localLanguage}
            setLocalLanguage={setLocalLanguage}
          />
        </div>
      </div>
      <div className={styles.categoriesContainer}>
        {(scroll === "LEFT" || scroll === "BOTH") && (
          <div
            className={classnames(styles.scrollButton, styles.scrollButtonBack)}
          >
            <button
              onClick={() => {
                const el = document.getElementById("list");
                el.scrollLeft -= 300;
                if (el.scrollLeft === 0) {
                  setScroll("RIGHT");
                } else if (
                  el.scrollLeft > 0
                  // &&
                  // el.scrollLeft !== el.scrollWidth - el.offsetWidth
                ) {
                  setScroll("BOTH");
                }
              }}
            >
              <ArrowRight />
            </button>
          </div>
        )}
        <ul id="list" className={styles.categories}>
          {categories &&
            categories.map((item) => (
              <li
                key={item.id}
                className={
                  categoryFilter &&
                  categoryFilter.id === item.id &&
                  styles.activeCategory
                }
                onClick={() => setCategoryFilter(item)}
              >
                {item[nameAttr]}
              </li>
            ))}
        </ul>
        {(scroll === "RIGHT" || scroll === "BOTH") && (
          <div
            className={classnames(
              styles.scrollButton,
              styles.scrollButtonForward
            )}
          >
            <button
              onClick={() => {
                const el = document.getElementById("list");
                el.scrollLeft += 300;
                if (el.scrollLeft - 0.5 === el.scrollWidth - el.offsetWidth) {
                  setScroll("LEFT");
                } else if (el.scrollLeft > 0) {
                  setScroll("BOTH");
                }
              }}
            >
              <ArrowRight />
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default PageHeader;
