import {
  AUTH_COOKIE_NAME,
  APP_ENV,
  TTP_HUB_URL,
  TTP_HOME_URL,
} from "../config";

export function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(";");

  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }

  return "";
}

export function setCookie(name, value, expires, path, domain, secure) {
  document.cookie =
    name +
    " = " +
    escape(value) +
    "  " +
    (!expires ? "" : "; expires = " + expires.toUTCString()) +
    (!path ? "" : "; path = " + path) +
    (!domain ? "" : "; domain = " + domain) +
    (secure === true ? "; secure" : "");
}

export const getAuthInfosFromCookie = () => {
  const cookie = getCookie(AUTH_COOKIE_NAME);

  if (!cookie) {
    return null;
  }

  return JSON.parse(cookie);
};

export const authHasExpired = (authInfos) => {
  const now = Math.floor(new Date().getTime() / 1000);

  return (
    !authInfos ||
    typeof authInfos !== "object" ||
    !authInfos.createdAt ||
    !authInfos.expiresIn ||
    now >= parseFloat(authInfos.createdAt) + parseFloat(authInfos.expiresIn)
  );
};

export const getLocalStorageUser = () => {
  const stringUser = localStorage.getItem("user");
  let user = [];

  try {
    user = JSON.parse(stringUser);
  } catch (e) {}
  return user instanceof Object ? user : {};
};

export const getLocalStorageAuth = () => {
  const stringAuth = localStorage.getItem("auth");
  let auth = {};

  try {
    auth = JSON.parse(stringAuth);
  } catch (e) {}
  return typeof auth === "object" ? auth : {};
};

export const setLocalStorageAuth = (auth) => {
  if (typeof auth === "object") {
    localStorage.setItem("auth", JSON.stringify(auth));
    return auth;
  }
  if (auth == null) {
    localStorage.removeItem("auth");
  }
};

export const getLocalStorageCookie = () => {
  const stringAuth = localStorage.getItem("cookie_auth");
  let cookie_auth = null;

  try {
    cookie_auth = JSON.parse(stringAuth);
  } catch (e) {}
  return cookie_auth && typeof cookie_auth === "object" ? cookie_auth : null;
};

export const setLocalStorageCookie = (cookie_auth) => {
  if (cookie_auth && typeof cookie_auth === "object") {
    localStorage.setItem("cookie_auth", JSON.stringify(cookie_auth));
    return cookie_auth;
  }
  if (cookie_auth == null) {
    localStorage.removeItem("auth");
  }
};

export const logout = (path = "") => {
  setLocalStorageCookie(null);

  let dtExpire = new Date();
  dtExpire.setTime(dtExpire.getTime() - 3600000 * 1000);

  setCookie(`ttp_auth_${APP_ENV}`, "", dtExpire, "/", "tamtam.pro");
  setCookie(`ttp_session_${APP_ENV}`, "", dtExpire, "/", "tamtam.pro");
  setCookie("XSRF-TOKEN", "", dtExpire, "/", "tamtam.pro");
  setCookie("laravel_session", "", dtExpire, "/", "tamtam.pro");

  const goto = encodeURIComponent(TTP_HUB_URL + path);
  window.location.assign(`${TTP_HOME_URL}?goto=${goto}`);
};

export const getUserCurrentNavCommunity = (user, currentNavCommunityId) => {
  if (
    currentNavCommunityId &&
    user.communities &&
    user.communities.length > 0
  ) {
    let currentUserNavCommunity = user.communities.filter(
      (c) => c.id == currentNavCommunityId
    )[0];
    if (currentUserNavCommunity) {
      return currentUserNavCommunity;
    }
  }
  return null;
};

const getLowestLoggedAs = (values) => {
  if (values.includes("REDACTOR")) {
    return "REDACTOR";
  } else if (values.includes("REDACTOR_MANDATED")) {
    return "REDACTOR_MANDATED";
  } else if (values.includes("AUTHOR")) {
    return "AUTHOR";
  } else if (values.includes("AUTHOR_MANDATED")) {
    return "AUTHOR_MANDATED";
  } else if (values.includes("CHIEF_EDITOR")) {
    return "CHIEF_EDITOR";
  }

  return "GUEST";
};

const getUserMinLoggedAs = (user) => {
  const { communities } = user;

  let loggedAsValues = [];

  if (communities && communities.length > 0) {
    communities.forEach((community) => {
      const { blogs } = community;
      if (blogs && blogs.length > 0) {
        blogs.forEach((blog) => {
          if (
            blog.role &&
            ["AUTHOR", "REDACTOR", "CHIEF_EDITOR"].includes(blog.role)
          ) {
            let loggedAsValue = blog.role;
            if (
              blog.mandated == 1 &&
              ["AUTHOR", "REDACTOR"].includes(blog.role)
            ) {
              loggedAsValue += "_MANDATED";
            }
            loggedAsValues.push(loggedAsValue);
          }
        });
      }
    });
  }

  let loggedAsMin = getLowestLoggedAs(loggedAsValues);
  return loggedAsMin;
};

export const getUserLoggedAsValues = (user, currentNavCommunity) => {
  let loggedAsMin = getUserMinLoggedAs(user);
  let loggedAs = loggedAsMin;
  let loggedAsPost = null;

  if (currentNavCommunity) {
    const { blogs } = currentNavCommunity;
    if (blogs && blogs.length > 0) {
      blogs.forEach((blog) => {
        if (
          blog.role &&
          ["AUTHOR", "REDACTOR", "CHIEF_EDITOR"].includes(blog.role)
        ) {
          loggedAs = blog.role;
          if (
            blog.mandated == 1 &&
            ["AUTHOR", "REDACTOR"].includes(blog.role)
          ) {
            loggedAs += "_MANDATED";
          }
        }
      });
    }

    if (user.roles && user.roles.length > 0) {
      let currentUserRoleInNavCommunity = user.roles.filter(
        (role) => role.type && role.organization.id == currentNavCommunity.id
      )[0];
      if (currentUserRoleInNavCommunity) {
        loggedAsPost = currentUserRoleInNavCommunity.type;
      }
    }
  }

  return { loggedAsMin, loggedAs, loggedAsPost };
};
