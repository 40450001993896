import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { arrayMoveImmutable } from "array-move";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { ModalConfirm } from "tamtam-components";
import { toast } from "react-toastify";

import mediaStyles from "./MediaForm.module.scss";
import styles from "./ArticleForm.module.scss";
import _ from "../../../i18n";
import IconSearch from "../../common/Icons/IconSearch";
import IconArrowRight from "../../common/Icons/IconArrowRight";
import Article from "../../common/Article";
import Loader from "../../common/Loader";
import { getArticles, removeQuestion, saveQuestion } from "../../../api";
import Button from "../../common/Button";
import { convertDateToUTC } from "../../../utils";
import { deleteQuestion } from "../../../store";
import { IconTrash } from "../../common/Icons";

let tabsVals = [/* "my_publications", */ "fff_publications"];

const DATE_FORMAT = "DD-MM-YYYY HH:mm";
const API_DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";

const ArticleForm = ({
  auth,
  onCancel,
  afterSave,
  status,
  publishedAt,
  isPrivate,
  privateGroups,
  currentRubric,
  currentQuestion,
}) => {
  const categories = useSelector((state) => state.categories.items);
  const language = useSelector((state) => state.params.language);

  const [tab, setTab] = useState("fff_publications");
  const [scroll, setScroll] = useState("RIGHT");
  const [search, setSearch] = useState("");
  const [selectedArticles, setSelectedArticles] = useState([]);
  const [categoryFilter, setCategoryFilter] = useState(null);
  const [start, setStart] = useState(0);
  const [articles, setArticles] = useState([]);
  const [loadingArticles, setLoadingArticles] = useState(false);
  const [scrollEnd, setScrollEnd] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const dispatch = useDispatch();
  const nameAttr = `name${
    language.charAt(0).toUpperCase() + language.slice(1)
  }`;
  const { navCommunity, token } = auth;
  const sortArticles = ({ oldIndex, newIndex }) => {
    setSelectedArticles(
      arrayMoveImmutable(selectedArticles, oldIndex, newIndex)
    );
  };

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }
  };

  useEffect(() => {
    if (scrollEnd) {
      setStart(start + 10);
    }
  }, [scrollEnd]);

  let { isLoading } = useQuery(
    ["listArticles", navCommunity, token, tab, search, categoryFilter, start],
    async () => {
      if (token) {
        try {
          setLoadingArticles(true);
          const response = await getArticles({
            token,
            communityId: tab === "fff_publications" ? 9 : navCommunity.id,
            language,
            search,
            category: categoryFilter,
            start,
          });
          setLoadingArticles(false);
          if (start > 0 && articles.length > 0) {
            setArticles([...articles, ...response.data.data]);
          } else {
            setArticles(response.data.data);
          }
        } catch (error) {
          if (error.response.data && error.response.data.statusCode === 404)
            setLoadingArticles(false);
        }
      }
    }
  );

  const handleSave = async () => {
    let tabPrivateGroups = privateGroups.map((item) => {
      return item.id;
    });

    let data = {
      community: navCommunity ? navCommunity.id : 9,
      status: status.value,
      rubric: currentRubric && currentRubric.id,
      isPrivate,
      privateGroups: tabPrivateGroups,
      publishedAt: publishedAt
        ? convertDateToUTC(publishedAt, DATE_FORMAT, API_DATE_FORMAT)
        : "",
      objectType: "ARTICLE",
      objectIds: selectedArticles.map((item) => item.id),
    };
    if (currentQuestion) {
      data.id = currentQuestion.id;
    }
    setIsSaving(true);
    try {
      await saveQuestion(token, data);
      setIsSaving(false);
      afterSave();
    } catch (e) {
      setIsSaving(false);
    }
  };

  const handleDelete = () => {
    setIsDeleting(true);
    removeQuestion(token, currentQuestion.id)
      .then(() => {
        dispatch(deleteQuestion(currentQuestion.id));
        setIsDeleting(false);
        afterSave();
        setIsOpenDeleteModal(false);
        onCancel();
      })
      .catch((err) => {
        setIsDeleting(false);
        setIsOpenDeleteModal(false);
        toast.error(_("rubric_delete_error"));
      });
  };

  return (
    <div className={mediaStyles.content}>
      {currentQuestion ? (
        <div style={{ height: "13.5rem", position: "relative", zIndex: "-1" }}>
          <Article article={currentQuestion.object} language={language} />
        </div>
      ) : (
        <>
          {" "}
          {selectedArticles.length > 0 && (
            <>
              <div className={mediaStyles.section_title}>
                {_("selected_articles")}
              </div>
              <div className={mediaStyles.selected_media_container}>
                {selectedArticles.map((item) => (
                  <Article
                    article={item}
                    setArticles={setSelectedArticles}
                    articles={selectedArticles}
                    language={language}
                  />
                ))}
              </div>
              <div className={mediaStyles.section_title}>
                {_("disponible_articles")}
              </div>
            </>
          )}
          <div className={mediaStyles.header}>
            <ul className={mediaStyles.tabs}>
              {tabsVals.map((v) => (
                <li
                  key={v}
                  className={classNames(
                    tab === v ? mediaStyles.selected : "",
                    styles.tab
                  )}
                  onClick={() => setTab(v)}
                >
                  {_(`${v}`)}
                </li>
              ))}
            </ul>
            <div className={mediaStyles.search}>
              <IconSearch />
              <input
                type="text"
                placeholder={_("search_placeholder")}
                value={search}
                onChange={(e) => {
                  setArticles([]);
                  setStart(0);
                  setSearch(e.target.value);
                }}
              />
            </div>
          </div>
          <div className={mediaStyles.categoriesContainer}>
            {(scroll === "LEFT" || scroll === "BOTH") && (
              <div
                className={classNames(
                  mediaStyles.scrollButton,
                  mediaStyles.scrollButtonBack
                )}
              >
                <button
                  onClick={() => {
                    const el = document.getElementById("list");
                    el.scrollLeft -= 300;
                    if (el.scrollLeft === 0) {
                      setScroll("RIGHT");
                    } else if (el.scrollLeft > 0) {
                      setScroll("BOTH");
                    }
                  }}
                >
                  <IconArrowRight />
                </button>
              </div>
            )}
            <ul id="list" className={mediaStyles.categories}>
              {categories &&
                categories.map((item) => (
                  <li
                    key={item.id}
                    className={
                      categoryFilter &&
                      categoryFilter.id === item.id &&
                      mediaStyles.activeCategory
                    }
                    onClick={() =>
                      setCategoryFilter(
                        categoryFilter && categoryFilter.id === item.id
                          ? null
                          : item
                      )
                    }
                  >
                    {item[nameAttr]}
                  </li>
                ))}
            </ul>
            {(scroll === "RIGHT" || scroll === "BOTH") && (
              <div
                className={classNames(
                  mediaStyles.scrollButton,
                  mediaStyles.scrollButtonForward
                )}
              >
                <button
                  onClick={() => {
                    const el = document.getElementById("list");
                    el.scrollLeft += 300;
                    if (
                      el.scrollLeft - 0.5 ===
                      el.scrollWidth - el.offsetWidth
                    ) {
                      setScroll("LEFT");
                    } else if (el.scrollLeft > 0) {
                      setScroll("BOTH");
                    }
                  }}
                >
                  <IconArrowRight />
                </button>
              </div>
            )}
          </div>
          <div
            className={mediaStyles.listContainer}
            onScroll={(e) => {
              if (
                e.target.offsetHeight + e.target.scrollTop + 0.5 >=
                e.target.scrollHeight
              ) {
                setScrollEnd(true);
              } else {
                setScrollEnd(false);
              }
            }}
          >
            {loadingArticles && start === 0 ? (
              <Loader
                style={{
                  width: "100%",
                  height: "1rem",
                  margin: "auto",
                }}
                color={"#18a0fb"}
              />
            ) : (
              articles && (
                <div className={styles.media_list}>
                  {articles
                    .filter(
                      (item) =>
                        !currentRubric.questions
                          .filter((el) => el.objectType === "ARTICLE")
                          .map((el) => el.objectId)
                          .includes(item.id)
                    )
                    .filter(
                      (item) =>
                        !selectedArticles.map((el) => el.id).includes(item.id)
                    )
                    .map((item) => (
                      <Article
                        article={item}
                        setArticles={setSelectedArticles}
                        articles={selectedArticles}
                        language={language}
                      />
                    ))}
                </div>
              )
            )}
            {loadingArticles && start > 0 && (
              <Loader
                style={{
                  width: "100%",
                  height: "1rem",
                  margin: "auto",
                  marginTop: "1rem",
                }}
                color={"#18a0fb"}
              />
            )}
          </div>
        </>
      )}

      <div className={mediaStyles.formControls}>
        <Button onClick={() => handleCancel()} variant="default">
          {_("cancel")}
        </Button>

        <div className={mediaStyles.actions}>
          {currentQuestion && (
            <span onClick={() => setIsOpenDeleteModal(true)}>
              <IconTrash size={18} />
            </span>
          )}
          {isSaving ? (
            <Button
              variant="primary"
              style={{ paddingTop: "15px", paddingBottom: "15px" }}
              className={styles.okBtn}
            >
              <Loader
                style={{
                  height: "10px",
                }}
                color={"#fff"}
              />
            </Button>
          ) : (
            <Button
              onClick={() => handleSave()}
              variant="primary"
              className={mediaStyles.okBtn}
              disabled={selectedArticles.length === 0 && !currentQuestion}
            >
              {_("save")}
            </Button>
          )}
        </div>
      </div>
      <ModalConfirm
        type="delete"
        isOpen={isOpenDeleteModal}
        inProcess={isDeleting}
        onCancel={() => setIsOpenDeleteModal(false)}
        onConfirm={handleDelete}
        title={_("modal_delete_title")}
        text={_("modal_delete_text")}
        labelNo={_("no")}
        labelYes={_("yes")}
      />
    </div>
  );
};

export default ArticleForm;
