import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  getThemes,
  saveTheme as apiSaveTheme,
  savePage as apiSavePage,
  getListThemes,
} from "../api";

export const fetchThemes = createAsyncThunk(
  "themes/fetchThemes",
  async ({ communityId }, { dispatch, getState }) => {
    const { token } = getState().auth;
    const response = await getThemes({
      token,
      communityId,
    });
    return response;
  }
);

export const fetchBannerThemes = createAsyncThunk(
  "themes/fetchBannerThemes",
  async ({ domain, rubrics, isEditable }, { dispatch, getState }) => {
    const { token, navCommunity } = getState().auth;
    const response = await getListThemes({
      token,
      domain,
      rubrics,
      community: navCommunity ? navCommunity.id : null,
      isEditable,
    });
    return response;
  }
);

export const saveTheme = createAsyncThunk(
  "themes/saveTheme",
  async (data, { dispatch, getState, rejectWithValue }) => {
    const { token } = getState().auth;
    try {
      const response = await apiSaveTheme(token, data);
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const savePage = createAsyncThunk(
  "themes/savePage",
  async (data, { dispatch, getState, rejectWithValue }) => {
    const { token } = getState().auth;
    try {
      const response = await apiSavePage(token, data);
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const initialState = {
  fetching: false,
  fetched: false,
  items: [],
  bannerThemesFetching: false,
  bannerThemesError: null,
  bannerThemes: [],
  currentLanguage: "",
  error: null,
  isSaving: false,
  pageIsSaving: false,
};

export const themesSlice = createSlice({
  name: "themes",
  initialState,
  reducers: {
    setThemeCurrentLanguage: (state, { payload }) => {
      state.currentLanguage = payload;
    },
    setBannerThemes: (state, { payload }) => {
      state.bannerThemes = payload;
    },
  },
  extraReducers: {
    [fetchThemes.pending]: (state, action) => {
      state.fetching = true;
    },
    [fetchThemes.fulfilled]: (state, { payload }) => {
      state.fetching = false;
      state.items = payload.data.data;
    },
    [fetchThemes.rejected]: (state, { payload }) => {
      state.error = payload;
      state.fetching = false;
      state.items = [];
    },
    [fetchBannerThemes.pending]: (state, action) => {
      state.bannerThemesFetching = true;
    },
    [fetchBannerThemes.fulfilled]: (state, { payload }) => {
      state.bannerThemesFetching = false;
      state.bannerThemes = payload.data.data;
    },
    [fetchBannerThemes.rejected]: (state, { payload }) => {
      state.bannerThemesError = payload;
      state.bannerThemesFetching = false;
      state.bannerThemes = [];
    },
    [saveTheme.pending]: (state, action) => {
      state.isSaving = true;
    },
    [saveTheme.fulfilled]: (state, { payload }) => {
      state.isSaving = false;
      state.error = null;
      state.items.push(payload.data.data);
    },
    [saveTheme.rejected]: (state, { payload }) => {
      state.error = payload.title;
      state.isSaving = false;
    },
    [savePage.pending]: (state, action) => {
      state.pageIsSaving = true;
    },
    [savePage.fulfilled]: (state, { payload }) => {
      state.pageIsSaving = false;
      state.error = null;
      const page = payload.data.data;
      state.items = state.items.map((theme) => {
        if (theme.id === page.themeId) {
          if (theme.pages === undefined) {
            theme.pages = [];
          }
          theme.pages.push(page);
        }
        return theme;
      });
    },
    [savePage.rejected]: (state, { payload }) => {
      state.error = payload.title;
      state.pageIsSaving = false;
    },
  },
});

export const { setThemeCurrentLanguage, setBannerThemes } = themesSlice.actions;

export default themesSlice.reducer;
