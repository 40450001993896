import { SortableElement } from "react-sortable-hoc";

import RubricCard from "./RubricCard";

export default SortableElement(
  ({
    item,
    language,
    setSelectedRubric,
    selectedRubric,
    setSortableRubrics,
    sortableRubrics,
  }) => {
    return (
      <RubricCard
        item={item}
        language={language}
        selectedRubric={selectedRubric}
        setSelectedRubric={setSelectedRubric}
        setSortableRubrics={setSortableRubrics}
        sortableRubrics={sortableRubrics}
        edit={true}
        order={true}
      />
    );
  }
);
