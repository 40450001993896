import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Autosuggest from "react-autosuggest";

import { fetchChains } from "../../../store";
import { getUserNameForAvatar, addLandaSize } from "../../../utils";

import { IconClose } from "../../common/Icons";
import _ from "../../../i18n";
import styles from "./AuthorSuggestion.module.scss";
import "./AutoSuggest.scss";

export default function ChainSuggestion(props) {
  const [addingAuthor, setAddingAuthor] = useState(false);
  const [authorInputValue, setAuthorInputValue] = useState("");
  const chainsSuggestions = useSelector((state) => state.users.chains);
  const loadingChainSuggestions = useSelector((state) => state.users.fetching);

  const dispatch = useDispatch();

  let { selectedCommunity, selectedLanguage } = props;

  const nameAttr = `name${
    selectedLanguage.charAt(0).toUpperCase() + selectedLanguage.slice(1)
  }`;

  const toggleAddAuthor = () => {
    setAuthorInputValue("");
    setAddingAuthor(!addingAuthor);
  };

  const loadChainSuggestions = (value) => {
    const communityId = selectedCommunity ? selectedCommunity.value : null;
    dispatch(fetchChains({ word: value, communityId }));
  };

  const handleSelectChain = (newChain) => {
    let avatar = null;
    let avatarUrl = null;
    if (newChain.mediaChain) {
      let media = newChain.mediaChain.filter(
        (item) => item.language === selectedLanguage
      );
      if (media && media.length === 1) {
        avatar = media[0].avatar;
        avatarUrl = media[0].avatarUrl;
      }
    }

    props.onChainChange({
      id: newChain.id,
      signature: {
        title: newChain.company,
        head: newChain.headline,
      },
      company: newChain.company,
      head: newChain.headline,
      enableAvatar: true,
      avatar: avatarUrl,
      avatarUrl: avatarUrl,
      nameFr: newChain.nameFr,
      nameEn: newChain.nameEn,
      nameNl: newChain.nameNl,
      priority: 0,
      isAvatar: true,
    });

    setAddingAuthor(false);
  };

  if (!addingAuthor) {
    if (!selectedCommunity) {
      return null;
    }
    return (
      <div className={styles.addChain} onClick={toggleAddAuthor}>
        <i className="icon-ttp-plus-outline"></i>
        <p>{_("add_chain")}</p>
      </div>
    );
  }

  const inputProps = {
    placeholder: _("type_chain"),
    value: authorInputValue,
    onChange: (event, { newValue }) => setAuthorInputValue(newValue),
    autoFocus: true,
    spellCheck: false,
    autoCorrect: "off",
    className: styles.autoSuggestInput,
  };

  let chainsList = chainsSuggestions
    ? [{ title: _("chains"), suggestions: chainsSuggestions }]
    : [];
  return (
    <div>
      <span className={styles.closeCoauthor} onClick={toggleAddAuthor}>
        <IconClose />
      </span>
      <Autosuggest
        multiSection={true}
        suggestions={chainsList}
        renderSectionTitle={(section) =>
          section.suggestions.length == 0 ? null : section.title
        }
        getSectionSuggestions={(section) => section.suggestions}
        shouldRenderSuggestions={(value) => value && value.trim().length > 2}
        onSuggestionsFetchRequested={({ value }) => loadChainSuggestions(value)}
        onSuggestionSelected={(e, { suggestion }) => {
          handleSelectChain(suggestion);
        }}
        getSuggestionValue={(suggestion) => {
          return suggestion.company;
        }}
        renderSuggestion={(suggestion) => {
          let avatarUrl = suggestion.avatarUrl ? suggestion.avatarUrl : null;

          if (suggestion.mediaChain) {
            let media = suggestion.mediaChain.filter(
              (item) => item.language === selectedLanguage
            );
            if (media && media.length === 1) {
              avatarUrl = media[0].avatarUrl;
            }
          }
          return (
            <div className={styles.coauthorSuggestion}>
              {avatarUrl ? (
                <div
                  className={styles.imgWrap}
                  style={{
                    backgroundImage: `url(${addLandaSize(avatarUrl, 240)})`,
                  }}
                ></div>
              ) : (
                <div className={`${styles.imgWrap} ${styles.emptyAvatar}`}>
                  <span style={{ fontSize: "inherit" }}>
                    {getUserNameForAvatar("", suggestion[nameAttr])}
                  </span>
                </div>
              )}

              <div className={styles.content}>
                <h4>{suggestion[nameAttr]}</h4>
              </div>
            </div>
          );
        }}
        inputProps={inputProps}
        renderSuggestionsContainer={({ containerProps, children, query }) => {
          return (
            <div {...containerProps}>
              {loadingChainSuggestions ? (
                <span className={styles.sidebarPlaceholder}>
                  {_("loading_suggestions")}
                </span>
              ) : (
                children
              )}
            </div>
          );
        }}
      />
    </div>
  );
}
