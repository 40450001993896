import React, { useState } from "react";
import { useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import { useQuery } from "react-query";

import _ from "../../i18n";
import Sidebar from "../Themes/Sidebar";
import styles from "./Domains.module.scss";
import DomainItem from "./DomainItem";
import ThemesLayout from "../ThemesLayout";
import { getDomainsList } from "../../api";

export default function DomainsList() {
  const auth = useSelector((state) => state.auth);
  const lng = useSelector((state) => state.params.language);
  const [sidebarOpened, setSidebarOpened] = useState(false);
  const [selectedDomain, setSelectedDomain] = useState(null);
  const [domains, setDomains] = useState(null);

  let { isFetching } = useQuery(
    ["getDomainsList", auth.currentCommunity, auth.token],
    async () => {
      if (auth.token) {
        const response = await getDomainsList({
          token: auth.token,
          community: auth.navCommunity.id,
        });
        setDomains(response.data.data);
      }
    }
  );

  const handleShowSidebar = (domain) => {
    setSelectedDomain(domain);
    setSidebarOpened(true);
  };

  const afterDomainSave = ({ val, isEdit }) => {
    if (isEdit) {
      setDomains(
        domains.map((domain) => {
          if (domain.id === val.id) {
            return val;
          }
          return domain;
        })
      );
    } else {
      setDomains([...domains, val]);
    }
  };

  const afterDomainDelete = ({ id }) => {
    setDomains(domains.filter((domain) => domain.id !== id));
  };

  return (
    <ThemesLayout>
      <>
        <div className={styles.container}>
          <h1 className={styles.title}>{_("domain_title")}</h1>
          <h2 className={styles.subtitle}>{_("domain_sub_title")}</h2>

          <div className={`${styles.itemsContainer} grid-x`}>
            {isFetching
              ? [...Array(4).keys()].map((i) => (
                  <div
                    key={`themefetch-${i}`}
                    className={`${styles.domainItem} cell small-12 medium-4 large-3`}
                  >
                    <Skeleton height={64} />
                  </div>
                ))
              : domains &&
                domains.map((domain) => (
                  <div
                    key={domain.id}
                    className={`${styles.domainItem} cell small-12 medium-4 large-3`}
                  >
                    <DomainItem
                      domain={domain}
                      showSidebar={() => handleShowSidebar(domain)}
                      afterDomainDelete={afterDomainDelete}
                    />
                  </div>
                ))}
          </div>
        </div>

        <div className={styles.bubbles}>
          <div
            onClick={() => {
              setSelectedDomain(null);
              setSidebarOpened(true);
            }}
          >
            <i className="icon-ttp-plus-outline"></i>
            <span style={{ paddingLeft: "1rem" }}>{_("add")}</span>
          </div>
        </div>
        <Sidebar
          isOpened={sidebarOpened}
          hideSidebar={() => {
            setSidebarOpened(false);
            setSelectedDomain(null);
          }}
          selectedDomain={selectedDomain}
          language={lng}
          isDomain
          afterDomainSave={afterDomainSave}
        />
      </>
    </ThemesLayout>
  );
}
