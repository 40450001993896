import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Autosuggest from "react-autosuggest";

import { fetchAuthors } from "../../../store";
import {
  getUserNameForAvatar,
  addLandaSize,
  getAuthorHeadlines,
  getAuthorAllHeadlines,
} from "../../../utils";

import { IconClose } from "../../common/Icons";
import _ from "../../../i18n";
import styles from "./AuthorSuggestion.module.scss";
import "./AutoSuggest.scss";

export default function RenderAuthorSuggestions(props) {
  const [addingAuthor, setAddingAuthor] = useState(false);
  const [authorInputValue, setAuthorInputValue] = useState("");
  const authors = useSelector((state) => state.users.authors);
  const loadingAuthorSuggestions = useSelector((state) => state.users.fetching);

  const dispatch = useDispatch();

  let { selectedCommunity, selectedLanguage } = props;

  const nameAttr = `name${
    selectedLanguage.charAt(0).toUpperCase() + selectedLanguage.slice(1)
  }`;

  const toggleAddAuthor = () => {
    setAuthorInputValue("");
    setAddingAuthor(!addingAuthor);
  };

  const loadAuthorSuggestions = (value) => {
    const communityId = selectedCommunity ? selectedCommunity.value : null;
    dispatch(fetchAuthors({ word: value, communityId }));
  };

  const handleSelectAuthor = (newAuthor) => {
    let { authors } = props;

    if (newAuthor.isAuthor) {
      props.onAuthorChange({
        id: newAuthor.id,
        signature: {
          title: newAuthor.firstName + " " + newAuthor.lastName,
          head: getAuthorHeadlines(newAuthor, selectedLanguage),
        },
        enableAvatar: true,
        avatar: newAuthor.avatar,
        avatarUrl: newAuthor.avatar,
        firstName: newAuthor.firstName,
        lastName: newAuthor.lastName,
        priority: authors.length,
        isAuthor: true,
        headlines: getAuthorAllHeadlines(newAuthor),
      });
    }

    setAddingAuthor(false);
  };

  if (!addingAuthor) {
    if (!selectedCommunity) {
      return null;
    }
    return (
      <div className={styles.addCoauthor} onClick={toggleAddAuthor}>
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.1317 6.88466H9.11625V0.869191C9.11625 0.595162 8.74415 0.000976562 7.99997 0.000976562C7.25579 0.000976562 6.88368 0.595194 6.88368 0.869191V6.88469H0.868215C0.594218 6.88466 0 7.25676 0 8.00091C0 8.74506 0.594218 9.1172 0.868215 9.1172H6.88371V15.1327C6.88371 15.4067 7.25579 16.0009 8 16.0009C8.74421 16.0009 9.11629 15.4067 9.11629 15.1327V9.1172H15.1318C15.4058 9.1172 16 8.74512 16 8.00091C16 7.2567 15.4057 6.88466 15.1317 6.88466Z"
            fill="#6D7F92"
          />
        </svg>
      </div>
    );
  }

  const inputProps = {
    placeholder: _("type_author"),
    value: authorInputValue,
    onChange: (event, { newValue }) => setAuthorInputValue(newValue),
    autoFocus: true,
    spellCheck: false,
    autoCorrect: "off",
    className: styles.autoSuggestInput,
  };

  let authorsList = [{ title: _("authors"), suggestions: authors }];
  return (
    <div>
      <span className={styles.closeCoauthor} onClick={toggleAddAuthor}>
        <IconClose />
      </span>
      <Autosuggest
        multiSection={true}
        suggestions={authorsList}
        renderSectionTitle={(section) =>
          section.suggestions.length == 0 ? null : section.title
        }
        getSectionSuggestions={(section) => section.suggestions}
        shouldRenderSuggestions={(value) => value && value.trim().length > 2}
        onSuggestionsFetchRequested={({ value }) =>
          loadAuthorSuggestions(value)
        }
        onSuggestionSelected={(e, { suggestion }) => {
          handleSelectAuthor(suggestion);
        }}
        getSuggestionValue={(suggestion) => {
          return suggestion.firstName + " " + suggestion.lastName;
        }}
        renderSuggestion={(suggestion) => {
          let avatarUrl = suggestion.avatar ? suggestion.avatar : null;
          return (
            <div className={styles.coauthorSuggestion}>
              {avatarUrl ? (
                <div
                  className={styles.imgWrap}
                  style={{
                    backgroundImage: `url(${addLandaSize(avatarUrl, 240)})`,
                  }}
                ></div>
              ) : (
                <div className={`${styles.imgWrap} ${styles.emptyAvatar}`}>
                  <span style={{ fontSize: "inherit" }}>
                    {getUserNameForAvatar(
                      suggestion.firstName,
                      suggestion.lastName
                    )}
                  </span>
                </div>
              )}

              <div className={styles.content}>
                <h4>
                  {suggestion.firstName} {suggestion.lastName}
                </h4>
                <p>{suggestion.description}</p>
              </div>
            </div>
          );
        }}
        inputProps={inputProps}
        renderSuggestionsContainer={({ containerProps, children, query }) => {
          return (
            <div {...containerProps}>
              {loadingAuthorSuggestions ? (
                <span className={styles.sidebarPlaceholder}>
                  {_("loading_suggestions")}
                </span>
              ) : (
                children
              )}
            </div>
          );
        }}
      />
    </div>
  );
}
