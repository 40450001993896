import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { getAvatarsAndUsers, getAvatars, getAuthors } from "../api";

const initialState = {
  fetching: false,
  fetched: false,
  items: [],
  authors: [],
  chains: [],
  mixedItems: [],
  error: null,
};

export const fetchAvatarsAndUsers = createAsyncThunk(
  "users/fetchAvatarsAndUsers",
  async ({ word = "", communityId = null }, { dispatch, getState }) => {
    const { token } = getState().auth;
    const response = await getAvatarsAndUsers({
      token,
      word,
      organizationId: communityId,
    });
    return response;
  }
);

export const fetchAuthors = createAsyncThunk(
  "users/fetchAuthors",
  async ({ word = "", communityId = null }, { dispatch, getState }) => {
    const { token } = getState().auth;
    const response = await getAuthors({
      token,
      word,
      organizationId: communityId,
    });
    return response;
  }
);

export const fetchChains = createAsyncThunk(
  "users/fetchChains",
  async ({ word = "", communityId = null }, { dispatch, getState }) => {
    const { token } = getState().auth;
    const response = await getAvatars({
      token,
      word,
      organizationId: communityId,
    });
    return response;
  }
);

export const usersSlice = createSlice({
  name: "users",
  initialState,
  extraReducers: {
    [fetchAvatarsAndUsers.pending]: (state, action) => {
      state.fetching = true;
    },
    [fetchAvatarsAndUsers.fulfilled]: (state, { payload }) => {
      state.fetching = false;
      state.mixedItems = getAvatarsAndUsersInfos(payload.data.data);
    },
    [fetchAvatarsAndUsers.rejected]: (state, { payload }) => {
      state.error = payload;
      state.fetching = false;
      state.mixedItems = [];
    },
    [fetchAuthors.pending]: (state, action) => {
      state.fetching = true;
    },
    [fetchAuthors.fulfilled]: (state, { payload }) => {
      state.fetching = false;
      if (payload.data.data) {
        state.authors = payload.data.data.map((item) => {
          let usr = item.defaultSignature
            ? {
                ...item.user,
                title: item.defaultSignature.title,
                header: item.defaultSignature.header,
              }
            : {
                ...item.user,
                title: item.user.firstName + " " + item.user.lastName,
              };

          return {
            ...usr,
            isAuthor: true,
            author: {
              headlineEn: item.headlineEn,
              headlineFr: item.headlineFr,
              headlineNl: item.headlineNl,
            },
          };
        });
      }
    },
    [fetchAuthors.rejected]: (state, { payload }) => {
      state.error = payload;
      state.fetching = false;
      state.authors = [];
    },
    [fetchChains.pending]: (state, action) => {
      state.fetching = true;
    },
    [fetchChains.fulfilled]: (state, { payload }) => {
      state.fetching = false;
      state.chains = payload.data.data;
    },
    [fetchChains.rejected]: (state, { payload }) => {
      state.error = payload;
      state.fetching = false;
      state.chains = [];
    },
  },
});

function getAvatarsAndUsersInfos(data) {
  let result = {
    chains: data.Chains,
    users: data.Users.map((user) => {
      let usr = user.defaultSignature
        ? {
            ...user,
            title: user.defaultSignature.title,
            header: user.defaultSignature.header,
          }
        : user.role
        ? {
            ...user,
            title: user.firstName + " " + user.lastName,
            header: user.role.function,
          }
        : { ...user, title: user.firstName + " " + user.lastName };
      return { ...usr, isAuthor: true };
    }),
  };
  return result;
}

export default usersSlice.reducer;
