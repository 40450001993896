import React, { useEffect } from "react";
import MultiSwitchContainer from "../../common/Switch/MultiSwitch/MultiSwitchContainer";
import InputMask from "react-input-mask";

import styles from "./Status.module.scss";

import _ from "../../../i18n";

const date = new Date();
const dateSTR = `${date.getDay() > 9 ? date.getDay() : "0" + date.getDay()}-${
  date.getMonth() > 9 ? date.getMonth() : "0" + date.getMonth()
}-${date.getFullYear()} ${
  date.getHours() > 9 ? date.getHours() : "0" + date.getHours()
}:${date.getMinutes() > 9 ? date.getMinutes() : "0" + date.getMinutes()}`;

function RenderPublishedAt(props) {
  const { selectedStatus, setPublishedAt, publishedAt } = props;

  let label =
    selectedStatus === "PUBLISHED"
      ? _("rubric_published_on")
      : _("rubric_scheduled_for");

  let inputMaskProps = {
    mask: "99-99-9999 99:99",
    onChange: (e) => {
      setPublishedAt(e.target.value);
    },
    className: "published-at-input",
    autoComplete: "off",
    placeholder: _("Publication date (eg. 24-07-2017 12:00)"),
  };

  inputMaskProps.defaultValue = publishedAt ? publishedAt : dateSTR;

  return (
    <div className={styles.publishRow}>
      <span className={styles.publishLabel}>{label} :</span>
      <InputMask {...inputMaskProps} />
    </div>
  );
}

export default function Status(props) {
  let { status, setStatus, publishedAt, setPublishedAt } = props;
  let labels = [_("draft"), _("ready"), _("scheduled"), _("published")];
  let vals = ["DRAFT", "READY", "SCHEDULED", "PUBLISHED"];

  useEffect(() => {
    if (
      (status === "PUBLISHED" || status === "SCHEDULED") &&
      publishedAt === null
    ) {
      setPublishedAt(dateSTR);
    } else {
      setPublishedAt(null);
    }
  }, [status]);

  return (
    <div>
      <MultiSwitchContainer
        labels={labels}
        vals={vals}
        name="status"
        selectedValue={status}
        afterChange={setStatus}
      />

      {status === "PUBLISHED" && (
        <>
          <RenderPublishedAt
            selectedStatus={status}
            publishedAt={publishedAt}
            setPublishedAt={setPublishedAt}
          />
        </>
      )}
      {status === "SCHEDULED" && (
        <RenderPublishedAt
          selectedStatus={status}
          publishedAt={publishedAt}
          setPublishedAt={setPublishedAt}
        />
      )}
    </div>
  );
}
