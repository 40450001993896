import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { getDomains } from "../api";

export const fetchDomains = createAsyncThunk(
  "domains/fetchDomains",
  async ({ communityId }, { dispatch, getState }) => {
    const { token } = getState().auth;
    const response = await getDomains({
      token,
      community: communityId,
    });
    return response;
  }
);

const initialState = {
  fetching: false,
  fetched: false,
  items: [],
  error: null,
  currentDomain: null,
};

export const domainsSlice = createSlice({
  name: "domains",
  initialState,
  reducers: {
    setCurrentDomain: (state, { payload }) => {
      state.currentDomain = payload;
    },
  },
  extraReducers: {
    [fetchDomains.pending]: (state, action) => {
      state.fetching = true;
    },
    [fetchDomains.fulfilled]: (state, { payload }) => {
      state.fetching = false;
      state.items = payload.data.data;
      // if (payload.data.data && payload.data.data.length > 0) {
      //   state.currentDomain = payload.data.data[0];
      // }
    },
    [fetchDomains.rejected]: (state, { payload }) => {
      state.error = payload;
      state.fetching = false;
      state.items = [];
    },
  },
});

export const { setCurrentDomain } = domainsSlice.actions;

export default domainsSlice.reducer;
