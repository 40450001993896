import React from "react";
import { useSelector } from "react-redux";

import styles from "../Search.module.scss";
import Domains from "./Domains";
import Themes from "./Themes";
import Pages from "./Pages";
import Rubrics from "./Rubrics";
import Questions from "./Questions";
import Breadcrumb from "../../common/Breadcrumb";

const PageContent = ({
  currentDomain,
  currentTheme,
  currentPage,
  currentRubric,
  setCurrentDomain,
  setCurrentTheme,
  setCurrentPage,
  setCurrentRubric,
  data,
}) => {
  const language = useSelector((state) => state.params.language);
  const titleAttr = `title${
    language.charAt(0).toUpperCase() + language.slice(1)
  }`;

  const goBack = () => {
    if (currentDomain) {
      if (currentTheme) {
        if (currentPage) {
          if (currentRubric) {
            localStorage.removeItem("currentRubric");
            setCurrentRubric(null);
          } else {
            localStorage.removeItem("currentPage");
            setCurrentPage(null);
          }
        } else {
          localStorage.removeItem("currentTheme");
          setCurrentTheme(null);
        }
      } else {
        localStorage.removeItem("currentRubric");
        setCurrentDomain(null);
      }
    }
  };

  return (
    <div className={styles.pageContent}>
      <div className={styles.pageHeader}>
        {currentDomain ? (
          <div className={styles.goBack}>
            <button onClick={() => goBack()}>
              <svg
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M16.25 8.15251C16.25 8.69823 15.8023 9.14062 15.25 9.14062L1.25 9.14062C0.697715 9.14062 0.25 8.69823 0.25 8.1525C0.25 7.60678 0.697715 7.16439 1.25 7.16439L15.25 7.16439C15.8023 7.16439 16.25 7.60678 16.25 8.15251Z"
                  fill="#29394D"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M8.95808 15.7692C8.56756 16.1551 7.93439 16.1551 7.54387 15.7692L0.543872 8.85235C0.153347 8.46647 0.153347 7.84083 0.543872 7.45494L7.54387 0.538113C7.9344 0.152228 8.56756 0.152228 8.95808 0.538114C9.34861 0.923997 9.34861 1.54964 8.95808 1.93552L2.66519 8.15365L8.95808 14.3718C9.34861 14.7577 9.34861 15.3833 8.95808 15.7692Z"
                  fill="#29394D"
                />
              </svg>
            </button>
            <Breadcrumb
              setCurrentDomain={setCurrentDomain}
              setCurrentTheme={setCurrentTheme}
              setCurrentPage={setCurrentPage}
              setCurrentRubric={setCurrentRubric}
              items={[
                {
                  label:
                    currentDomain[titleAttr].length > 15
                      ? currentDomain[titleAttr].substring(0, 15) + "..."
                      : currentDomain[titleAttr],
                  type: "domain",
                },
                currentTheme && {
                  label:
                    currentTheme[titleAttr].length > 15
                      ? currentTheme[titleAttr].substring(0, 15) + "..."
                      : currentTheme[titleAttr],
                  type: "theme",
                },
                currentPage && {
                  label:
                    currentPage[titleAttr].length > 15
                      ? currentPage[titleAttr].substring(0, 15) + "..."
                      : currentPage[titleAttr],
                  type: "page",
                },
                currentRubric && {
                  label:
                    currentRubric[titleAttr].length > 15
                      ? currentRubric[titleAttr].substring(0, 15) + "..."
                      : currentRubric[titleAttr],
                  type: "rubric",
                },
                {
                  label: currentRubric
                    ? "Accompagnement et conseil"
                    : currentPage
                    ? "Selectioner une rubrique ..."
                    : currentTheme
                    ? "Selectioner une page ..."
                    : "Selectioner un theme ...",
                },
              ]}
            />
          </div>
        ) : (
          <h4>séléctionnez une option ...</h4>
        )}
      </div>

      {currentRubric ? (
        <Questions rubricID={currentRubric.id} />
      ) : currentPage ? (
        <Rubrics
          setCurrentRubric={setCurrentRubric}
          pageID={currentPage.id}
          data={data}
        />
      ) : currentTheme ? (
        <Pages
          setCurrentPage={setCurrentPage}
          themeID={currentTheme.id}
          data={currentTheme}
        />
      ) : currentDomain ? (
        <Themes setCurrentTheme={setCurrentTheme} data={data} />
      ) : (
        <Domains setCurrentDomain={setCurrentDomain} />
      )}
    </div>
  );
};

export default PageContent;
