import React from "react";
import { SortableContainer } from "react-sortable-hoc";

import SortableRubric from "./SortableRubric";

export default SortableContainer(
  ({
    language,
    sortableRubrics,
    selectedRubric,
    setSelectedRubric,
    setSortableRubrics,
  }) => {
    let rubricsJsx = sortableRubrics.map((item, i) => {
      return (
        <SortableRubric
          item={item}
          language={language}
          index={i}
          selectedRubric={selectedRubric}
          setSelectedRubric={setSelectedRubric}
          setSortableRubrics={setSortableRubrics}
          sortableRubrics={sortableRubrics}
        />
      );
    });

    return <div>{rubricsJsx}</div>;
  }
);
