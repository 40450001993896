import React, { useState } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";

import ThemePopOver from "./ThemePopOver";
import _ from "../../../i18n";
import styles from "./FAQ.module.scss";

export default function Theme(props) {
  const loadingThemes = useSelector((state) => state.themes.fetching);
  let themes = useSelector((state) => state.themes.items);
  const loggedAs = useSelector((state) => state.auth.loggedAs);
  const [showPopOver, setShowPopOver] = useState(false);
  const { theme, language, community } = props;

  const titleAttr = `title${
    language.charAt(0).toUpperCase() + language.slice(1)
  }`;

  const customFilter = (option, searchText) => {
    return option.data.title.toLowerCase().includes(searchText.toLowerCase());
  };

  let themeOptions = [];
  themes.map((theme, index) => {
    const themeTitle =
      theme[titleAttr] ||
      theme["titleFr"] ||
      theme["titleEn"] ||
      theme["titleNl"];
    themeOptions.push({
      id: theme.id,
      title: themeTitle,
    });
  });

  const togglePopOver = () => {
    setShowPopOver(!showPopOver);
  };

  const handleChangeTheme = (theme) => {
    props.setTheme(theme);
    props.setPages([]);
    props.setDomain([]);
  };

  return (
    <div
      className={`${styles.inputContainer} ${showPopOver ? styles.active : ""}`}
    >
      <Select
        styles={props.selectStyles}
        isLoading={loadingThemes}
        options={themeOptions}
        className={styles.input}
        filterOption={customFilter}
        placeholder={_("select_theme")}
        value={theme}
        getOptionLabel={(option) => option.title}
        getOptionValue={(option) => option.id}
        onChange={handleChangeTheme}
      />
      {["GUEST", "CHIEF_EDITOR"].includes(loggedAs) &&
        props.community?.value === 9 && (
          <div className={styles.inputAdd} onClick={() => togglePopOver()}>
            <i className="icon-ttp-plus"></i>
            <ThemePopOver
              language={language}
              closePopOver={() => togglePopOver()}
              community={community}
              onSave={handleChangeTheme}
            />
          </div>
        )}
    </div>
  );
}
