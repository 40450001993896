import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useQuery } from "react-query";
import classNames from "classnames";

import styles from "./Authors.module.scss";
import Languages from "../common/Languages";
import _ from "../../i18n";
import AuthorCard from "./components/AuthorCard";
import { ReactComponent as BlackLeftArrow } from "../Pages/assets/BlackLeftArrow.svg";
import AuthorSolutions from "./components/AuthorSolutions";
import { getAuthorsAndQuestions } from "../../api";
import IconSearch from "../common/Icons/IconSearch";
const Authors = () => {
  const { authorID } = useParams();
  const lng = useSelector((state) => state.params.language);
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(`/${lng}/authors`);
  };
  const auth = useSelector((state) => state.auth);
  const { token, navCommunity } = auth;
  let { data } = useQuery(["listChains", navCommunity, token], async () => {
    if (token) {
      const response = await getAuthorsAndQuestions({
        token,
        organizationId: navCommunity.id,
      });
      return response.data.data;
    }
  });
  const authorName = () => {
    const author = data.filter(
      (item) => item.author.id.toString() === authorID
    )[0].author;

    return author.user.firstName + " " + author.user.lastName;
  };

  return (
    <div className={styles.authorsPage}>
      {authorID && (
        <div className={styles.backNav}>
          <button onClick={() => handleBack()}>
            <BlackLeftArrow />
            {_("back")}
          </button>
          {data && <span>{authorName()}</span>}
        </div>
      )}
      <div className={styles.pageContent}>
        {!authorID && (
          <div className={classNames(styles.pageHeader, "grid-x")}>
            <div className={classNames(styles.titles, "cell small-12 large-8")}>
              <h1>{_("authors")}</h1>
              <h2>
                Cum saepe multa, tum memini domi in hemicyclio sedentem, ut
                solebat
              </h2>
            </div>
            <div
              className={classNames(styles.filters, "cell small-12 large-4")}
            >
              <Languages />
              <div className={styles.search}>
                <IconSearch />
                <input type="text" placeholder={_("search_placeholder")} />
              </div>
            </div>
          </div>
        )}
        {authorID && data ? (
          <AuthorSolutions
            lng={lng}
            data={
              data.filter((item) => item.author.id.toString() === authorID)[0]
            }
          />
        ) : (
          <div className={`${styles.authorsList} grid-x`}>
            {data &&
              data.map((item, i) => (
                <div
                  className={classNames(
                    styles.cardCont,
                    "cell small-12 medium-6 large-3"
                  )}
                >
                  <Link key={i} to={`/${lng}/authors/${item.author.id}`}>
                    <AuthorCard
                      data={item.author}
                      lng={lng}
                      nbr={item.questions.length}
                    />
                  </Link>
                </div>
              ))}
            {data &&
              data.map((item, i) => (
                <div
                  className={classNames(
                    styles.cardCont,
                    "cell small-12 medium-6 large-3"
                  )}
                >
                  <Link key={i} to={`/${lng}/authors/${item.author.id}`}>
                    <AuthorCard
                      data={item.author}
                      lng={lng}
                      nbr={item.questions.length}
                    />
                  </Link>
                </div>
              ))}
            {data &&
              data.map((item, i) => (
                <div
                  className={classNames(
                    styles.cardCont,
                    "cell small-12 medium-6 large-3"
                  )}
                >
                  <Link key={i} to={`/${lng}/authors/${item.author.id}`}>
                    <AuthorCard
                      data={item.author}
                      lng={lng}
                      nbr={item.questions.length}
                    />
                  </Link>
                </div>
              ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Authors;
