import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Header } from "tamtam-components";

import {
  S3_FOLDER_URL,
  TTP_HELP_URL,
  TTP_HOME_URL,
  APP_ENV,
} from "../../config";
import { setNavCommunity, setAuthUser } from "../../store";
import { setCookie, setLocalStorageAuth } from "../../utils";
import _ from "../../i18n";

const Menu = () => {
  const lng = useSelector((state) => state.params.language);
  const auth = useSelector((state) => state.auth);
  const { loggedAs, loggedAsPost, currentCommunity } = auth;
  // const searchVisible = useSelector((state) => state.params.searchVisible);
  const dispatch = useDispatch();

  // const lng = "fr";

  const getAppData = () => {
    let data = {
      appName: "FAQ",
      appLogoUrl: `${S3_FOLDER_URL}/apps/blog.png`,
      appUrl: `${TTP_HELP_URL}/${lng}`,
      homeUrl: TTP_HOME_URL,
    };

    return data;
  };

  const App = getAppData();

  const rightIcons = {
    home: {
      activated: false,
      url: "https://help.tamtam.pro/" + lng,
    },
    profile: {
      activated: false,
    },
    ebox: {
      activated: false,
    },
    search: {
      activated: false,
    },
    notifs: {
      activated: true,
    },
    backoffice: {
      activated: false,
    },
    faq: {
      activated: false,
    },
    apps: {
      activated: false,
    },
  };

  const settings = [];
  if (auth.user && auth.user.isUaAdmin) {
    settings.push({
      label: _("privacy"),
      url: "/" + lng + "/privacy",
    });
    settings.push({
      label: _("themes_and_pages"),
      url: "/" + lng + "/settings/themes",
    });
  }

  const changeLng = (language) => {
    document.location.href = "/" + language;
  };

  const setCummunity = (community) => {
    if (community) {
      dispatch(setNavCommunity(community));
      let dtExpire = new Date();
      dtExpire.setTime(dtExpire.getTime() + 9900000 * 1000);
      setCookie(
        "ttp_community_" + APP_ENV,
        community.id,
        dtExpire,
        "/",
        "tamtam.pro"
      );
      window.location.reload();
    }
  };

  const logout = (e) => {
    e.preventDefault();
    dispatch(setAuthUser(null));
    setLocalStorageAuth(null);

    let dtExpire = new Date();
    dtExpire.setTime(dtExpire.getTime() - 3600000 * 1000);

    setCookie("ttp_auth_" + APP_ENV, "", dtExpire, "/", "tamtam.pro");
    setCookie("ttp_auth_" + APP_ENV, "", dtExpire, "/");
    setCookie("ttp_community_" + APP_ENV, "", dtExpire, "/", "tamtam.pro");
    setCookie("ttp_lang_" + APP_ENV, "", dtExpire, "/", "tamtam.pro");
    setCookie("ttp_lang_" + APP_ENV, "", dtExpire, "/");
    setCookie("XSRF-TOKEN", "", dtExpire, "/", "tamtam.pro");
    setCookie(`ttp_session_${APP_ENV}`, "", dtExpire, "/", "tamtam.pro");

    setTimeout(() => {
      window.location = "/" + lng;
    }, 200);
  };

  if (!auth.user) {
    return (
      <Header
        auth={{
          navCommunity: null,
          user: null,
          token: null,
        }}
        lng={lng}
        onLanguageChange={(langue) => changeLng(langue)}
        app={App}
      />
    );
  } else {
    return (
      <Header
        rightIcons={rightIcons}
        app={App}
        auth={{
          navCommunity: auth.navCommunity,
          user: auth.user,
          token: auth.token,
        }}
        settings={settings}
        lng={lng}
        env={APP_ENV}
        onLanguageChange={(langue) => changeLng(langue)}
        onSelectCommunity={(community) => setCummunity(community)}
        onLogoutClick={(e) => logout(e)}
        //uaFolder={auth.navCommunity}
        //isUA={true}
      />
    );
  }
};

export default Menu;
