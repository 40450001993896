import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";

import { sortCategoriesAlphabetically } from "../../../utils";
import _ from "../../../i18n";

export default function Category({
  selectStyles,
  language,
  category,
  setCategory,
}) {
  const loadingCategories = useSelector((state) => state.categories.fetching);
  let categories = useSelector((state) => state.categories.items);

  const dispatch = useDispatch();

  const customFilter = (option, searchText) => {
    return option.data.name.toLowerCase().includes(searchText.toLowerCase());
  };

  let categoryOptions = [];
  const nameAttr = `name${
    language.charAt(0).toUpperCase() + language.slice(1)
  }`;

  categories = sortCategoriesAlphabetically(categories, nameAttr);

  categories.map((category, index) => {
    const categoryName =
      category[nameAttr] ||
      category["nameFr"] ||
      category["nameEn"] ||
      category["nameNl"];
    categoryOptions.push({
      id: category.id,
      name: categoryName,
    });
  });

  return (
    <Select
      styles={selectStyles}
      isLoading={loadingCategories}
      options={categoryOptions}
      filterOption={customFilter}
      placeholder={_("select_category")}
      value={category}
      getOptionLabel={(option) => option.name}
      getOptionValue={(option) => option.id}
      onChange={(e) => setCategory(e)}
    />
  );
}
