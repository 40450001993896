import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import React from "react";
import moment from "moment";
import classNames from "classnames";

import { TTP_API_URL, TTP_BLOG_URL } from "../../../config";
import styles from "./Article.module.scss";
import _ from "../../../i18n";
import { IconPlus } from "../Icons/IconPlus";
import IconCheck from "../Icons/IconCheck";
import { setCurrentQuestion } from "../../../store";
import SidebarQuestion from "../../Settings/SidebarQuestion";
import ShareTooltip from "../Question/ShareTooltip";
import { ClickAwayListener } from "@mui/base";

const API_DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";

const Article = ({
  article,
  language,
  articles,
  setArticles,
  mIndex,
  showAllStatus,
  question,
  type = "TYPE1",
  edit,
  sharingDate,
}) => {
  const auth = useSelector((state) => state.auth);
  const { user } = auth;
  const nameAttr = `name${
    language.charAt(0).toUpperCase() + language.slice(1)
  }`;
  const headlineAttr = `headline${
    language.charAt(0).toUpperCase() + language.slice(1)
  }`;
  const [sidebarOpened, setSidebarOpened] = useState(false);
  const [openShare, setOpenShare] = useState(false);
  const dispatch = useDispatch();
  if (type === "TYPE1") {
    return (
      <div
        className={classNames(
          styles.article,
          articles ? styles.hover_effect : "",
          articles && articles.map((el) => el.id).includes(article.id)
            ? styles.selected
            : ""
        )}
      >
        <div
          className={styles.img}
          style={{
            backgroundImage: article.main_media
              ? `url(${
                  article.main_media.fullMediaUrl
                    ? article.main_media.fullMediaUrl
                    : TTP_API_URL + "/" + article.main_media.webPath
                })`
              : "",
          }}
        ></div>

        <div className={styles.content}>
          <div className={styles.authors}>
            {article.author ? (
              article.author.map((item) => (
                <div className={styles.author}>
                  <div
                    className={styles.author_img}
                    style={{
                      backgroundImage: `url(${
                        item.avatarUrl
                          ? item.avatarUrl
                          : TTP_API_URL + "/" + item.avatar
                      })`,
                    }}
                  ></div>
                  <div className={styles.info}>
                    {article.author && (
                      <div
                        className={styles.name}
                      >{`${item.firstName}  ${item.lastName}`}</div>
                    )}
                    <div className={styles.date}>
                      {moment(article.publishedAt, API_DATE_FORMAT)
                        .locale(language)
                        .format("DD MMMM, " + _("à") + " HH:mm")}
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className={styles.date}>
                {moment(article.publishedAt, API_DATE_FORMAT)
                  .locale(language)
                  .format("DD MMMM, " + _("à") + " HH:mm")}
              </div>
            )}
          </div>
          <div className={styles.title}>{article.title}</div>
          <a
            href={`${TTP_BLOG_URL}/${language}/article/${article.url}/${article.id}`}
            target="_blank"
            rel="noreferrer"
            className={styles.read_more}
          >
            {_("read_more")}
            <svg
              width="10"
              height="10"
              viewBox="0 0 10 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.60686 4.64308L3.1099 0.147619C2.91258 -0.0492063 2.59288 -0.0492063 2.39506 0.147619C2.19774 0.344444 2.19774 0.664139 2.39506 0.860964L6.53532 4.99973L2.39556 9.1385C2.19824 9.33532 2.19824 9.65502 2.39556 9.85234C2.59288 10.0492 2.91308 10.0492 3.1104 9.85234L7.60736 5.35688C7.80169 5.16209 7.80169 4.83737 7.60686 4.64308Z"
                fill="#6D7F92"
              />
            </svg>
          </a>
        </div>
        <div
          className={styles.category}
          style={{ backgroundColor: article.category.colorCode }}
        >
          {article.category[nameAttr]}
        </div>

        {articles && (
          <div
            className={styles.icon}
            onClick={() => {
              if (articles.map((el) => el.id).includes(article.id)) {
                setArticles(articles.filter((item) => item.id !== article.id));
              } else {
                setArticles([article, ...articles]);
              }
            }}
          >
            <span className={styles.other}>
              {mIndex ? mIndex : <IconPlus size={14} />}
            </span>
            <span className={styles.check}>
              <IconCheck size={14} />
            </span>
          </div>
        )}
      </div>
    );
  }
  if (type === "TYPE2") {
    return (
      <div className={styles.articleContainer}>
        {edit && (
          <span className={styles.dragHandler}>
            <i className="icon-ttp-drag"></i>
          </span>
        )}
        <div
          className={styles.articleContent}
          style={{
            backgroundImage: article.main_media
              ? `url(${
                  article.main_media.fullMediaUrl
                    ? article.main_media.fullMediaUrl
                    : TTP_API_URL + "/" + article.main_media.webPath
                })`
              : "",
          }}
        >
          <div className={styles.top}>
            <div
              className={styles.category}
              style={{ backgroundColor: article.category.colorCode }}
            >
              {article.category[nameAttr]}
            </div>
            <div className={styles.flex}>
              {article.chains && article.chains.length > 0 && (
                <div
                  className={styles.chain}
                  style={{
                    backgroundImage: article.chains[0].mediaChain[0].avatarUrl
                      ? `url(${article.chains[0].mediaChain[0].avatarUrl})`
                      : "",
                  }}
                ></div>
              )}
              {user &&
                user.role &&
                user.role.organization.portalScope === "COLLABORATER" && (
                  <span
                    onClick={(e) => {
                      setOpenShare(!openShare);
                    }}
                    className={classNames(
                      styles.threeDots,
                      openShare ? "icon-ttp-close" : "icon-ttp-three-dots",
                      openShare ? styles.active : ""
                    )}
                  ></span>
                )}
              {openShare && (
                <ClickAwayListener onClickAway={() => setOpenShare(false)}>
                  <div className={styles.toolTipContainer}>
                    <ShareTooltip
                      question={question}
                      close={() => setOpenShare(false)}
                    />
                  </div>
                </ClickAwayListener>
              )}
            </div>
          </div>
          <div className={styles.meta}>
            {article.type ? <span>{article.type[nameAttr]}</span> : null}
            <div
              className={styles.community}
              style={{ borderLeftColor: article.category.colorCode }}
            >
              {article.organization.abbreviation ||
                (article.organization.name.length <= 30
                  ? article.organization.name
                  : article.organization.name.substr(0, 30) + "...")}
            </div>
          </div>
          <div className={styles.title}>{article.title}</div>
          {sharingDate && (
            <span className={styles.sharingDate}>
              {" "}
              Partagé le{" "}
              {moment(sharingDate, API_DATE_FORMAT)
                .locale(language)
                .format("DD MMM YYYY " + _("à") + "  HH:mm")}
            </span>
          )}
          <div className={styles.authors}>
            {article.author &&
              article.author.map((item) => (
                <div className={styles.author}>
                  <div
                    className={styles.author_img}
                    style={{
                      backgroundImage: `url(${
                        item.avatarUrl
                          ? item.avatarUrl
                          : TTP_API_URL + "/" + item.avatar
                      })`,
                    }}
                  ></div>
                  <div className={styles.info}>
                    {article.author && (
                      <div
                        className={styles.name}
                      >{`${item.firstName}  ${item.lastName}`}</div>
                    )}
                    {item.authorDetails &&
                    item.authorDetails[headlineAttr] &&
                    item.authorDetails[headlineAttr].title ? (
                      <div className={styles.headline}>
                        {item.authorDetails[headlineAttr].title}
                      </div>
                    ) : null}
                  </div>
                </div>
              ))}
          </div>
          {article.publishedAt && (
            <div className={styles.publishedAtReadTime}>
              Publié le{" "}
              {moment(article.publishedAt, API_DATE_FORMAT)
                .locale(language)
                .format("DD MMM YYYY " + _("à") + "  HH:mm")}
              {article.readTime && (
                <div className={styles.readTime}>
                  <div className={styles.dot}></div>
                  <span>
                    {_("Lecture de")} {article.readTime}min
                  </span>
                </div>
              )}
            </div>
          )}
          <button
            className={styles.readArticle}
            onClick={() =>
              window.open(
                `${TTP_BLOG_URL}/${language}/article/${article.url}/${article.id}`,
                "_blank"
              )
            }
          >
            {_("read_article")}
          </button>
          {showAllStatus && (
            <div className={styles.edit}>
              <span
                className={`${styles.status} ${
                  question.status === "PUBLISHED" && styles.published
                }`}
              >
                {question.status}
              </span>
              <span
                className={styles.item_edit}
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch(
                    setCurrentQuestion({ ...question, object: article })
                  );
                  setSidebarOpened(true);
                }}
              >
                <i className="icon-ttp-edit"></i>
              </span>
            </div>
          )}
        </div>
        {showAllStatus && sidebarOpened && (
          <SidebarQuestion
            isOpened={sidebarOpened}
            hideSidebar={() => {
              setSidebarOpened(false);
              dispatch(setCurrentQuestion(null));
            }}
          />
        )}
      </div>
    );
  }
};

export default Article;
