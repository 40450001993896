import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import Loader from "./components/Loader";
import PageHeader from "./components/PageHeader";
import PageContent from "./components/PageContent";
import styles from "./Home.module.scss";

import { getListThemes } from "../../api";
import { fetchBannerThemes, setCurrentDomain } from "../../store";

const Home = () => {
  const { domainID } = useParams();
  const language = useSelector((state) => state.params.language);
  const auth = useSelector((state) => state.auth);
  const domainsList = useSelector((state) => state.domains.items);
  const bannerThemes = useSelector((state) => state.themes.bannerThemes);

  const { token, navCommunity } = auth;
  const dispatch = useDispatch();
  // const urlAttr = `url${language.charAt(0).toUpperCase() + language.slice(1)}`;
  const [categoryFilter, setCategoryFilter] = useState(null);
  const [filteredRubrics, setFilteredRubrics] = useState(null);
  const [fetchingRubrics, setFetchingRubrics] = useState(false);
  const [searching, setSearching] = useState(false);
  const [rubricList, setRubricList] = useState(null);
  const [search, setSearch] = useState("");
  const [localLanguage, setLocalLanguage] = useState("fr");

  const currentDomain = useSelector((state) => state.domains.currentDomain);

  useEffect(() => {
    if (bannerThemes.length === 0 && token && currentDomain) {
      dispatch(
        fetchBannerThemes({
          domain: currentDomain.id,
          isEditable: currentDomain.isEditable
            ? currentDomain.isEditable
            : false,
          rubrics: true,
        })
      );
    }
  }, [token, currentDomain]);

  let { isLoading, data } = useQuery(
    ["listThemes", token, currentDomain],
    async () => {
      if (token && currentDomain) {
        const response = await getListThemes({
          token,
          community: navCommunity ? navCommunity.id : null,
          domain: currentDomain.id,
          isEditable: currentDomain.isEditable
            ? currentDomain.isEditable
            : false,
        });
        return response.data.data;
      }
    }
  );
  if (!currentDomain) {
    return null;
  }
  return (
    <div className={styles.homePage}>
      <PageHeader
        language={language}
        data={data}
        token={token}
        navCommunity={navCommunity}
        domainList={domainsList}
        currentDomain={currentDomain}
        categoryFilter={categoryFilter}
        setCategoryFilter={setCategoryFilter}
        setFilteredRubrics={setFilteredRubrics}
        setFetchingRubrics={setFetchingRubrics}
        setSearching={setSearching}
        setRubricList={setRubricList}
        search={search}
        setSearch={setSearch}
        localLanguage={localLanguage}
        setLocalLanguage={setLocalLanguage}
      />

      {isLoading ? (
        <Loader />
      ) : (
        <PageContent
          categoryFilter={categoryFilter}
          setCategoryFilter={setCategoryFilter}
          filteredRubrics={filteredRubrics}
          setFilteredRubrics={setFilteredRubrics}
          data={data}
          currentDomain={currentDomain}
          fetchingRubrics={fetchingRubrics}
          rubricList={rubricList}
          searching={searching}
          setSearch={setSearch}
          search={search}
          setRubricList={setRubricList}
          localLanguage={localLanguage}
        />
      )}
    </div>
  );
};

export default Home;
