const faqFr = {
  save: "Enregistrer",
  cancel: "Annuler",
  english: "Anglais",
  french: "Français",
  dutch: "Néerlandais",
  title: "Foire Aux Questions",
  subtitle: "Bienvenue dans le centre d'aide de Tamtam",
  add_title: "Ajouter une question",
  rubric: "Rubrique",
  rubrics: "Rubriques",
  rubric_add_headline: "Ajouter une rubrique",
  rubric_edit_headline: "Modifier la rubrique",
  rubric_form_title: "Titre",
  rubric_form_intro: "Introduction",
  rubric_parent: "Parent",
  appName: "Application",
  faq_add_headline: "Ajouter une question liée à la rubrique",
  faq_edit_headline: "Modifier une question liée à la rubrique",
  faq_status: "Statut",
  faq_form_anwser: "Réponse",
  helpful: "Est-ce que cela a été utile?",
  yes: "Oui",
  no: "Non",
  add_question: "Ajouter une question",
  edit_question: "Modifier une question",
  question: "Question",
  modal_delete_title: "Confirmation de suppression",
  modal_delete_text: "Êtes-vous sûr de bien vouloir supprimer cet élément ?",
  rubric_delete_error: "Une erreur est survenue!",
  save_order: "Enregistrer l'ordre",
  home_title: "Centre d'aide",
  home_title2: "Besoin d’aide ?",
  home_sub_title: "Comment pouvons nous vous aider?",
  home_sub_title2: "Trouvez les réponses à toutes vos questions",
  home_need_help: "Besoin d'aide?",
  home: "Accueil",
  loading: "Chargement ...",
  type_author: "Tapez le nom de l'auteur ...",
  loading_suggestions: "Loading suggestions ...",
  avatars: "Avatars",
  authors: "Auteurs",
  errors: "Erreurs",
  validate_title: "Vous devez fournir un titre",
  validate_category: "Vous devez sélectionner une catégorie",
  validate_community: "Vous devez sélectionner une communauté",
  validate_icon: "Vous devez sélectionner une icône",
  library_page_title: "BIBLIOTHÉQUE",
  library_page_subtitle: "Liste des rubriques partagées",
  type_of_sharing: "Type de partage",
  shared_on: "Pargaté le",
  shared_with: "Pargaté avec",
  share_with: "Pargater avec",
  rubrics_to_share: "Rubriques à partager",
  rubric_published_on: "Publié le :",
  rubric_scheduled_for: "Planifié le :",
  draft: "Brouillon",
  ready: "Ready",
  scheduled: "Scheduled",
  published: "Publié",
  add_chain: "Ajouter une chaîne",
  chain: "Chaîne",
  home_form_button: "Suivez le guide",
  search_placeholder: "Recherche ...",
  back: "Retour",
  select_rubric: "Sélectionnez une rebrique",
  need_help: "Besoin d’aide",
  follow_guide:
    "Suivez le guide pour trouver des réponses à toutes vos questions",
  searching_results: "Recherche de résultats...",
  no_results_found: "Aucun résultat trouvé",
  suggest_answer: "Proposez votre solution",
  suggestion_success:
    "Merci pour votre suggestion nous allons la prendre en considération",
  suggestion_exists:
    "Vous avez déjà proposé une réponse à cette question (Cliquez pour la voir)",
  update_suggestion: "Mise à jour de suggestion",
  individuals: "Particuliers",
  companies: "Entreprises",
  independent_liberal: "Indépendants & Professions Libérales",
  experts_partners: "Experts & Partenaires",
  ASBL: "ASBL",
  slug_individuals: "particuliers",
  slug_companies: "entreprises",
  slug_independent_liberal: "independants-professions-liberales",
  slug_experts_partners: "experts-partenaires",
  slug_asbl: "asbl",
  chains: "Chaînes",
  "Event brings together the best content.":
    "Event rassemble les meilleurs contenus.",
  "solution rubrics published by this channel":
    "rubriques de solutions publiées par cette chaîne",
  show_full_description: "Lire la suite",
  see_chain: "voir la chaine",
  solutions_published_by: "Solutions publiées par",
  solutions: "solutions",
  source_title: "Titre de la source",
  source_url: "Url de la source",
  community: "Communauté",
  select_community: "Sélectionnez une communauté ...",
  category: "Catégorie",
  select_category: "Choisir une catégorie ...",
  theme: "Thème",
  select_theme: "Thème par défaut ...",
  page: "Page",
  select_page: "Choisir une page ...",
  tag_modal_title: "Traduction du tag",
  tag_name: "Nom",
  validate_tag_name: "Vous devez fournir la traduction des tags",
  filter_results: "Résultats du filtre",
  clear_filter: "Effacer le filtre",
  search_results: "Résultats de recherche",
  clear_search: "Effacer la recherche",
  add_content: "Ajouter DU contenu",
  add_content_sub:
    "Principium autem unde latius se funditabat, emersit ex negotio tali.",
  modal_save_order: "Confirmation des changements",
  modal_save_order_text:
    "Êtes-vous sûr de bien vouloir enregisterer ces changements ?",
  fff_publications: "Actualités FFF",
  selected_medias: "Medias sélectionnés",
  disponible_medias: "Medias disponibles",
  selected_articles: "Articles sélectionnés",
  disponible_articles: "Articles disponibles",
  selected_formations: "Formations sélectionnés",
  disponible_formations: "Formations disponibles",
  read_article: "Lire l’article",
  read_more: "LIRE PLUS",
  read_more_details: "Plus de détails",
  download: "Télécharger",
  privacy: "Confidentialité",
  privacy_rubrics: "Rubriques de confidentialité :",
  published_at: "Publié le",
  visible_in_footer: "Visible sur le footer",
  add_rubric: "Ajouter une rubrique",
  activated: "Activé",
  deactivated: "Désactivé",
  description: "Description",
  functional_cookies: "Cookies fonctionnel",
  optional_cookies: "Cookies optionnel",
  terms_of_use: "Conditions d'utilisation",
  nothing_found: "Rien n'a été trouvé ...",
  library: "Bibliothèque",
  share: "Partager",
  ALL_COLLABORATORS: "Tous les collaborateurs",
  ALL_CLIENTS: "Tous les clients",
  ALL_CONTACTS: "Tous les contacts",
  SPEC_COLLABORATOR: "Collaborateurs spécifique",
  SPEC_CLIENT: "Clients spécifique",
  SPEC_CONTACT: "Contacts spécifique",
  page_has_no_rubrics: "Cette page n'a pas de rubriques publiées",
  domain_has_no_themes: "Ce domaine n'a pas de thèmes publiés",
  theme_has_no_pages: "Ce thème n'a pas de pages publiées",
  linked_pages: "Pages liées:",
  themes_and_pages: "Themes & Pages",
  themes_and_pages_list: "LISTE DES THÈMES ET PAGES",
  themes_and_pages_list_subtitle: "AJOUTER OU CONFIGURER LES THÈMES ET PAGES",
  add: "Ajouter",
  change_image: "Changer l'image",
  dropzone_text: "Cliquez ou glisser et déposer votre image ici",
  invalid_theme_title: "Titre du thème non valide",
  invalid_private_groups: "Vous devez spécifier des groupes pour les abonnés",
  select_icon: "Sélectionner l'icône",
  title_fr: "Titre Fr",
  title_en: "Titre En",
  title_nl: "Titre Nl",
  is_private: "Réservé aux abonnés",
  subscribers_groups: "Groupes d'abonnés",
  invalid_domain_title: "Titre du domaine non valide",
  invalid_theme_domain: "Selectionnez les domaines du thème",
  change_domain: "Changer le domaine",
  add_domain: "Ajouter un domaine",
  change_theme: "Changer le thème",
  add_theme: "Ajouter un thème",
  theme_isdefault: "Theme par défaut",
  domain: "Domaine",
  is_editable: "Est Éditable",
  add_page: "Ajouter une page",
  themes: "Thèmes",
  domains: "Domaines",
  domain_title: "LISTE DES DOMAINES",
  domain_sub_title: "AJOUTER OU CONFIGURER LES DOMAINES",
};
export default faqFr;
