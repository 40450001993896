import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import classnames from "classnames";

import styles from "../Home.module.scss";
import ThemeCard from "./ThemeCard";
import { ReactComponent as Arrow } from "../assets/Arrow.svg";
import RubricCard from "../../common/RubricCard";
import _ from "../../../i18n";
import Fetching from "../../common/RubricCard/Fetching";
import SearchCard from "./SearchCard";

const PageContent = ({
  data,
  currentDomain,
  categoryFilter,
  filteredRubrics,
  setCategoryFilter,
  setFilteredRubrics,
  fetchingRubrics,
  rubricList,
  searching,
  setSearch,
  search,
  setRubricList,
  localLanguage,
}) => {
  const showFiduciaryStatus = useSelector(
    (state) => state.faq.showFiduciaryStatus
  );
  const hiddenFaqs = useSelector((state) => state.faq.hiddenFaqs);
  const language = useSelector((state) => state.params.language);
  const navigate = useNavigate();

  const urlAttr = `url${language.charAt(0).toUpperCase() + language.slice(1)}`;

  if (search.length > 2) {
    return (
      <div>
        <div className={styles.filterHeader}>
          <h4>
            {_("search_results")}{" "}
            {!searching && !rubricList ? `: ${_("no_results_found")}` : ""}
          </h4>
          <span
            onClick={() => {
              setSearch("");
              setRubricList(null);
            }}
          >
            {_("clear_search")}
          </span>
        </div>
        {searching ? (
          <div className={classnames(styles.body, "grid-x")}>
            {[...new Array(4)].map(() => (
              <div className={styles.rubCardCont}>
                <Fetching />
              </div>
            ))}
          </div>
        ) : (
          rubricList &&
          rubricList.length > 0 && (
            <div className={classnames(styles.body, "grid-x")}>
              {rubricList.map((rubric) => {
                return (
                  <div
                    className={classnames(
                      styles.rubCardCont,
                      "cell small-12 medium-4 large-3"
                    )}
                  >
                    <SearchCard data={rubric} language={localLanguage} />
                  </div>
                );
              })}
            </div>
          )
        )}
      </div>
    );
  } else if (categoryFilter) {
    return (
      <div>
        <div className={styles.filterHeader}>
          <h4>
            {_("filter_results")}{" "}
            {!fetchingRubrics && !filteredRubrics
              ? `: ${_("no_results_found")}`
              : ""}
          </h4>
          <span
            onClick={() => {
              setCategoryFilter(null);
              setFilteredRubrics(null);
            }}
          >
            {_("clear_filter")}
          </span>
        </div>
        {fetchingRubrics ? (
          <div className={classnames(styles.body, "grid-x")}>
            <div className={styles.rubCardCont}>
              <Fetching />
            </div>
            <div className={styles.rubCardCont}>
              <Fetching />
            </div>
            <div className={styles.rubCardCont}>
              <Fetching />
            </div>
            <div className={styles.rubCardCont}>
              <Fetching />
            </div>
          </div>
        ) : (
          filteredRubrics &&
          filteredRubrics.length > 0 && (
            <div className={classnames(styles.body, "grid-x")}>
              {filteredRubrics.map((rubric) => {
                return (
                  <div
                    className={classnames(
                      styles.rubCardCont,
                      "cell small-12 medium-4 large-3"
                    )}
                  >
                    <RubricCard
                      chainImg={
                        rubric.chain && rubric.chain[0].mediaChain[0].avatarUrl
                      }
                      data={rubric}
                      url={`/${language}/${currentDomain[urlAttr]}/themes/${rubric.theme.id}/pages/${rubric.pages[0].id}/rubrics/${rubric.id}`}
                      language={localLanguage}
                    />
                  </div>
                );
              })}
            </div>
          )
        )}
      </div>
    );
  } else {
    return (
      <div className={classnames(styles.body, "grid-x")}>
        <div
          className={classnames(
            styles.themes,
            "cell small-12 medium-8 large-9"
          )}
        >
          <div className={classnames(styles.cards, "grid-x")}>
            {data &&
              data.map((theme) => {
                if (!showFiduciaryStatus && hiddenFaqs.T.includes(theme.id)) {
                  return null;
                }
                return (
                  <ThemeCard
                    key={theme.id}
                    url={`/${language}/${currentDomain[urlAttr]}/themes/${theme.id}`}
                    data={theme}
                    language={localLanguage}
                  />
                );
              })}
          </div>
        </div>
        <div
          className={classnames(styles.guide, "cell small-12 medium-4 large-3")}
        >
          <div className={styles.followGuide}>
            <span>Guide</span>
            <h3>Trouvez vos réponses en suivant le guide</h3>
            <button
              onClick={() => {
                navigate(`/${language}/search`);
              }}
            >
              Suivez le guide <Arrow />
            </button>
          </div>
        </div>
      </div>
    );
  }
};

export default PageContent;
