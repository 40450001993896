import React from "react";
import Slider from "react-slick";
import { useSelector } from "react-redux";

import styles from "../Chaines.module.scss";
import LeftBanner from "./LeftBanner";
import _ from "../../../i18n";
import classNames from "classnames";
import RubricCard from "../../common/RubricCard";

const Chaine = ({ lng, data }) => {
  const currentDomain = useSelector((state) => state.domains.currentDomain);
  const urlAttr = `url${lng.charAt(0).toUpperCase() + lng.slice(1)}`;

  const slider = React.useRef();
  const settings = {
    dots: true,
    // dotsClass: classnames(styles.dots, "slick-dots"),
    infinite: false,
    arrows: false,
    speed: 500,
    autoplay: false,
    className: styles.slider,
    slidesToShow: 3,
    slidesToScroll: 3,
    variableWidth: true,
    appendDots: (dots) => (
      <>
        <div className={`${styles.sliderDots} slick-dots`}>
          <ul>{dots}</ul>
        </div>
      </>
    ),
    responsive: [
      {
        breakpoint: 1028,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          dots: false,
        },
      },
      {
        breakpoint: 770,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  };

  if (!currentDomain) {
    return null;
  }
  if (
    data &&
    data.rubrics.filter((rub) => !rub.pages).length === data.rubrics.length
  )
    return null;

  return (
    <div className={`${styles.chain} grid-x`}>
      <div
        className={classNames(
          styles.bannerDiv,
          "cell medium-5 large-3 small-12"
        )}
      >
        <LeftBanner chain={data.chain} single={false} lng={lng} />
      </div>

      <div className={`${styles.chainContent} cell medium-7 large-9 small-12`}>
        <div className={classNames(styles.contentTitle, "grid-x")}>
          <h4 className="cell small-12 medium-12 large-10">
            {data.chain.rubricCounter}{" "}
            {_("solution rubrics published by this channel")}
          </h4>
          <div
            className={classNames(styles.sliderButtons, "cell small-4 large-2")}
          >
            <button
              onClick={() => {
                slider?.current?.slickPrev();
              }}
            >
              <svg
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M16.252 8.15251C16.252 8.69823 15.8042 9.14062 15.252 9.14062L1.25195 9.14062C0.699668 9.14062 0.251953 8.69823 0.251953 8.1525C0.251953 7.60678 0.699668 7.16439 1.25195 7.16439L15.252 7.16439C15.8042 7.16439 16.252 7.60678 16.252 8.15251Z"
                  fill="#29394D"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M8.96022 15.7692C8.5697 16.1551 7.93653 16.1551 7.54601 15.7692L0.546008 8.85235C0.155483 8.46647 0.155483 7.84083 0.546008 7.45494L7.54601 0.538113C7.93653 0.152228 8.5697 0.152228 8.96022 0.538114C9.35075 0.923997 9.35075 1.54964 8.96022 1.93552L2.66733 8.15365L8.96022 14.3718C9.35074 14.7577 9.35074 15.3833 8.96022 15.7692Z"
                  fill="#29394D"
                />
              </svg>
            </button>
            <button
              onClick={() => {
                slider?.current?.slickNext();
              }}
            >
              <svg
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M0.751953 8.84749C0.751953 8.30177 1.19967 7.85938 1.75195 7.85938L15.752 7.85938C16.3042 7.85938 16.752 8.30177 16.752 8.8475C16.752 9.39322 16.3042 9.83562 15.752 9.83562L1.75195 9.83561C1.19967 9.83561 0.751953 9.39322 0.751953 8.84749Z"
                  fill="#29394D"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M8.04369 1.23082C8.43421 0.844935 9.06738 0.844935 9.4579 1.23082L16.4579 8.14765C16.8484 8.53353 16.8484 9.15918 16.4579 9.54506L9.4579 16.4619C9.06737 16.8478 8.43421 16.8478 8.04368 16.4619C7.65316 16.076 7.65316 15.4504 8.04368 15.0645L14.3366 8.84635L8.04369 2.62823C7.65316 2.24235 7.65316 1.6167 8.04369 1.23082Z"
                  fill="#29394D"
                />
              </svg>
            </button>
          </div>
        </div>
        <div className={styles.contentRubrics}>
          <Slider ref={slider} {...settings}>
            {data.rubrics &&
              data.rubrics.map((rubric) => {
                if (!rubric.pages) return null;
                return (
                  <div style={{ width: "307px" }}>
                    <RubricCard
                      language={lng}
                      chainImg={data.chain.mediaChain[0].avatarUrl}
                      data={rubric}
                      url={`/${lng}/${currentDomain[urlAttr]}/themes/${rubric.theme.id}/pages/${rubric.pages[0].id}/rubrics/${rubric.id}`}
                    />
                  </div>
                );
              })}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Chaine;
