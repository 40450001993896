import React from "react";

const IconQuestion = () => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18 4.5C10.5442 4.5 4.5 10.5442 4.5 18C4.5 25.4558 10.5442 31.5 18 31.5C25.4558 31.5 31.5 25.4558 31.5 18C31.5 10.5442 25.4558 4.5 18 4.5ZM1.5 18C1.5 8.8873 8.8873 1.5 18 1.5C27.1127 1.5 34.5 8.8873 34.5 18C34.5 27.1127 27.1127 34.5 18 34.5C8.8873 34.5 1.5 27.1127 1.5 18Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.3874 12.0365C17.6891 11.9167 16.9709 12.0479 16.3601 12.4069C15.7493 12.7659 15.2853 13.3294 15.0502 13.9978C14.7753 14.7793 13.9189 15.1899 13.1374 14.915C12.3559 14.6401 11.9453 13.7837 12.2202 13.0022C12.6904 11.6656 13.6185 10.5385 14.8401 9.8205C16.0617 9.10256 17.498 8.84011 18.8945 9.07966C20.2911 9.31921 21.5578 10.0453 22.4703 11.1293C23.3827 12.2131 23.8821 13.5847 23.8802 15.0014C23.8795 17.2969 22.1776 18.8128 20.9622 19.6231C20.3088 20.0587 19.666 20.379 19.1925 20.5895C18.9536 20.6956 18.7525 20.7762 18.6075 20.8314C18.5349 20.8591 18.4761 20.8805 18.4332 20.8957L18.3811 20.914L18.3645 20.9197L18.3587 20.9216L18.3564 20.9224C18.3559 20.9226 18.3545 20.923 17.8802 19.5L18.3545 20.923C17.5686 21.185 16.7191 20.7603 16.4571 19.9743C16.1954 19.189 16.6194 18.3401 17.4043 18.0775L17.4023 18.0781C17.4025 18.0781 17.4026 18.0781 17.4043 18.0775L17.4282 18.0691C17.4513 18.0609 17.4891 18.0472 17.5395 18.028C17.6407 17.9894 17.7911 17.9294 17.9741 17.848C18.3443 17.6835 18.8266 17.4413 19.2981 17.1269C20.3325 16.4373 20.8802 15.7037 20.8802 15L20.8802 14.9978C20.8812 14.2893 20.6315 13.6033 20.1753 13.0613C19.719 12.5193 19.0856 12.1563 18.3874 12.0365Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.5 25.5C16.5 24.6716 17.1716 24 18 24H18.015C18.8434 24 19.515 24.6716 19.515 25.5C19.515 26.3284 18.8434 27 18.015 27H18C17.1716 27 16.5 26.3284 16.5 25.5Z"
      fill="currentColor"
    />
  </svg>
);

export default IconQuestion;
