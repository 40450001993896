import React from "react";
import { SortableContainer } from "react-sortable-hoc";
import classNames from "classnames";

import SortableQuestion from "./SortableQuestion";
import styles from "../Pages.module.scss";

export default SortableContainer(
  ({
    questions,
    showAllStatus,
    showFiduciaryStatus,
    fetchedDataById,
    language,
    hiddenFaqs,
    rubric,
  }) => {
    let questionsJsx = questions.map((item, i) => {
      return (
        <SortableQuestion
          item={item}
          showAllStatus={showAllStatus}
          showFiduciaryStatus={showFiduciaryStatus}
          fetchedDataById={fetchedDataById}
          hiddenFaqs={hiddenFaqs}
          rubric={rubric}
          language={language}
          index={i}
          // mIndex={i + 1}
        />
      );
    });

    return (
      <div
        className={classNames(
          styles.listContent,
          styles.questionsList,
          "grid-x"
        )}
      >
        {questionsJsx}
      </div>
    );
  }
);
