import React, { useEffect } from "react";
import $ from "jquery";
import { useSelector, useDispatch } from "react-redux";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { QueryClient, QueryClientProvider } from "react-query";

import Layout from "./components/Layout";
import Home from "./components/Home";
import Pages from "./components/Pages";
import Settings from "./components/Settings";
import Library from "./components/Library";
import Chaines from "./components/Chaines";
import Search from "./components/Search";
import Authors from "./components/Authors";
import HomeRedirect from "./components/HomeRedirect";
import { DOMAIN_LIST } from "./config";
import { setHeader, setSubMenu } from "./store";

import Privacy from "./components/Privacy";
import Themes from "./components/Themes";
import Domains from "./components/Domains";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

function HandleLanguageRedirect() {
  const dispatch = useDispatch();
  var query = window.location.search.substring(1);
  var vars = query.split("&");

  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (pair[0] === "without_header") {
      dispatch(setHeader(false));
    }
    if (pair[0] === "without_submenu") {
      dispatch(setSubMenu(false));
    }
  }

  const lng = localStorage.getItem("lng") || "fr";
  return <Navigate to={`/${lng}`} />;
}

function App() {
  useEffect(() => {
    $("#app-loader")
      .fadeOut()
      .promise()
      .done(() => $("#app-loader").remove());
  });

  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <Routes>
          <Route element={<Layout />}>
            <Route path="/" element={<HandleLanguageRedirect />} />
            <Route path="/:lang" element={<HomeRedirect />} />
            <Route path=":lang">
              <Route path="privacy" element={<Privacy />}>
                <Route path="cookies" element={<Privacy />} />
                <Route path="terms_of_use" element={<Privacy />} />
              </Route>
              <Route path="search" element={<Search />} />
              <Route path="chains" element={<Chaines />}>
                <Route path=":chaineID" element={<Chaines />} />
              </Route>
              <Route path="authors" element={<Authors />} />
              <Route path="authors/:authorID" element={<Authors />} />

              <Route path="settings">
                <Route path="themes" element={<Themes />} />
                <Route path="domains" element={<Domains />} />
                <Route path="rubrics" element={<Settings />} />
              </Route>
              <Route path="library" element={<Library />} />

              <Route path=":domainID">
                <Route index={true} element={<Home />} />
                <Route path="themes/:themeID" element={<Pages />}>
                  <Route path="pages/:pageID" element={<Pages />}>
                    <Route path="rubrics/:rubricID" element={<Pages />} />
                  </Route>
                </Route>
              </Route>
            </Route>
          </Route>
        </Routes>
        <ToastContainer autoClose={3000} />
      </QueryClientProvider>
    </BrowserRouter>
  );
}

export default App;
