import axios from "axios";

import { TTP_API_URL, RUBRICS_PAGE_LIMIT, TTP_SEO_URL } from "../config";
import {
  getRequestCancellationToken,
  getRequestConfig,
  generateCancellationTokenSource,
  throwCatchedError,
} from "../utils";

let getTagsCTS;
let getAvatarsUsersCTS;
let getLibraryRubricsCTS;
let getUsedRubricsCTS;
let getCommunityFollowCTS;
let getAuthorsCTS;
let searchCTS;
let getArticlesCTS;
let getEventCTS;
let getMediasCTS;

export const getFAQs = ({ token, community }) => {
  const filter = [
    { property: "organization", value: community, operator: "eq" },
  ];

  const fields = [
    "*",
    "icon",
    "organization",
    "parent",
    "tags",
    "children",
    "author",
    "chain",
    "category",
    "theme",
    "type",
    "pages",
    "domains",
  ];

  const requestUrl = `${TTP_API_URL}/media/faq-rubric-tree`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      filter: JSON.stringify(filter),
      fields: fields.join(","),
    },
  });
};

export const getListFAQs = ({ token, community }) => {
  const filter = [];
  if (community) {
    filter.push({ property: "organization", value: 9, operator: "eq" });
  }

  const fields = ["*", "icon", "children"];

  const requestUrl = `${TTP_API_URL}/media/faq-rubric-list`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      filter: JSON.stringify(filter),
      fields: fields.join(","),
    },
  });
};

export const getListThemes = ({
  token,
  community,
  domain,
  addQuestion,
  rubrics = false,
  isEditable = false,
}) => {
  const requestUrl = `${TTP_API_URL}/blog/theme/faq-home-list`;

  let filters = [
    {
      property: "isDefault",
      value: 0,
      operator: "eq",
    },
    {
      property: "app",
      value: "FAQ",
      operator: "eq",
    },
  ];
  if (domain) {
    filters.push({
      property: "domain",
      value: domain,
      operator: "eq",
    });
  }
  if (community) {
    if (isEditable) {
      filters.push({
        property: "organization",
        value: community,
        operator: "eq",
      });
    } else {
      filters.push({
        property: "organization",
        value: 9,
        operator: "eq",
      });
    }
  }

  const fields = ["*", "mediaThemes", "pages", "icon"];
  if (rubrics) {
    fields.push("rubrics");
  }
  if (addQuestion) {
    fields.push("questions");
  }

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      filter: JSON.stringify(filters),
      fields: fields.join(","),
    },
  });
};

export const getTheme = ({ token, themeId }) => {
  const requestUrl = `${TTP_API_URL}/blog/theme`;

  let filters = [
    {
      property: "id",
      value: themeId,
      operator: "eq",
    },
  ];

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      filter: JSON.stringify(filters),
      fields: "*,mediaThemes,pages,icon,rubrics",
    },
  });
};

export const getPage = ({ token, pageId, questions = false }) => {
  const requestUrl = `${TTP_API_URL}/blog/page`;

  let filters = [
    {
      property: "id",
      value: pageId,
      operator: "in",
    },
  ];

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      filter: JSON.stringify(filters),
      fields: `*,mediaThemes,pages,icon,rubrics${
        questions ? ",questions" : ""
      }`,
    },
  });
};

export const getListPages = ({ token, page }) => {
  const requestUrl = `${TTP_API_URL}/blog/page/faq-home-list`;

  let filters = [
    {
      property: "page",
      value: page,
      operator: "eq",
    },
  ];

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      filter: JSON.stringify(filters),
      fields: "*,mediaThemes,pages,icon",
    },
  });
};

export const saveRubric = (token, data) => {
  const requestUrl = `${TTP_API_URL}/media/faq-rubric`;

  var formData = new FormData();
  formData.append("access_token", token);
  formData.append("titleFr", data.title.fr);
  formData.append("titleEn", data.title.en);
  formData.append("titleNl", data.title.nl);
  formData.append("organization", data.community);
  formData.append("csScope", data.csScope);
  formData.append("parent", data.parent);
  formData.append("category", data.categoryId);
  formData.append("theme", data.themeId);
  formData.append("status", data.status);
  formData.append("publishedAt", data.publishedAt);
  formData.append("source", data.source);
  if (data.groups) {
    formData.append("groups", data.groups);
  }
  formData.append("isPrivate", data.isPrivate ? 1 : 0);
  if (data.privateGroups) {
    formData.append("privateGroups", data.privateGroups);
  }
  if (data.domain) {
    data.domain.map((item, i) => {
      return formData.append(`domainRubric[${i}]`, item.value);
    });
  }

  formData.append("introductionFr", data.introduction.fr);
  formData.append("introductionEn", data.introduction.en);
  formData.append("introductionNl", data.introduction.nl);

  if (data.pages && data.pages.length > 0) {
    for (let i = 0; i < data.pages.length; i++) {
      formData.append(`pages[${i}]`, data.pages[i].id);
    }
  }

  if (data.tags && data.tags.length > 0) {
    data.tags.forEach((tag, i) => {
      if (tag.id) {
        formData.append(`tag[${i}][id]`, tag.id);
      } else {
        formData.append(`tag[${i}][nameFr]`, tag["nameFr"] || "");
        formData.append(`tag[${i}][nameEn]`, tag["nameEn"] || "");
        formData.append(`tag[${i}][nameNl]`, tag["nameNl"] || "");
      }
    });
  }

  if (data.authors && data.authors.length > 0) {
    let userIds = [];
    let users = data.authors.filter((user) => {
      const result =
        user.isAuthor === true &&
        user.enableAvatar != "D" &&
        userIds.indexOf(user.id) === -1;
      if (result) {
        userIds.push(user.id);
      }
      return result;
    });

    if (users && users.length > 0) {
      users.forEach((author, i) => {
        formData.append(
          `faqBlogRole[${i}][signature][title]`,
          author.signature.title
        );

        if (
          author.signature.head &&
          author.signature.head !== undefined &&
          author.signature.head.trim() !== "undefined"
        ) {
          formData.append(
            `faqBlogRole[${i}][signature][head]`,
            author.signature.head
          );
        } else {
          formData.append(`faqBlogRole[${i}][signature][head]`, "");
        }

        formData.append(
          `faqBlogRole[${i}][enableAvatar]`,
          author.enableAvatar ? 1 : 0
        );
        formData.append(`faqBlogRole[${i}][priority]`, author.priority);
        formData.append(`faqBlogRole[${i}][action]`, "WRITE");
        formData.append(`faqBlogRole[${i}][user]`, author.id);
        if (author.status) {
          formData.append(`faqBlogRole[${i}][status]`, author.status);
        }
      });
    }
  }

  if (data.chains && data.chains.length > 0) {
    data.chains.forEach((chain, i) => {
      if (chain.status === "DELETED" && data.id) {
        formData.append(`faqChain[${i}][chain]`, chain.id);
        formData.append(`faqChain[${i}][status]`, chain.status);
        formData.append(`faqChain[${i}][faqRubric]`, data.id);
      } else if (chain.status !== "DELETED") {
        formData.append(`faqChain[${i}][chain]`, chain.id);
      }
    });
  }

  // if (data.parent) {
  //   formData.append("parent", data.parent);
  // }

  if (data.id) {
    formData.append("id", data.id);
  }

  return axios.post(requestUrl, formData);
};

export const saveQuestion = (token, data) => {
  const requestUrl = `${TTP_API_URL}/media/faq-question`;

  var formData = new FormData();
  formData.append("access_token", token);
  data.title && formData.append("titleFr", data.title.fr);
  data.title && formData.append("titleEn", data.title.en);
  data.title && formData.append("titleNl", data.title.nl);
  formData.append("status", data.status);
  formData.append("publishedAt", data.publishedAt);
  formData.append("rubric", data.rubric);
  formData.append("objectType", data.objectType);
  data.answer && formData.append("answer[contentFr]", data.answer.fr);
  data.answer && formData.append("answer[contentEn]", data.answer.en);
  data.answer && formData.append("answer[contentNl]", data.answer.nl);
  if (!data.id || (data.id && data.objectType === "QUESTION")) {
    formData.append("answer[organization]", data.community);
  }

  formData.append("isPrivate", data.isPrivate ? 1 : 0);
  if (data.privateGroups) {
    formData.append("privateGroups", data.privateGroups);
  }

  if (data.tmpAttachments) {
    data.tmpAttachments.forEach((media, i) => {
      // Tmp Attachment
      formData.append(`tmpMedia[${i}][isAttachment]`, 1);
      formData.append(`tmpMedia[${i}][name]`, media.name);
      formData.append(`tmpMedia[${i}][isTmp]`, 1);
      const urlParts = media.url.split("/");
      if (urlParts.length > 0) {
        formData.append(`tmpMedia[${i}][file]`, urlParts[urlParts.length - 1]);
      }

      let type = "FILE";
      let types = ["image", "video", "audio"];

      for (let i = 0; i < types.length; i++) {
        if (media.type.substr(0, types[i].length) === types[i]) {
          type = types[i].toUpperCase();
          break;
        }
      }
      formData.append(`tmpMedia[${i}][type]`, type);
    });
  }

  if (data.attachments && data.attachments.length > 0) {
    data.attachments.forEach((attachment, index) => {
      if (!attachment.isTmp) {
        formData.append(`mediaFaqQuestion[${index}][id]`, attachment.id);
        formData.append(
          `mediaFaqQuestion[${index}][inHistory]`,
          attachment.inHistory ? 1 : 0
        );
      }
    });
  }

  if (data.tags && data.tags.length > 0) {
    data.tags.forEach((tag, i) => {
      if (tag.id) {
        formData.append(`tag[${i}][id]`, tag.id);
      } else {
        formData.append(`tag[${i}][nameFr]`, tag["nameFr"] || "");
        formData.append(`tag[${i}][nameEn]`, tag["nameEn"] || "");
        formData.append(`tag[${i}][nameNl]`, tag["nameNl"] || "");
      }
    });
  }

  if (data.id) {
    formData.append("id", data.id);
  }

  if (data.objectIds && data.objectIds.length > 0) {
    data.objectIds.forEach((item, i) => {
      formData.append(`objectIds[${i}]`, item);
    });
  }

  if (data.embedUrls && data.embedUrls.length > 0) {
    data.embedUrls.forEach((item, i) => {
      formData.append(`embedUrls[${i}]`, item);
    });
  }

  return axios.post(requestUrl, formData);
};

export const removeRubric = (token, rubricId, chain) => {
  const requestUrl = `${TTP_API_URL}/media/faq-rubric/${rubricId}`;

  var formData = new FormData();

  formData.append("access_token", token);
  formData.append("_method", "delete");
  if (chain) {
    formData.append("chain", chain.id);
  }

  return axios.post(requestUrl, formData);
};

export const removeQuestion = (token, questionId) => {
  const requestUrl = `${TTP_API_URL}/media/faq-question/${questionId}`;

  var formData = new FormData();

  formData.append("access_token", token);
  formData.append("_method", "delete");

  return axios.post(requestUrl, formData);
};

export const saveOrder = (token, data) => {
  const requestUrl = `${TTP_API_URL}/media/faq-rubric-order`;

  var formData = new FormData();
  formData.append("access_token", token);
  data.forEach((item, index) => {
    formData.append(`rubrics[${index}][id]`, item.id);
    formData.append(`rubrics[${index}][order]`, index + 1);
    if (item.children.length > 0) {
      item.children.forEach((child, childIndex) => {
        if (child.type === "RUBRIC") {
          formData.append(
            `rubrics[${index}][rubrics][${childIndex}][id]`,
            child.detail.id
          );
          formData.append(
            `rubrics[${index}][rubrics][${childIndex}][order]`,
            childIndex + 1
          );
        } else {
          formData.append(
            `rubrics[${index}][questions][${childIndex}][id]`,
            child.detail ? child.detail.id : child.id
          );
          formData.append(
            `rubrics[${index}][questions][${childIndex}][order]`,
            childIndex + 1
          );
        }
        return child;
      });
    }
  });

  return axios.post(requestUrl, formData);
};

export const getGroups = ({ token, clientId = null, customFilter = null }) => {
  const requestUrl = `${TTP_API_URL}/mailing/group`;
  let filter = [
    {
      property: "client.id",
      value: clientId,
      operator: "eq",
    },
  ];

  if (customFilter !== null && Array.isArray(customFilter)) {
    filter.push(...customFilter);
  }

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      nolimit: 1,
      fields: "id, name",
      filter: JSON.stringify(filter),
    },
  });
};

export const getTags = ({ token, customFilter = null }) => {
  let cancellationTokenSource = generateCancellationTokenSource();

  let requestCancellationToken = getRequestCancellationToken(
    getTagsCTS,
    cancellationTokenSource
  );
  getTagsCTS = cancellationTokenSource;

  const requestUrl = `${TTP_API_URL}/blog/tag`;
  let filter = [];

  if (customFilter !== null) {
    if (Array.isArray(customFilter)) {
      filter.push(...customFilter);
    } else {
      filter.push(customFilter);
    }
  }

  let params = {
    access_token: token,
    nolimit: 1,
    fields:
      "id, nameFr, nameEn, nameNl, sanitizedNameFr, parent, isSynonym, isUniversal",
    filter: JSON.stringify(filter),
  };

  let requestConfig = getRequestConfig(params, requestCancellationToken);
  return axios.get(requestUrl, requestConfig).catch(function (thrown) {
    throwCatchedError(thrown);
  });
};

export const getWebtoolIcons = (token) => {
  const sort = [
    {
      property: "label",
      dir: "asc",
    },
  ];
  const fields = ["*"];
  const requestUrl = `${TTP_API_URL}/media/webtool-icon`;

  return axios
    .get(requestUrl, {
      params: {
        access_token: token,
        sort: JSON.stringify(sort),
        fields: fields.join(","),
        nolimit: 1,
      },
    })
    .catch(function (thrown) {
      throwCatchedError(thrown);
    });
};

export const getAvatarsAndUsers = ({ token, word, organizationId }) => {
  let cancellationTokenSource = generateCancellationTokenSource();

  let requestCancellationToken = getRequestCancellationToken(
    getAvatarsUsersCTS,
    cancellationTokenSource
  );
  getAvatarsUsersCTS = cancellationTokenSource;

  const fields = [
    "*",
    "email",
    "mediaChain",
    "avatar",
    "blogRoleInOrganization",
  ];

  let requestUrl = `${TTP_API_URL}/blog/avatar/avatars-and-users`;

  let filter = [];

  if (word !== "" && word.length >= 3) {
    filter.push({
      property: "name",
      value: word,
      operator: "like",
    });
  }

  let params = {
    access_token: token,
    filter: JSON.stringify(filter),
    fields: fields.join(","),
    limit: 5,
    start: 0,
    organization_id: organizationId,
  };

  let requestConfig = getRequestConfig(params, requestCancellationToken);
  return axios.get(requestUrl, requestConfig).catch(function (thrown) {
    throwCatchedError(thrown);
  });
};

export const getAvatars = ({ token, word, organizationId }) => {
  let cancellationTokenSource = generateCancellationTokenSource();

  let requestCancellationToken = getRequestCancellationToken(
    getAvatarsUsersCTS,
    cancellationTokenSource
  );
  getAvatarsUsersCTS = cancellationTokenSource;

  const fields = ["*", "avatar", "mediaChain", "organization", "creator"];

  let requestUrl = `${TTP_API_URL}/blog/avatar/blog-avatars`;

  let filter = [];
  if (organizationId) {
    filter.push({
      property: "organization",
      value: organizationId,
      operator: "eq",
    });
  }
  if (word !== "" && word.length >= 3) {
    filter.push({
      property: "name",
      value: word,
      operator: "like",
    });
  }

  let params = {
    access_token: token,
    filter: JSON.stringify(filter),
    fields: fields.join(","),
    limit: 5,
    start: 0,
  };

  let requestConfig = getRequestConfig(params, requestCancellationToken);
  return axios.get(requestUrl, requestConfig).catch(function (thrown) {
    throwCatchedError(thrown);
  });
};
export const getAvatarsAndRubrics = ({
  token,
  organizationId,
  chaineID,
  start,
  limit,
  rubricsStart,
  rubricsLimit,
}) => {
  let cancellationTokenSource = generateCancellationTokenSource();

  let requestCancellationToken = getRequestCancellationToken(
    getAvatarsUsersCTS,
    cancellationTokenSource
  );
  getAvatarsUsersCTS = cancellationTokenSource;

  const fields = ["*", "avatar", "mediaChain"];

  let requestUrl = `${TTP_API_URL}/blog/avatar/blog-avatars-rubrics`;

  let filter = [];
  if (organizationId) {
    filter.push({
      property: "organization",
      value: organizationId,
      operator: "eq",
    });
  }

  if (chaineID) {
    filter.push({
      property: "id",
      value: parseInt(chaineID),
      operator: "eq",
    });
  }

  let params = {
    access_token: token,
    filter: JSON.stringify(filter),
    fields: fields.join(","),
    limit,
    start,
    rubricsStart,
    rubricsLimit,
  };

  let requestConfig = getRequestConfig(params, requestCancellationToken);
  return axios.get(requestUrl, requestConfig).catch(function (thrown) {
    throwCatchedError(thrown);
  });
};

export const getAuthorsAndQuestions = ({ token, organizationId }) => {
  let cancellationTokenSource = generateCancellationTokenSource();

  let requestCancellationToken = getRequestCancellationToken(
    getAvatarsUsersCTS,
    cancellationTokenSource
  );
  getAvatarsUsersCTS = cancellationTokenSource;

  const fields = ["*"];

  let requestUrl = `${TTP_API_URL}/blog/author/list-questions`;

  let filter = [];
  if (organizationId) {
    filter.push({
      property: "organization",
      value: organizationId,
      operator: "eq",
    });
  }

  let params = {
    access_token: token,
    filter: JSON.stringify(filter),
    fields: fields.join(","),
  };

  let requestConfig = getRequestConfig(params, requestCancellationToken);
  return axios.get(requestUrl, requestConfig).catch(function (thrown) {
    throwCatchedError(thrown);
  });
};

export const getCategories = ({
  token,
  language = "fr",
  customFilter = null,
}) => {
  const requestUrl = `${TTP_API_URL}/blog/category`;

  let filter = [];

  if (customFilter !== null) {
    if (Array.isArray(customFilter)) {
      filter.push(...customFilter);
    } else {
      filter.push(customFilter);
    }
  }

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      filter: JSON.stringify(filter),
      nolimit: 1,
    },
  });
};

export const getMediaCategories = ({
  token,
  language = "fr",
  customFilter = null,
}) => {
  const requestUrl = `${TTP_API_URL}/media/category`;

  let filter = [];

  if (customFilter !== null) {
    if (Array.isArray(customFilter)) {
      filter.push(...customFilter);
    } else {
      filter.push(customFilter);
    }
  }

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      filter: JSON.stringify(filter),
      nolimit: 1,
    },
  });
};

//Libraru requests
export const getLibraryRubrics = (
  token,
  communitiesId = [],
  filters,
  options,
  cancellationTokenSource = null
) => {
  let requestCancellationToken = getRequestCancellationToken(
    getLibraryRubricsCTS,
    cancellationTokenSource
  );

  options = options || {};
  filters = filters || {};

  let page = options.page || 1;
  let limit = RUBRICS_PAGE_LIMIT;

  const sort = [
    /*{
      property: "publishedAt",
      dir: "desc",
    },*/
  ];
  const fields = ["*", "icon", "organization", "author", "avatars", "category"];

  let requestUrl = `${TTP_API_URL}/media/faq-rubric/get-library-rubrics`;

  if (communitiesId.length > 0) {
    requestUrl += `?organization_id=${communitiesId[0]}`;
  }

  const filterData = [{ property: "parent", value: null, operator: "null" }];

  if (filters.community) {
    filterData.push({
      property: "organization.id",
      value: filters.community,
      operator: "in",
    });
  }

  if (filters.search) {
    filterData.push({
      property: "titleFr",
      value: encodeURI(filters.search),
      operator: "like",
    });
  }

  let params = {
    access_token: token,
    filter: JSON.stringify(filterData),
    sort: JSON.stringify(sort),
    fields: fields.join(","),
    limit: limit,
    start: (page - 1) * limit,
  };

  let requestConfig = getRequestConfig(params, requestCancellationToken);

  return axios.get(requestUrl, requestConfig).catch(function (thrown) {
    throwCatchedError(thrown);
  });
};

export const getUsedRubrics = (
  token,
  communitiesId = [],
  filters,
  options,
  cancellationTokenSource = null
) => {
  let requestCancellationToken = getRequestCancellationToken(
    getUsedRubricsCTS,
    cancellationTokenSource
  );

  options = options || {};
  filters = filters || {};

  let page = options.page || 1;
  let limit = RUBRICS_PAGE_LIMIT;

  /*const sort = [
    {
      property: "publishedAt",
      dir: "desc",
    },
  ];*/
  const fields = ["*", "icon", "organization", "author", "avatars", "category"];

  let requestUrl = `${TTP_API_URL}/media/faq-rubric/get-used-rubrics`;

  if (communitiesId.length > 0) {
    requestUrl += `?organization_id=${communitiesId[0]}`;
  }

  const filterData = [];

  if (filters.csScope) {
    filterData.push({
      property: "or.csScope",
      value: filters.csScope,
      operator: "like",
    });
  }

  if (filters.community) {
    filterData.push({
      property: "organization.id",
      value: filters.community,
      operator: "in",
    });
  }

  if (filters.search) {
    filterData.push({
      property: "titleFr",
      value: encodeURI(filters.search),
      operator: "like",
    });
  }

  /*if (filters.languages) {
    filterData.push({
      property: "language",
      value: filters.languages,
      operator: "in",
    });
  }*/

  if (
    filters.csScope &&
    filters.csScope === "GROUP_SHARE" &&
    filters.group !== null
  ) {
    filterData.push({
      property: "or.groups",
      value: filters.group.id,
      operator: "flike",
    });
  }

  let params = {
    access_token: token,
    filter: JSON.stringify(filterData),
    //sort: JSON.stringify(sort),
    fields: fields.join(","),
    limit: limit,
    start: (page - 1) * limit,
  };

  let requestConfig = getRequestConfig(params, requestCancellationToken);

  return axios.get(requestUrl, requestConfig).catch(function (thrown) {
    throwCatchedError(thrown);
  });
};

export const setAddToLibrary = ({
  token,
  rubric,
  owner,
  organization,
  isOwner,
  isAuto,
  csScope,
  groups,
  sharedId,
  onProgress,
}) => {
  const requestUrl = sharedId
    ? `${TTP_API_URL}/media/organization-rubric/${sharedId}`
    : `${TTP_API_URL}/media/organization-rubric`;
  let config = {};
  if (onProgress) {
    config.onUploadProgress = (progressEvent) => {
      let percentCompleted = Math.floor(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      onProgress(percentCompleted);
    };
  }

  let formData = new FormData();
  formData.append("access_token", token);
  formData.append("organization", organization);
  formData.append("owner", owner);
  formData.append("rubric", rubric);
  formData.append("isOwner", isOwner);
  formData.append("isAuto", isAuto);
  formData.append("groups", groups.join(","));
  formData.append("csScope", csScope.join(","));

  return axios.post(requestUrl, formData, config);
};

export const removeFromLibrary = (token, rubricId) => {
  const requestUrl = `${TTP_API_URL}/media/organization-rubric/by-rubric/${rubricId}`;

  var formData = new FormData();

  formData.append("access_token", token);
  formData.append("_method", "delete");

  return axios.post(requestUrl, formData);
};

export const getCommunityFollow = (token, communityId, sourceToken = null) => {
  let requestCancellationToken = getRequestCancellationToken(
    getCommunityFollowCTS,
    sourceToken
  );

  const filter = [{ property: "follower", value: communityId, operator: "eq" }];

  const fields = ["*", "following"];
  const requestUrl = `${TTP_API_URL}/organization/follow`;

  const params = {
    access_token: token,
    fields: fields.join(","),
    filter: JSON.stringify(filter),
  };

  let requestConfig = getRequestConfig(params, requestCancellationToken);

  return axios.get(requestUrl, requestConfig).catch(function (thrown) {
    throwCatchedError(thrown);
  });
};

export const getTypes = ({ token, language = null, communityId = null }) => {
  const requestUrl = `${TTP_API_URL}/blog/type`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      fields: "*",
    },
  });
};

export const getThemes = ({ token, communityId = null }) => {
  const requestUrl = `${TTP_API_URL}/blog/theme/faq-list`;

  let filters = [
    {
      property: "isDefault",
      value: 0,
      operator: "eq",
    },
    {
      property: "app",
      value: "FAQ",
      operator: "eq",
    },
  ];
  if (communityId) {
    filters.push({
      property: "organization",
      value: communityId,
      operator: "eq",
    });
  }

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      filter: JSON.stringify(filters),
      fields: "*,mediaThemes,pages,domains",
    },
  });
};

export const saveTheme = async (token, data) => {
  const requestUrl = `${TTP_API_URL}/blog/theme`;

  var formData = new FormData();
  formData.append("access_token", token);
  formData.append("titleFr", data.titleFr);
  formData.append("titleNl", data.titleNl);
  formData.append("titleEn", data.titleEn);
  formData.append("organization", data.organization);
  formData.append("isDefault", data.isDefault);
  formData.append("app", data.app);
  if (data.domain) {
    data.domain.map((item, i) => {
      return formData.append(`domainTheme[${i}]`, item);
    });
  }

  if (data.icon) {
    formData.append("icon", data.icon);
  }

  formData.append("isPrivate", data.isPrivate ? 1 : 0);
  if (data.privateGroups) {
    formData.append("privateGroups", data.privateGroups);
  }

  if (data.coverFile) {
    formData.append(`mediaTheme[0][yPos]`, data.yPos);
    if (data.coverFile instanceof File) {
      formData.append(`mediaTheme[0][file]`, data.coverFile);
      formData.append(`mediaTheme[0][name]`, data.coverFile.name);
    }
  }

  if (data.id) {
    formData.append("id", data.id);
  }

  const res = await axios.post(requestUrl, formData);
  if (data.icon) {
    var iconFormData = new FormData();
    iconFormData.append("access_token", token);
    iconFormData.append("id", data.icon);
    iconFormData.append("isUsed", 1);
    await axios.post(`${TTP_API_URL}/media/webtool-icon`, iconFormData);
  }

  return res;
};

export const savePage = async (token, data) => {
  const requestUrl = `${TTP_API_URL}/blog/page`;

  var formData = new FormData();
  formData.append("access_token", token);
  formData.append("titleFr", data.titleFr);
  formData.append("titleNl", data.titleNl);
  formData.append("titleEn", data.titleEn);
  formData.append("organization", data.organization);
  formData.append("theme", data.theme);

  if (data.icon) {
    formData.append("icon", data.icon);
  }

  formData.append("isPrivate", data.isPrivate ? 1 : 0);
  if (data.privateGroups) {
    formData.append("privateGroups", data.privateGroups);
  }

  if (data.coverFile) {
    formData.append(`mediaPage[0][yPos]`, data.yPos);
    if (data.coverFile instanceof File) {
      formData.append(`mediaPage[0][file]`, data.coverFile);
      formData.append(`mediaPage[0][name]`, data.coverFile.name);
    }
  }

  if (data.id) {
    formData.append("id", data.id);
  }

  const res = await axios.post(requestUrl, formData);
  if (data.icon) {
    var iconFormData = new FormData();
    iconFormData.append("access_token", token);
    iconFormData.append("id", data.icon);
    iconFormData.append("isUsed", 1);
    await axios.post(`${TTP_API_URL}/media/webtool-icon`, iconFormData);
  }

  return res;
};

export const getAuthors = ({ token, word, organizationId }) => {
  let cancellationTokenSource = generateCancellationTokenSource();

  let requestCancellationToken = getRequestCancellationToken(
    getAuthorsCTS,
    cancellationTokenSource
  );
  getAuthorsCTS = cancellationTokenSource;

  const fields = ["*", "user", "mediaChain", "avatar"];

  let requestUrl = `${TTP_API_URL}/blog/author/list`;

  let filter = [];
  if (organizationId) {
    filter.push({
      property: "organization",
      value: organizationId,
      operator: "eq",
    });
  }

  if (word !== "" && word.length >= 3) {
    filter.push({
      property: "name",
      value: word,
      operator: "like",
    });
  }

  let params = {
    access_token: token,
    filter: JSON.stringify(filter),
    fields: fields.join(","),
    limit: 5,
    start: 0,
  };

  let requestConfig = getRequestConfig(params, requestCancellationToken);
  return axios.get(requestUrl, requestConfig).catch(function (thrown) {
    throwCatchedError(thrown);
  });
};

export const getSearchResult = async ({ community = 9, value, language }) => {
  let cancellationTokenSource = generateCancellationTokenSource();
  let requestCancellationToken = getRequestCancellationToken(
    searchCTS,
    cancellationTokenSource
  );
  searchCTS = cancellationTokenSource;

  const requestUrl = `${TTP_SEO_URL}/faq/_msearch`;

  let params = {
    lng: language,
    keyword: value,
    org: [community],
    size: 20,
  };

  let requestConfig = getRequestConfig(params, requestCancellationToken);

  return axios.get(requestUrl, requestConfig).catch(function (thrown) {
    throwCatchedError(thrown);
  });
};

export const addAnswer = async (token, data) => {
  const requestUrl = `${TTP_API_URL}/media/faq-answer`;

  var formData = new FormData();
  formData.append("access_token", token);
  formData.append("contentFr", data.answer.fr);
  formData.append("contentEn", data.answer.en);
  formData.append("contentNl", data.answer.nl);
  formData.append("question", data.id);
  formData.append("organization", data.community);
  formData.append("isMain", 1);

  if (data.answerId) {
    formData.append("id", data.answerId);
  }

  const res = await axios.post(requestUrl, formData);
  return res.data.data;
};

export const suggestAnswer = async (token, data) => {
  const requestUrl = `${TTP_API_URL}/media/faq-suggestion`;

  var formData = new FormData();
  formData.append("access_token", token);
  formData.append("contentFr", data.answer.fr);
  formData.append("contentEn", data.answer.en);
  formData.append("contentNl", data.answer.nl);
  formData.append("question", data.id);
  formData.append("status", "PENDING");
  data.suggestion && formData.append("id", data.suggestion);
  const res = await axios.post(requestUrl, formData);
  return res.data.data;
};

export const fetchSuggestion = async (token, id) => {
  const requestUrl = `${TTP_API_URL}/media/suggestion/${id}`;

  const res = await axios.get(requestUrl, {
    params: {
      access_token: token,
    },
  });
  return res.data.data;
};

export const getRubric = ({ token, id = null, community }) => {
  const requestUrl = `${TTP_API_URL}/media/faq-rubric`;

  let filters = [
    {
      property: "id",
      value: id,
      operator: "eq",
    },
    {
      property: "organization",
      value: community === 9 ? [9] : [9, community],
      operator: "in",
    },
  ];

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      filter: JSON.stringify(filters),
      fields:
        "*,mediaThemes,icon,organization,theme,pages,children,author,chain,category,tags,domains",
    },
  });
};

export const saveTag = (token, data) => {
  const requestUrl = `${TTP_API_URL}/blog/tag`;

  var formData = new FormData();
  formData.append("access_token", token);
  formData.append("nameFr", data.nameFr);
  formData.append("nameNl", data.nameNl);
  formData.append("nameEn", data.nameEn);

  if (data.id) {
    formData.append("id", data.id);
  }

  return axios.post(requestUrl, formData);
};

export const getDomains = ({ token, community }) => {
  const requestUrl = `${TTP_API_URL}/blog/domain`;

  let filters = [];
  // if (community) {
  filters.push({
    property: "organization",
    value: 9,
    operator: "eq",
  });
  // }

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      filter: JSON.stringify(filters),
      fields: "*,icon",
    },
  });
};

export const uploadFaqTmpMedia = ({ token, data }) => {
  const requestUrl = `${TTP_API_URL}/media/faq-question/upload-tmp-media`;

  var formData = new FormData();
  formData.append("access_token", token);
  formData.append("file", data);

  return axios.post(requestUrl, formData, {
    Warning: "413",
  });
};

export const deleteFaqTmpMedias = (token) => {
  const requestUrl = `${TTP_API_URL}/media/faq-question/delete-tmp-medias`;

  var formData = new FormData();
  formData.append("access_token", token);

  return axios.post(requestUrl, formData);
};

export const getFilteredRubrics = ({
  token,
  domain,
  category,
  communityId = null,
}) => {
  const requestUrl = `${TTP_API_URL}/media/faq-rubric/filter-list`;

  let filters = [];
  if (communityId) {
    filters.push({
      property: "organization",
      value: 9,
      operator: "eq",
    });
  }

  if (domain) {
    filters.push({
      property: "domain",
      value: domain,
      operator: "eq",
    });
  }

  if (category) {
    filters.push({
      property: "category",
      value: category,
      operator: "eq",
    });
  }

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      filter: JSON.stringify(filters),
      fields: "*,chain,author,theme,pages,children",
    },
  });
};

export const removeAnswer = (token, answerId) => {
  const requestUrl = `${TTP_API_URL}/media/faq-answer/${answerId}`;

  var formData = new FormData();

  formData.append("access_token", token);
  formData.append("_method", "delete");

  return axios.post(requestUrl, formData);
};

export const getMedias = ({
  token,
  limit = 20,
  communityId,
  language,
  docType = "IMAGE",
  search,
  start,
  ids,
}) => {
  let cancellationTokenSource = generateCancellationTokenSource();

  let requestCancellationToken = getRequestCancellationToken(
    getMediasCTS,
    cancellationTokenSource
  );

  getMediasCTS = cancellationTokenSource;
  const fields = ["*", "avatar", "preview"];

  let requestUrl = `${TTP_API_URL}/media/media`;
  const filter = [];

  const IdFilter = [];
  if (ids && ids.length > 0) {
    IdFilter.push({
      property: "id",
      value: ids,
      operator: "in",
    });
  } else {
    filter.push(
      ...[
        { property: "mediaStatus", operator: "eq", value: "PUBLISHED" },
        { property: "objectType", operator: "nin", value: ["LOGO", "MASK"] },
        { property: "docType", operator: "eq", value: docType },
        { property: "isPrivate", operator: "eq", value: "0" },
      ]
    );
    if (language) {
      filter.push({
        property: "languages",
        operator: "flike",
        value: language,
      });
    } else {
      filter.push({ property: "languages", operator: "flike", value: "fr" });
    }
    if (communityId) {
      filter.push({
        property: "organization",
        value: [communityId, 9],
        operator: "in",
      });
    }

    if (search.trim().length > 0) {
      filter.push({
        property: `title${
          language.charAt(0).toUpperCase() + language.slice(1)
        }`,
        value: search,
        operator: "like",
      });
    }
  }

  const sort = [
    {
      property: "createdAt",
      dir: "desc",
    },
  ];

  const params = {
    access_token: token,
    filter: JSON.stringify(IdFilter.length > 0 ? IdFilter : filter),
    sort: JSON.stringify(sort),
    fields: fields.join(","),
    limit,
    start,
  };
  let requestConfig = getRequestConfig(params, requestCancellationToken);
  return axios.get(requestUrl, requestConfig).catch(function (thrown) {
    throwCatchedError(thrown);
  });
};

export const saveMedia = ({
  token,
  community,
  file,
  titleFR,
  titleEN,
  titleNL,
  isPrivate,
  inTheNews,
  overrideMainImage = false,
  languages = ["FR", "EN", "NL"],
}) => {
  const requestUrl = `${TTP_API_URL}/media/media`;
  let config = {};

  let formData = new FormData();
  formData.append("access_token", token);
  formData.append("organization", community.id);

  if (languages && languages.includes("FR")) {
    formData.append("titleFr", titleFR || file.name);
  }

  if (languages && languages.includes("NL")) {
    formData.append("titleNl", titleNL || file.name);
  }

  if (languages && languages.includes("EN")) {
    formData.append("titleEn", titleEN || file.name);
  }
  formData.append("overrideMainImage", overrideMainImage);
  file && formData.append("file", file);

  formData.append("isPrivate", isPrivate ? 1 : 0);
  formData.append("inTheNews", inTheNews ? 1 : 0);

  languages && formData.append("languages", languages.join(","));
  return axios.post(requestUrl, formData, config);
};

export const getArticles = ({
  token,
  limit = 10,
  communityId,
  language,
  search,
  category,
  start,
  ids,
}) => {
  let cancellationTokenSource = generateCancellationTokenSource();

  let requestCancellationToken = getRequestCancellationToken(
    getArticlesCTS,
    cancellationTokenSource
  );

  getArticlesCTS = cancellationTokenSource;

  const fields = ["*", "url", "author", "main_media", "category"];
  const IdsFields = [
    "*",
    "url",
    "introduction",
    "author",
    "main_media",
    "category",
    "tags",
    "image_reduced",
    "organization",
    "avatars",
    "pages",
    "theme",
    "type",
    "social",
    "chains",
  ];

  let requestUrl = `${TTP_API_URL}/blog/article`;

  const filter = [];
  const IdsFilter = [];

  if (ids && ids.length > 0) {
    IdsFilter.push({
      property: "id",
      value: ids,
      operator: "in",
    });
  } else {
    if (language) {
      filter.push({ property: "language", operator: "in", value: language });
    } else {
      filter.push({ property: "language", operator: "in", value: "fr" });
    }
    if (communityId) {
      filter.push({
        property: "organization.id",
        value: communityId,
        operator: "eq",
      });
    }

    if (search.trim().length > 0) {
      filter.push({
        property: "title",
        value: search,
        operator: "like",
      });
    }

    if (category) {
      filter.push({
        property: "category.id",
        value: category.id,
        operator: "eq",
      });
    }
  }

  const sort = [
    {
      property: "publishedAt",
      dir: "desc",
    },
  ];

  const params = {
    access_token: token,
    filter: JSON.stringify(IdsFilter.length > 0 ? IdsFilter : filter),
    sort: JSON.stringify(sort),
    fields: ids ? IdsFields.join(",") : fields.join(","),
    limit,
    start,
  };

  let requestConfig = getRequestConfig(params, requestCancellationToken);
  return axios.get(requestUrl, requestConfig).catch(function (thrown) {
    throwCatchedError(thrown);
  });
};

export const getEvents = ({
  token,
  limit = 10,
  communityId,
  language,
  search,
  start,
  ids,
}) => {
  let cancellationTokenSource = generateCancellationTokenSource();

  let requestCancellationToken = getRequestCancellationToken(
    getEventCTS,
    cancellationTokenSource
  );

  getEventCTS = cancellationTokenSource;

  const fields = [
    "id",
    "nameFr",
    "nameNl",
    "nameEn",
    "urlBannerFr",
    "urlBannerNl",
    "urlBannerEn",
    "eventDate",
    "memberPrice",
    "nonMemberPrice",
    "languages",
    "isReplayable",
    "speakers-abstract",
    "channel",
  ];

  const IdsFields = [
    "id",
    "type",
    "nameFr",
    "nameNl",
    "nameEn",
    "placeFr",
    "placeNl",
    "placeEn",
    "descriptionFr",
    "descriptionNl",
    "descriptionEn",
    "urlBannerFr",
    "urlBannerNl",
    "urlBannerEn",
    "eventDate",
    "slotsCount",
    "client",
    "memberPrice",
    "nonMemberPrice",
    "languages",
    "isReplayable",
    "accreditationHours",
    "status",
    "replayStatus",
    "labelFr",
    "labelNl",
    "labelEn",
    "speakers-abstract",
    "user-registered",
    "eventCycles",
  ];

  let requestUrl = `${TTP_API_URL}/event/event/get-list-for-user`;

  const filter = [];
  const IdsFilter = [];
  if (ids && ids.length > 0) {
    IdsFilter.push({
      property: "id",
      value: ids,
      operator: "in",
    });
  } else {
    if (communityId) {
      filter.push({
        property: "client",
        value: communityId,
        operator: "eq",
      });
    }

    if (search.trim().length > 0) {
      filter.push({
        property: "search",
        value: search,
        operator: "like",
      });
    }
  }

  const params = {
    access_token: token,
    filter: JSON.stringify(IdsFilter.length > 0 ? IdsFilter : filter),
    fields: IdsFields ? IdsFields.join(",") : fields.join(","),
    limit,
    start,
    period: "FAQ_APP",
  };
  if (language) {
    params.language = language;
  }

  let requestConfig = getRequestConfig(params, requestCancellationToken);
  return axios.get(requestUrl, requestConfig).catch(function (thrown) {
    throwCatchedError(thrown);
  });
};

export const getPrivacyRubrics = ({ token, communityId = null }) => {
  const requestUrl = `${TTP_API_URL}/media/privacy-rubric`;

  let filters = [
    {
      property: "isCookie",
      operator: "eq",
      value: "0",
    },
    {
      property: "termsOfUse",
      operator: "eq",
      value: "0",
    },
  ];
  if (communityId) {
    filters.push({
      property: "organization",
      value: communityId,
      operator: "eq",
    });
  }
  const sort = [
    {
      property: "order",
      dir: "asc",
    },
  ];

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      filter: JSON.stringify(filters),
      sort: JSON.stringify(sort),
      fields: "*,versions,introductions",
    },
  });
};

export const saveVersion = async ({
  token,
  data,
  rubricId,
  communityId,
  isCookie,
}) => {
  const versionRequestUrl = `${TTP_API_URL}/media/rubric-version`;
  const rubricRequestUrl = `${TTP_API_URL}/media/privacy-rubric`;

  var versionFormData = new FormData();
  var rubricFormData = new FormData();

  rubricFormData.append("access_token", token);
  versionFormData.append("access_token", token);
  versionFormData.append("name", data.name);
  versionFormData.append("titleFr", data.title.fr);
  versionFormData.append("titleEn", data.title.en);
  versionFormData.append("titleNl", data.title.nl);
  versionFormData.append("introductionFr", data.introduction.fr);
  versionFormData.append("introductionEn", data.introduction.en);
  versionFormData.append("introductionNl", data.introduction.nl);
  versionFormData.append("status", data.status);
  versionFormData.append("publishedAt", data.publishedAt);

  if (data.id) {
    versionFormData.append("id", data.id);
  }

  if (rubricId) {
    versionFormData.append("privacyRubric", rubricId);
  } else {
    rubricFormData.append("organization", 9 /*communityId*/);
    if (isCookie) {
      rubricFormData.append("isCookie", 1);
    }
    const resRub = await axios.post(rubricRequestUrl, rubricFormData);
    versionFormData.append("privacyRubric", resRub.data.data.id);
    const resVer = await axios.post(versionRequestUrl, versionFormData);
    return { resRub, resVer };
  }

  return axios.post(versionRequestUrl, versionFormData);
};

export const updateVersionStatus = async ({ token, data }) => {
  const versionRequestUrl = `${TTP_API_URL}/media/rubric-version`;

  var versionFormData = new FormData();

  versionFormData.append("access_token", token);
  versionFormData.append("status", data.status);
  versionFormData.append("publishedAt", data.publishedAt);
  versionFormData.append("id", data.id);

  return axios.post(versionRequestUrl, versionFormData);
};

export const savePrivacyRubricsOrder = (token, data) => {
  const requestUrl = `${TTP_API_URL}/media/privacy-rubric-order`;

  var formData = new FormData();
  formData.append("access_token", token);
  data.forEach((item, index) => {
    formData.append(`rubrics[${index}][id]`, item.id);
    formData.append(`rubrics[${index}][order]`, index + 1);
  });

  return axios.post(requestUrl, formData);
};

export const updateRubricStatus = async ({ token, data }) => {
  const requestUrl = `${TTP_API_URL}/media/privacy-rubric`;

  var formData = new FormData();

  formData.append("access_token", token);
  formData.append("showInFooter", data.showInFooter ? 1 : 0);
  formData.append("id", data.id);

  return axios.post(requestUrl, formData);
};

export const getPrivacyCookies = ({ token, communityId = null }) => {
  const requestUrl = `${TTP_API_URL}/media/privacy-rubric`;

  let filters = [
    {
      property: "isCookie",
      operator: "eq",
      value: "1",
    },
  ];

  if (communityId) {
    filters.push({
      property: "organization",
      value: communityId,
      operator: "eq",
    });
  }
  const sort = [
    {
      property: "order",
      dir: "asc",
    },
  ];

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      filter: JSON.stringify(filters),
      sort: JSON.stringify(sort),
      fields: "*,versions,introductions",
    },
  });
};

export const saveCookie = async ({ token, data, communityId }) => {
  const rubricRequestUrl = `${TTP_API_URL}/media/privacy-rubric`;

  var rubricFormData = new FormData();

  rubricFormData.append("access_token", token);
  rubricFormData.append("organization", communityId);
  rubricFormData.append("isCookie", 1);
  rubricFormData.append("functionalCookiesFr", data.functionalFr);
  rubricFormData.append("functionalCookiesEn", data.functionalEn);
  rubricFormData.append("functionalCookiesNl", data.functionalNl);
  rubricFormData.append("optionalCookiesFr", data.optionalFr);
  rubricFormData.append("optionalCookiesEn", data.optionalEn);
  rubricFormData.append("optionalCookiesNl", data.optionalNl);

  if (data.id) {
    rubricFormData.append("id", data.id);
  }

  return axios.post(rubricRequestUrl, rubricFormData);
};

export const getTermsOfUse = ({ token, communityId = null }) => {
  const requestUrl = `${TTP_API_URL}/media/privacy-rubric`;

  let filters = [
    {
      property: "termsOfUse",
      operator: "eq",
      value: "1",
    },
  ];

  if (communityId) {
    filters.push({
      property: "organization",
      value: communityId,
      operator: "eq",
    });
  }
  const sort = [
    {
      property: "order",
      dir: "asc",
    },
  ];

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      filter: JSON.stringify(filters),
      sort: JSON.stringify(sort),
      fields: "*,versions,introductions",
    },
  });
};

export const saveTermOfUse = async ({ token, data, communityId, rubricId }) => {
  const versionRequestUrl = `${TTP_API_URL}/media/rubric-version`;
  const rubricRequestUrl = `${TTP_API_URL}/media/privacy-rubric`;

  var versionFormData = new FormData();
  var rubricFormData = new FormData();

  rubricFormData.append("access_token", token);
  versionFormData.append("access_token", token);
  versionFormData.append("name", data.name);
  versionFormData.append("status", data.status);
  versionFormData.append("publishedAt", data.publishedAt);
  versionFormData.append("introductionFr", data.introductionFr);
  versionFormData.append("introductionEn", data.introductionEn);
  versionFormData.append("introductionNl", data.introductionNl);

  if (data.id) {
    versionFormData.append("id", data.id);
  }

  if (rubricId) {
    versionFormData.append("privacyRubric", rubricId);
  } else {
    rubricFormData.append("organization", communityId);
    rubricFormData.append("termsOfUse", 1);
    rubricFormData.append("organization", communityId);
    const resRub = await axios.post(rubricRequestUrl, rubricFormData);
    versionFormData.append("privacyRubric", resRub.data.data.id);
    const resVer = await axios.post(versionRequestUrl, versionFormData);
    return { resRub, resVer };
  }

  return axios.post(versionRequestUrl, versionFormData);
};

export const removePrivacyRubric = ({ token, rubricId }) => {
  const requestUrl = `${TTP_API_URL}/media/privacy-rubric/${rubricId}`;

  var formData = new FormData();

  formData.append("access_token", token);
  formData.append("_method", "delete");

  return axios.post(requestUrl, formData);
};

export const shareFaq = ({ token, data }) => {
  const requestUrl = `${TTP_API_URL}/media/faq-sharing`;

  var formData = new FormData();

  formData.append("access_token", token);
  formData.append("objectType", data.objectType);
  formData.append("objectId", data.objectId);
  formData.append("publishingDate", data.publishingDate);
  formData.append("sharingDate", data.sharingDate);
  formData.append("target", data.target);
  if (data.specCollaborators)
    formData.append("specCollaborators", data.specCollaborators);
  if (data.specClients) formData.append("specClients", data.specClients);
  if (data.specContacts) formData.append("specContacts", data.specContacts);

  return axios.post(requestUrl, formData);
};

export const getLibrary = ({ token, user }) => {
  const requestUrl = `${TTP_API_URL}/media/faq-sharing`;

  let filters = [
    {
      property: "id",
      value: user.id,
      operator: "eq",
    },
    {
      property: "userType",
      value: user && user.role ? user.role.organization.portalScope : null,
      operator: "eq",
    },
  ];

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      filter: JSON.stringify(filters),
    },
  });
};
export const uploadTmpMedia = ({ token, data }) => {
  const requestUrl = `${TTP_API_URL}/blog/article/upload-tmp-media`;

  var formData = new FormData();
  formData.append("access_token", token);
  formData.append("file", data);

  return axios.post(requestUrl, formData, {
    Warning: "413",
  });
};
