const faqNl = {
  save: "Opslaan",
  cancel: "Annuleren",
  english: "English",
  french: "French",
  dutch: "Dutch",
  title: "Veel Gestelde Vragen",
  subtitle: "Welkom bij het Tamtam Helpcentrum",
  add_title: "Voeg een vraag toe",
  rubric: "Rubriek",
  rubrics: "Rubrieken",
  rubric_add_headline: "Rubriek toevoegen",
  rubric_edit_headline: "Rubriek bewerken",
  rubric_form_title: "Titel",
  rubric_form_intro: "Invoering",
  rubric_parent: "Ouder",
  appName: "Application",
  faq_add_headline: "Voeg een vraag toe met betrekking tot de rubriek",
  faq_edit_headline: "Een aan de rubriek gerelateerde vraag bewerken",
  faq_status: "Status",
  faq_form_anwser: "Antwoord geven",
  helpful: "Was dit nuttig?",
  yes: "Ja",
  no: "Nee",
  add_question: "Vraag toevoegen",
  edit_question: "Vraag bewerken",
  question: "Vraag",
  modal_delete_title: "Bevestiging verwijderen",
  modal_delete_text: "Weet u zeker dat u dit item wilt verwijderen?",
  rubric_delete_error: "An error occured!",
  save_order: "Bestelling opslaan",
  home_title: "Help center",
  home_sub_title: "How can we help you today?",
  home_need_help: "Need help?",
  home: "Huis",
  loading: "Bezig met laden ...",
  type_author: "Typ de naam van de auteur ...",
  loading_suggestions: "Suggesties laden ...",
  avatars: "Avatars",
  authors: "Auteurs",
  errors: "fouten",
  validate_title: "U moet een titel opgeven",
  validate_category: "U moet een categorie selecteren",
  validate_community: "Je moet een community selecteren",
  validate_icon: "U moet een pictogram selecteren",
  library_page_title: "Library",
  library_page_subtitle: "List of shared rubrics",
  type_of_sharing: "Type of sharing",
  shared_on: "Shared on",
  shared_with: "Shared with",
  share_with: "Share with",
  rubrics_to_share: "Rubrics to share",
  add_chain: "Add chain",
  chain: "Chain",
  home_form_button: "Volg de gids",
  search_placeholder: "Zoekopdracht ...",
  back: "Rug",
  select_rubric: "Selecteer een rubriek",
  need_help: "Hulp nodig",
  follow_guide: "Volg de gids om antwoorden op al uw vragen te vinden",
  searching_results: "Zoeken naar resultaten...",
  no_results_found: "Geen resultaten gevonden",
  suggest_answer: "Stel antwoord voor",
  suggestion_success: "Bedankt voor je suggestie, we nemen het in overweging",
  suggestion_exists:
    "U heeft al een antwoord op deze vraag voorgesteld (klik om het te zien)",
  update_suggestion: "Suggestie bijwerken",
  individuals: "Particulieren",
  companies: "Bedrijven",
  independent_liberal: "Zelfstandigen en vrije beroepen",
  experts_partners: "Experts & Partners",
  ASBL: "ASBL",
  slug_individuals: "particulieren",
  slug_companies: "bedrijven",
  slug_independent_liberal: "zelfstandigen-en-vrije-beroepen",
  slug_experts_partners: "experts-partners",
  slug_asbl: "asbl",
  chains: "kettingen",
  "Event brings together the best content.":
    "Event brengt de beste content samen.",
  "solution rubrics published by this channel":
    "oplossingssecties gepubliceerd door dit kanaal",
  show_full_description: "Lees verder",
  see_chain: "zie het kanaal",
  solutions_published_by: "Oplossingen gepubliceerd door",
  solutions: "oplossingen",
  source_title: "Brontitel",
  source_url: "Bron-URL",
  community: "Gemeenschap",
  select_community: "Selecteer een gemeenschap ...",
  category: "Categorie",
  select_category: "Kies een categorie ...",
  theme: "Thema",
  select_theme: "Standaard thema ...",
  page: "Bladzijde",
  select_page: "Kies een pagina ...",
  tag_modal_title: "Vertaling van de tag",
  tag_name: "Naam",
  validate_tag_name: "U moet de vertaling van de tags opgeven",
  filter_results: "Resultaten filteren",
  clear_filter: "Filter wissen",
  search_results: "Zoekresultaten",
  clear_search: "Duidelijke zoek",
  add_content: "Inhoud toevoegen",
  modal_save_order: "Bevestiging van wijzigingen",
  modal_save_order_text: "Weet u zeker dat u deze wijzigingen wilt opslaan?",
  fff_publications: "FFF Nieuws",
  selected_medias: "Geselecteerde Media",
  disponible_medias: "Beschikbare media",
  selected_articles: "Geselecteerde artikelen",
  disponible_articles: "Beschikbare artikelen",
  selected_formations: "Geselecteerde formaties",
  disponible_formations: "Beschikbare trainingen",
  read_article: "Artikel lezen",
  read_more: "LEES MEER",
  read_more_details: "Meer details",
  download: "Downloaden",
  privacy: "Privacy",
  privacy_rubrics: "Privacyrubrieken :",
  gepubliceerd_at: "Gepubliceerd op",
  zichtbaar_in_footer: "Zichtbaar in voettekst",
  add_rubric: "Voeg een rubriek toe",
  geactiveerd: "Geactiveerd",
  gedeactiveerd: "Uitgeschakeld",
  omschrijving: "Beschrijving",
  functional_cookies: "Functionele Cookies",
  optionele_cookies: "Optionele cookies",
  terms_of_use: "Gebruiksvoorwaarden",
  nothing_found: "Nothing found...",
  library: "Library",
  share: "Deel",
  ALL_COLLABORATORS: "Alle medewerkers",
  ALL_CLIENTS: "Alle klanten",
  ALL_CONTACTS: "Alle contacten",
  SPEC_COLLABORATOR: "Specifieke medewerkers",
  SPEC_CLIENT: "Specifieke klanten",
  SPEC_CONTACT: "Specifieke contacten",
  page_has_no_rubrics: "Deze pagina heeft geen gepubliceerde rubrieken",
  domain_has_no_themes: "Dit domein heeft geen gepubliceerde thema's",
  theme_has_no_pages: "Dit thema heeft geen gepubliceerde pagina's",

  linked_pages: "Pagina's:",
  themes_and_pages: "Thema's en pagina's",
  themes_and_pages_list: "LIJST MET THEMA'S EN PAGINA'S",
  themes_and_pages_list_subtitle:
    "THEMA'S EN PAGINA'S TOEVOEGEN OF CONFIGUREREN",
  add: "Add",
  change_image: "Verander afbeelding",
  dropzone_text:
    "Sleep bestanden hierheen en zet ze neer, of klik om de bestanden te selecteren die u wilt downloaden.",
  invalid_theme_title: "Ongeldige thematitel",
  invalid_private_groups: "Je moet groepen voor abonnees specificeren",
  select_icon: "Selecteer icoon",
  title_fr: "Titel Fr",
  title_en: "Titel En",
  title_nl: "Titel Nl",
  is_private: "Alleen voor abonnees",
  subscribers_groups: "Abonnee groepen",
  invalid_domain_title: "Ongeldige domeinnaam",
  invalid_theme_domain: "Select theme domain",
  change_domain: "Het domein wijzigen",
  add_domain: "Een domein toevoegen",
  change_theme: "Verander het thema",
  add_theme: "Voeg een thema toe",
  theme_isdefault: "Standaard thema",
  domain: "Domein",
  is_editable: "Is bewerkbaar",
  add_page: "Een pagina toevoegen",
  themes: "Thema's",
  domains: "Domeinen",
  domain_title: "LIJST MET DOMEINEN",
  domain_sub_title: "DOMEINEN TOEVOEGEN OF CONFIGUREREN",
};
export default faqNl;
