import { configureStore, combineReducers } from "@reduxjs/toolkit";

import authReducer from "./auth";
import paramsSlice from "./params";
import faqSlice from "./faq";
import userSlice from "./user";
import categoriesSlice from "./categories";
import typesSlice from "./types";
import themesSlice from "./themes";
import domainsSlice from "./domains";

export const store = configureStore({
  reducer: combineReducers({
    auth: authReducer,
    params: paramsSlice,
    faq: faqSlice,
    users: userSlice,
    categories: categoriesSlice,
    types: typesSlice,
    themes: themesSlice,
    domains: domainsSlice,
  }),
});
