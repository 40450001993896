import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, Outlet, Link, useLocation } from "react-router-dom";
import { SubMenu } from "tamtam-components";
import CryptoJS from "crypto-js";

import {
  setAuthUser,
  setAuthToken,
  setExpiresIn,
  setTokenCreatedAt,
  setAuthLoggedAs,
  setAuthLoggedAsPost,
  fetchAuthUser,
  setLanguage,
  setNavCommunity,
  fetchNavCommunity,
  fetchDomains,
  setAuthLoggedIsAdmin,
  setHiddenFaqs,
  //fetchFiduciary,
  setHeader,
  setSubMenu,
  setBackNav,
  setCurrentDomain,
  setCurrentApp,
  fetchCategories,
} from "../../store";
import {
  getAuthInfosFromCookie,
  getLocalStorageAuth,
  setLocalStorageAuth,
  authHasExpired,
  getCookie,
  getUserCurrentNavCommunity,
} from "../../utils";
import { getClientCredential } from "../../api";
import { APP_ENV, URL_HASH_KEY } from "../../config";
import _ from "../../i18n";
import AddRubric from "../Settings/AddRubric";
import AddQuestion from "../Settings/AddQuestion";
import StatusFilter from "../Settings/StatusFilter";
import AdminStatusFilter from "../Settings/AdminStatusFilter";

import Menu from "./Menu";

const decryptWithAES = (ciphertext) => {
  var encryptMethod = "AES-256-CBC";
  var aesNumber = encryptMethod.match(/\d+/)[0];
  var encryptMethodLength = parseInt(aesNumber);

  var json = JSON.parse(
    CryptoJS.enc.Utf8.stringify(CryptoJS.enc.Base64.parse(ciphertext))
  );

  var salt = CryptoJS.enc.Hex.parse(json.salt);
  var iv = CryptoJS.enc.Hex.parse(json.iv);

  var encrypted = json.ciphertext; // no need to base64 decode.

  var iterations = parseInt(json.iterations);
  if (iterations <= 0) {
    iterations = 999;
  }
  var encryptMethodLength = encryptMethodLength / 4; // example: AES number is 256 / 4 = 64
  var hashKey = CryptoJS.PBKDF2(URL_HASH_KEY, salt, {
    hasher: CryptoJS.algo.SHA512,
    keySize: encryptMethodLength / 8,
    iterations: iterations,
  });

  var decrypted = CryptoJS.AES.decrypt(encrypted, hashKey, {
    mode: CryptoJS.mode.CBC,
    iv: iv,
  });

  return decrypted.toString(CryptoJS.enc.Utf8);
};

const Layout = ({ children }) => {
  const location = useLocation();
  const auth = useSelector((state) => state.auth);
  const language = useSelector((state) => state.params.language);
  const showHeader = useSelector((state) => state.params.header);
  const currentDomain = useSelector((state) => state.domains.currentDomain);
  const showSubMenu = useSelector((state) => state.params.subMenu);
  const dispatch = useDispatch();
  let { lang, domainID } = useParams();
  const domainsList = useSelector((state) => state.domains.items);
  const [loadedWidget, setLoadedWidget] = useState(true);
  const categories = useSelector((state) => state.categories.items);

  useEffect(() => {
    if (auth.navCommunity) {
      if (categories.length === 0) {
        dispatch(fetchCategories({ language }));
      }
      if (!currentDomain && domainsList.length === 0) {
        dispatch(fetchDomains({ communityId: auth.navCommunity.id }));
      }
      if (
        auth.navCommunity.faqPreferences &&
        auth.navCommunity.faqPreferences.hiddenFaqs
      ) {
        dispatch(setHiddenFaqs(auth.navCommunity.faqPreferences.hiddenFaqs));
      }
    }
  }, [auth.navCommunity]);

  const handleAuthUser = (user) => {
    dispatch(setAuthToken(user.token));
    dispatch(setExpiresIn(user.expiresIn));
    dispatch(setTokenCreatedAt(user.createdAt));
    dispatch(fetchAuthUser(user));
    // dispatch(fetchFiduciary());
  };

  useEffect(() => {
    var query = window.location.search.substring(1);
    var vars = query.split("&");

    let queryToken = "";
    let userId = "";
    let organizationId = "";
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      if (pair[0] === "params") {
        const hashParams = decryptWithAES(decodeURIComponent(pair[1]));

        const tabQueryParams = hashParams.split(",");
        tabQueryParams.forEach((item) => {
          const tmp = item.split("=");
          if (tmp.length > 0) {
            if (tmp[0] === "token") {
              queryToken = tmp[1];
            } else if (tmp[0] === "userId") {
              userId = tmp[1];
            } else if (tmp[0] === "without_header") {
              dispatch(setHeader(false));
            } else if (tmp[0] === "without_submenu") {
              dispatch(setSubMenu(false));
            } else if (tmp[0] === "without_backnav") {
              dispatch(setBackNav(false));
            } else if (tmp[0] === "current_app") {
              dispatch(setCurrentApp(tmp[1]));
            } else if (tmp[0] === "organization_id") {
              organizationId = parseInt(tmp[1]);
              dispatch(setNavCommunity({ id: organizationId, name: "" }));
            }
          }
        });
      }
    }

    let authInfos = getAuthInfosFromCookie();
    if (!authInfos || authHasExpired(authInfos)) {
      setLocalStorageAuth(null);
      // logout();
    }

    if (["fr", "en", "nl"].includes(lang)) {
      dispatch(setLanguage(lang));
      // cookieCutter.set(`ttp_lng_${APP_ENV}`, lng);
    } else {
      const lng = localStorage.getItem("lng") || "fr";
      dispatch(setLanguage(lng));
    }

    const auth = getLocalStorageAuth();

    if (queryToken && userId) {
      handleAuthUser({
        token: queryToken,
        createdAt: Date.now(),
        expiresIn: 10000,
        id: userId,
      });
    } else if (auth && auth.user && authInfos.token === auth.token) {
      dispatch(setAuthToken(auth.token));
      dispatch(setExpiresIn(auth.expiresIn));
      dispatch(setTokenCreatedAt(auth.createdAt));
      dispatch(setAuthUser(auth.user));
      dispatch(setAuthLoggedAs(auth.loggedAs));
      dispatch(setAuthLoggedAsPost(auth.loggedAsPost));
      // dispatch(fetchFiduciary());

      if (
        auth.user.role &&
        ["LEGAL_REPRESENTATIVE", "MANAGER"].includes(auth.user.role.type) &&
        auth.user.role.typeStatus !== "FOLDER"
      ) {
        dispatch(setAuthLoggedIsAdmin(true));
      }
      if (auth.navCommunity) {
        dispatch(setNavCommunity(auth.navCommunity));
      }

      const lng = localStorage.getItem("lng");
      if (!lng || lng.length === 0) {
        dispatch(setLanguage(auth.user.language));
      }

      if (!auth.navCommunity) {
        dispatch(fetchNavCommunity());
      }
    } else if (authInfos) {
      handleAuthUser(authInfos);
    } else {
      const tokenData = getClientCredential();
      if (tokenData?.responseJSON?.token) {
        const savedToken = tokenData?.responseJSON.token;
        dispatch(setAuthToken(savedToken.access_token));
        dispatch(setExpiresIn(savedToken.expiresIn));
        dispatch(setTokenCreatedAt(savedToken.createdAt));
        // dispatch(setAuthUser(auth.user));
      }
    }
  }, []);

  useEffect(() => {
    if (auth.user && !auth.navCommunity) {
      const cookieCommunity = getCookie("ttp_community_" + APP_ENV);
      if (cookieCommunity) {
        let currentNavCommunity = getUserCurrentNavCommunity(
          auth.user,
          cookieCommunity
        );
        if (currentNavCommunity) {
          dispatch(setNavCommunity(currentNavCommunity));
        } else {
          dispatch(fetchNavCommunity());
        }
      } else {
        dispatch(fetchNavCommunity());
      }
    }
  }, [auth.user]);

  const urlAttr = `url${language.charAt(0).toUpperCase() + language.slice(1)}`;
  useEffect(() => {
    if (domainsList) {
      let domain = domainsList.filter((item) => item[urlAttr] === domainID)[0];

      if (!domain) {
        domain = domainsList[0];
      }
      dispatch(setCurrentDomain(domain));
    }
  }, [domainID, domainsList]);

  const subMenu = [
    {
      title: _("home"),
      url: `/${language}`,
      iconUrl:
        "https://tamtam.s3.eu-west-1.amazonaws.com/cdn/img/icon/header/home.svg",
      community: false,
    },
    {
      title: _("chains"),
      url: `/${language}/chains`,
      iconUrl:
        "https://tamtam.s3.eu-west-1.amazonaws.com/cdn/img/icon/header/articles.svg",
      className: "hide-for-small-only",
    },
    {
      title: _("authors"),
      url: `/${language}/authors`,
      iconUrl:
        "https://tamtam.s3.eu-west-1.amazonaws.com/cdn/img/icon/header/authors.svg",
      className: "hide-for-small-only",
    },
    {
      title: _("library"),
      url: `/${language}/library`,
      iconUrl:
        "https://tamtam.s3.eu-west-1.amazonaws.com/cdn/img/icon/header/categories.svg",
      className: "hide-for-small-only",
    },
  ];

  return (
    <div>
      {showHeader && <Menu />}
      {auth.user && showSubMenu && (
        <SubMenu
          RouterLink={Link}
          lng={language}
          hideVertical={!showHeader}
          menu={subMenu}
        />
      )}
      <Outlet />
      {!location.pathname.includes("/privacy") &&
        auth.navCommunity?.id !== 9 &&
        auth.loggedIsAdmin && <AdminStatusFilter />}
      {!location.pathname.includes("/privacy") &&
        auth.navCommunity?.id === 9 &&
        ["AUTHOR_MANDATED", "CHIEF_EDITOR"].includes(auth.loggedAs) && (
          <StatusFilter />
        )}
      {!location.pathname.includes("/privacy") &&
        [
          "AUTHOR",
          "AUTHOR_MANDATED",
          "REDACTOR",
          "REDACTOR_MANDATED",
          "CHIEF_EDITOR",
        ].includes(auth.loggedAs) && (
          <>
            <AddRubric />
            <AddQuestion />
          </>
        )}
    </div>
  );
};

export default Layout;
