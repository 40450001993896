import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { useQuery } from "react-query";

import styles from "../Pages.module.scss";
import RubricCard from "../../common/RubricCard";
import { getPage } from "../../../api";
import PageLoader from "../../common/PageLoader";

const RubricList = ({ lng, domainID, themeID, pageID, token }) => {
  const showAllStatus = useSelector((state) => state.faq.showAllStatus);
  const showFiduciaryStatus = useSelector(
    (state) => state.faq.showFiduciaryStatus
  );
  const hiddenFaqs = useSelector((state) => state.faq.hiddenFaqs);

  let { isFetching, data } = useQuery(
    ["getPageById", token, pageID],
    async () => {
      if (token && pageID) {
        const response = await getPage({
          token,
          pageId: [parseInt(pageID)],
          questions: true,
        });
        if (response.data.data && response.data.data[0]) {
          return response.data.data[0];
        } else {
          return null;
        }
      }
    }
  );

  return (
    <>
      {" "}
      {isFetching ? (
        <PageLoader />
      ) : (
        <div className={`${styles.cardsList} grid-x`}>
          {data &&
            data.rubrics &&
            data.rubrics.map((rubric) => {
              if (!showAllStatus && rubric.status !== "PUBLISHED") {
                return null;
              }
              if (!showFiduciaryStatus && hiddenFaqs.R.includes(rubric.id)) {
                return null;
              }
              return (
                <div
                  className={classNames(
                    styles.rubricCardCont,
                    "cell small-12 medium-6 large-4"
                  )}
                >
                  <RubricCard
                    chainImg={
                      rubric.chain.chain &&
                      rubric.chain.chain[0].mediaChain[0].avatarUrl
                    }
                    data={{ ...rubric, author: rubric.author.author }}
                    url={`/${lng}/${domainID}/themes/${themeID}/pages/${pageID}/rubrics/${rubric.id}`}
                    language={lng}
                  />
                </div>
              );
            })}
        </div>
      )}
    </>
  );
};

export default RubricList;
