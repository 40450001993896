import React, { useRef, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import { toast } from "react-toastify";
import { useQueryClient } from "react-query";

import Button from "../../common/Button";
import Loader from "../../common/Loader";
import Switch from "../../common/Switch/Switch";
import DisabledSwitch from "../../common/Switch/Switch/DisabledSwitch";
import Tabs from "../../common/Tabs";
import { saveTheme } from "../../../store";
import _ from "../../../i18n";
import { DOMAIN_LIST } from "../../../config";

import GroupsSelect from "./GroupsSelect";
import SidebarIcon from "./SidebarIcon";
import styles from "./FAQ.module.scss";

const languages = [
  { value: "fr", label: _("french") },
  { value: "nl", label: _("dutch") },
  { value: "en", label: _("english") },
];

const selectStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: state.isDisabled ? "#e6e6e6" : "#fff",
    boxShadow: "none",
    border: state.isFocused ? "1px solid #2495E1" : "1px solid #CED4DB",
    "&:hover": {
      borderColor: state.isFocused ? "#18A0FB" : "#DFE2E6",
    },
    padding: 0,
  }),
  placeholder: (provided, state) => ({
    ...provided,
    fontSize: "12px",
    lineHeight: "14px",
    color: "#6D7F92",
    fontWeight: 400,
  }),
  menuList: (provided, state) => ({
    ...provided,
    paddingTop: "0",
    paddingBottom: "0",
  }),
  menu: (provided, state) => ({
    ...provided,
    borderRadius: "5px",
    backgroundColor: "#FFFFFF",
    overflow: "hidden",
  }),
  option: (provided, state) => ({
    ...provided,
    textAlign: "left",
    fontSize: "12px",
    lineHeight: "14px",
    color: state.isSelected ? "#FFFFFF" : "#6D7F92",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
  }),
  singleValue: (provided, state) => ({
    ...provided,
    fontSize: "14px",
    lineHeight: "16px",
    color: "#29394D",
  }),
};

export default function ThemePopOver(props) {
  const dispatch = useDispatch();
  const [title, setTitle] = useState({ en: "", fr: "", nl: "" });
  const [themeCommunity, setThemeCommunity] = useState(props.community);
  const [icon, setIcon] = useState(null);
  const [isOpenIconList, setIsOpenIconList] = useState(false);
  const isSaving = useSelector((state) => state.themes.isSaving);
  const [app, setApp] = useState(["FAQ"]);
  const [domain, setDomain] = useState([]);
  const [isPrivate, setIsPrivate] = useState(false);
  const [privateGroups, setPrivateGroups] = useState([]);
  const auth = useSelector((state) => state.auth);
  const { user } = auth;
  const { communities } = user;
  const queryClient = useQueryClient();
  const communityOptions = [];
  communities.map((com) => {
    communityOptions.push({
      value: com.id,
      label: com.name,
    });
  });

  const validate = () => {
    let errors = [];

    if (
      title.fr.trim().length === 0 ||
      title.en.trim().length === 0 ||
      title.nl.trim().length === 0
    ) {
      errors.push(_("validate_theme_title"));
    }

    if (!themeCommunity) {
      errors.push(_("validate_community"));
    }

    if (app.length === 0) {
      errors.push(_("validate_theme_app"));
    }

    if (domain.length === 0) {
      errors.push(_("validate_theme_domain"));
    }

    if (isPrivate && privateGroups.length === 0) {
      errors.push(_("validate_private_groups"));
    }

    return errors;
  };

  const save = () => {
    let errors = validate();
    if (errors && errors.length > 0) {
      let ErrorsContainer = ({ errors }) => (
        <div>
          <span>{_("errors") + " :"}</span>
          <ul>
            {errors.map((e, i) => (
              <li key={i}>{e}</li>
            ))}
          </ul>
        </div>
      );
      toast.error(<ErrorsContainer errors={errors} />, { autoClose: true });
      return;
    }

    let tabPrivateGroups = privateGroups.map((item) => {
      return item.id;
    });

    let data = {
      titleFr: title.fr,
      titleNl: title.nl,
      titleEn: title.en,
      organization: themeCommunity.value,
      isDefault: 0,
      app: app.join(","),
      domain: domain.join(","),
      icon: icon ? icon.id : null,
      isPrivate,
      privateGroups: tabPrivateGroups,
    };

    const titleAttr = `title${
      props.language.charAt(0).toUpperCase() + props.language.slice(1)
    }`;

    dispatch(saveTheme(data)).then(({ payload }) => {
      if (payload.statusCode && payload.statusCode === 400) {
        toast.error(payload.title, { autoClose: true });
      } else {
        const data = payload.data.data;

        const themeTitle =
          data[titleAttr] ||
          data["titleFr"] ||
          data["titleEn"] ||
          data["titleNl"];
        props.onSave({ id: data.id, title: themeTitle });
        toast.success("Success");
        queryClient.invalidateQueries("getWebtoolIcons");
        props.closePopOver();
        setTitle({ en: "", fr: "", nl: "" });
      }
    });
  };

  const handleTitleChange = (e, lng) => {
    let tmp = { ...title };
    tmp[lng] = e.target.value;
    setTitle(tmp);
  };

  const handleAppChange = (name, value) => {
    let tmpValue = value ? [...app, name] : app.filter((item) => item !== name);
    setApp(tmpValue);
  };

  const handleDomainChange = (name, value) => {
    let tmpValue = value
      ? [...domain, name]
      : domain.filter((item) => item !== name);
    setDomain(tmpValue);
  };

  return (
    <>
      <div className={styles.popOver} onClick={(e) => e.stopPropagation()}>
        <Tabs theme="wtheme">
          {languages.map((lng) => (
            <div
              key={`langtab${lng.value}`}
              label={lng.label}
              icon={`https://tamtam.s3-eu-west-1.amazonaws.com/cdn/img/flags/${lng.value}.png`}
            >
              <div className={styles.langTab}>
                <label className={styles.configLabel}>
                  {_("theme_title")} {lng.value}
                </label>
                <input
                  className={styles.formInput}
                  value={title[lng.value]}
                  onChange={(e) => {
                    handleTitleChange(e, lng.value);
                  }}
                />
              </div>
            </div>
          ))}
        </Tabs>

        <div className={styles.selectIconArea}>
          <div
            id="select-icon"
            className={`${styles.selectIcon} ${icon ? "icon-selected" : ""}`}
            onClick={() => setIsOpenIconList(true)}
          >
            {icon ? (
              <div className="icon-area">
                <i className={`icon webtool-${icon.label}`} />
                <p>{icon.label}</p>
              </div>
            ) : null}
            <span className={icon ? "hide" : ""}>{_("select_icon")}</span>
          </div>
        </div>

        <div className={styles.formRow}>
          <label className={styles.configLabel}>{_("community")}</label>
          <Select
            styles={selectStyles}
            options={communityOptions}
            isSearchable={false}
            placeholder={_("select_community")}
            value={themeCommunity}
            onChange={(e) => setThemeCommunity(e)}
            isDisabled
          />
        </div>

        <div className={styles.formRow}>
          <div className={styles.switchRow}>
            <label className={styles.configLabel}>{_("is_private")}</label>
            <Switch isChecked={isPrivate} onChange={(e) => setIsPrivate(e)} />
          </div>
        </div>
        {isPrivate && (
          <div className={styles.formRow}>
            <label className={styles.configLabel}>
              {_("subscribers_groups")}
            </label>
            <GroupsSelect
              isMulti={true}
              selectedGroups={privateGroups}
              onChange={(groups) => setPrivateGroups(groups)}
              auth={auth}
              community={props.community}
              selectStyles={selectStyles}
            />
          </div>
        )}

        <div className={styles.formRow}>
          <label className={styles.configLabel}>{_("application")}</label>
          <ul className={styles.switches}>
            <li>
              <span>{_("help_center")}</span>
              <DisabledSwitch isChecked={true} />
            </li>
            <li>
              <span>Blog</span>
              <Switch
                isChecked={app.includes("BLOG")}
                onChange={(e) => handleAppChange("BLOG", e)}
              />
            </li>
          </ul>
        </div>

        <div className={styles.formRow}>
          <label className={styles.configLabel}>{_("domain")}</label>
          <ul className={styles.switches}>
            {DOMAIN_LIST.map((item) => {
              return (
                <li key={`d${item.value}`}>
                  <span>{item.label}</span>
                  <Switch
                    isChecked={domain.includes(item.value)}
                    onChange={(e) => handleDomainChange(item.value, e)}
                  />
                </li>
              );
            })}
          </ul>
        </div>

        <Button block onClick={() => save()}>
          {isSaving ? (
            <Loader
              style={{
                height: "10px",
              }}
              color={"#fff"}
            />
          ) : (
            _("add_theme")
          )}
        </Button>
      </div>
      <SidebarIcon
        isOpened={isOpenIconList}
        hideSidebar={(e) => {
          e.stopPropagation();
          setIsOpenIconList(false);
        }}
        setIcon={(item) => setIcon(item)}
        sidebarClass="sidebar"
      />
    </>
  );
}
