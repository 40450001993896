import React from "react";

const IconFormation = () => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M27.3353 27.8724L18.002 33.0067L8.66862 27.8724V22.4477L6.00195 20.9988V29.3975L18.002 35.9988L30.002 29.3975V20.9988L27.3353 22.4477V27.8724Z"
      fill="currentColor"
    />
    <path
      d="M18 0L0 10.3888V12.8694L18 24L33.4286 14.4596V22.3632H36V10.3888L18 0ZM30.8571 12.7754L28.2857 14.3655L18 20.7263L7.71429 14.3655L5.14286 12.7754L3.36062 11.6733L18 3.22411L32.6394 11.6733L30.8571 12.7754Z"
      fill="currentColor"
    />
  </svg>
);

export default IconFormation;
