import axios from "axios";

import { TTP_API_URL } from "../config";

import {
  getRequestCancellationToken,
  getRequestConfig,
  generateCancellationTokenSource,
  throwCatchedError,
} from "../utils";

let getCollaboratorsCTS;
let getClientsCTS;
let getContactsCTS;

export const saveFaqSettings = ({ token, communityId, data }) => {
  const requestUrl = `${TTP_API_URL}/organization/organization-settings/save-faq-preferences?access_token=${token}&organization_id=${communityId}`;

  var formData = new FormData();

  formData.append("access_token", token);
  formData.append("organization_id", communityId);
  formData.append("hiddenFaqs", JSON.stringify(data.hiddenFaqs));

  return axios.post(requestUrl, formData);
};

export const getClients = ({ token, search }) => {
  let cancellationTokenSource = generateCancellationTokenSource();

  let requestCancellationToken = getRequestCancellationToken(
    getClientsCTS,
    cancellationTokenSource
  );
  getClientsCTS = cancellationTokenSource;

  let requestUrl = `${TTP_API_URL}/organization/folder`;

  let params = {
    access_token: token,
    fields: "id,legalRepresentative",
    start: 0,
    limit: 20,
  };

  if (search) {
    params.filter = JSON.stringify([
      { property: "name", value: search, operator: "like" },
    ]);
  }

  let requestConfig = getRequestConfig(params, requestCancellationToken);
  return axios.get(requestUrl, requestConfig).catch(function (thrown) {
    throwCatchedError(thrown);
  });
};

export const getCollaborators = ({ token, search, organizationId }) => {
  let cancellationTokenSource = generateCancellationTokenSource();
  // getGroupsCTS
  let requestCancellationToken = getRequestCancellationToken(
    getCollaboratorsCTS,
    cancellationTokenSource
  );
  getCollaboratorsCTS = cancellationTokenSource;

  let requestUrl = `${TTP_API_URL}/organization/user`;

  const filter = [
    {
      property: "organization.id",
      value: organizationId,
      operator: "eq",
    },
  ];

  if (search) {
    filter.push({ property: "name", value: search, operator: "like" });
    filter.push({ property: "email.main", value: 1, operator: "eq" });
  }

  let params = {
    access_token: token,
    fields: "*",
    start: 0,
    filter: JSON.stringify(filter),
    limit: 20,
    workspace: "ua",
  };

  let requestConfig = getRequestConfig(params, requestCancellationToken);
  return axios.get(requestUrl, requestConfig).catch(function (thrown) {
    throwCatchedError(thrown);
  });
};

export const getContacts = ({ token, search, organizationId }) => {
  let cancellationTokenSource = generateCancellationTokenSource();

  let requestCancellationToken = getRequestCancellationToken(
    getContactsCTS,
    cancellationTokenSource
  );
  getContactsCTS = cancellationTokenSource;

  let requestUrl = `${TTP_API_URL}/mailing/contact`;
  const filter = [
    { property: "client.id", value: organizationId, operator: "eq" },
    { property: "firstName", value: "", operator: "neq" },
  ];
  if (search) {
    filter.push(
      ...[
        { property: "name", value: search, operator: "like" },
        { property: "email.main", value: 1, operator: "eq" },
      ]
    );
  }

  let params = {
    filter: JSON.stringify(filter),
    access_token: token,
    fields: "*",
    start: 0,
    limit: 20,
  };

  let requestConfig = getRequestConfig(params, requestCancellationToken);
  return axios.get(requestUrl, requestConfig).catch(function (thrown) {
    throwCatchedError(thrown);
  });
};
