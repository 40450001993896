import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import classnames from "classnames";

import {
  setCurrentQuestion,
  setCurrentRubric,
  setSortedRubric,
  closeRubric,
  addOpenedRubric,
} from "../../store";
import {
  IconPlus,
  IconPencil2,
  IconChevronDown,
  IconHandler,
} from "../common/Icons";
import _ from "../../i18n";
import styles from "./FaqTree.module.scss";

const DragHandle = sortableHandle(() => (
  <span className={styles.dragHandler}>
    <IconHandler />
  </span>
));

const QuestionContainer = sortableContainer(
  ({ children, openedRubrics, item }) => {
    return (
      <ul
        className={`${styles.container} ${
          openedRubrics.includes(item.id) ? styles.active : ""
        }`}
      >
        {children}
      </ul>
    );
  }
);

export default function SortableFaqTreeItem(props) {
  const { item, child } = props;
  const currentQuestion = useSelector((state) => state.faq.current);
  const openedRubrics = useSelector((state) => state.faq.openedRubrics);
  const language = useSelector((state) => state.params.language);
  const dispatch = useDispatch();

  const titleAttr = `title${
    language.charAt(0).toUpperCase() + language.slice(1)
  }`;

  const onQuestionSortEnd = ({ oldIndex, newIndex }, rubric) => {
    const sorted = arrayMoveImmutable(rubric.children, oldIndex, newIndex);
    const tmp = { ...rubric };
    tmp.children = sorted;
    dispatch(setSortedRubric(tmp));
    props.showSortSave();
  };

  const SortableQuestion = sortableElement(({ item, child, changeView }) => (
    <li
      className={`${styles.questionContainer} 
          ${
            currentQuestion && currentQuestion.id === child.detail.id
              ? styles.active
              : ""
          }
        `}
      onClick={(e) => handleShowQuestion(e, child.detail, item)}
    >
      <DragHandle />
      <span className={styles.question}>
        <h6>{child.detail[titleAttr]}</h6>
      </span>
    </li>
  ));

  const SortableSubRubric = sortableElement(({ item, child, changeView }) => (
    <li
      id={child.detail.id ? `subItem-${child.detail.id}` : "subItem-0"}
      className={classnames(
        styles.item,
        styles.subRubric,
        openedRubrics.includes(child.detail.id) ? styles.activeList : ""
      )}
    >
      <div className={styles.main}>
        <div className={styles.title}>
          <DragHandle />
          <h4>{child.detail[titleAttr]}</h4>
        </div>
        <div className={styles.actions}>
          <span
            className={styles.edit}
            onClick={(e) => handleRubricEdit(e, child.detail)}
          >
            <IconPencil2 />
          </span>
          {child.detail.children?.length > 0 ? (
            <span
              className={classnames(styles.arrow)}
              onClick={(e) => {
                e.stopPropagation();
                const parentTag = document.getElementById(
                  `subItem-${child.detail.id}`
                ).classList;
                if (parentTag.contains(styles.activeList)) {
                  parentTag.remove(styles.activeList);
                  dispatch(closeRubric(child.detail.id));
                } else {
                  parentTag.add(styles.activeList);
                }
              }}
            >
              <IconChevronDown />
            </span>
          ) : (
            <span
              className={styles.arrow}
              onClick={() => showRubricAddQuestion(child.detail)}
            >
              <IconChevronDown />
            </span>
          )}
        </div>
      </div>

      <div className={styles.item_content}>
        <ul
          className={`${classnames(styles.subquestion, styles.container)} ${
            openedRubrics.includes(child.detail.id) ? styles.active : ""
          }`}
        >
          {child.detail.questions?.map((subChild, subChildIdx) => {
            return (
              <li
                className={`${styles.questionContainer} 
                ${
                  currentQuestion && currentQuestion.id === subChild.id
                    ? styles.active
                    : ""
                }
              `}
                key={`ques${subChildIdx}`}
                onClick={(e) => handleShowQuestion(e, subChild, child.detail)}
              >
                <DragHandle />
                <h6>{subChild[titleAttr]}</h6>
              </li>
            );
          })}
        </ul>
      </div>
    </li>
  ));

  const handleShowQuestion = (e, question, rubric) => {
    e.stopPropagation();
    dispatch(setCurrentQuestion(question));
    dispatch(setCurrentRubric(rubric));
    props.changeView("ANSWER");
  };

  const handleRubricEdit = (e, rubric) => {
    e.stopPropagation();
    dispatch(setCurrentRubric(rubric));
    dispatch(setCurrentQuestion(null));
    props.changeView("RUBRIC_FORM");
  };

  const handleQuestionEdit = (e, question, rubric) => {
    e.stopPropagation();
    dispatch(setCurrentQuestion(question));
    props.changeView("ANSWER");
    dispatch(setCurrentRubric(rubric));
    setTimeout(() => {
      props.changeView("FAQ_FORM");
    }, 1);
  };

  const showRubricAddQuestion = (rubric) => {
    dispatch(setCurrentRubric(rubric));
    props.changeView("FAQ_ADD");
  };

  const hasQuestion = (item) => {
    let result = false;
    if (item.children?.length > 0) {
      item.children.forEach((child) => {
        if (child.type === "QUESTION") {
          result = true;
        }
      });
    }
    return result;
  };

  return (
    <li
      id={item.id ? `item-${item.id}` : "item-0"}
      className={classnames(
        styles.item,
        styles.parentRubric,
        openedRubrics.includes(item.id) ? styles.activeList : ""
      )}
    >
      <div className={styles.main}>
        <div className={styles.title}>
          <DragHandle />
          <h4>{item[titleAttr]}</h4>
        </div>
        <div className={styles.actions}>
          <span
            className={styles.edit}
            onClick={(e) => handleRubricEdit(e, item)}
          >
            <IconPencil2 />
          </span>
          {item.children?.length > 0 ? (
            <span
              className={classnames(styles.arrow, styles.parentArrow)}
              onClick={(e) => {
                e.stopPropagation();
                if (!hasQuestion(item)) {
                  showRubricAddQuestion(item);
                }
                const currentTag =
                  e.currentTarget.tagName.toUpperCase() === "SPAN"
                    ? e.currentTarget
                    : e.currentTarget.parentElement;

                const currentClass = currentTag.classList.contains(
                  styles.parentArrow
                )
                  ? styles.parentRubric
                  : styles.subRubric;

                const parentTag = document.getElementById(
                  `item-${item.id}`
                ).classList;

                if (parentTag.contains(styles.activeList)) {
                  parentTag.remove(styles.activeList);
                  dispatch(closeRubric(item.id));
                } else {
                  const itemsTag =
                    document.getElementsByClassName(currentClass);
                  for (let i = 0; i < itemsTag.length; i++) {
                    const id = itemsTag[i].getAttribute("id").split("-");
                    if (id.length === 2) {
                      dispatch(closeRubric(parseInt(id[1])));
                    }
                    itemsTag[i].classList.remove(styles.activeList);
                  }
                  parentTag.add(styles.activeList);
                  dispatch(addOpenedRubric(item.id));
                }
              }}
            >
              <IconChevronDown />
            </span>
          ) : (
            <span
              className={styles.arrow}
              onClick={(e) => {
                e.stopPropagation();
                showRubricAddQuestion(item);

                const currentTag =
                  e.currentTarget.tagName.toUpperCase() === "SPAN"
                    ? e.currentTarget
                    : e.currentTarget.parentElement;

                const currentClass = currentTag.classList.contains(
                  styles.parentArrow
                )
                  ? styles.parentRubric
                  : styles.subRubric;

                const parentTag = document.getElementById(
                  `item-${item.id}`
                ).classList;

                if (parentTag.contains(styles.activeList)) {
                  parentTag.remove(styles.activeList);
                  dispatch(closeRubric(item.id));
                } else {
                  const itemsTag =
                    document.getElementsByClassName(currentClass);
                  for (let i = 0; i < itemsTag.length; i++) {
                    const id = itemsTag[i].getAttribute("id").split("-");
                    if (id.length === 2) {
                      dispatch(closeRubric(parseInt(id[1])));
                    }
                    itemsTag[i].classList.remove(styles.activeList);
                  }
                  parentTag.add(styles.activeList);
                  dispatch(addOpenedRubric(item.id));
                }
              }}
            >
              <IconChevronDown />
            </span>
          )}
        </div>
      </div>
      <div
        className={classnames(styles.item_content, styles.item_content_parent)}
      >
        {item.children && (
          <QuestionContainer
            onSortEnd={(e) => onQuestionSortEnd(e, item)}
            useDragHandle
            item={item}
            openedRubrics={openedRubrics}
          >
            {item.children.map((child, index) =>
              child.type === "QUESTION" ? (
                <SortableQuestion
                  item={item}
                  child={child}
                  index={index}
                  key={`sortQ${index}`}
                />
              ) : (
                <SortableSubRubric
                  child={child}
                  item={item}
                  index={index}
                  key={`sortSR${index}`}
                />
              )
            )}
          </QuestionContainer>
        )}
        <div
          className={styles.addFaq}
          onClick={() => {
            dispatch(setCurrentRubric(item));
            dispatch(setCurrentQuestion(null));
            props.changeView("FAQ_FORM");
          }}
        >
          <IconPlus size="12" />
          <span>{_("add_question")}</span>
        </div>
      </div>
    </li>
  );
}
