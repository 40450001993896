import axios from "axios";

import { TTP_API_URL } from "../config";

export const getDomainsList = ({ token, community, customFilter }) => {
  const requestUrl = `${TTP_API_URL}/blog/domain`;

  let filters = [];

  if (community) {
    if (community === 9) {
      filters.push({
        property: "organization",
        value: community,
        operator: "eq",
      });
    } else {
      filters.push({
        property: "isEditable",
        value: 1,
        operator: "eq",
      });
    }
  }

  if (customFilter !== null && Array.isArray(customFilter)) {
    filters.push(...customFilter);
  }

  let params = {
    access_token: token,
    filter: JSON.stringify(filters),
    fields: "*,icon",
  };

  return axios.get(requestUrl, { params });
};

export const getThemesList = ({ community, token }) => {
  const requestUrl = `${TTP_API_URL}/blog/theme`;

  let filters = [
    {
      property: "app",
      value: "FAQ",
      operator: "flike",
    },
  ];

  if (community) {
    filters.push({
      property: "organization",
      value: community,
      operator: "eq",
    });
  }

  let params = {
    access_token: token,
    filter: JSON.stringify(filters),
    fields: "*,icon,media,pages,domains",
    nolimit: 1,
  };

  return axios.get(requestUrl, { params });
};

export const saveDomain = async (token, data) => {
  const requestUrl = `${TTP_API_URL}/blog/domain`;

  var formData = new FormData();
  formData.append("access_token", token);
  formData.append("titleFr", data.titleFr);
  formData.append("titleNl", data.titleNl);
  formData.append("titleEn", data.titleEn);
  formData.append("organization", data.organization);
  if (data.icon) {
    formData.append("icon", data.icon);
  }

  formData.append("isPrivate", data.isPrivate ? 1 : 0);
  formData.append("isEditable", data.isEditable ? 1 : 0);
  if (data.privateGroups) {
    formData.append("privateGroups", data.privateGroups);
  }

  if (data.id) {
    formData.append("id", data.id);
  }

  const res = await axios.post(requestUrl, formData);
  if (data.icon) {
    var iconFormData = new FormData();
    iconFormData.append("access_token", token);
    iconFormData.append("id", data.icon);
    iconFormData.append("isUsed", 1);
    await axios.post(`${TTP_API_URL}/media/webtool-icon`, iconFormData);
  }

  return res;
};

export const removePage = (token, pageId) => {
  const requestUrl = `${TTP_API_URL}/blog/page/${pageId}`;

  var formData = new FormData();

  formData.append("access_token", token);
  formData.append("_method", "delete");

  return axios.post(requestUrl, formData);
};

export const removeTheme = (token, themeId) => {
  const requestUrl = `${TTP_API_URL}/blog/theme/${themeId}`;

  var formData = new FormData();

  formData.append("access_token", token);
  formData.append("_method", "delete");

  return axios.post(requestUrl, formData);
};

export const removeDomain = (token, domainId) => {
  const requestUrl = `${TTP_API_URL}/blog/domain/${domainId}`;

  var formData = new FormData();

  formData.append("access_token", token);
  formData.append("_method", "delete");

  return axios.post(requestUrl, formData);
};
