import { useQuery } from "react-query";
import { useSelector } from "react-redux";

import { getLibrary } from "../../api";
import _ from "../../i18n";
import QuestionList from "../Pages/components/QuestionList";
import styles from "./Library.module.scss";
import PageLoader from "../common/PageLoader";

const Library = () => {
  const auth = useSelector((state) => state.auth);
  const lng = useSelector((state) => state.params.language);
  const { token, user } = auth;

  let { isLoading, data } = useQuery(
    ["libraryFetch", user, token],
    async () => {
      if (user && token) {
        const response = await getLibrary({
          token,
          user,
        });
        return response.data.data;
      }
    }
  );

  return (
    <>
      <div className={styles.header}>
        <div>
          <div className={styles.left}>
            <i className="icon-ttp-browser"></i>
            <div>
              <span className={styles.title}>{_("library_page_title")}</span>
              <span className={styles.subTitle}>
                {_("library_page_subtitle")}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.body}>
        {isLoading ? (
          <PageLoader />
        ) : (
          <>
            {data ? (
              <QuestionList isLibrary={true} libraryQuestions={data} />
            ) : (
              <div
                style={{
                  textAlign: "center",
                  maxWidth: "600px",
                  margin: "0 auto 60px",
                  color: "#2C394B",
                  fontSize: "2rem",
                }}
              >
                <img alt="not found" src="/img/notFound.svg" />
                <div>{_("nothing_found")}</div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default Library;
