import React, { useEffect, useState } from "react";
import SunEditor from "suneditor-react";
import Select from "react-select";
import { toast } from "react-toastify";

import styles from "./Privacy.module.scss";
import _ from "../../i18n";
import Tabs from "../common/Tabs";
import {
  API_DATE_FORMAT,
  APP_ENV,
  SELECT_STYLES,
  TTP_API_URL,
} from "../../config";
import Button from "../common/Button";
import { saveTermOfUse, uploadTmpMedia } from "../../api";
import { convertDateToUTC } from "../../utils";
import classNames from "classnames";
import { useLocation } from "react-router-dom";

const languages = [
  { value: "fr", label: _("french") },
  { value: "en", label: _("english") },
  { value: "nl", label: _("dutch") },
];

const appOptions = [
  { value: "UNITED_ASSOCIATES", label: _("UNITED_ASSOCIATES") },
  { value: "OFFFCOURSE", label: _("OFFFCOURSE") },
  { value: "BLOG", label: _("BLOG") },
  { value: "HELP_CENTER", label: _("HELP_CENTER") },
  { value: "DASHBOARD", label: _("DASHBOARD") },
  { value: "POWERTEAM", label: _("POWERTEAM") },
  { value: "SENDING", label: _("SENDING") },
  { value: "WEBINAR", label: _("WEBINAR") },
  { value: "EVENT", label: _("EVENT") },
];

const TermsOfUse = ({ data, isUaAdmin, lng, token, navCommunity, user }) => {
  const location = useLocation();
  const [showForm, setShowForm] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [introductionFr, setIntroductionFr] = useState("");
  const [introductionEn, setIntroductionEn] = useState("");
  const [introductionNl, setIntroductionNl] = useState("");
  const [termsRubric, setTermsRubric] = useState(null);
  const [app, setApp] = useState(null);
  const [selectedVersion, setSelectedVersion] = useState(null);
  const [versionLanguage, setVersionLanguage] = useState(lng);

  const introductionAttr = `introduction${
    versionLanguage.charAt(0).toUpperCase() + versionLanguage.slice(1)
  }`;
  const titlesAttr = `titles${lng.charAt(0).toUpperCase() + lng.slice(1)}`;

  const getChapterTitles = (lastVer) => {
    let parser = new DOMParser();
    const docFr = parser.parseFromString(lastVer.introductionFr, "text/html");
    const docEn = parser.parseFromString(lastVer.introductionEn, "text/html");
    const docNl = parser.parseFromString(lastVer.introductionNl, "text/html");

    const titlesFr = [];
    const titlesEn = [];
    const titlesNl = [];

    [...docFr.getElementsByTagName("h2")].forEach((el, i) => {
      el.setAttribute("id", `ch_fr_${i}`);
      titlesFr.push({ id: `ch_fr_${i}`, title: el.innerText });
    });
    [...docEn.getElementsByTagName("h2")].forEach((el, i) => {
      el.setAttribute("id", `ch_en_${i}`);
      titlesEn.push({ id: `ch_en_${i}`, title: el.innerText });
    });
    [...docNl.getElementsByTagName("h2")].forEach((el, i) => {
      el.setAttribute("id", `ch_nl_${i}`);
      titlesNl.push({ id: `ch_nl_${i}`, title: el.innerText });
    });

    return {
      titlesFr,
      titlesEn,
      titlesNl,
      version: {
        ...lastVer,
        introductionFr: docFr.body.innerHTML,
        introductionEn: docEn.body.innerHTML,
        introductionNl: docNl.body.innerHTML,
      },
    };
  };

  useEffect(() => {
    if (selectedVersion) {
      setTermsRubric({
        ...termsRubric,
        titlesFr: getChapterTitles(selectedVersion).titlesFr,
        titlesEn: getChapterTitles(selectedVersion).titlesEn,
        titlesNl: getChapterTitles(selectedVersion).titlesNl,
      });
    }
  }, [selectedVersion]);

  useEffect(() => {
    if (data) {
      setTermsRubric({
        ...data,
        titlesFr: getChapterTitles(data.versions[0]).titlesFr,
        titlesEn: getChapterTitles(data.versions[0]).titlesEn,
        titlesNl: getChapterTitles(data.versions[0]).titlesNl,
        versions: data.versions.map((ver) => getChapterTitles(ver).version),
      });
      setSelectedVersion(getChapterTitles(data.versions[0]).version);
    }

    if (!data && isUaAdmin) {
      setShowForm(true);
    }
  }, [data, isUaAdmin]);

  const handlePaste = (event, cleanData, maxCharCount, core) => {
    let content = cleanData.replace(/\s+style="[^"]*"/gi, "");
    return content.replace(/(<\/?h)([0-1])/gi, "$12");
  };

  const resetForm = () => {
    setApp(null);
    setIntroductionFr("");
    setIntroductionEn("");
    setIntroductionNl("");
  };
  const validate = () => {
    let errors = [];

    if (
      (lng === "fr" && introductionFr.trim().length === 0) ||
      (lng === "en" && introductionEn.trim().length === 0) ||
      (lng === "nl" && introductionNl.trim().length === 0)
    ) {
      errors.push(_("validate_cookies"));
    }

    return errors;
  };

  const handleSave = async () => {
    let errors = validate();

    if (errors && errors.length > 0) {
      let ErrorsContainer = ({ errors }) => (
        <div>
          <span>{_("errors") + " :"}</span>
          <ul>
            {errors.map((e, i) => (
              <li key={i}>{e}</li>
            ))}
          </ul>
        </div>
      );
      toast.error(<ErrorsContainer errors={errors} />);
      return;
    }
    const reqData = {
      introductionFr,
      introductionEn,
      introductionNl,
      name: app.value,
      status: "PUBLISHED",
      publishedAt: convertDateToUTC(
        new Date(),
        API_DATE_FORMAT,
        API_DATE_FORMAT
      ),
    };

    if (selectedVersion) {
      reqData.id = selectedVersion.id;
    }

    setIsSaving(true);
    try {
      const res = await saveTermOfUse({
        token,
        data: reqData,
        communityId: navCommunity ? navCommunity.id : 9,
        rubricId: termsRubric ? termsRubric.id : null,
      });
      if (!termsRubric && res.resRub.data.data && res.resVer.data.data) {
        setTermsRubric({
          ...res.resRub.data.data,
          titlesFr: getChapterTitles(res.resVer.data.data).titlesFr,
          titlesEn: getChapterTitles(res.resVer.data.data).titlesEn,
          titlesNl: getChapterTitles(res.resVer.data.data).titlesNl,
          versions: [getChapterTitles(res.resVer.data.data).version],
        });
        setSelectedVersion(getChapterTitles(res.resVer.data.data).version);
      } else if (res.data.data && selectedVersion) {
        setTermsRubric({
          ...termsRubric,
          titlesFr: getChapterTitles(res.data.data).titlesFr,
          titlesEn: getChapterTitles(res.data.data).titlesEn,
          titlesNl: getChapterTitles(res.data.data).titlesNl,
          versions: termsRubric.versions.map((ver) => {
            if (ver.id === res.data.data.id) {
              return {
                ...getChapterTitles(res.data.data).version,
              };
            }
            return ver;
          }),
        });

        setSelectedVersion(getChapterTitles(res.data.data).version);
      } else if (res.data.data) {
        setTermsRubric({
          ...termsRubric,
          titlesFr: getChapterTitles(res.data.data).titlesFr,
          titlesEn: getChapterTitles(res.data.data).titlesEn,
          titlesNl: getChapterTitles(res.data.data).titlesNl,
          versions: [
            getChapterTitles(res.data.data).version,
            ...termsRubric.versions,
          ],
        });

        setSelectedVersion(getChapterTitles(res.data.data).version);
      }

      setIsSaving(false);
      resetForm();
      setShowForm(false);
    } catch (error) {
      console.error(error);
      toast.error(_("saving_error"));
      setIsSaving(false);
      if (termsRubric) {
        setShowForm(false);
      }
    }
  };

  const renderForm = () => {
    return (
      <div className={styles.cookiesForm}>
        {isSaving && <div className={styles.saving}></div>}
        <div className={styles.formBody}>
          <div className={styles.statusRow}>
            <div className={styles.statusSelect}>
              <label>{_("app")}</label>
              <Select
                styles={SELECT_STYLES}
                options={
                  termsRubric
                    ? appOptions
                        .filter((el) => {
                          const names = termsRubric.versions.map(
                            (ver) => ver.name
                          );
                          if (!names.includes(el.value)) return el;
                          return null;
                        })
                        .filter(Boolean)
                    : appOptions
                }
                isSearchable={false}
                value={app}
                onChange={(e) => setApp(e)}
              />
            </div>
          </div>
          <Tabs theme="wtheme">
            {languages.map((lng) => (
              <div
                key={`langtab${lng.value}`}
                label={lng.label}
                icon={`https://tamtam.s3-eu-west-1.amazonaws.com/cdn/widget/${APP_ENV}/img/flags/${lng.value.toUpperCase()}.jpg`}
              >
                <div className={styles.langTab}>
                  <label className={styles.configLabel}>
                    {_("terms_of_use")} {lng.value}
                  </label>
                  <div className={`ttp-helpeditor ${styles.editorContainer} `}>
                    <SunEditor
                      setOptions={{
                        attributesWhitelist: {
                          // all: "style",
                          input: "checked",
                        },
                        stickyToolbar: false,
                        height: "auto",
                        minHeight: "300px",
                        showPathLabel: false,
                        resizingBar: false,
                        imageUrlInput: false,
                        imageWidth: "75%",
                        formats: [
                          "p",
                          "blockquote",
                          "h2",
                          "h3",
                          "h4",
                          "h5",
                          "h6",
                        ],
                        buttonList: [
                          [
                            "undo",
                            "redo",
                            "formatBlock",
                            "bold",
                            "underline",
                            "italic",
                            "strike",
                            "outdent",
                            "indent",
                            "align",
                            "list",
                            "table",
                            "link",
                            "image",
                            "video",
                            // "fullScreen",
                            "showBlocks",
                          ],
                        ],
                      }}
                      setContents={
                        lng.value === "fr"
                          ? introductionFr
                          : lng.value === "en"
                          ? introductionEn
                          : introductionNl
                      }
                      onChange={(c) => {
                        lng.value === "fr"
                          ? setIntroductionFr(c)
                          : lng.value === "en"
                          ? setIntroductionEn(c)
                          : setIntroductionNl(c);
                      }}
                      onPaste={handlePaste}
                      onImageUploadBefore={handleImageUploadBefore}
                    />
                  </div>
                </div>
              </div>
            ))}
          </Tabs>
        </div>
        <div className={styles.formFooter}>
          <Button
            variant="default"
            onClick={() => {
              resetForm();
              if (termsRubric) {
                setShowForm(false);
                if (!selectedVersion)
                  setSelectedVersion(termsRubric.versions[0]);
              }
            }}
          >
            {_("cancel")}
          </Button>
          <Button variant="primary" onClick={() => handleSave()}>
            {_("save")}
          </Button>
        </div>
      </div>
    );
  };

  const handleImageUploadBefore = (files, info, uploadHandler) => {
    uploadTmpMedia({ token, data: files[0] }).then((resp) => {
      const url = resp.data.data.url;
      const startsWithHttp = url.lastIndexOf("http://", 0) === 0;
      const startsWithHttps = url.lastIndexOf("https://", 0) === 0;
      const isAbsolute = startsWithHttp || startsWithHttps;
      const imgUrl = isAbsolute ? url : `${TTP_API_URL}/${url}`;
      uploadHandler({
        result: [
          {
            url: imgUrl,
            name: files[0].name,
            size: files[0].size,
          },
        ],
      });
    });
  };

  return (
    <div className={styles.pageContent}>
      {!termsRubric && showForm ? (
        <div className={classNames(styles.createTermsRubric)}>
          {renderForm()}
        </div>
      ) : (
        termsRubric && (
          <div className="grid-x">
            <div className={classNames(" cell medium-12 large-4")}>
              <div className={styles.left}>
                <h1>{_("terms_of_use")}</h1>
                {termsRubric[titlesAttr] &&
                  termsRubric[titlesAttr].length > 0 &&
                  termsRubric[titlesAttr].map((chapter) => (
                    <a href={`#${chapter.id}`}>
                      <div className={styles.item}>
                        <div
                          className={classNames(
                            styles.rubric,
                            location.hash.includes(chapter.id)
                              ? styles.rubric_active
                              : ""
                          )}
                        >
                          <p className={styles.chapterTitle}>{chapter.title}</p>
                          {location.hash.includes(chapter.id) && (
                            <div className={styles.arrow}>
                              <svg
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g clip-path="url(#clip0_50815_37489)">
                                  <path
                                    d="M10.6482 6.50031L4.3525 0.206666C4.07624 -0.0688887 3.62867 -0.0688887 3.35172 0.206666C3.07547 0.482221 3.07547 0.929795 3.35172 1.20535L9.14809 6.99962L3.35242 12.7939C3.07617 13.0695 3.07616 13.517 3.35242 13.7933C3.62867 14.0688 4.07694 14.0688 4.35319 13.7933L10.6489 7.49963C10.921 7.22693 10.921 6.77232 10.6482 6.50031Z"
                                    fill="#18A0FB"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_50815_37489">
                                    <rect width="14" height="14" fill="white" />
                                  </clipPath>
                                </defs>
                              </svg>
                            </div>
                          )}
                        </div>
                      </div>
                    </a>
                  ))}
              </div>
            </div>

            <div
              className={classNames(styles.right, " cell medium-12 large-8")}
            >
              {user && user.isUaAdmin ? (
                <div className={styles.top}>
                  <ul className={styles.versions}>
                    {!showForm &&
                      appOptions
                        .filter((el) => {
                          const names = termsRubric.versions.map(
                            (ver) => ver.name
                          );
                          if (!names.includes(el.value)) return el;
                          return null;
                        })
                        .filter(Boolean).length > 0 && (
                        <li
                          onClick={() => {
                            resetForm();
                            setShowForm(true);
                            setSelectedVersion(null);
                          }}
                        >
                          <svg
                            width="12"
                            height="12"
                            viewBox="0 0 12 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_50815_37461)">
                              <path
                                d="M11.3488 5.16374H6.83719V0.652138C6.83719 0.446616 6.55811 0.000976562 5.99998 0.000976562C5.44184 0.000976562 5.16276 0.44664 5.16276 0.652138V5.16376H0.651161C0.445663 5.16374 0 5.44282 0 6.00093C0 6.55904 0.445663 6.83814 0.651161 6.83814H5.16279V11.3498C5.16279 11.5552 5.44184 12.0009 6 12.0009C6.55816 12.0009 6.83721 11.5552 6.83721 11.3498V6.83814H11.3488C11.5543 6.83814 12 6.55909 12 6.00093C12 5.44277 11.5543 5.16374 11.3488 5.16374Z"
                                fill="#6D7F92"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_50815_37461">
                                <rect width="12" height="12" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </li>
                      )}

                    {termsRubric &&
                      termsRubric.versions.map((version) => (
                        <li
                          className={
                            selectedVersion && version.id === selectedVersion.id
                              ? styles.active_label
                              : ""
                          }
                          onClick={() => {
                            setSelectedVersion(version);
                            setShowForm(false);
                          }}
                        >
                          {version.name}
                        </li>
                      ))}
                  </ul>
                  {!showForm && (
                    <div
                      className={styles.edit}
                      onClick={() => {
                        setShowForm(true);
                        setApp(
                          appOptions.filter(
                            (el) => el.value === selectedVersion.name
                          )[0]
                        );
                        setIntroductionFr(selectedVersion.introductionFr);
                        setIntroductionEn(selectedVersion.introductionEn);
                        setIntroductionNl(selectedVersion.introductionNl);
                      }}
                    >
                      <svg
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_50853_38838)">
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M11.082 1.84977C10.942 1.84977 10.8033 1.87736 10.6739 1.93096C10.5445 1.98456 10.4269 2.06313 10.3279 2.16217L2.5624 9.92763L1.99676 12.0016L4.07077 11.436L11.8362 3.67054C11.9353 3.5715 12.0138 3.45392 12.0674 3.32452C12.121 3.19512 12.1486 3.05642 12.1486 2.91635C12.1486 2.77629 12.121 2.63759 12.0674 2.50819C12.0138 2.37879 11.9353 2.26121 11.8362 2.16217C11.7372 2.06313 11.6196 1.98456 11.4902 1.93096C11.3608 1.87736 11.2221 1.84977 11.082 1.84977ZM10.2274 0.853101C10.4984 0.74087 10.7888 0.683105 11.082 0.683105C11.3753 0.683105 11.6657 0.74087 11.9367 0.853101C12.2076 0.965333 12.4538 1.12983 12.6612 1.33721C12.8686 1.54458 13.0331 1.79078 13.1453 2.06173C13.2575 2.33268 13.3153 2.62308 13.3153 2.91635C13.3153 3.20963 13.2575 3.50003 13.1453 3.77098C13.0331 4.04193 12.8686 4.28812 12.6612 4.4955L4.78619 12.3705C4.71441 12.4423 4.62514 12.4941 4.5272 12.5208L1.31886 13.3958C1.11691 13.4509 0.90092 13.3935 0.752899 13.2455C0.604878 13.0975 0.54752 12.8815 0.602599 12.6795L1.4776 9.4712C1.50431 9.37326 1.55612 9.28399 1.6279 9.21221L9.5029 1.33721C9.71028 1.12983 9.95647 0.965333 10.2274 0.853101Z"
                            fill="#6D7F92"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_50853_38838">
                            <rect width="14" height="14" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                  )}
                </div>
              ) : (
                <div className={styles.fakeTop}></div>
              )}
              {showForm ? (
                <>{renderForm()}</>
              ) : (
                <div className={styles.rubric_display}>
                  <div className={styles.languageTabs}>
                    {selectedVersion && (
                      <ul>
                        {selectedVersion.introductionFr.length > 0 && (
                          <li onClick={() => setVersionLanguage("fr")}>
                            <div
                              className={styles.flag}
                              style={{
                                backgroundImage: `url(${`https://tamtam.s3-eu-west-1.amazonaws.com/cdn/widget/${APP_ENV}/img/flags/FR.jpg`})`,
                              }}
                            ></div>
                            <div
                              style={{
                                backgroundColor:
                                  versionLanguage === "fr" && "#18A0FB",
                              }}
                              className={styles.border}
                            ></div>
                          </li>
                        )}
                        {selectedVersion.introductionEn.length > 0 && (
                          <li onClick={() => setVersionLanguage("en")}>
                            <div
                              className={styles.flag}
                              style={{
                                backgroundImage: `url(${`https://tamtam.s3-eu-west-1.amazonaws.com/cdn/widget/${APP_ENV}/img/flags/EN.jpg`})`,
                              }}
                            ></div>
                            <div
                              style={{
                                backgroundColor:
                                  versionLanguage === "en" && "#18A0FB",
                              }}
                              className={styles.border}
                            ></div>
                          </li>
                        )}
                        {selectedVersion.introductionNl.length > 0 && (
                          <li onClick={() => setVersionLanguage("nl")}>
                            <div
                              className={styles.flag}
                              style={{
                                backgroundImage: `url(${`https://tamtam.s3-eu-west-1.amazonaws.com/cdn/widget/${APP_ENV}/img/flags/NL.jpg`})`,
                              }}
                            ></div>
                            <div
                              style={{
                                backgroundColor:
                                  versionLanguage === "nl" && "#18A0FB",
                              }}
                              className={styles.border}
                            ></div>
                          </li>
                        )}
                      </ul>
                    )}
                  </div>
                  <div
                    className="sun-editor-editable"
                    dangerouslySetInnerHTML={{
                      __html: selectedVersion
                        ? selectedVersion[introductionAttr]
                        : "",
                    }}
                  ></div>
                </div>
              )}
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default TermsOfUse;
