import React from "react";
import { Link } from "react-router-dom";
import _ from "../../../i18n";
import styles from "./Breadcrumb.module.scss";

const Breadcrumb = ({
  items,
  setCurrentDomain,
  setCurrentTheme,
  setCurrentPage,
  setCurrentRubric,
}) => {
  const handleClick = (item) => {
    if (item.type === "domain") {
      localStorage.removeItem("currentDomain");
      localStorage.removeItem("currentTheme");
      localStorage.removeItem("currentPage");
      localStorage.removeItem("currentRubric");
      setCurrentDomain(null);
      setCurrentTheme(null);
      setCurrentPage(null);
      setCurrentRubric(null);
    }
    if (item.type === "theme") {
      localStorage.removeItem("currentTheme");
      localStorage.removeItem("currentPage");
      localStorage.removeItem("currentRubric");
      setCurrentTheme(null);
      setCurrentPage(null);
      setCurrentRubric(null);
    }
    if (item.type === "page") {
      localStorage.removeItem("currentPage");
      localStorage.removeItem("currentRubric");
      setCurrentPage(null);
      setCurrentRubric(null);
    }
    if (item.type === "rubric") {
      localStorage.removeItem("currentRubric");
      setCurrentRubric(null);
    }
  };

  return (
    <nav role="navigation">
      <ul className={styles.breadcrumb}>
        {items &&
          items
            .filter((item) => item !== null)
            .map((item, i) => {
              return item && item.url ? (
                <li key={i}>
                  <Link
                    to={item.url}
                    style={{
                      color:
                        i === items.filter((item) => item !== null).length - 1
                          ? "#18A0FB"
                          : "#6d7f92",
                    }}
                    // onClick={() => handleClick(item)}
                  >
                    {item.label}
                  </Link>
                  {i !== items.filter((item) => item !== null).length - 1 &&
                    "/"}
                </li>
              ) : (
                item && (
                  <li key={i}>
                    <span
                      style={{
                        color:
                          i === items.filter((item) => item !== null).length - 1
                            ? "#18A0FB"
                            : "#6d7f92",
                      }}
                      onClick={() => handleClick(item)}
                    >
                      {item.label}
                    </span>
                    {i !== items.filter((item) => item !== null).length - 1 &&
                      "/"}
                  </li>
                )
              );
            })}
      </ul>
    </nav>
  );
};

export default Breadcrumb;
