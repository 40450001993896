import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";

import _ from "../../../i18n";
import PagePopOver from "./PagePopOver";
import styles from "./FAQ.module.scss";

export default function Page(props) {
  const loadingThemes = useSelector((state) => state.themes.fetching);
  let themes = useSelector((state) => state.themes.items);
  const [pageOptions, setPageOptions] = useState([]);
  const [showPopOver, setShowPopOver] = useState(false);
  const loggedAs = useSelector((state) => state.auth.loggedAs);
  const { pages, theme, language } = props;

  const titleAttr = `title${
    language.charAt(0).toUpperCase() + language.slice(1)
  }`;

  useEffect(() => {
    if (theme) {
      themes.map((t, index) => {
        if (t.id === theme.id) {
          let tmp = [];
          t.pages?.map((page) => {
            const pageTitle =
              page[titleAttr] ||
              page["titleFr"] ||
              page["titleEn"] ||
              page["titleNl"];
            tmp.push({
              id: page.id,
              title: pageTitle,
            });
          });
          setPageOptions(tmp);
          if (tmp.length === 1) {
            props.setPages([tmp[0]]);
          }
        }
      });
    }
  }, [theme]);

  const togglePopOver = () => {
    setShowPopOver(!showPopOver);
  };

  return (
    <div
      className={`${styles.inputContainer} ${showPopOver ? styles.active : ""}`}
    >
      <Select
        styles={props.selectStyles}
        isLoading={loadingThemes}
        options={pageOptions}
        placeholder={_("select_page")}
        value={pages}
        className={styles.input}
        getOptionLabel={(option) => option.title}
        getOptionValue={(option) => option.id}
        onChange={(e) => props.setPages(e)}
        isMulti
      />
      {["GUEST", "CHIEF_EDITOR"].includes(loggedAs) &&
        props.community?.value === 9 && (
          <div className={styles.inputAdd} onClick={() => togglePopOver()}>
            <i className="icon-ttp-plus"></i>
            <PagePopOver
              language={language}
              closePopOver={() => togglePopOver()}
              community={props.community}
              theme={theme}
              selectedPages={pages}
              onSave={(e) => props.setPages(e)}
            />
          </div>
        )}
    </div>
  );
}
