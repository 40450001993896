import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useQuery } from "react-query";

import { getPage } from "../../../api";
import styles from "../Pages.module.scss";
import ThemeCard from "../../Home/components/ThemeCard";
import PageLoader from "../../common/PageLoader";

const PagesList = ({ themes, lng, domainID, themeID, token }) => {
  const showFiduciaryStatus = useSelector(
    (state) => state.faq.showFiduciaryStatus
  );
  const hiddenFaqs = useSelector((state) => state.faq.hiddenFaqs);
  const [currentTheme, setCurrentTheme] = useState(null);

  useEffect(() => {
    if (themes && themeID) {
      const result = themes.filter((item) => item.id === parseInt(themeID));
      if (result.length === 1) {
        setCurrentTheme(result[0]);
      }
    }
  }, [themes, themeID]);

  let { isLoading, data } = useQuery(
    ["getPage", token, themes, currentTheme],
    async () => {
      if (
        token &&
        currentTheme &&
        currentTheme.pages &&
        currentTheme.pages.length > 0
      ) {
        const response = await getPage({
          token,
          pageId: currentTheme.pages.map((item) => item.id),
        });
        if (response.data.data) {
          return response.data.data;
        } else {
          return null;
        }
      }
    }
  );

  return (
    <>
      {isLoading && <PageLoader />}
      <div className={`${styles.cardsList} grid-x`}>
        {data &&
          data.map((page) => {
            if (!showFiduciaryStatus && hiddenFaqs.P.includes(page.id)) {
              return null;
            }

            return (
              <ThemeCard
                key={page.id}
                url={`/${lng}/${domainID}/themes/${themeID}/pages/${page.id}`}
                language={lng}
                data={page}
                page
              />
            );
          })}
      </div>
    </>
  );
};

export default PagesList;
