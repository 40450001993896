import React from "react";
import classnames from "classnames";
import { useNavigate } from "react-router-dom";

import { ReactComponent as BlueRightArrow } from "../assets/BlueRightArrow.svg";
import styles from "../Home.module.scss";
import _ from "../../../i18n";

const SearchCard = ({ language, data }) => {
  const navigate = useNavigate();

  const titleAttr = `title${
    language.charAt(0).toUpperCase() + language.slice(1)
  }`;
  const questionTitleAttr = `questionTitle${
    language.charAt(0).toUpperCase() + language.slice(1)
  }`;
  const answerAttr = `answer${
    language.charAt(0).toUpperCase() + language.slice(1)
  }`;
  const contentAttr = `content${
    language.charAt(0).toUpperCase() + language.slice(1)
  }`;

  const renderChildUrl = (id) => {
    if (data.entity === "theme") {
      return `${data.url}/pages/${id}`;
    } else if (data.entity === "page") {
      return `${data.url}/rubrics/${id}`;
    } else if (data.entity === "faq_rubric") {
      return `${data.url}?q=${id}`;
    }
  };

  return (
    <div
      className={classnames(styles.cardCont, "cell small-12 medium-6 large-4")}
    >
      <div className={styles.card}>
        <div className={styles.icon}>
          <h3
            onClick={() => navigate(data.url)}
            dangerouslySetInnerHTML={{
              __html:
                data.entity === "faq_answer"
                  ? data[questionTitleAttr]
                  : data[titleAttr],
            }}
          ></h3>
        </div>
        {["faq_answer", "faq_question"].includes(data.entity) ? (
          <>
            <p
              className={styles.answer}
              dangerouslySetInnerHTML={{
                __html:
                  data.entity === "faq_question"
                    ? data[answerAttr]
                    : data[contentAttr],
              }}
            ></p>
          </>
        ) : (
          <>
            <ul className={styles.childrenList}>
              {data.children &&
                data.children.map((item, i) => {
                  if (i >= 4) {
                    return null;
                  }
                  return (
                    <li
                      className={"grid-x"}
                      key={i}
                      onClick={() => navigate(renderChildUrl(item.id))}
                    >
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="cell small-1"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M5.52876 3.52729C5.78911 3.26694 6.21122 3.26694 6.47157 3.52729L10.4716 7.52729C10.7319 7.78764 10.7319 8.20975 10.4716 8.4701L6.47157 12.4701C6.21122 12.7305 5.78911 12.7305 5.52876 12.4701C5.26841 12.2098 5.26841 11.7876 5.52876 11.5273L9.05735 7.9987L5.52876 4.4701C5.26841 4.20975 5.26841 3.78764 5.52876 3.52729Z"
                          fill="currentColor"
                        />
                      </svg>
                      <p className="cell small-10">{item[titleAttr]}</p>
                    </li>
                  );
                })}
            </ul>
            {data.children && data.children.length > 4 && (
              <div className={styles.nbPages}>
                <span>
                  <strong>+ {data.children.length - 4}&nbsp;</strong>{" "}
                  {data.entity === "theme"
                    ? _("Pages")
                    : data.entity === "page"
                    ? _("Rubrics")
                    : data.entity === "faq_rubric"
                    ? _("Questions")
                    : ""}
                </span>
              </div>
            )}
          </>
        )}

        <div className={styles.blueArrow} onClick={() => navigate(data.url)}>
          <BlueRightArrow />
        </div>
      </div>
    </div>
  );
};

export default SearchCard;
