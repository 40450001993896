import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useQuery } from "react-query";

import PageLoader from "../common/PageLoader";
import Languages from "../common/Languages";
import styles from "./Chaines.module.scss";
import Chaine from "./components/Chaine";
import { ReactComponent as BlackLeftArrow } from "../Pages/assets/BlackLeftArrow.svg";
import SingleChaine from "./components/SingleChaine";
import { getAvatarsAndRubrics } from "../../api";
import _ from "../../i18n";

const Chaines = () => {
  const [start, setStart] = useState(0);
  const [channels, setChannels] = useState(null);
  const [loadingChannels, setLoadingChannels] = useState(false);
  const [showBtn, setShowBtn] = useState(true);
  let { chaineID } = useParams();
  const navigate = useNavigate();
  const lng = useSelector((state) => state.params.language);
  const handleBack = () => {
    navigate(`/${lng}/chains`);
  };
  const auth = useSelector((state) => state.auth);
  const { token, navCommunity } = auth;
  let { data } = useQuery(
    ["listChains", navCommunity, token, start],
    async () => {
      if (token && !chaineID) {
        try {
          setLoadingChannels(true);
          const response = await getAvatarsAndRubrics({
            token,
            organizationId: navCommunity.id,
            start,
            limit: 10,
            rubricsStart: 0,
            rubricsLimit: 18,
          });
          setLoadingChannels(false);
          if (start > 0 && channels.length > 0) {
            setChannels([...channels, ...response.data.data]);
          } else {
            setChannels(response.data.data);
          }
        } catch (error) {
          if (error.response.data && error.response.data.statusCode === 404) {
            setLoadingChannels(false);
            setShowBtn(false);
          }
        }
      }
    }
  );

  return (
    <div className={styles.chainsPage}>
      {chaineID && (
        <div className={styles.backNav}>
          <button onClick={() => handleBack()}>
            <BlackLeftArrow />
            {_("back")}
          </button>
          <span>
            {channels &&
              channels.filter(
                (item) => item.chain.id.toString() === chaineID
              )[0].chain[`name${lng.charAt(0).toUpperCase() + lng.slice(1)}`]}
          </span>
        </div>
      )}
      <div className={styles.pageContent}>
        {!chaineID && (
          <div className={styles.pageHeader}>
            <div className={styles.titles}>
              <h1>{_("chains")}</h1>
              <h2>{_("Event brings together the best content.")}</h2>
            </div>
            <Languages />
          </div>
        )}
        {chaineID ? (
          <div>
            <SingleChaine lng={lng} chaineID={chaineID} />
          </div>
        ) : (
          <div className={styles.pageList}>
            {channels &&
              channels.map((item, i) => (
                <Chaine key={i} data={item} lng={lng} />
              ))}
            {loadingChannels ? (
              <PageLoader />
            ) : (
              showBtn && (
                <div className={styles.showMore}>
                  <div>
                    <button onClick={() => setStart(start + 10)}>
                      {_("show_more_chaines")}
                      <i className="icon-ttp-chevron-down"></i>
                    </button>
                  </div>
                </div>
              )
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Chaines;
