import React from "react";
import classnames from "classnames";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { ReactComponent as BlueRightArrow } from "../assets/BlueRightArrow.svg";
import styles from "../Home.module.scss";
import {
  updateFaqPreferences,
  updateHiddenFaqsLocalStorage,
} from "../../../store";

const ThemeCard = ({ language, data, url, page }) => {
  const auth = useSelector((state) => state.auth);
  const showAllStatus = useSelector((state) => state.faq.showAllStatus);
  const showFiduciaryStatus = useSelector(
    (state) => state.faq.showFiduciaryStatus
  );
  const hiddenFaqs = useSelector((state) => state.faq.hiddenFaqs);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let count = 0;
  let countLength = 0;

  const titleAttr = `title${
    language.charAt(0).toUpperCase() + language.slice(1)
  }`;

  const handleHideFaq = (e, key, id) => {
    e.stopPropagation();
    let tmpHiddenFaqs = JSON.parse(JSON.stringify(hiddenFaqs));
    if (tmpHiddenFaqs[key].includes(id)) {
      tmpHiddenFaqs[key] = tmpHiddenFaqs[key].filter((i) => i !== id);
    } else {
      tmpHiddenFaqs[key].push(id);
    }
    if (auth.navCommunity) {
      dispatch(
        updateFaqPreferences({
          hiddenFaqs: tmpHiddenFaqs,
          communityId: auth.navCommunity.id,
        })
      );
      dispatch(updateHiddenFaqsLocalStorage(tmpHiddenFaqs));
    }
  };

  const currentHiddenFaqs = page ? hiddenFaqs.P : hiddenFaqs.T;

  return (
    <div
      className={classnames(styles.cardCont, "cell small-12 medium-6 large-4")}
    >
      <div className={styles.card}>
        {showFiduciaryStatus && (
          <div
            className={`${styles.status_eye} ${styles.status_eye_absolute} ${
              currentHiddenFaqs.includes(data.id) && styles.hidden
            } `}
            onClick={(e) => handleHideFaq(e, page ? "P" : "T", data.id)}
          >
            <i className="icon-ttp-eye"></i>
          </div>
        )}
        <div className={styles.icon}>
          {data.icon && <i className={`icon webtool-${data.icon.label}`}></i>}
          <h3 onClick={() => navigate(url)}>{data[titleAttr]}</h3>
        </div>
        <ul className={styles.childrenList}>
          {page
            ? data.rubrics &&
              data.rubrics.map((item, i) => {
                if (!showAllStatus && item.status !== "PUBLISHED") {
                  return null;
                }
                if (!showFiduciaryStatus && hiddenFaqs.R.includes(item.id)) {
                  return null;
                }
                countLength++;
                if (count >= 4) {
                  return null;
                }
                count++;
                return (
                  <li
                    className={"grid-x"}
                    key={i}
                    onClick={() => navigate(`${url}/rubrics/${item.id}`)}
                  >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="cell small-1"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M5.52876 3.52729C5.78911 3.26694 6.21122 3.26694 6.47157 3.52729L10.4716 7.52729C10.7319 7.78764 10.7319 8.20975 10.4716 8.4701L6.47157 12.4701C6.21122 12.7305 5.78911 12.7305 5.52876 12.4701C5.26841 12.2098 5.26841 11.7876 5.52876 11.5273L9.05735 7.9987L5.52876 4.4701C5.26841 4.20975 5.26841 3.78764 5.52876 3.52729Z"
                        fill="currentColor"
                      />
                    </svg>
                    <p className="cell small-10">{item[titleAttr]}</p>
                    {showFiduciaryStatus && (
                      <div
                        className={`cell small-1 ${styles.status_eye} ${
                          hiddenFaqs.R.includes(item.id) && styles.hidden
                        } `}
                        onClick={(e) => handleHideFaq(e, "R", item.id)}
                      >
                        <i className="icon-ttp-eye"></i>
                      </div>
                    )}
                  </li>
                );
              })
            : data.pages &&
              data.pages.map((item, i) => {
                if (!showFiduciaryStatus && hiddenFaqs.P.includes(item.id)) {
                  return null;
                }
                if (i >= 4) {
                  return null;
                }
                return (
                  <li
                    className={"grid-x"}
                    key={i}
                    onClick={() => navigate(`${url}/pages/${item.id}`)}
                  >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="cell small-1"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M5.52876 3.52729C5.78911 3.26694 6.21122 3.26694 6.47157 3.52729L10.4716 7.52729C10.7319 7.78764 10.7319 8.20975 10.4716 8.4701L6.47157 12.4701C6.21122 12.7305 5.78911 12.7305 5.52876 12.4701C5.26841 12.2098 5.26841 11.7876 5.52876 11.5273L9.05735 7.9987L5.52876 4.4701C5.26841 4.20975 5.26841 3.78764 5.52876 3.52729Z"
                        fill="currentColor"
                      />
                    </svg>
                    <p className="cell small-10">{item[titleAttr]}</p>
                    {showFiduciaryStatus && (
                      <div
                        className={`cell small-1 ${styles.status_eye} ${
                          hiddenFaqs.P.includes(item.id) && styles.hidden
                        } `}
                        onClick={(e) => handleHideFaq(e, "P", item.id)}
                      >
                        <i className="icon-ttp-eye"></i>
                      </div>
                    )}
                  </li>
                );
              })}
        </ul>
        {page
          ? data.rubrics &&
            countLength > 4 && (
              <div className={styles.nbPages}>
                <span>
                  <strong>+ {countLength - 4}&nbsp;</strong> rubrics
                </span>
              </div>
            )
          : data.pages &&
            data.pages.length > 4 && (
              <div className={styles.nbPages}>
                <span>
                  <strong>+ {data.pages.length - 4}&nbsp;</strong> pages
                </span>
              </div>
            )}
        <div className={styles.blueArrow} onClick={() => navigate(url)}>
          <BlueRightArrow />
        </div>
      </div>
    </div>
  );
};

export default ThemeCard;
