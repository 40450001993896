import { useState } from "react";
import { useSelector } from "react-redux";

import SidebarQuestion from "./SidebarQuestion";
import styles from "./AddRubric.module.scss";

import _ from "../../i18n";

const AddQuestion = () => {
  const currentRubric = useSelector((state) => state.faq.currentRubric);
  const [sidebarOpened, setSidebarOpened] = useState(false);

  if (!currentRubric) {
    return null;
  }

  return (
    <>
      <div
        className={styles.question_trigger}
        onClick={() => setSidebarOpened(true)}
      >
        <i className="icon-ttp-plus-outline"></i>
      </div>
      <SidebarQuestion
        isOpened={sidebarOpened}
        hideSidebar={() => {
          setSidebarOpened(false);
        }}
      />
    </>
  );
};

export default AddQuestion;
