import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { fetchDomains } from "../../store";

const HomeRedirect = () => {
  const auth = useSelector((state) => state.auth);
  const currentDomain = useSelector((state) => state.domains.currentDomain);
  const domainsList = useSelector((state) => state.domains.items);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let { lang } = useParams();
  const urlAttr = `url${lang.charAt(0).toUpperCase() + lang.slice(1)}`;

  useEffect(() => {
    if (auth.navCommunity && domainsList.length === 0) {
      dispatch(fetchDomains({ communityId: auth.navCommunity.id }));
    }
  }, [auth.navCommunity]);

  useEffect(() => {
    if (currentDomain) {
      navigate(`/${lang}/${currentDomain[urlAttr]}`);
    }
  }, [currentDomain]);

  return null;
};

export default HomeRedirect;
