import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import styles from "../Search.module.scss";
import { ReactComponent as BannerPic1 } from "../assets/BannerPic1.svg";
import { ReactComponent as BannerPic2 } from "../assets/BannerPic2.svg";
import { ReactComponent as Close } from "../assets/Close.svg";
import { addLandaSize } from "../../../utils";
import classNames from "classnames";

const LeftBanner = ({
  currentDomain,
  currentTheme,
  currentPage,
  currentRubric,
}) => {
  const language = useSelector((state) => state.params.language);
  const showSubMenu = useSelector((state) => state.params.subMenu);
  const showHeader = useSelector((state) => state.params.header);
  const navigate = useNavigate();
  const titleAttr = `title${
    language.charAt(0).toUpperCase() + language.slice(1)
  }`;
  let countAuthor = 0;

  const handleClick = () => {
    localStorage.removeItem("currentDomain");
    localStorage.removeItem("currentTheme");
    localStorage.removeItem("currentPage");
    localStorage.removeItem("currentRubric");
    navigate(`/${language}`);
  };

  let chainUrl = null;
  if (
    currentRubric &&
    currentRubric.chain &&
    currentRubric.chain[0] &&
    currentRubric.chain[0].mediaChain
  ) {
    currentRubric.chain[0].mediaChain.forEach((mediaChain) => {
      if (mediaChain.language === language) {
        chainUrl = mediaChain.avatarUrl;
      }
    });
  }

  return (
    <div
      className={classNames(styles.leftBanner, !showHeader ? styles.isUa : "")}
    >
      <div className={styles.bannerHeader}>
        {showSubMenu && (
          <button className={styles.closeBtn} onClick={() => handleClick()}>
            <Close />
          </button>
        )}
        <BannerPic1 />
        <h3>
          {currentRubric
            ? currentRubric[titleAttr]
            : currentPage
            ? "4. Rubric..."
            : currentTheme
            ? "3. Page..."
            : currentDomain
            ? "2. Thème..."
            : "1. Je suis..."}
        </h3>
        <span>
          {currentRubric
            ? ""
            : currentPage
            ? "Rubric Dum apud Persas, ut supra narravimus, perfidia regis motus agitat insperatos, et in eois tractibus bella rediviva consurgunt, anno sexto decimo"
            : currentTheme
            ? "Page Dum apud Persas, ut supra narravimus, perfidia regis motus agitat insperatos, et in eois tractibus bella rediviva consurgunt, anno sexto decimo"
            : currentDomain
            ? "Theme Dum apud Persas, ut supra narravimus, perfidia regis motus agitat insperatos, et in eois tractibus bella rediviva consurgunt, anno sexto decimo"
            : "Qui êtes-vous ? Quel est votre profil ? Dites-nous en un peu sur vous !"}
        </span>
      </div>
      <div className={styles.bannerContent}>
        {currentRubric ? (
          <div className={styles.bannerFooter}>
            {currentRubric.questions && (
              <div className={styles.numberQuestions}>
                <span>
                  <strong>{currentRubric.questions.length}&nbsp;</strong>{" "}
                  questions
                </span>
              </div>
            )}

            <div className={styles.users}>
              {currentRubric.author.length > 0 &&
                currentRubric.author.map((path, i) => {
                  if (!path.enableAvatar) {
                    return null;
                  }
                  countAuthor++;
                  if (countAuthor >= 4) {
                    return null;
                  }
                  return (
                    <span
                      key={`avar${i}`}
                      className={styles.avatar}
                      style={{ backgroundImage: `url(${path.avatarUrl})` }}
                      alt=""
                    />
                  );
                })}
              {countAuthor > 4 && (
                <div className={styles.extraUsers}>+{countAuthor - 4}</div>
              )}
            </div>
            {chainUrl && (
              <span
                className={styles.chainImg}
                style={{
                  backgroundImage: `url(${addLandaSize(chainUrl, 240)})`,
                }}
                alt=""
              />
            )}
          </div>
        ) : (
          <BannerPic2 className={styles.bannerPic2} />
        )}
      </div>
    </div>
  );
};

export default LeftBanner;
