import { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import Loader from "../../common/Loader";
import Button from "../../common/Button";
import _ from "../../../i18n";

import styles from "./ShareTooltip.module.scss";
import CollaboratorsSelect from "./CollaboratorsSelect";
import ClientSelect from "./ClientSelect";
import ContactSelect from "./ContactSelect";
import { convertDateToUTC } from "../../../utils";
import { shareFaq } from "../../../api";

const DATE_FORMAT = "DD-MM-YYYY HH:mm";
const API_DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";

const ShareTooltip = ({ question, close }) => {
  const token = useSelector((state) => state.auth.token);
  const navCommunity = useSelector((state) => state.auth.navCommunity);

  const [saving, setSaving] = useState(false);
  const scopes = [
    "ALL_COLLABORATORS",
    "ALL_CLIENTS",
    "ALL_CONTACTS",
    "SPEC_COLLABORATOR",
    "SPEC_CLIENT",
    "SPEC_CONTACT",
  ];

  const [checkedItems, setCheckedItems] = useState([]);
  const [specCollaborators, setSpecCollaborators] = useState([]);
  const [specClients, setSpecClients] = useState([]);
  const [specContacts, setSpecContacts] = useState([]);

  const handleChange = (scope) => {
    switch (scope) {
      case "ALL_COLLABORATORS":
        if (checkedItems.includes("ALL_COLLABORATORS")) {
          setCheckedItems([
            ...checkedItems.filter((el) => el !== "ALL_COLLABORATORS"),
          ]);
        } else {
          setCheckedItems([
            ...checkedItems.filter((el) => el !== "SPEC_COLLABORATOR"),
            "ALL_COLLABORATORS",
          ]);
          setSpecCollaborators([]);
        }

        break;
      case "ALL_CLIENTS":
        if (checkedItems.includes("ALL_CLIENTS")) {
          setCheckedItems([
            ...checkedItems.filter((el) => el !== "ALL_CLIENTS"),
          ]);
        } else {
          setCheckedItems([
            ...checkedItems.filter((el) => el !== "SPEC_CLIENT"),
            "ALL_CLIENTS",
          ]);
          setSpecClients([]);
        }

        break;
      case "ALL_CONTACTS":
        if (checkedItems.includes("ALL_CONTACTS")) {
          setCheckedItems([
            ...checkedItems.filter((el) => el !== "ALL_CONTACTS"),
          ]);
        } else {
          setCheckedItems([
            ...checkedItems.filter((el) => el !== "SPEC_CONTACT"),
            "ALL_CONTACTS",
          ]);
          setSpecContacts([]);
        }

        break;
      case "SPEC_COLLABORATOR":
        if (checkedItems.includes("SPEC_COLLABORATOR")) {
          setCheckedItems([
            ...checkedItems.filter((el) => el !== "SPEC_COLLABORATOR"),
          ]);
          setSpecCollaborators([]);
        } else {
          setCheckedItems([
            ...checkedItems.filter((el) => el !== "ALL_COLLABORATORS"),
            "SPEC_COLLABORATOR",
          ]);
        }

        break;
      case "SPEC_CLIENT":
        if (checkedItems.includes("SPEC_CLIENT")) {
          setCheckedItems([
            ...checkedItems.filter((el) => el !== "SPEC_CLIENT"),
          ]);
          setSpecClients([]);
        } else {
          setCheckedItems([
            ...checkedItems.filter((el) => el !== "ALL_CLIENTS"),
            "SPEC_CLIENT",
          ]);
        }

        break;
      case "SPEC_CONTACT":
        if (checkedItems.includes("SPEC_CONTACT")) {
          setCheckedItems([
            ...checkedItems.filter((el) => el !== "SPEC_CONTACT"),
          ]);
          setSpecContacts([]);
        } else {
          setCheckedItems([
            ...checkedItems.filter((el) => el !== "ALL_CONTACTS"),
            "SPEC_CONTACT",
          ]);
        }

        break;

      default:
        break;
    }
  };

  const validate = () => {
    let errors = [];

    if (checkedItems.length === 0) {
      errors.push(_("pick_at_least_one_choice"));
    }
    if (
      checkedItems.includes("SPEC_COLLABORATOR") &&
      specCollaborators.length === 0
    ) {
      errors.push(_("pick_at_least_a_collaborator"));
    }
    if (checkedItems.includes("SPEC_CLIENT") && specClients.length === 0) {
      errors.push(_("pick_at_least_a_client"));
    }
    if (checkedItems.includes("SPEC_CONTACT") && specContacts.length === 0) {
      errors.push(_("pick_at_least_a_contact"));
    }

    return errors;
  };

  const save = async () => {
    let errors = validate();

    if (errors && errors.length > 0) {
      let ErrorsContainer = ({ errors }) => (
        <div>
          <span>{_("errors") + " :"}</span>
          <ul>
            {errors.map((e, i) => (
              <li key={i}>{e}</li>
            ))}
          </ul>
        </div>
      );
      toast.error(<ErrorsContainer errors={errors} />);
      return;
    }

    const data = {
      objectType: "QUESTION",
      objectId: question.id,
      publishingDate: question.publishedAt
        ? convertDateToUTC(
            question.publishedAt.date,
            API_DATE_FORMAT,
            API_DATE_FORMAT
          )
        : "",
      sharingDate: convertDateToUTC(new Date(), DATE_FORMAT, API_DATE_FORMAT),
      target: checkedItems.join(),
      specCollaborators:
        specCollaborators.length > 0
          ? specCollaborators.map((el) => el.id).join()
          : null,
      specClients:
        specClients.length > 0 ? specClients.map((el) => el.id).join() : null,
      specContacts:
        specContacts.length > 0 ? specContacts.map((el) => el.id).join() : null,
    };
    setSaving(true);
    try {
      await shareFaq({ token, data });
      toast.success(_("question_share_success"));
      close();
      setSaving(false);
    } catch (error) {
      toast.error(_("rubric_delete_error"));
      close();
      setSaving(false);
    }
  };

  return (
    <div className={styles.container} onClick={(e) => e.stopPropagation()}>
      <div className={styles.share__btn}>
        <span className={styles.title}>{_("share_with")}:</span>
        <ul>
          {scopes.map((scope) => (
            <>
              <li>
                <label>
                  <input
                    name={scope}
                    type="checkbox"
                    onChange={(e) => handleChange(e.target.value)}
                    checked={checkedItems.includes(scope)}
                    value={scope}
                  />
                  &nbsp; {_(`${scope}`)}
                </label>
              </li>
              {scope === "SPEC_COLLABORATOR" &&
                checkedItems.includes("SPEC_COLLABORATOR") && (
                  <CollaboratorsSelect
                    isMulti={true}
                    selectedCollaborators={specCollaborators}
                    onChange={setSpecCollaborators}
                    organizationId={navCommunity.id}
                  />
                )}
              {scope === "SPEC_CLIENT" &&
                checkedItems.includes("SPEC_CLIENT") && (
                  <ClientSelect
                    isMulti={true}
                    selectedClients={specClients}
                    onChange={setSpecClients}
                  />
                )}
              {scope === "SPEC_CONTACT" &&
                checkedItems.includes("SPEC_CONTACT") && (
                  <ContactSelect
                    isMulti={true}
                    selectedClients={specContacts}
                    onChange={setSpecContacts}
                    organizationId={navCommunity.id}
                  />
                )}
            </>
          ))}
        </ul>

        <Button disabled={saving} onClick={() => save()}>
          {saving ? (
            <Loader
              style={{
                height: "10px",
              }}
              color={"#fff"}
            />
          ) : (
            _("share")
          )}
        </Button>
      </div>
    </div>
  );
};

export default ShareTooltip;
