import classNames from "classnames";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import _ from "../../i18n";
import IconArticle from "../common/Icons/IconArticle";
import IconBackArrow from "../common/Icons/IconBackArrow";
import IconFormation from "../common/Icons/IconFormation";
import IconMedia from "../common/Icons/IconMedia";
import IconQuestion from "../common/Icons/IconQuestion";
import ArticleForm from "./faq/ArticleForm";
import FormationForm from "./faq/FormationForm";
import FaqForm from "./faq/FaqForm";
import MediaForm from "./faq/MediaForm";
import StatusForm from "./faq/StatusForm";
import styles from "./Sidebar.module.scss";
import { getGroups } from "../../api";
import { convertDateToUTC } from "../../utils";
import { setCurrentQuestion } from "../../store";

const statusOptions = [
  { value: "DRAFT", label: _("draft") },
  { value: "PUBLISHED", label: _("published") },
];

const DATE_FORMAT = "DD-MM-YYYY HH:mm";
const API_DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";

const SidebarQuestion = (props) => {
  const currentQuestionStore = useSelector((state) => state.faq.current);
  const { isOpened } = props;
  const [type, setType] = useState(null);
  const auth = useSelector((state) => state.auth);
  const currentRubric = useSelector((state) => state.faq.currentRubric);
  const [isPrivate, setIsPrivate] = useState(false);
  const [privateGroups, setPrivateGroups] = useState([]);
  const [status, setStatus] = useState(statusOptions[0]);
  const [publishedAt, setPublishedAt] = useState(null);
  const [addMediaModalOpened, setAddMediaModalOpened] = useState(false);
  const [showEmbedInput, setShowEmbedInput] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!currentQuestionStore) {
      reset();
    }
    setAddMediaModalOpened(false);
  }, [type]);

  const reset = () => {
    setStatus(statusOptions[0]);
    setIsPrivate(false);
    setPrivateGroups([]);
    setPublishedAt(null);
    setShowEmbedInput(false);
  };

  const closeSidebar = () => {
    setType(null);

    props.hideSidebar();
    reset();
    dispatch(setCurrentQuestion(null));
  };

  useEffect(() => {
    if (currentQuestionStore) {
      reset();
      setType(
        currentQuestionStore.objectType === "MEDIA"
          ? "MEDIAS"
          : currentQuestionStore.objectType === "ARTICLE"
          ? "ARTICLES"
          : currentQuestionStore.objectType === "EVENT"
          ? "FORMATIONS"
          : currentQuestionStore.objectType
      );
      currentQuestionStore.status === statusOptions[0].value
        ? setStatus(statusOptions[0])
        : setStatus(statusOptions[1]);

      if (currentQuestionStore.isPrivate) {
        setIsPrivate(true);
        let customFilter = [
          {
            property: "id",
            value: currentQuestionStore.privateGroups,
            operator: "in",
          },
        ];
        getGroups({
          token: auth.token,
          clientId: currentRubric.organization
            ? currentRubric.organization.id
            : null,
          customFilter,
        }).then((result) => {
          setPrivateGroups(result.data.data);
        });
      } else {
        setIsPrivate(false);
        setPrivateGroups([]);
      }
      if (currentQuestionStore.publishedAt) {
        setPublishedAt(
          convertDateToUTC(
            currentQuestionStore.publishedAt,
            API_DATE_FORMAT,
            DATE_FORMAT
          )
        );
      }
    } else {
      setType(null);
    }
  }, [currentQuestionStore]);

  return (
    <>
      <div
        className={`${styles.mask} ${isOpened ? "" : styles.hide}`}
        onClick={() => closeSidebar()}
      ></div>
      <div
        className={`${styles.sidebar} ${styles.small} ${
          isOpened && styles.display
        }`}
      >
        <div onClick={() => closeSidebar()} className={styles.close}>
          <i className="icon-ttp-close"></i>
        </div>
        <div className={styles.title}>
          {_("add_content")}
          <div className={styles.sub_title}>{_("add_content_sub")}</div>
        </div>
        {type && (
          <StatusForm
            status={status}
            setStatus={setStatus}
            publishedAt={publishedAt}
            setPublishedAt={setPublishedAt}
            isPrivate={isPrivate}
            setIsPrivate={setIsPrivate}
            privateGroups={privateGroups}
            setPrivateGroups={setPrivateGroups}
            auth={auth}
          />
        )}
        {type && (
          <div className={styles.back}>
            <div className={styles.btn}>
              {!currentQuestionStore && (
                <button onClick={() => setType(null)}>
                  <IconBackArrow />
                </button>
              )}
              <span>{_(`${type}`)}</span>
            </div>

            {type === "MEDIAS" && !currentQuestionStore && (
              <div className={styles.btns_group}>
                <button
                  className={styles.upload_btn}
                  onClick={() => setAddMediaModalOpened(true)}
                >
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M2.33333 6.41666C2.6555 6.41666 2.91667 6.67782 2.91667 6.99999V11.6667C2.91667 11.8214 2.97812 11.9697 3.08752 12.0791C3.19692 12.1885 3.34529 12.25 3.5 12.25H10.5C10.6547 12.25 10.8031 12.1885 10.9125 12.0791C11.0219 11.9697 11.0833 11.8214 11.0833 11.6667V6.99999C11.0833 6.67782 11.3445 6.41666 11.6667 6.41666C11.9888 6.41666 12.25 6.67782 12.25 6.99999V11.6667C12.25 12.1308 12.0656 12.5759 11.7374 12.9041C11.4092 13.2323 10.9641 13.4167 10.5 13.4167H3.5C3.03587 13.4167 2.59075 13.2323 2.26256 12.9041C1.93437 12.5759 1.75 12.1308 1.75 11.6667V6.99999C1.75 6.67782 2.01117 6.41666 2.33333 6.41666Z"
                      fill="#18A0FB"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M6.58817 0.754198C6.81598 0.526392 7.18532 0.526392 7.41313 0.754198L9.74646 3.08753C9.97427 3.31534 9.97427 3.68468 9.74646 3.91249C9.51866 4.14029 9.14931 4.14029 8.92151 3.91249L7.00065 1.99163L5.0798 3.91249C4.85199 4.14029 4.48264 4.14029 4.25484 3.91249C4.02703 3.68468 4.02703 3.31534 4.25484 3.08753L6.58817 0.754198Z"
                      fill="#18A0FB"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M6.99935 0.583344C7.32152 0.583344 7.58268 0.844511 7.58268 1.16668V8.75001C7.58268 9.07218 7.32152 9.33334 6.99935 9.33334C6.67718 9.33334 6.41602 9.07218 6.41602 8.75001V1.16668C6.41602 0.844511 6.67718 0.583344 6.99935 0.583344Z"
                      fill="#18A0FB"
                    />
                  </svg>
                  Importer un fichier
                </button>
                <button
                  className={classNames(styles.upload_btn, styles.embed_btn)}
                  onClick={() => setShowEmbedInput(!showEmbedInput)}
                >
                  Ajouter embed
                </button>
              </div>
            )}
          </div>
        )}
        {type === "QUESTION" ? (
          <FaqForm
            afterSave={() => window.location.reload()}
            onCancel={closeSidebar}
            sideBar={true}
            status={status}
            publishedAt={publishedAt}
            isPrivate={isPrivate}
            privateGroups={privateGroups}
          />
        ) : type === "MEDIAS" ? (
          <MediaForm
            auth={auth}
            addMediaModalOpened={addMediaModalOpened}
            setAddMediaModalOpened={setAddMediaModalOpened}
            setShowEmbedInput={setShowEmbedInput}
            showEmbedInput={showEmbedInput}
            onCancel={closeSidebar}
            afterSave={() => window.location.reload()}
            status={status}
            publishedAt={publishedAt}
            isPrivate={isPrivate}
            privateGroups={privateGroups}
            currentRubric={currentRubric}
            currentQuestion={currentQuestionStore}
          />
        ) : type === "ARTICLES" ? (
          <ArticleForm
            auth={auth}
            onCancel={closeSidebar}
            afterSave={() => window.location.reload()}
            status={status}
            publishedAt={publishedAt}
            isPrivate={isPrivate}
            privateGroups={privateGroups}
            currentRubric={currentRubric}
            currentQuestion={currentQuestionStore}
          />
        ) : type === "FORMATIONS" ? (
          <FormationForm
            auth={auth}
            onCancel={closeSidebar}
            afterSave={() => window.location.reload()}
            status={status}
            publishedAt={publishedAt}
            isPrivate={isPrivate}
            privateGroups={privateGroups}
            currentRubric={currentRubric}
            currentQuestion={currentQuestionStore}
          />
        ) : (
          <div className={classNames(styles.cards, "grid-x")}>
            <div
              className={classNames(
                styles.cardCont,
                "cell small-12 medium-6 large-3"
              )}
            >
              <div className={styles.card} onClick={() => setType("QUESTION")}>
                <div className={styles.blue}></div>
                <div className={styles.icon}>
                  <IconQuestion />
                </div>
                <div className={styles.name}>{_("QUESTION")}</div>
                <div className={styles.description}>
                  {_(
                    "Principium autem unde latius se funditabat, emersit ex negotio tali."
                  )}
                </div>
              </div>
            </div>
            <div
              className={classNames(
                styles.cardCont,
                "cell small-12 medium-6 large-3"
              )}
            >
              <div className={styles.card} onClick={() => setType("MEDIAS")}>
                <div className={styles.blue}></div>
                <div className={styles.icon}>
                  <IconMedia />
                </div>
                <div className={styles.name}>{_("Medias")}</div>
                <div className={styles.description}>
                  {_(
                    "Principium autem unde latius se funditabat, emersit ex negotio tali."
                  )}
                </div>
              </div>
            </div>
            <div
              className={classNames(
                styles.cardCont,
                "cell small-12 medium-6 large-3"
              )}
            >
              <div
                className={styles.card}
                onClick={() => setType("FORMATIONS")}
              >
                <div className={styles.blue}></div>
                <div className={styles.icon}>
                  <IconFormation />
                </div>
                <div className={styles.name}>{_("fORMATIONs")}</div>
                <div className={styles.description}>
                  {_(
                    "Principium autem unde latius se funditabat, emersit ex negotio tali."
                  )}
                </div>
              </div>
            </div>
            <div
              className={classNames(
                styles.cardCont,
                "cell small-12 medium-6 large-3"
              )}
              style={{ paddingRight: "0rem" }}
            >
              <div className={styles.card} onClick={() => setType("ARTICLES")}>
                <div className={styles.blue}></div>
                <div className={styles.icon}>
                  <IconArticle />
                </div>
                <div className={styles.name}>{_("Articles")}</div>
                <div className={styles.description}>
                  {_(
                    "Principium autem unde latius se funditabat, emersit ex negotio tali."
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default SidebarQuestion;
