import { useState } from "react";
import React from "react";
import classNames from "classnames";
import moment from "moment";
import { useSelector } from "react-redux";

import _ from "../../i18n";
import styles from "./Privacy.module.scss";
import { updateRubricStatus } from "../../api";

const API_DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";

const RubricCard = ({
  item,
  language,
  edit = false,
  setSelectedRubric,
  selectedRubric,
  setSortableRubrics,
  sortableRubrics,
  setVersionLanguage,
}) => {
  const titleAttr = `title${
    language.charAt(0).toUpperCase() + language.slice(1)
  }`;
  const titlesAttr = `titles${
    language.charAt(0).toUpperCase() + language.slice(1)
  }`;
  const [saving, setSaving] = useState(false);
  const auth = useSelector((state) => state.auth);
  const { token } = auth;

  const handleUpdateRubricStatus = async () => {
    try {
      setSaving(true);
      const res = await updateRubricStatus({
        token,
        data: {
          id: item.id,
          showInFooter: !item.showInFooter,
        },
      });

      if (res.data.data) {
        setSortableRubrics(
          sortableRubrics.map((el) => {
            if (el.id === res.data.data.id) {
              return {
                ...el,
                showInFooter: res.data.data.showInFooter === 1 ? true : false,
              };
            }
            return el;
          })
        );
      }
      // queryClient.invalidateQueries("getPrivacyRubrics");
      setSaving(false);
    } catch (error) {
      setSaving(false);
    }
  };

  return (
    <div
      className={styles.item}
      onClick={() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
        setSelectedRubric(item);
        if (setVersionLanguage) setVersionLanguage(language);
      }}
    >
      {saving && <div className={styles.saving}></div>}
      <div
        className={classNames(
          selectedRubric && selectedRubric.id === item.id
            ? styles.rubric_active
            : "",
          styles.rubric
        )}
      >
        {edit && (
          <span className={styles.dragHandler}>
            <i className="icon-ttp-drag"></i>
          </span>
        )}
        <div className={styles.rubricContent}>
          <p className={styles.title}>{item.versions[0][titleAttr]}</p>
          <div className={styles.details}>
            {edit && (
              <>
                <span className={styles.publishedAt}>
                  {item.versions[0].status === "DRAFT"
                    ? _("draft")
                    : _("published_at") +
                      " " +
                      moment(item.versions[0].publishedAt, API_DATE_FORMAT)
                        .locale(language)
                        .format("DD MMM YYYY")}
                </span>
                <label className={styles.dot}></label>
              </>
            )}
            <span className={styles.version}>
              version {item.versions[0].name}
            </span>
          </div>
          {item[titlesAttr] && item[titlesAttr].length > 0 && (
            <ul className={styles.chapters}>
              {item[titlesAttr].map((title) => (
                <li>
                  <a href={`#${title.id}`}>{title.title}</a>
                </li>
              ))}
            </ul>
          )}
        </div>
        {selectedRubric && selectedRubric.id === item.id && (
          <div className={styles.arrow}>
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_50815_37489)">
                <path
                  d="M10.6482 6.50031L4.3525 0.206666C4.07624 -0.0688887 3.62867 -0.0688887 3.35172 0.206666C3.07547 0.482221 3.07547 0.929795 3.35172 1.20535L9.14809 6.99962L3.35242 12.7939C3.07617 13.0695 3.07616 13.517 3.35242 13.7933C3.62867 14.0688 4.07694 14.0688 4.35319 13.7933L10.6489 7.49963C10.921 7.22693 10.921 6.77232 10.6482 6.50031Z"
                  fill="#18A0FB"
                />
              </g>
              <defs>
                <clipPath id="clip0_50815_37489">
                  <rect width="14" height="14" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
        )}
      </div>
      {edit && (
        <div className={styles.isVisible}>
          <div
            className={classNames(
              styles.checkbox,
              item.showInFooter ? styles.checkbox_active : ""
            )}
            onClick={() => handleUpdateRubricStatus()}
          >
            {item.showInFooter ? (
              <svg
                width="10"
                height="10"
                viewBox="0 0 10 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_49976_35660)">
                  <path
                    d="M3.95836 9.44352C3.68037 9.44352 3.41746 9.31425 3.24844 9.09253L0.965733 6.09609C0.668833 5.70637 0.746124 5.15145 1.1382 4.85621C1.53092 4.56085 2.08882 4.63818 2.38584 5.02764L3.88721 6.99818L7.66322 0.971768C7.9232 0.557165 8.47216 0.430308 8.89017 0.68834C9.30767 0.946626 9.43517 1.49266 9.17506 1.90764L4.71441 9.02637C4.55945 9.27386 4.29193 9.42955 3.99899 9.44275C3.98532 9.44326 3.97191 9.44352 3.95836 9.44352Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_49976_35660">
                    <rect
                      width="8.94276"
                      height="8.88889"
                      fill="white"
                      transform="translate(0.576172 0.554688)"
                    />
                  </clipPath>
                </defs>
              </svg>
            ) : null}
          </div>
          <label onClick={() => handleUpdateRubricStatus()}>
            {_("visible_in_footer")}
          </label>
        </div>
      )}
    </div>
  );
};

export default RubricCard;
