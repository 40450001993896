import React from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";

import styles from "../Search.module.scss";
import Card from "./Card";
import _ from "../../../i18n";

const Themes = ({ data, setCurrentTheme }) => {
  const hiddenFaqs = useSelector((state) => state.faq.hiddenFaqs);

  return (
    <div className={`${styles.cardsList} grid-x `}>
      {!data && (
        <div className={styles.no_data}>{_("domain_has_no_themes")}</div>
      )}
      {data &&
        data.map((theme) => {
          if (hiddenFaqs.T.includes(theme.id)) {
            return null;
          }
          return (
            <div
              className={classNames(
                styles.cardCont,
                "cell small-12 medium-6 large-4"
              )}
            >
              <Card
                key={theme.id}
                type={"theme"}
                data={theme}
                setCurrentTheme={setCurrentTheme}
              />
            </div>
          );
        })}
    </div>
  );
};

export default Themes;
