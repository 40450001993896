import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "../Chaines.module.scss";
import _ from "../../../i18n";
import classNames from "classnames";

const LeftBanner = ({ single, lng, chain, chainAuthors }) => {
  const nameAttr = `name${lng.charAt(0).toUpperCase() + lng.slice(1)}`;
  const headlineAttr = `headline${lng.charAt(0).toUpperCase() + lng.slice(1)}`;
  const descriptionAttr = `description${
    lng.charAt(0).toUpperCase() + lng.slice(1)
  }`;
  const navigate = useNavigate();
  const [showFullDescription, setShowFullDescription] = useState(false);
  return (
    <div className={`${styles.leftBanner}`}>
      <span className={styles.chainSpan}>{_("chain")}</span>
      <h2>{chain[nameAttr]}</h2>
      {chain[descriptionAttr] && (
        <div className={styles.description}>
          <p>
            {!showFullDescription
              ? chain[descriptionAttr].substring(0, 172) + " ..."
              : chain[descriptionAttr]}
          </p>
          {!showFullDescription ? (
            <label
              className={styles.showAll}
              onClick={() => setShowFullDescription(true)}
            >
              {_("show_full_description")}
            </label>
          ) : null}
        </div>
      )}

      <div
        className={styles.chainLogo}
        style={{ backgroundImage: `url(${chain.mediaChain[0].avatarUrl})` }}
      />
      {single ? (
        <ul className={styles.users}>
          {chainAuthors.slice(0, 6).map((item) => (
            <li key={item.id} className={classNames(styles.userItem, "grid-x")}>
              <div className={classNames(styles.userAvatar, "cell small-4")}>
                <div
                  className={styles.img}
                  style={{ backgroundImage: `url(${item.avatarUrl})` }}
                ></div>
              </div>
              <div className={classNames(styles.userInfo, "cell small-8")}>
                <p>{`${item.firstName} ${item.lastName}`}</p>
                <span>{item[headlineAttr]}</span>
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <button
          className={styles.seeChain}
          onClick={() => {
            navigate(`/${lng}/chains/${chain.id}`);
          }}
        >
          {_("see_chain")}
        </button>
      )}
    </div>
  );
};

export default LeftBanner;
