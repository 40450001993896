import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal } from "antd";
import { Avatar } from "tamtam-components";
import { useSearchParams } from "react-router-dom";
import classNames from "classnames";
import { ClickAwayListener } from "@mui/base";

import styles from "./Question.module.scss";
import { IconChevronDown } from "../Icons";
import {
  setCurrentQuestion,
  setCurrentRubric,
  updateFaqPreferences,
  updateHiddenFaqsLocalStorage,
} from "../../../store";
import FaqForm from "../../Settings/faq/FaqForm";
import SidebarQuestion from "../../Settings/SidebarQuestion";
import _ from "../../../i18n";
import { fetchSuggestion } from "../../../api";
import Attachments from "./Attachments";
import Tags from "./Tags";
import ShareTooltip from "./ShareTooltip";
import moment from "moment";

const API_DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";

const Question = ({ item, index, rubric, search, edit, sharingDate }) => {
  const lng = useSelector((state) => state.params.language);
  const showHeader = useSelector((state) => state.params.header);
  const showAllStatus = useSelector((state) => state.faq.showAllStatus);
  const showFiduciaryStatus = useSelector(
    (state) => state.faq.showFiduciaryStatus
  );
  const hiddenFaqs = useSelector((state) => state.faq.hiddenFaqs);
  const auth = useSelector((state) => state.auth);
  const { user, token } = auth;
  const [modalOpen, setModalOpen] = useState(false);
  const [suggestion, setSuggestion] = useState(null);
  const [activeQuestion, setActiveQuestion] = useState("0");
  const [sidebarOpened, setSidebarOpened] = useState(false);
  const [answer, setAnswer] = useState(null);
  const [isCustomAnswer, setIsCustomAnswer] = useState(false);
  const [openShare, setOpenShare] = useState(false);
  const dispatch = useDispatch();

  const titleAttr = `title${lng.charAt(0).toUpperCase() + lng.slice(1)}`;
  const contentAttr = `content${lng.charAt(0).toUpperCase() + lng.slice(1)}`;

  const [searchParams] = useSearchParams();
  const questionIndex = searchParams.get("q");
  useEffect(() => {
    if (questionIndex && rubric)
      setActiveQuestion(
        rubric.questions.indexOf(
          rubric.questions.filter((item) => item.id == questionIndex)[0]
        )
      );
  }, [questionIndex]);

  useEffect(() => {
    if (item.answers && item.answers.length > 0) {
      if (item.answers.length === 1) {
        setAnswer(item.answers[0]);
      } else {
        item.answers.forEach((a) => {
          if (a.isMain) {
            setAnswer(a);
            setIsCustomAnswer(true);
          }
        });
      }
    }
    const f = async () => {
      if (!suggestion && user && item?.suggestionDetails?.[user.id]) {
        const res = await fetchSuggestion(
          token,
          item?.suggestionDetails?.[user.id]
        );
        setSuggestion(res);
      }
    };
    f();
  }, [user, item]);

  let chainUrl = null;
  if (rubric && rubric.chain) {
    if (rubric.chain[0] && rubric.chain[0].mediaChain) {
      rubric.chain[0].mediaChain.forEach((mediaChain) => {
        if (mediaChain.language === lng) {
          chainUrl = mediaChain.avatarUrl;
        }
      });
    }
  }

  const handleCloseModal = () => {
    setModalOpen(false);
    dispatch(setCurrentQuestion(null));
  };

  const handleHideFaq = (e, key, id) => {
    e.stopPropagation();
    let tmpHiddenFaqs = JSON.parse(JSON.stringify(hiddenFaqs));
    if (tmpHiddenFaqs[key].includes(id)) {
      tmpHiddenFaqs[key] = tmpHiddenFaqs[key].filter((i) => i !== id);
    } else {
      tmpHiddenFaqs[key].push(id);
    }
    if (auth.navCommunity) {
      dispatch(
        updateFaqPreferences({
          hiddenFaqs: tmpHiddenFaqs,
          communityId: auth.navCommunity.id,
        })
      );
      dispatch(updateHiddenFaqsLocalStorage(tmpHiddenFaqs));
    }
  };

  const formattedAnswer = (originalAnswer) => {
    let parser = new DOMParser();
    const htmlDoc = parser.parseFromString(originalAnswer, "text/html");

    [...htmlDoc.getElementsByTagName("a")].forEach((el, i) => {
      el.setAttribute("target", `_blank`);
    });
    return htmlDoc.body.innerHTML;
  };

  return (
    <ClickAwayListener
      onClickAway={(e) => {
        if (openShare) {
          e.stopPropagation();
          setOpenShare(false);
        }
      }}
    >
      <div>
        <div className={styles.item} key={item.id}>
          <div
            className={`${styles.itemTitle} ${
              index == activeQuestion && styles.activeTitle
            } ${search ? styles.search : ""} grid-x`}
            id={`title_${item.id}`}
          >
            <span
              className={`cell small-10 ${styles.itemTitle_text}`}
              onClick={() => {
                const title = document.getElementById(`title_${item.id}`);
                title.classList.toggle(`${styles.activeTitle}`);
                const content = document.getElementById(`content_${item.id}`);
                content.classList.toggle(`${styles.activeContent}`);
                if (openShare) {
                  setOpenShare(false);
                }
              }}
            >
              {edit && (
                <span className={styles.dragHandler}>
                  <i className="icon-ttp-drag"></i>
                </span>
              )}
              <div className={styles.title_sharingDate}>
                <span>{item[titleAttr]}</span>
                {sharingDate && (
                  <span className={styles.sharingDate}>
                    {" "}
                    Partagé le{" "}
                    {moment(sharingDate, API_DATE_FORMAT)
                      .locale(lng)
                      .format("DD MMM YYYY " + _("à") + "  HH:mm")}
                  </span>
                )}
              </div>{" "}
              {showAllStatus && (
                <>
                  <span
                    className={`${styles.status} ${
                      item.status === "PUBLISHED" && styles.published
                    }`}
                  >
                    {item.status}
                  </span>
                  <span
                    className={styles.item_edit}
                    onClick={(e) => {
                      e.stopPropagation();
                      dispatch(setCurrentRubric(rubric));
                      dispatch(setCurrentQuestion(item));
                      setTimeout(() => {
                        setSidebarOpened(true);
                      }, 100);
                    }}
                  >
                    <i className="icon-ttp-edit"></i>
                  </span>
                </>
              )}
              {showFiduciaryStatus && (
                <div
                  className={`${styles.status_eye} ${
                    hiddenFaqs.Q.includes(item.id) && styles.hidden
                  } `}
                  onClick={(e) => handleHideFaq(e, "Q", item.id)}
                >
                  <i className="icon-ttp-eye"></i>
                </div>
              )}
            </span>
            <div className={classNames(styles.actions, "cell small-1")}>
              {user &&
                user.role &&
                user.role.organization.portalScope === "COLLABORATER" && (
                  <span
                    onClick={(e) => {
                      setOpenShare(!openShare);
                    }}
                    className={classNames(
                      styles.threeDots,
                      openShare ? "icon-ttp-close" : "icon-ttp-three-dots",
                      openShare ? styles.active : ""
                    )}
                  ></span>
                )}

              {openShare && (
                <div className={styles.toolTipContainer}>
                  <ShareTooltip
                    question={item}
                    close={() => setOpenShare(false)}
                  />
                </div>
              )}

              <span
                className={styles.itemTitle_icon}
                onClick={() => {
                  const title = document.getElementById(`title_${item.id}`);
                  title.classList.toggle(`${styles.activeTitle}`);
                  const content = document.getElementById(`content_${item.id}`);
                  content.classList.toggle(`${styles.activeContent}`);
                  if (openShare) {
                    setOpenShare(false);
                  }
                }}
              >
                <IconChevronDown />
              </span>
            </div>
          </div>
          <div
            className={`${styles.itemContent} ${
              index == activeQuestion && styles.activeContent
            }`}
            id={`content_${item.id}`}
            onClick={(e) => e.stopPropagation()}
          >
            {answer ? (
              <p
                dangerouslySetInnerHTML={{
                  __html: formattedAnswer(answer[contentAttr]),
                }}
              ></p>
            ) : (
              <p>(No answers exist for this question yet)</p>
            )}
            <Tags data={item} lng={lng} />
            <Attachments data={item} />
            <div className={styles.contentFooter}>
              <div className={styles.author}>
                {chainUrl && (
                  <div
                    className={styles.img}
                    style={{ backgroundImage: `url(${chainUrl})` }}
                  ></div>
                )}

                {rubric &&
                  rubric.author &&
                  rubric.author.map((aut, index) => {
                    if (!aut.enableAvatar) {
                      return null;
                    }
                    let authorName = aut.firstName + " " + aut.lastName;
                    let signatureText = "";
                    if (aut.signature) {
                      if (aut.signature.title) {
                        authorName = aut.signature.title;
                      }
                      if (aut.signature.head) {
                        signatureText = aut.signature.head;
                      }
                    }
                    return (
                      <Avatar
                        key={`author${aut.id}`}
                        avatarUrl={aut.avatarUrl}
                        firstName={aut.firstName}
                        lastName={aut.lastName}
                        avatarSignature={signatureText}
                      />
                    );
                  })}
              </div>
              {showHeader && (
                <div className={styles.info}>
                  <div className={styles.question}>
                    {/* <p>Est-ce que cela à été utile ?</p>
                <button>Oui</button>
                <button>Non</button> */}
                  </div>

                  {isCustomAnswer && auth.loggedIsAdmin ? (
                    <span
                      className={styles.suggestExist}
                      onClick={(e) => {
                        e.stopPropagation();
                        setModalOpen(true);
                      }}
                    >
                      {_("suggestion_exists")}
                    </span>
                  ) : (
                    user &&
                    (item?.suggestionDetails?.[user.id] || suggestion ? (
                      <span
                        className={styles.suggestExist}
                        onClick={(e) => {
                          e.stopPropagation();
                          setModalOpen(true);
                        }}
                      >
                        {_("suggestion_exists")}
                      </span>
                    ) : (
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          setModalOpen(true);
                        }}
                      >
                        <svg
                          width="17"
                          height="17"
                          viewBox="0 0 17 17"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M7.79175 14.1654C7.79175 13.7742 8.10888 13.457 8.50008 13.457H14.8751C15.2663 13.457 15.5834 13.7742 15.5834 14.1654C15.5834 14.5566 15.2663 14.8737 14.8751 14.8737H8.50008C8.10888 14.8737 7.79175 14.5566 7.79175 14.1654Z"
                            fill="#6D7F92"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M12.7501 2.7487C12.5394 2.7487 12.3374 2.83238 12.1885 2.98133L3.47303 11.6968L3.09861 13.1945L4.59629 12.82L13.3117 4.1046C13.3855 4.03084 13.444 3.94328 13.4839 3.84692C13.5238 3.75055 13.5444 3.64727 13.5444 3.54297C13.5444 3.43866 13.5238 3.33538 13.4839 3.23901C13.444 3.14265 13.3855 3.05509 13.3117 2.98133C13.238 2.90758 13.1504 2.84907 13.054 2.80916C12.9577 2.76924 12.8544 2.7487 12.7501 2.7487ZM11.1867 1.9796C11.6014 1.56497 12.1637 1.33203 12.7501 1.33203C13.0404 1.33203 13.3279 1.38922 13.5962 1.50033C13.8644 1.61144 14.1082 1.77429 14.3135 1.9796C14.5188 2.1849 14.6816 2.42863 14.7927 2.69688C14.9038 2.96512 14.961 3.25262 14.961 3.54297C14.961 3.83331 14.9038 4.12081 14.7927 4.38905C14.6816 4.6573 14.5188 4.90103 14.3135 5.10633L5.4593 13.9605C5.36852 14.0513 5.25477 14.1157 5.13023 14.1468L2.29689 14.8552C2.05551 14.9155 1.80017 14.8448 1.62423 14.6688C1.44829 14.4929 1.37757 14.2376 1.43791 13.9962L2.14625 11.1628C2.17738 11.0383 2.24178 10.9245 2.33256 10.8338L11.1867 1.9796Z"
                            fill="#6D7F92"
                          />
                        </svg>
                        <span>{_("suggest_answer")}</span>
                      </button>
                    ))
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        {showAllStatus && sidebarOpened && (
          <SidebarQuestion
            isOpened={sidebarOpened}
            hideSidebar={() => {
              setSidebarOpened(false);
              dispatch(setCurrentQuestion(null));
            }}
          />
        )}
        <Modal
          width="60vw"
          height="50vh"
          closable={false}
          visible={modalOpen}
          footer={null}
          onCancel={(e) => {
            e.stopPropagation();
            handleCloseModal();
          }}
          destroyOnClose={true}
        >
          <div className={styles.modal_header}>
            {item?.suggestionDetails?.[user.id]
              ? _("update_suggestion")
              : _("suggest_answer")}
          </div>
          <div
            className={styles.modal_close}
            onClick={(e) => {
              e.stopPropagation();
              handleCloseModal();
            }}
          >
            <i className="icon-ttp-close"></i>
          </div>
          <div className={styles.modal_body}>
            <FaqForm
              onCancel={() => handleCloseModal()}
              afterSave={() => setModalOpen(false)}
              currentQuestion={item}
              suggest={true}
              suggestion={suggestion}
              setSuggestion={setSuggestion}
              isCustomAnswer={isCustomAnswer}
              customAnswer={answer}
            />
          </div>
        </Modal>
      </div>
    </ClickAwayListener>
  );
};

export default Question;
