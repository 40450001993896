import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { getFAQs, getRubric, saveFaqSettings } from "../api";

const initialState = {
  fetching: false,
  fetched: false,
  deleting: false,
  items: [],
  oldItems: [],
  error: null,
  current: null,
  currentRubric: null,
  openedRubrics: [],
  showAllStatus: false,
  showFiduciaryStatus: false, // show content status for admin fiduciary
  hiddenFaqs: { T: [], P: [], R: [], Q: [] },
};

export const fetchFaqData = createAsyncThunk(
  "faq/fetch",
  async (community, { getState }) => {
    const { token } = getState().auth;
    const response = await getFAQs({
      token,
      community,
    });
    return response;
  }
);

export const fetchRubric = createAsyncThunk(
  "faq/fetchRubric",
  async ({ id, community }, { getState }) => {
    const { token } = getState().auth;
    const response = await getRubric({
      token,
      id,
      community,
    });
    return response;
  }
);

export const updateFaqPreferences = createAsyncThunk(
  "faq/updateFaqPreferences",
  async ({ hiddenFaqs, communityId }, { getState }) => {
    const { token } = getState().auth;
    const response = await saveFaqSettings({
      token,
      data: { hiddenFaqs },
      communityId,
    });
    return response;
  }
);

export const faqSlice = createSlice({
  name: "faq",
  initialState,
  reducers: {
    setSortedItems: (state, { payload }) => {
      if (state.oldItems.length === 0) {
        state.oldItems = [...state.items];
      }
      state.items = payload;
    },
    cancelSortedItems: (state) => {
      state.items = state.oldItems;
      state.oldItems = [];
    },
    setSortedRubric: (state, { payload }) => {
      if (state.oldItems.length === 0) {
        state.oldItems = [...state.items];
      }
      state.items = state.items.map((item) => {
        if (item.id === payload.id) {
          return payload;
        }
        return item;
      });
    },
    setCurrentQuestion: (state, { payload }) => {
      state.current = payload;
    },
    setCurrentRubric: (state, { payload }) => {
      state.currentRubric = payload;
      // if (payload && !state.openedRubrics.includes(payload.id)) {
      //   state.openedRubrics.push(payload.id);
      // }
    },
    addOpenedRubric: (state, { payload }) => {
      if (payload && !state.openedRubrics.includes(payload)) {
        state.openedRubrics.push(payload);
      }
    },
    closeRubric: (state, { payload }) => {
      state.openedRubrics = state.openedRubrics.filter(
        (item) => item !== payload
      );
    },
    addRubric: (state, { payload }) => {
      if (!payload.parent) {
        state.items.push({ ...payload.rubric, children: [] });
      } else {
        state.items = state.items.map((item) => {
          if (item.id === payload.parent) {
            item.children.push({
              type: "RUBRIC",
              order: 100,
              detail: payload.rubric,
            });
          }
          return item;
        });
      }
    },
    resetOpenedRubrics: (state) => {
      state.openedRubrics = [];
    },
    deleteRubric: (state, { payload }) => {
      if (!state.currentRubric.parent) {
        state.items = state.items.filter(
          (item) => item.id !== state.currentRubric.id
        );
      } else {
        state.items = state.items.map((item) => {
          if (item.children.length > 0) {
            item.children = item.children.map((child) => {
              if (
                child.type === "QUESTION" ||
                (child.type === "RUBRIC" &&
                  child.detail.id !== state.currentRubric.id)
              ) {
                return child;
              }
            });
            if (!item.children) {
              item.children = [];
            }
          }
          return item;
        });
      }
      state.currentRubric = null;
    },
    addQuestion: (state, { payload }) => {
      state.items = state.items.map((item) => {
        if (item.id === state.currentRubric.id) {
          item.children.push({
            type: "QUESTION",
            order: 100,
            detail: payload,
          });
        } else if (item.children.length > 0) {
          item.children = item.children.map((child) => {
            if (
              child.type === "RUBRIC" &&
              child.detail.id === state.currentRubric.id
            ) {
              if (!child.detail.questions) {
                child.detail.questions = [];
              }
              child.detail.questions.push(payload);
            }
            return child;
          });
        }
        return item;
      });
    },
    deleteQuestion: (state, { payload }) => {
      state.items = state.items.map((item) => {
        if (item.children.length > 0) {
          let tab = [];
          item.children.forEach((child) => {
            if (
              child.type === "QUESTION" &&
              child.detail.id !== state.current.id
            ) {
              tab.push(child);
            } else if (child.type === "RUBRIC") {
              if (child.detail.id === state.currentRubric.id) {
                child.detail.questions = child.detail.questions.filter(
                  (q) => q.id !== state.current.id
                );
              }
              tab.push(child);
            }
          });
          if (tab) {
            item.children = tab;
          }
        }
        return item;
      });

      state.current = null;
    },
    selectFirstQuestion: (state) => {
      let i = 0;
      let found = false;
      state.items.forEach((item) => {
        if (!found && item.children && item.children.length > 0) {
          if (item.children[0].type === "QUESTION") {
            state.current = item.children[0].detail;
            state.currentRubric = item;
            if (!state.openedRubrics.includes(item.id)) {
              state.openedRubrics.push(item.id);
            }
            found = true;
          } else {
            if (
              item.children[0].detail.questions &&
              item.children[0].detail.questions.length > 0
            ) {
              state.current = item.children[0].detail.questions[0];
              state.currentRubric = item.children[0];
              if (!state.openedRubrics.includes(item.children[0].id)) {
                state.openedRubrics.push(item.children[0].id);
              }
              found = true;
            }
          }
        }
        i++;
      });
    },
    setShowAllStatus: (state, { payload }) => {
      state.showAllStatus = payload;
    },
    setShowFiduciaryStatus: (state, { payload }) => {
      state.showFiduciaryStatus = payload;
    },
    setHiddenFaqs: (state, { payload }) => {
      state.hiddenFaqs = payload;
    },
  },
  extraReducers: {
    [fetchFaqData.pending]: (state, action) => {
      state.fetching = true;
    },
    [fetchFaqData.fulfilled]: (state, { payload }) => {
      state.fetching = false;
      state.items = payload.data.data;
      state.error = null;
      if (payload.data.data && payload.data.data.length > 0) {
        let i = 0;
        let found = false;
        payload.data.data.forEach((item) => {
          if (!found && item.children && item.children.length > 0) {
            if (item.children[0].type === "QUESTION") {
              state.current = item.children[0].detail;
              state.currentRubric = item;
              if (!state.openedRubrics.includes(item.id)) {
                state.openedRubrics.push(item.id);
              }
              found = true;
            } else {
              if (
                item.children[0].detail.questions &&
                item.children[0].detail.questions.length > 0
              ) {
                state.current = item.children[0].detail.questions[0];
                state.currentRubric = item.children[0].detail;
                if (!state.openedRubrics.includes(item.children[0].id)) {
                  state.openedRubrics.push(item.children[0].id);
                }
                found = true;
              }
            }
          }
          i++;
        });
      }
    },
    [fetchFaqData.rejected]: (state, { payload }) => {
      state.fetching = false;
      state.error = payload;
      state.items = [];
    },
    [fetchRubric.fulfilled]: (state, { payload }) => {
      state.error = null;
      if (payload.data.data && payload.data.data.length > 0) {
        state.currentRubric = payload.data.data[0];
      }
    },
    [fetchRubric.rejected]: (state, { payload }) => {
      state.error = payload;
      state.currentRubric = null;
    },
    [updateFaqPreferences.fulfilled]: (state, { payload }) => {
      state.error = null;
      state.hiddenFaqs = payload.data.data.hiddenFaqs;
    },
  },
});

export const {
  setSortedItems,
  cancelSortedItems,
  setSortedRubric,
  setCurrentQuestion,
  setCurrentRubric,
  closeRubric,
  addOpenedRubric,
  resetOpenedRubrics,
  selectFirstQuestion,
  addRubric,
  addQuestion,
  deleteRubric,
  deleteQuestion,
  setShowAllStatus,
  setShowFiduciaryStatus,
  setHiddenFaqs,
} = faqSlice.actions;

export default faqSlice.reducer;
