import { useState } from "react";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { ClickAwayListener } from "@mui/base";
import moment from "moment";

import { APP_ENV, TTP_EVENT_URL } from "../../../config";
import styles from "./Formation.module.scss";
import articleStyles from "../Article/Article.module.scss";
import _ from "../../../i18n";
import { IconPlus } from "../Icons/IconPlus";
import IconCheck from "../Icons/IconCheck";
import { setCurrentQuestion } from "../../../store";
import SidebarQuestion from "../../Settings/SidebarQuestion";
import { getDateLabel } from "../../../utils";
import ShareTooltip from "../Question/ShareTooltip";

const API_DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";

const Formation = ({
  formation,
  language,
  formations,
  setFormations,
  mIndex,
  showAllStatus,
  question,
  type = "TYPE1",
  edit,
  sharingDate,
}) => {
  const auth = useSelector((state) => state.auth);
  const { user } = auth;
  const headlineAttr = `headline${
    language.charAt(0).toUpperCase() + language.slice(1)
  }`;
  const [sidebarOpened, setSidebarOpened] = useState(false);
  const dispatch = useDispatch();
  const nameAttr = `name${
    language.charAt(0).toUpperCase() + language.slice(1)
  }`;
  const bannerAttr = `urlBanner${
    language.charAt(0).toUpperCase() + language.slice(1)
  }`;
  const [openShare, setOpenShare] = useState(false);
  let banner = formation[bannerAttr];
  if (!banner || banner.length === 0) {
    const lngs = ["Fr", "En", "Nl"];
    for (let i = 0; i < lngs.length; i++) {
      if (
        lngs[i].toLowerCase() !== language &&
        formation[`banner${lngs[i]}`] &&
        formation[`banner${lngs[i]}`].length > 0
      ) {
        banner = formation[`banner${lngs[i]}`];
        break;
      }
    }
  }
  let duration =
    new Date(formation.endDateTime).getTime() -
    new Date(formation.startDateTime).getTime();
  const hours = Math.floor(duration / 1000 / 60 / 60);
  duration -= hours * 1000 * 60 * 60;

  const minutes = Math.floor(duration / 1000 / 60);
  duration -= minutes * 1000 * 60;

  let processing = false;
  if (
    new Date().getTime() > new Date(formation.startDateTime).getTime() &&
    new Date().getTime() - new Date(formation.startDateTime).getTime() <
      duration
  ) {
    processing = true;
  }

  let helpDate = getDateLabel(formation.eventDate).split(",")[0];
  const sameDay = duration < 86400000;
  if (sameDay) {
    const start = getDateLabel(formation.startDateTime).split(", at");
    helpDate = `${start[0]} from ${start[1]} to ${
      getDateLabel(formation.endDateTime).split(", at")[1]
    }`;
  }

  if (type === "TYPE1") {
    return (
      <div
        className={classNames(
          styles.article,
          formations ? styles.hover_effect : "",
          formations && formations.map((el) => el.id).includes(formation.id)
            ? styles.selected
            : ""
        )}
      >
        <div
          className={styles.img}
          style={{
            backgroundImage: `url(https://s3.tamtam.pro/${APP_ENV}${banner.replace(
              "eventsFolder",
              "events-folder"
            )})`,
          }}
        ></div>

        <div className={styles.content}>
          <div className={styles.price_time}>
            <div className={styles.price}>
              {formation.nonMemberPrice
                ? formation.nonMemberPrice
                : formation.memberPrice}{" "}
              €
            </div>
            <div className={styles.time}>
              {hours > 0 ? `${hours}h ${minutes}min` : `${minutes}min`}
            </div>
          </div>
          <div className={styles.title}>
            {formation[nameAttr].length > 90
              ? formation[nameAttr].substring(0, 90) + "..."
              : formation[nameAttr]}
          </div>
          <div className={styles.speaker}>
            {formation["speakers-abstract"] &&
              formation["speakers-abstract"].speakers &&
              formation["speakers-abstract"].speakers[0] &&
              `${formation["speakers-abstract"].speakers[0].firstName} ${formation["speakers-abstract"].speakers[0].lastName}`}
          </div>
          {formation.isReplayable && formation.isReplayable === 1 && (
            <div className={styles.replay}>
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M2.23078 0.085602C2.45553 -0.0371012 2.72934 -0.0272934 2.94474 0.111176L12.7447 6.41117C12.9451 6.53998 13.0662 6.76182 13.0662 7C13.0662 7.23818 12.9451 7.46002 12.7447 7.58883L2.94474 13.8888C2.72934 14.0273 2.45553 14.0371 2.23078 13.9144C2.00603 13.7917 1.86621 13.5561 1.86621 13.3V0.700001C1.86621 0.443936 2.00603 0.208305 2.23078 0.085602ZM3.26621 1.98217V12.0178L11.0717 7L3.26621 1.98217Z"
                  fill="#29394D"
                />
              </svg>
              {_("en_replay")}
            </div>
          )}
          <span className={styles.replay_span}>
            Possible de revoir la formation pendant max 15 jours après la date
            du live
          </span>
          <div className={styles.certificate}>
            <i className="icon icon-badge" />

            <span>
              {_("ITTA_certificate")}{" "}
              <strong>{formation.clientData.abbreviation}</strong>{" "}
            </span>
          </div>
          <a
            href={`${TTP_EVENT_URL}/event/${formation.id}/reception?tool=register`}
            target="_blank"
            rel="noreferrer"
            className={styles.read_more}
          >
            {_("read_more_details")}
            <svg
              width="10"
              height="10"
              viewBox="0 0 10 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.60686 4.64308L3.1099 0.147619C2.91258 -0.0492063 2.59288 -0.0492063 2.39506 0.147619C2.19774 0.344444 2.19774 0.664139 2.39506 0.860964L6.53532 4.99973L2.39556 9.1385C2.19824 9.33532 2.19824 9.65502 2.39556 9.85234C2.59288 10.0492 2.91308 10.0492 3.1104 9.85234L7.60736 5.35688C7.80169 5.16209 7.80169 4.83737 7.60686 4.64308Z"
                fill="#6D7F92"
              />
            </svg>
          </a>
        </div>

        {formations && (
          <div
            className={styles.icon}
            onClick={() => {
              if (formations.map((el) => el.id).includes(formation.id)) {
                setFormations(
                  formations.filter((item) => item.id !== formation.id)
                );
              } else {
                setFormations([formation, ...formations]);
              }
            }}
          >
            <span className={styles.other}>
              {mIndex ? mIndex : <IconPlus size={14} />}
            </span>
            <span className={styles.check}>
              <IconCheck size={14} />
            </span>
          </div>
        )}
      </div>
    );
  }
  if (type === "TYPE2") {
    return (
      <div className={styles.eventContainer}>
        {edit && (
          <span className={styles.dragHandler}>
            <i className="icon-ttp-drag"></i>
          </span>
        )}
        <div
          className={styles.eventContent}
          style={{ paddingLeft: !edit ? "1rem" : "0rem" }}
        >
          <div
            className={styles.img}
            style={{
              backgroundImage: `url(https://s3.tamtam.pro/${APP_ENV}${banner.replace(
                "eventsFolder",
                "events-folder"
              )})`,
            }}
          ></div>
          <div className={styles.content}>
            <div className={styles.top}>
              <div className={styles.title}>
                {formation[nameAttr].length > 90
                  ? formation[nameAttr].substring(0, 90) + "..."
                  : formation[nameAttr]}
              </div>

              {user &&
                user.role &&
                user.role.organization.portalScope === "COLLABORATER" && (
                  <span
                    onClick={(e) => {
                      setOpenShare(!openShare);
                    }}
                    className={classNames(
                      styles.threeDots,
                      openShare ? "icon-ttp-close" : "icon-ttp-three-dots",
                      openShare ? styles.active : ""
                    )}
                  ></span>
                )}
              {openShare && (
                <ClickAwayListener onClickAway={() => setOpenShare(false)}>
                  <div className={styles.toolTipContainer}>
                    <ShareTooltip
                      question={question}
                      close={() => setOpenShare(false)}
                    />
                  </div>
                </ClickAwayListener>
              )}
            </div>
            {sharingDate && (
              <span className={styles.sharingDate}>
                {" "}
                Partagé le{" "}
                {moment(sharingDate, API_DATE_FORMAT)
                  .locale(language)
                  .format("DD MMM YYYY " + _("à") + "  HH:mm")}
              </span>
            )}
            {formation["speakers-abstract"] &&
              formation["speakers-abstract"].speakers.length > 0 && (
                <div className={styles.speaker}>
                  <div
                    className={styles.speaker_img}
                    style={{
                      backgroundImage: `url(https://s3.tamtam.pro/${APP_ENV}/events-folder${formation["speakers-abstract"].speakers[0].pictureUrl})`,
                    }}
                  ></div>
                  <div className={styles.info}>
                    <span
                      className={styles.name}
                    >{`${formation["speakers-abstract"].speakers[0].firstName} ${formation["speakers-abstract"].speakers[0].lastName}`}</span>

                    <span className={styles.headline}>
                      {formation["speakers-abstract"].speakers[0][headlineAttr]}
                    </span>
                  </div>
                </div>
              )}
            <ul className={styles.infos}>
              {processing ? (
                <li>
                  <i className={styles.pending} />
                  <span>{_("In progress")}</span>
                </li>
              ) : (
                <li>
                  <i className="icon icon-calendar" />
                  <span>
                    <strong>{_("En Live")}</strong> : {helpDate}
                  </span>
                </li>
              )}

              {formation.isReplayable && formation.isReplayable === 1 ? (
                <li>
                  <i className="icon icon-control-play" />

                  <span>
                    {_(
                      "Possible to review the training for max 15 days after the date of the live"
                    )}
                  </span>
                </li>
              ) : null}

              <li>
                <i className="icon icon-badge" />

                <span>
                  {_("ITTA_certificate")}{" "}
                  <strong>{formation.clientData.abbreviation}</strong>{" "}
                </span>
              </li>
            </ul>
            <div className={styles.event_price}>
              <span>
                {formation.nonMemberPrice
                  ? formation.nonMemberPrice
                  : formation.memberPrice}{" "}
                €
              </span>
              <button
                className={styles.moreDetails}
                onClick={() =>
                  window.open(
                    `${TTP_EVENT_URL}/event/${formation.id}/reception?tool=register`,
                    "_blank"
                  )
                }
              >
                {_("read_more_details")}
              </button>
            </div>
          </div>
        </div>
        {showAllStatus && (
          <div className={articleStyles.edit}>
            <span
              className={`${articleStyles.status} ${
                question.status === "PUBLISHED" && articleStyles.published
              }`}
            >
              {question.status}
            </span>
            <span
              className={articleStyles.item_edit}
              onClick={(e) => {
                e.stopPropagation();
                dispatch(
                  setCurrentQuestion({ ...question, object: formation })
                );
                setSidebarOpened(true);
              }}
            >
              <i className="icon-ttp-edit"></i>
            </span>
          </div>
        )}
        {showAllStatus && sidebarOpened && (
          <SidebarQuestion
            isOpened={sidebarOpened}
            hideSidebar={() => {
              setSidebarOpened(false);
              dispatch(setCurrentQuestion(null));
            }}
          />
        )}
      </div>
    );
  }
};

export default Formation;
