import _ from "../../i18n";
import RubricForm from "./faq/RubricForm";
import styles from "./Sidebar.module.scss";

const Sidebar = (props) => {
  const { isOpened, isNew, selectedRubric } = props;
  const closeSidebar = () => {
    props.hideSidebar();
  };

  return (
    <>
      <div
        className={`${styles.mask} ${isOpened ? "" : styles.hide}`}
        onClick={() => closeSidebar()}
      ></div>
      <div className={`${styles.sidebar} ${isOpened && styles.display}`}>
        <div onClick={() => closeSidebar()} className={styles.close}>
          <i className="icon-ttp-close"></i>
        </div>

        <div className={styles.title}>
          {selectedRubric
            ? _("rubric_edit_headline")
            : _("rubric_add_headline")}
        </div>

        <RubricForm
          isNew={isNew}
          closeSidebar={closeSidebar}
          afterSave={props.afterSave}
          isOpened={isOpened}
          // onCancel={closeSidebar}
        />
      </div>
    </>
  );
};

export default Sidebar;
