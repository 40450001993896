import { useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import Sidebar from "./Sidebar";
import styles from "./AddRubric.module.scss";

import { fetchBannerThemes, setBannerThemes } from "../../store";

import { ReactComponent as Pencil } from "../common/Icons/Pen.svg";
import { useQueryClient } from "react-query";

const AddRubric = () => {
  const { themeID, pageID } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const language = useSelector((state) => state.params.language);
  const [sidebarOpened, setSidebarOpened] = useState(false);
  const currentDomain = useSelector((state) => state.domains.currentDomain);
  const domainsList = useSelector((state) => state.domains.items);
  const urlAttr = `url${language.charAt(0).toUpperCase() + language.slice(1)}`;
  const queryClient = useQueryClient();
  const handleAfterSave = (rubric) => {
    dispatch(setBannerThemes([]));
    dispatch(
      fetchBannerThemes({
        domain: domainsList.filter((el) => el[urlAttr] === rubric.domainUrl)[0]
          .id,
        isEditable: domainsList.filter(
          (el) => el[urlAttr] === rubric.domainUrl
        )[0].isEditable,
        rubrics: true,
      })
    );
    queryClient.invalidateQueries("getPageById");
    navigate(
      `/${language}/${rubric.domainUrl}/themes/${rubric.themeId}/pages/${rubric.pageId}`
    );
  };

  const afterDelete = () => {
    dispatch(setBannerThemes([]));
    dispatch(fetchBannerThemes({ domain: currentDomain.id, rubrics: true }));
    queryClient.invalidateQueries("getPageById");
    navigate(
      `/${language}/${currentDomain[urlAttr]}/themes/${themeID}/pages/${pageID}`
    );
  };

  return (
    <>
      <div
        className={styles.trigger}
        onClick={() => {
          setSidebarOpened(true);
        }}
      >
        <Pencil />
      </div>
      <Sidebar
        isOpened={sidebarOpened}
        isNew={true}
        hideSidebar={() => {
          setSidebarOpened(false);
        }}
        afterSave={handleAfterSave}
      />
    </>
  );
};

export default AddRubric;
