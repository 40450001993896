import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { getTypes } from "../api";

export const fetchTypes = createAsyncThunk(
  "types/fetchTypes",
  async ({}, { dispatch, getState }) => {
    const { token } = getState().auth;
    const response = await getTypes({
      token,
    });
    return response;
  }
);

const initialState = {
  fetching: false,
  fetched: false,
  items: [],
  error: null,
};

export const typesSlice = createSlice({
  name: "types",
  initialState,
  extraReducers: {
    [fetchTypes.pending]: (state, action) => {
      state.fetching = true;
    },
    [fetchTypes.fulfilled]: (state, { payload }) => {
      state.fetching = false;
      state.items = payload.data.data;
    },
    [fetchTypes.rejected]: (state, { payload }) => {
      state.error = payload;
      state.fetching = false;
      state.items = [];
    },
  },
});

export default typesSlice.reducer;
