import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import classNames from "classnames";

import styles from "./ThemesLayout.module.scss";
import _ from "../../i18n";
import BlackLeftArrow from "../Icons/BlackLeftArrow";

const ThemesLayout = ({ children }) => {
  const navCommunity = useSelector((state) => state.auth.navCommunity);
  const lng = useSelector((state) => state.params.language);
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <div className={styles.layout}>
      <div className={styles.backNav}>
        <button onClick={() => navigate("/")}>
          <BlackLeftArrow />
          {_("home")}
        </button>
        <span>
          {_(
            location.pathname.includes("domains")
              ? "domains"
              : "themes_and_pages"
          )}
        </span>
      </div>
      <ul className={styles.headerTab}>
        <li
          className={classNames(
            styles.headerTab__button,
            location.pathname.includes("themes")
              ? styles.headerTab__button_active
              : ""
          )}
          onClick={() => {
            if (!location.pathname.includes("themes")) {
              navigate(`/${lng}/settings/themes`);
            }
          }}
        >
          {_("themes")}
        </li>
        {navCommunity && navCommunity.id === 9 && (
          <li
            className={classNames(
              styles.headerTab__button,
              location.pathname.includes("domains")
                ? styles.headerTab__button_active
                : ""
            )}
            onClick={() => {
              if (!location.pathname.includes("domains")) {
                navigate(`/${lng}/settings/domains`);
              }
            }}
          >
            {_("domains")}
          </li>
        )}
        <li
          className={classNames(
            styles.headerTab__button,
            location.pathname.includes("rubrics")
              ? styles.headerTab__button_active
              : ""
          )}
          onClick={() => {
            if (!location.pathname.includes("rubrics")) {
              navigate(`/${lng}/settings/rubrics`);
            }
          }}
        >
          {_("rubrics")}
        </li>
      </ul>
      <div className={styles.content}>{children}</div>
    </div>
  );
};

export default ThemesLayout;
