import { createSlice } from "@reduxjs/toolkit";

import { getDefaultLanguage } from "../utils";

const defaultLanguage = localStorage.getItem("lng") || getDefaultLanguage();
const initialState = {
  language: defaultLanguage,
  header: true,
  subMenu: true,
  backNav: true,
  currentApp: "HELP_CENTER",
  allowCreateTags: false,
};

const paramsSlice = createSlice({
  name: "params",
  initialState,
  reducers: {
    setLanguage: (state, action) => {
      state.language = action.payload;
      localStorage.setItem("lng", action.payload);
    },
    setHeader: (state, action) => {
      state.header = action.payload;
    },
    setSubMenu: (state, action) => {
      state.subMenu = action.payload;
    },
    setBackNav: (state, action) => {
      state.backNav = action.payload;
    },
    setAllowCreateTags: (state, action) => {
      state.allowCreateTags = action.payload;
    },
    setCurrentApp: (state, action) => {
      state.currentApp = action.payload;
    },
  },
});

export const {
  setLanguage,
  setHeader,
  setSubMenu,
  setAllowCreateTags,
  setBackNav,
  setCurrentApp,
} = paramsSlice.actions;

export default paramsSlice.reducer;
