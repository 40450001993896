import React from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";

import styles from "../Search.module.scss";
import Card from "./Card";
import _ from "../../../i18n";

const Pages = ({ data, setCurrentPage }) => {
  const hiddenFaqs = useSelector((state) => state.faq.hiddenFaqs);

  return (
    <div className={`${styles.cardsList} grid-x `}>
      {data.pages ? (
        data.pages.map((page) => {
          if (hiddenFaqs.P.includes(page.id)) {
            return null;
          }
          return (
            <div
              className={classNames(
                styles.cardCont,
                "cell small-12 medium-6 large-4"
              )}
            >
              <Card
                key={page.id}
                type={"page"}
                data={page}
                setCurrentPage={setCurrentPage}
              />
            </div>
          );
        })
      ) : (
        <div className={styles.no_data}>{_("theme_has_no_pages")}</div>
      )}
    </div>
  );
};

export default Pages;
