import React from "react";
import Skeleton from "react-loading-skeleton";

import styles from "./RubricCard.module.scss";

const Fetching = () => {
  return (
    <div className={styles.card} style={{ width: "309px" }}>
      <div className={styles.title} style={{ margin: "auto" }}>
        <Skeleton height={64} width={250} style={{ marginBottom: "20px" }} />
      </div>
      <ul className={styles.rubricContent} style={{ margin: "auto" }}>
        <Skeleton height={20} width={250} style={{ marginBottom: "15px" }} />
        <Skeleton height={30} width={250} style={{ marginBottom: "15px" }} />
        <Skeleton height={20} width={250} style={{ marginBottom: "15px" }} />
        <Skeleton height={30} width={250} style={{ marginBottom: "15px" }} />
      </ul>
      <div style={{ margin: "auto" }}>
        <Skeleton height={20} width={250} />
      </div>
      <div className={styles.authors}>
        <div className={styles.image}>
          <Skeleton height={34} width={58} />
        </div>

        <div className={styles.users}>
          <div className={styles.avatar} style={{ border: "none" }}>
            <Skeleton height={34} width={34} />
          </div>
          <div className={styles.avatar} style={{ border: "none" }}>
            <Skeleton height={34} width={34} />
          </div>
          <div className={styles.avatar} style={{ border: "none" }}>
            <Skeleton height={34} width={34} />
          </div>
          <div className={styles.avatar} style={{ border: "none" }}>
            <Skeleton height={34} width={34} />
          </div>
          <div className={styles.avatar} style={{ border: "none" }}>
            <Skeleton height={34} width={34} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Fetching;
