import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import { getDomainsList } from "../../api";

export default function DomainsSelect({
  selectedDomains,
  organizationId,
  onChange,
  lng,
  isMulti,
}) {
  const token = useSelector((state) => state.auth.token);
  const titleAttr = `title${lng.charAt(0).toUpperCase() + lng.slice(1)}`;
  const [loading, setLoading] = useState(false);
  const [domains, setDomains] = useState({
    fr: null,
    en: null,
    nl: null,
  });

  const fetchDomains = (inputValue) => {
    let customFilter = [];

    if (null !== inputValue) {
      customFilter = [
        {
          property: `title${lng.charAt(0).toUpperCase() + lng.slice(1)}`,
          value: inputValue,
          operator: "like",
        },
      ];
    }

    setLoading(true);

    getDomainsList({
      token,
      community: organizationId,
      customFilter,
    }).then((result) => {
      const aFr = [];
      const aEn = [];
      const aNl = [];

      result.data.data.map((item) => {
        aFr.push({
          id: item.id,
          titleFr: item[`titleFr`],
        });
        aEn.push({
          id: item.id,
          titleEn: item[`titleEn`],
        });
        aNl.push({
          id: item.id,
          titleNl: item[`titleNl`],
        });
      });
      if (!inputValue && organizationId === 9) {
        aFr.unshift({
          id: "All",
          titleFr: "Tous",
        });
        aEn.unshift({
          id: "All",
          titleEn: "All",
        });
        aNl.unshift({
          id: "All",
          titleNl: "Alle",
        });
      }
      setDomains({ fr: aFr, en: aEn, nl: aNl });
      setLoading(false);
      return result.data.data;
    });
  };
  useEffect(() => {
    if (!domains.fr) {
      fetchDomains(null);
    }
  });

  return (
    <div style={{ marginBottom: "20px" }}>
      <Select
        isLoading={loading}
        isMulti={isMulti}
        options={domains[lng]}
        value={selectedDomains}
        getOptionLabel={(options) => options[titleAttr]}
        getOptionValue={(options) => options["id"]}
        onChange={(e) => {
          let all = false;
          e.map((val) => {
            if (val.id === "All") {
              all = true;
            }
          });
          if (all) {
            onChange(domains[lng].filter((item) => item.id !== "All"));
          } else onChange(e);
        }}
        classNamePrefix="custom-select"
      />
    </div>
  );
}
