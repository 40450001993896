import React from "react";

const IconCheck = ({ size = 12 }) => (
  <svg width={size} height={size} viewBox="0 0 12 12" fill="none">
    <g clipPath="url(#clip0)">
      <path
        d="M4.53854 11.9999C4.16551 11.9999 3.81271 11.8254 3.58591 11.5261L0.522827 7.48089C0.124427 6.95477 0.228142 6.20563 0.754256 5.80706C1.28123 5.40832 2.02986 5.51272 2.42843 6.03849L4.44306 8.69872L9.50997 0.563059C9.85883 0.00334478 10.5955 -0.167912 11.1564 0.180431C11.7166 0.529116 11.8877 1.26626 11.5387 1.82649L5.55306 11.4368C5.34511 11.7709 4.98614 11.9811 4.59306 11.9989C4.57471 11.9996 4.55671 11.9999 4.53854 11.9999Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="12" height="12" fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
);

export default IconCheck;
