import { useSelector, useDispatch } from "react-redux";

import styles from "./AddRubric.module.scss";

import { setShowAllStatus } from "../../store";

const StatusFilter = () => {
  const dispatch = useDispatch();
  const showAllStatus = useSelector((state) => state.faq.showAllStatus);

  return (
    <div
      className={`${styles.status_trigger} ${showAllStatus && styles.active} `}
      onClick={() => dispatch(setShowAllStatus(!showAllStatus))}
    >
      <i className="icon-ttp-eye"></i>
    </div>
  );
};

export default StatusFilter;
