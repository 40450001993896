import React, { useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";

import styles from "./Themes.module.scss";
import ThemesLayout from "../ThemesLayout";
import _ from "../../i18n";
import { getThemesList } from "../../api";
import ThemeItem from "./ThemeItem";
import Sidebar from "./Sidebar";

const Themes = () => {
  const language = useSelector((state) => state.params.language);
  const auth = useSelector((state) => state.auth);
  const { token, navCommunity } = auth;
  const [sidebarOpened, setSidebarOpened] = useState(false);
  const [selectedTheme, setSelectedTheme] = useState(null);
  const [themes, setThemes] = useState([]);
  const handleShowSidebar = (theme) => {
    setSelectedTheme(theme);
    setSidebarOpened(true);
  };

  const { isFetching } = useQuery(
    ["fetchThemes", token, navCommunity],
    async () => {
      if (token) {
        try {
          const res = await getThemesList({
            token,
            community: navCommunity.id,
          });
          setThemes(res.data.data);
        } catch (error) {
          console.error(error);
        }
      }
    }
  );

  const afterPageSave = ({ val, themeId, isEdit }) => {
    setThemes(
      themes.map((theme) => {
        if (theme.id === themeId) {
          if (isEdit) {
            return {
              ...theme,
              pages: theme.pages.map((page) => {
                if (page.id === val.id) {
                  return val;
                }
                return page;
              }),
            };
          } else {
            return {
              ...theme,
              pages: [...theme.pages, val],
            };
          }
        }
        return theme;
      })
    );
  };
  const afterThemeSave = ({ val, isEdit }) => {
    console.log(val, isEdit);
    if (isEdit) {
      setThemes(
        themes.map((theme) => {
          if (theme.id === val.id) {
            return val;
          }
          return theme;
        })
      );
    } else {
      setThemes([...themes, val]);
    }
  };

  const afterPageDelete = ({ id, themeId }) => {
    setThemes(
      themes.map((theme) => {
        if (theme.id === themeId) {
          return {
            ...theme,
            pages: theme.pages.filter((page) => page.id !== id),
          };
        }
        return theme;
      })
    );
  };

  const afterThemeDelete = ({ id }) => {
    setThemes(themes.filter((el) => el.id !== id));
  };
  return (
    <ThemesLayout>
      <>
        <div className={styles.container}>
          <h1 className={styles.title}>{_("themes_and_pages_list")}</h1>
          <h2 className={styles.subtitle}>
            {_("themes_and_pages_list_subtitle")}
          </h2>
          {isFetching
            ? [...Array(4).keys()].map((i) => (
                <div key={`themefetch-${i}`} className={styles.fetchItem}>
                  <Skeleton height={44} />
                </div>
              ))
            : themes.map((theme) => (
                <ThemeItem
                  key={`theme-${theme.id}`}
                  theme={theme}
                  showSidebar={() => handleShowSidebar(theme)}
                  afterPageSave={afterPageSave}
                  afterPageDelete={afterPageDelete}
                  afterThemeDelete={afterThemeDelete}
                />
              ))}
        </div>
        <div className={styles.bubbles}>
          <div
            onClick={() => {
              setSelectedTheme(null);
              setSidebarOpened(true);
            }}
          >
            <i className="icon-ttp-plus-outline"></i>
            <span style={{ paddingLeft: "1rem" }}>{_("add")}</span>
          </div>
        </div>

        <Sidebar
          isOpened={sidebarOpened}
          hideSidebar={() => {
            setSidebarOpened(false);
            setSelectedTheme(null);
          }}
          selectedTheme={selectedTheme}
          language={language}
          afterThemeSave={afterThemeSave}
        />
      </>
    </ThemesLayout>
  );
};

export default Themes;
