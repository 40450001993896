import React, { useState } from "react";
import classNames from "classnames";
import { Modal } from "antd";
import YouTube from "react-youtube";
import { useDispatch, useSelector } from "react-redux";
import { ClickAwayListener } from "@mui/base";

import { TTP_API_URL } from "../../../config";
import styles from "./Media.module.scss";
import _ from "../../../i18n";
import { IconPlus } from "../Icons/IconPlus";
import IconCheck from "../Icons/IconCheck";
import { setCurrentQuestion } from "../../../store";
import SidebarQuestion from "../../Settings/SidebarQuestion";
import ShareTooltip from "../Question/ShareTooltip";
import moment from "moment";

const API_DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";

const Media = ({
  media,
  language,
  setMedias,
  medias,
  mIndex,
  size,
  showAllStatus,
  question,
  type = "TYPE1",
  edit,
  sharingDate,
}) => {
  const auth = useSelector((state) => state.auth);
  const { user } = auth;
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const [sidebarOpened, setSidebarOpened] = useState(false);
  const [openShare, setOpenShare] = useState(false);
  const titleAttr = `title${
    language.charAt(0).toUpperCase() + language.slice(1)
  }`;
  const getPDFPathFromPPT = (media) => {
    const path = (
      media.fullMediaUrl
        ? media.fullMediaUrl
        : TTP_API_URL + "/" + media.webPath
    ).substring(
      0,
      media[media.fullMediaUrl ? "fullMediaUrl" : "webPath"].lastIndexOf("/")
    );

    return `${path}/PDF/${media.id}.pdf`;
  };

  const path = () => {
    if (media.youtube_id) {
      return `https://i.ytimg.com/vi/${media.youtube_id}/maxresdefault.jpg`;
    }
    if (media.docType === "IMAGE") {
      return media.fullMediaUrl
        ? media.fullMediaUrl
        : TTP_API_URL + "/" + media.webPath;
    } else if (media.docType === "VIDEO" && media.preview) {
      return media.preview.fullMediaUrl
        ? media.preview.fullMediaUrl
        : TTP_API_URL + "/" + media.preview.webPath;
    }
    return "/img/no-image.png";
  };

  if (type === "TYPE1") {
    return (
      <div
        className={classNames(
          styles.media,
          media.checked ? styles.checked : ""
        )}
        style={{
          height: size === "lg" ? "302px" : size === "md" ? "202px" : "7.5rem",
        }}
      >
        {(media.docType === "IMAGE" ||
          media.docType === "VIDEO" ||
          media.youtube_id) && (
          <div
            className={styles.mediaContent}
            style={{
              backgroundImage: `url(${path()})`,
            }}
          ></div>
        )}
        <div
          className={styles.overlay}
          style={{ cursor: size ? "default" : "pointer" }}
        >
          {size && (
            <p
              className={styles.preview}
              style={{ top: size === "md" ? "60%" : "75%", right: "10%" }}
              onClick={() => {
                if (media.youtube_url) {
                  window.open(media.youtube_url, "_blank");
                } else setModalOpen(true);
              }}
            >
              {" "}
              {_("afficher")}{" "}
            </p>
          )}
          <div className={styles.icon}>
            {media.docType === "VIDEO" && (
              <img src={`/img/play.png`} alt="play" />
            )}

            {(media.docType === "PDF" || media.docType === "PPT") && (
              <img
                className={styles.docImg}
                src={`/img/${media.docType.toLowerCase()}.png`}
                alt=""
              />
            )}

            {media.youtube_url && (
              <img
                className={styles.docImg}
                style={{ width: "70px" }}
                src={`/img/youtube.png`}
                alt=""
              />
            )}
          </div>
          <div className={styles.infos}>
            <h3>{media[titleAttr]}</h3>
          </div>

          {medias && (
            <div
              className={classNames(
                styles.blue_overlay,
                medias.map((el) => el.id).includes(media.id)
                  ? styles.selected
                  : ""
              )}
            >
              <div className={styles.container}>
                <p
                  className={styles.preview}
                  onClick={() => {
                    if (media.youtube_url) {
                      window.open(media.youtube_url, "_blank");
                    } else setModalOpen(true);
                  }}
                >
                  {" "}
                  {_("afficher")}{" "}
                </p>
                <div
                  className={styles.icon}
                  onClick={() => {
                    if (media.id) {
                      if (medias.map((el) => el.id).includes(media.id)) {
                        setMedias(
                          medias.filter((item) => item.id !== media.id)
                        );
                      } else {
                        setMedias([media, ...medias]);
                      }
                    }
                    if (media.youtube_id) {
                      setMedias(
                        medias.filter(
                          (item) => item.youtube_id !== media.youtube_id
                        )
                      );
                    }
                  }}
                >
                  <span className={styles.other}>
                    {mIndex ? mIndex : <IconPlus size={14} />}
                  </span>
                  <span className={styles.check}>
                    <IconCheck size={14} />
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>

        <Modal
          width="60vw"
          closable={false}
          visible={modalOpen}
          footer={null}
          onCancel={() => {
            setModalOpen(false);
          }}
          bodyStyle={{ height: "90vh" }}
          style={{ top: "7vh" }}
          destroyOnClose={true}
        >
          <div
            className={styles.modal_close}
            onClick={() => {
              setModalOpen(false);
            }}
          >
            <i className="icon-ttp-close"></i>
          </div>

          <div className={styles.modal_body}>
            {(media.docType === "IMAGE" || media.youtube_id) && (
              <img src={path()} alt="" />
            )}
            {media.docType === "VIDEO" && (
              <video
                id="vid"
                src={
                  media.fullMediaUrl
                    ? media.fullMediaUrl
                    : TTP_API_URL + "/" + media.webPath
                }
                controls
                autoPlay
                style={{
                  minWidth: "500px",
                }}
              />
            )}
            {(media.docType === "PDF" || media.docType === "PPT") && (
              <iframe
                style={{
                  width: "100%",
                  height: "100%",
                  border: "none",
                }}
                title={"title"}
                src={
                  media.docType === "PPT"
                    ? getPDFPathFromPPT(media)
                    : media.fullMediaUrl
                    ? media.fullMediaUrl
                    : TTP_API_URL + "/" + media.webPath
                }
              ></iframe>
            )}
          </div>
        </Modal>
      </div>
    );
  }
  if (type === "TYPE2") {
    return (
      <div
        className={styles.mediaContainer}
        style={{ height: media.youtube_id ? "31.5rem" : "17.125rem" }}
      >
        {edit && (
          <span className={styles.dragHandler}>
            <i className="icon-ttp-drag"></i>
          </span>
        )}
        {media.docType === "IMAGE" && (
          <div
            className={styles.mediaContent}
            style={{
              backgroundImage: `url(${path()})`,
            }}
          ></div>
        )}

        {media.docType === "VIDEO" && (
          <video
            id="vid"
            src={
              media.fullMediaUrl
                ? media.fullMediaUrl
                : TTP_API_URL + "/" + media.webPath
            }
            controls
            className={styles.mediaContent}
          />
        )}

        {media.youtube_id && (
          <YouTube
            className={styles.youtubeContent}
            videoId={media.youtube_id}
          />
        )}

        {(media.docType === "PDF" || media.docType === "PPT") && (
          <div
            className={classNames(
              styles.docContainer,
              media.docType === "PPT" ? styles.docContainerPPT : ""
            )}
            style={{ paddingLeft: !edit ? "1rem" : "0rem" }}
          >
            <div
              className={styles.docPreview}
              style={{
                backgroundImage: `url(${
                  media.preview
                    ? media.preview.fullMediaUrl
                      ? media.preview.fullMediaUrl
                      : TTP_API_URL + "/" + media.preview.webPath
                    : null
                })`,
              }}
            >
              {!media.preview && (
                <img
                  className={styles.docImg}
                  src={`/img/${media.docType.toLowerCase()}.png`}
                  alt=""
                />
              )}{" "}
            </div>
            <div className={styles.docInfo}>
              <p className={styles.title}>{media[titleAttr]}</p>
              {sharingDate && (
                <span className={styles.sharingDate}>
                  {" "}
                  Partagé le{" "}
                  {moment(sharingDate, API_DATE_FORMAT)
                    .locale(language)
                    .format("DD MMM YYYY " + _("à") + "  HH:mm")}
                </span>
              )}
              <div className={styles.meta}>
                <div>
                  <span>Document : {media.docType}</span>
                  {media.meta.size > 0 && (
                    <span>Taille : {media.meta.size}</span>
                  )}
                </div>

                <a
                  href={
                    media.fullMediaUrl
                      ? media.fullMediaUrl
                      : TTP_API_URL + "/" + media.webPath
                  }
                  className={styles.download}
                  download
                >
                  <svg
                    width="15"
                    height="14"
                    viewBox="0 0 15 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M2.11751 8.16663C2.43968 8.16663 2.70085 8.42779 2.70085 8.74996V11.0833C2.70085 11.238 2.7623 11.3864 2.8717 11.4958C2.9811 11.6052 3.12947 11.6666 3.28418 11.6666H11.4508C11.6056 11.6666 11.7539 11.6052 11.8633 11.4958C11.9727 11.3864 12.0342 11.238 12.0342 11.0833V8.74996C12.0342 8.42779 12.2953 8.16663 12.6175 8.16663C12.9397 8.16663 13.2008 8.42779 13.2008 8.74996V11.0833C13.2008 11.5474 13.0165 11.9925 12.6883 12.3207C12.3601 12.6489 11.915 12.8333 11.4508 12.8333H3.28418C2.82005 12.8333 2.37493 12.6489 2.04674 12.3207C1.71855 11.9925 1.53418 11.5474 1.53418 11.0833V8.74996C1.53418 8.42779 1.79535 8.16663 2.11751 8.16663Z"
                      fill="#6D7F92"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M4.03853 5.42085C4.26634 5.19305 4.63568 5.19305 4.86349 5.42085L7.36768 7.92504L9.87186 5.42085C10.0997 5.19305 10.469 5.19305 10.6968 5.42085C10.9246 5.64866 10.9246 6.01801 10.6968 6.24581L7.78015 9.16248C7.55235 9.39029 7.183 9.39029 6.9552 9.16248L4.03853 6.24581C3.81072 6.01801 3.81072 5.64866 4.03853 5.42085Z"
                      fill="#6D7F92"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M7.36751 1.16675C7.68968 1.16675 7.95085 1.42792 7.95085 1.75008V8.75008C7.95085 9.07225 7.68968 9.33341 7.36751 9.33341C7.04535 9.33341 6.78418 9.07225 6.78418 8.75008V1.75008C6.78418 1.42792 7.04535 1.16675 7.36751 1.16675Z"
                      fill="#6D7F92"
                    />
                  </svg>
                  {_("download")}
                </a>
              </div>
            </div>
          </div>
        )}
        {user &&
          user.role &&
          user.role.organization.portalScope === "COLLABORATER" && (
            <span
              onClick={(e) => {
                setOpenShare(!openShare);
              }}
              className={classNames(
                styles.threeDots,
                openShare ? "icon-ttp-close" : "icon-ttp-three-dots",
                openShare ? styles.active : ""
              )}
            ></span>
          )}
        {openShare && (
          <ClickAwayListener onClickAway={() => setOpenShare(false)}>
            <div
              className={classNames(
                styles.toolTipContainer,
                media.youtube_id ? styles.toolTipContainer_youtube : ""
              )}
            >
              <ShareTooltip
                question={question}
                close={() => setOpenShare(false)}
              />
            </div>
          </ClickAwayListener>
        )}
        {showAllStatus && (
          <div className={styles.edit}>
            <span
              className={`${styles.status} ${
                question.status === "PUBLISHED" && styles.published
              }`}
            >
              {question.status}
            </span>
            <span
              className={styles.item_edit}
              onClick={(e) => {
                e.stopPropagation();
                dispatch(setCurrentQuestion({ ...question, object: media }));
                setSidebarOpened(true);
              }}
            >
              <i className="icon-ttp-edit"></i>
            </span>
          </div>
        )}
        {showAllStatus && sidebarOpened && (
          <SidebarQuestion
            isOpened={sidebarOpened}
            hideSidebar={() => {
              setSidebarOpened(false);
              dispatch(setCurrentQuestion(null));
            }}
          />
        )}
      </div>
    );
  }
};

export default Media;
