import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { arrayMoveImmutable } from "array-move";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { ModalConfirm } from "tamtam-components";
import { toast } from "react-toastify";

import mediaStyles from "./MediaForm.module.scss";
import styles from "./FormationForm.module.scss";
import _ from "../../../i18n";
import IconSearch from "../../common/Icons/IconSearch";
import Loader from "../../common/Loader";
import { getEvents, removeQuestion, saveQuestion } from "../../../api";
import Formation from "../../common/Formation";
import Button from "../../common/Button";
import { convertDateToUTC } from "../../../utils";
import { deleteQuestion } from "../../../store";
import { IconTrash } from "../../common/Icons";

const DATE_FORMAT = "DD-MM-YYYY HH:mm";
const API_DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";

const FormationForm = ({
  auth,
  onCancel,
  afterSave,
  status,
  publishedAt,
  isPrivate,
  privateGroups,
  currentRubric,
  currentQuestion,
}) => {
  const language = useSelector((state) => state.params.language);

  const [search, setSearch] = useState("");
  const [selectedFormations, setSelectedFormations] = useState([]);

  const [start, setStart] = useState(0);
  const [formations, setFormations] = useState([]);
  const [loadingFormations, setLoadingFormations] = useState(false);
  const [scrollEnd, setScrollEnd] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const dispatch = useDispatch();
  const { navCommunity, token } = auth;
  const sortFormations = ({ oldIndex, newIndex }) => {
    setSelectedFormations(
      arrayMoveImmutable(selectedFormations, oldIndex, newIndex)
    );
  };

  useEffect(() => {
    if (scrollEnd) {
      setStart(start + 10);
    }
  }, [scrollEnd]);

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }
  };

  let { isLoading } = useQuery(
    ["listFormations", navCommunity, token, search, start],
    async () => {
      if (token) {
        try {
          setLoadingFormations(true);
          const response = await getEvents({
            token,
            communityId: navCommunity.id,
            language,
            search,
            start,
          });
          setLoadingFormations(false);
          if (start > 0 && formations.length > 0) {
            setFormations([...formations, ...response.data.data]);
          } else {
            setFormations(response.data.data);
          }
        } catch (error) {
          if (
            error.response &&
            error.response.data &&
            error.response.data.statusCode === 404
          )
            setLoadingFormations(false);
        }
      }
    }
  );

  const handleSave = async () => {
    let tabPrivateGroups = privateGroups.map((item) => {
      return item.id;
    });

    let data = {
      community: navCommunity ? navCommunity.id : 9,
      status: status.value,
      rubric: currentRubric && currentRubric.id,
      isPrivate,
      privateGroups: tabPrivateGroups,
      publishedAt: publishedAt
        ? convertDateToUTC(publishedAt, DATE_FORMAT, API_DATE_FORMAT)
        : "",
      objectType: "EVENT",
      objectIds: selectedFormations.map((item) => item.id),
    };

    if (currentQuestion) {
      data.id = currentQuestion.id;
    }

    setIsSaving(true);
    try {
      await saveQuestion(token, data);
      setIsSaving(false);
      afterSave();
    } catch (e) {
      setIsSaving(false);
    }
  };

  const handleDelete = () => {
    setIsDeleting(true);
    removeQuestion(token, currentQuestion.id)
      .then(() => {
        dispatch(deleteQuestion(currentQuestion.id));
        setIsDeleting(false);
        afterSave();
        setIsOpenDeleteModal(false);
        onCancel();
      })
      .catch((err) => {
        setIsDeleting(false);
        setIsOpenDeleteModal(false);
        toast.error(_("rubric_delete_error"));
      });
  };

  return (
    <div className={mediaStyles.content}>
      {currentQuestion ? (
        <div style={{ height: "13.5rem", position: "relative", zIndex: "-1" }}>
          <Formation formation={currentQuestion.object} language={language} />
        </div>
      ) : (
        <>
          {" "}
          {selectedFormations.length > 0 && (
            <>
              <div className={mediaStyles.section_title}>
                {_("selected_formations")}
              </div>
              <div className={mediaStyles.selected_media_container}>
                {selectedFormations.map((item) => (
                  <Formation
                    formation={item}
                    setFormations={setSelectedFormations}
                    formations={selectedFormations}
                    language={language}
                  />
                ))}
              </div>
              <div className={mediaStyles.section_title}>
                {_("disponible_formations")}
              </div>
            </>
          )}
          <div
            className={classNames(
              mediaStyles.header,
              mediaStyles.header_margin
            )}
          >
            <div className={classNames(styles.search, mediaStyles.search)}>
              <IconSearch />
              <input
                type="text"
                placeholder={_("search_placeholder")}
                value={search}
                onChange={(e) => {
                  setFormations([]);
                  setStart(0);
                  setSearch(e.target.value);
                }}
              />
            </div>
          </div>
          <div
            className={mediaStyles.listContainer}
            onScroll={(e) => {
              if (
                e.target.offsetHeight + e.target.scrollTop + 0.5 >=
                e.target.scrollHeight
              ) {
                setScrollEnd(true);
              } else {
                setScrollEnd(false);
              }
            }}
          >
            {loadingFormations && start === 0 ? (
              <Loader
                style={{
                  width: "100%",
                  height: "1rem",
                  margin: "auto",
                }}
                color={"#18a0fb"}
              />
            ) : (
              formations && (
                <div className={styles.media_list}>
                  {formations
                    .filter(
                      (item) =>
                        !currentRubric.questions
                          .filter((el) => el.objectType === "EVENT")
                          .map((el) => el.objectId)
                          .includes(item.id)
                    )
                    .filter(
                      (item) =>
                        !selectedFormations.map((el) => el.id).includes(item.id)
                    )
                    .map((item) => (
                      <Formation
                        formation={item}
                        setFormations={setSelectedFormations}
                        formations={selectedFormations}
                        language={language}
                      />
                    ))}
                </div>
              )
            )}
            {loadingFormations && start > 0 && (
              <Loader
                style={{
                  width: "100%",
                  height: "1rem",
                  margin: "auto",
                }}
                color={"#18a0fb"}
              />
            )}
          </div>
        </>
      )}
      <div className={mediaStyles.formControls}>
        <Button onClick={() => handleCancel()} variant="default">
          {_("cancel")}
        </Button>

        <div className={mediaStyles.actions}>
          {currentQuestion && (
            <span onClick={() => setIsOpenDeleteModal(true)}>
              <IconTrash size={18} />
            </span>
          )}
          {isSaving ? (
            <Button
              variant="primary"
              style={{ paddingTop: "15px", paddingBottom: "15px" }}
              className={styles.okBtn}
            >
              <Loader
                style={{
                  height: "10px",
                }}
                color={"#fff"}
              />
            </Button>
          ) : (
            <Button
              onClick={() => handleSave()}
              variant="primary"
              className={mediaStyles.okBtn}
              disabled={selectedFormations.length === 0 && !currentQuestion}
            >
              {_("save")}
            </Button>
          )}
        </div>
      </div>
      <ModalConfirm
        type="delete"
        isOpen={isOpenDeleteModal}
        inProcess={isDeleting}
        onCancel={() => setIsOpenDeleteModal(false)}
        onConfirm={handleDelete}
        title={_("modal_delete_title")}
        text={_("modal_delete_text")}
        labelNo={_("no")}
        labelYes={_("yes")}
      />
    </div>
  );
};

export default FormationForm;
