import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { ModalConfirm } from "tamtam-components";

import { deleteQuestion } from "../../../store";
import { removeQuestion } from "../../../api";
import { IconPencil2, IconTrash } from "../../common/Icons";
import _ from "../../../i18n";
import styles from "../FaqTree.module.scss";

export default function FaqAnswers(props) {
  const currentQuestion = useSelector((state) => state.faq.current);
  const language = useSelector((state) => state.params.language);
  const token = useSelector((state) => state.auth.token);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);

  const dispatch = useDispatch();
  const titleAttr = `title${
    language.charAt(0).toUpperCase() + language.slice(1)
  }`;
  const contentAttr = `content${
    language.charAt(0).toUpperCase() + language.slice(1)
  }`;

  if (!currentQuestion || !currentQuestion.answers) {
    return null;
  }

  const handleDelete = () => {
    setIsDeleting(true);
    removeQuestion(token, currentQuestion.id)
      .then(() => {
        dispatch(deleteQuestion(currentQuestion.id));
        setIsDeleting(false);
        setIsOpenDeleteModal(false);
        props.onCancel();
      })
      .catch((err) => {
        setIsDeleting(false);
        setIsOpenDeleteModal(false);
        toast.error(_("rubric_delete_error"));
      });
  };

  return (
    <div>
      <h3 className={styles.questionTitle}>
        <span className={styles.questionTitle_left}>
          {currentQuestion[titleAttr]}
        </span>

        <div className={styles.addRubricFaq}>
          <span
            className={styles.addRubricFaq_icon}
            onClick={() => {
              props.changeView("FAQ_FORM");
            }}
          >
            <IconPencil2 />
          </span>
          {currentQuestion && (
            <span
              className={styles.addRubricFaq_icon}
              onClick={() => setIsOpenDeleteModal(true)}
            >
              <IconTrash size={12} />
            </span>
          )}
        </div>
      </h3>
      <div className="sun-editor-editable">
        {currentQuestion.answers.map((item, index) => (
          <p
            key={`answer${index}`}
            dangerouslySetInnerHTML={{
              __html: item[contentAttr],
            }}
          ></p>
        ))}
      </div>
      <div className={styles.helpFul}>
        {_("helpful")} <span className={styles.helpFul_btn}>{_("yes")}</span>{" "}
        <span className={styles.helpFul_btn}>{_("no")}</span>
      </div>
      <ModalConfirm
        type="delete"
        isOpen={isOpenDeleteModal}
        inProcess={isDeleting}
        onCancel={() => setIsOpenDeleteModal(false)}
        onConfirm={handleDelete}
        title={_("modal_delete_title")}
        text={_("modal_delete_text")}
        labelNo={_("no")}
        labelYes={_("yes")}
      />
    </div>
  );
}
