import React, { PureComponent } from "react";

import { addLandaSize } from "../../../utils";
import _ from "../../../i18n";
import { IconClose } from "../../common/Icons";

import styles from "./Chain.module.scss";

export default class Chain extends PureComponent {
  constructor(props) {
    super(props);

    this.handleDeleteAuthor = this.handleDeleteAuthor.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.language !== this.props.language) {
      const { author, language, onChange } = this.props;
      if (author && author.signature) {
        const { title, head } = author.signature;
        const newHead = this.getAuthorHeadline(author, language, head);

        onChange({ ...author, signature: { head: newHead, title } });
      }
    }
  }

  handleDeleteAuthor(e) {
    e.stopPropagation();
    const { author, onDelete } = this.props;
    onDelete(author);
  }

  renderAvatar() {
    const { author } = this.props;
    const { enableAvatar, avatarUrl } = author;

    if (!avatarUrl) {
      return (
        <div
          className={`${styles.sidebarAvatar} ${styles.emptyAvatar}`}
          style={{ backgroundImage: `url("/img/user-avatar.svg")` }}
        >
          <div
            className={styles.avatarOverlay}
            style={
              enableAvatar === true && enableAvatar !== "D"
                ? { display: "none" }
                : { display: "block" }
            }
          ></div>
        </div>
      );
    }

    return (
      <div
        className={styles.sidebarAvatar}
        style={{ backgroundImage: `url(${addLandaSize(avatarUrl, 240)})` }}
      >
        <div
          style={
            enableAvatar === true && enableAvatar !== "D"
              ? { display: "none" }
              : { display: "block" }
          }
        ></div>
      </div>
    );
  }

  getAuthorHeadline(author, language, currentHead) {
    const { headlines } = author;

    if (headlines && headlines[language]) {
      return headlines[language];
    } else {
      return currentHead;
    }
  }

  render() {
    const { author } = this.props;

    let title = author.company;

    return (
      <div className={styles.sidebarAuthor}>
        <div className={styles.authorRemove} onClick={this.handleDeleteAuthor}>
          <IconClose size={16} />
        </div>

        {this.renderAvatar()}

        <div className={`${styles.sidebarUsername} ${styles.editableInput}`}>
          <input
            type="text"
            readOnly={true}
            value={title}
            placeholder={_("author_title")}
          />
        </div>
      </div>
    );
  }
}
