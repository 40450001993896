import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { components } from "react-select";
import AsyncCreatableSelect from "react-select/async-creatable";
import { Modal as AntModal } from "antd";
import { toast } from "react-toastify";

import { APP_ENV } from "../../../config";
import { getTagName } from "../../../utils";
import { getTags, saveTag } from "../../../api";
import Button from "../../common/Button";
import Loader from "../../common/Loader";
import { IconClose } from "../../common/Icons";
import _ from "../../../i18n";
import styles from "./FAQ.module.scss";

export default function Tag(props) {
  const { language, displayedLng, setTags, tags } = props;

  const allowCreateTags = useSelector((state) => state.params.allowCreateTags);
  const token = useSelector((state) => state.auth.token);
  const [loadingTags, setLoadingTags] = useState(false);

  const [openTagModal, setOpenTagModal] = useState(false);
  const [editTag, setEditTag] = useState(null);
  const [savingTag, setSavingTag] = useState(false);

  const lng = language;
  const nameAttr = `name${lng.charAt(0).toUpperCase() + lng.slice(1)}`;
  const displayedNameAttr = `name${
    displayedLng.charAt(0).toUpperCase() + displayedLng.slice(1)
  }`;

  useEffect(() => {
    let tab = tags.map((tag) => {
      if (tag.tag[displayedNameAttr]) {
        tag.label = tag.tag[displayedNameAttr];
      }

      tag.color =
        tag.tag && !tag.tag[displayedNameAttr] && !tag["__isNew__"]
          ? "#fed493"
          : "#F1F2F4";
      return tag;
    });
    setTags(tab);
  }, [displayedLng]);

  const fetchTTags = (inputValue) => {
    let customFilter = [];
    const lngs = ["en", "fr", "nl"].filter((itm) => itm !== lng);

    if (null !== inputValue) {
      customFilter = [
        {
          property: nameAttr,
          value: inputValue,
          operator: "like",
        },
      ];

      if (lngs) {
        lngs.forEach((itm) => {
          let lngNameAttr = `name${itm.charAt(0).toUpperCase() + itm.slice(1)}`;
          customFilter.push({
            property: lngNameAttr,
            value: inputValue,
            operator: "eq",
            logicalOperator: "OR",
          });
        });
      }
    }

    setLoadingTags(true);
    return getTags({
      token,
      lng,
      customFilter,
    })
      .then((result) => {
        const tags = result.data.data;
        setLoadingTags(false);
        return tags.map((tag) => {
          let label = getTagName(tag, lng);
          let tmp = {
            label,
            name: tag[nameAttr],
            value: tag.id,
            tag: {
              id: tag.id,
              nameEn: tag.nameEn,
              nameFr: tag.nameFr,
              nameNl: tag.nameNl,
            },
          };
          if (!tag[displayedNameAttr]) {
            tmp.color = "#fed493";
          }
          if (tag.isSynonym && tag.parent !== undefined) {
            tmp.parent = { label: tag.parent[nameAttr], value: tag.parent.id };
          }
          return tmp;
        });
      })
      .catch((e) => {
        setLoadingTags(false);
      });
  };

  const handleChange = (e) => {
    let tmp = null;
    if (e) {
      tmp = e.map((tag) => {
        if (tag.parent !== undefined) {
          return { label: tag.parent.label, value: tag.parent.value };
        }
        return tag;
      });
    }
    setTags(tmp);
  };

  const handleMultiValueClick = (e, { data }) => {
    e.stopPropagation();
    e.preventDefault();

    if (allowCreateTags) {
      setEditTag(data.tag);
      setOpenTagModal(true);
    }
  };

  const MultiValueLabel = (props) => {
    return (
      <div
        onClick={(e) => handleMultiValueClick(e, props)}
        className={allowCreateTags && styles.tagHover}
      >
        <components.MultiValueLabel {...props} />
      </div>
    );
  };

  const saveTranslateTag = () => {
    let emptyTagName = false;
    if (
      editTag.nameFr.length === 0 ||
      editTag.nameNl.length === 0 ||
      editTag.nameEn.length === 0
    ) {
      emptyTagName = true;
    }
    if (emptyTagName) {
      let ErrorsContainer = () => (
        <div>
          <span>{_("errors") + " :"}</span>
          <ul>
            <li>{_("validate_tag_name")}</li>
          </ul>
        </div>
      );
      toast.error(<ErrorsContainer />, { autoClose: true });
      return;
    }

    setSavingTag(true);
    saveTag(token, editTag)
      .then(() => {
        let tab = tags.map((item) => {
          if (item.value === editTag.id) {
            return {
              label: editTag[displayedNameAttr],
              name: editTag[displayedNameAttr],
              value: editTag.id,
              tag: editTag,
            };
          }
          return item;
        });

        setTags(tab);
        setSavingTag(false);
        setOpenTagModal(false);
      })
      .catch((e) => {
        setSavingTag(false);
        toast.error("Error", { autoClose: true });
      });
  };

  return (
    <>
      <AsyncCreatableSelect
        isLoading={loadingTags}
        isMulti
        cacheOptions
        value={tags}
        onChange={(e) => handleChange(e)}
        loadOptions={fetchTTags}
        createOptionPosition="first"
        styles={props.selectStyles}
        components={{ MultiValueLabel }}
      />

      <AntModal
        closable={false}
        visible={openTagModal}
        maskClosable={false}
        width="45vw"
        height="45vh"
        footer={null}
        onCancel={() => setOpenTagModal(false)}
        destroyOnClose={true}
        zIndex="9999"
        bodyStyle={{ padding: "0" }}
      >
        <div className={styles.modal_header}>{_("tag_modal_title")}</div>
        <div
          className={styles.modal_close}
          onClick={() => setOpenTagModal(false)}
        >
          <IconClose />
        </div>
        {editTag && (
          <>
            <div className={styles.modal_body}>
              <div className={styles.formRow}>
                <label
                  className={`${styles.configLabel} ${styles.configLabelFlex}`}
                >
                  <img
                    src={`https://tamtam.s3-eu-west-1.amazonaws.com/cdn/widget/${APP_ENV}/img/flags/FR.jpg`}
                  />
                  <span>{_("tag_name")} FR</span>
                </label>
                <input
                  className={styles.formInput}
                  value={editTag.nameFr}
                  onChange={(e) => {
                    setEditTag({ ...editTag, nameFr: e.target.value });
                  }}
                />
              </div>
              <div className={styles.formRow}>
                <label
                  className={`${styles.configLabel} ${styles.configLabelFlex}`}
                >
                  <img
                    src={`https://tamtam.s3-eu-west-1.amazonaws.com/cdn/widget/${APP_ENV}/img/flags/NL.jpg`}
                  />
                  <span>{_("tag_name")} NL</span>
                </label>
                <input
                  className={styles.formInput}
                  value={editTag.nameNl}
                  onChange={(e) => {
                    setEditTag({ ...editTag, nameNl: e.target.value });
                  }}
                />
              </div>
              <div className={styles.formRow}>
                <label
                  className={`${styles.configLabel} ${styles.configLabelFlex}`}
                >
                  <img
                    src={`https://tamtam.s3-eu-west-1.amazonaws.com/cdn/widget/${APP_ENV}/img/flags/EN.jpg`}
                  />
                  <span>{_("tag_name")} EN</span>
                </label>
                <input
                  className={styles.formInput}
                  value={editTag.nameEn}
                  onChange={(e) => {
                    setEditTag({ ...editTag, nameEn: e.target.value });
                  }}
                />
              </div>
            </div>
            <div className={styles.modal_controls}>
              <Button onClick={() => setOpenTagModal(false)} variant="default">
                {_("cancel")}
              </Button>
              {savingTag ? (
                <Button
                  variant="primary"
                  style={{ paddingTop: "15px", paddingBottom: "15px" }}
                  className={styles.controls__ok}
                >
                  <Loader
                    style={{
                      height: "10px",
                    }}
                    color={"#fff"}
                  />
                </Button>
              ) : (
                <Button onClick={saveTranslateTag} variant="primary">
                  {_("save")}
                </Button>
              )}
            </div>
          </>
        )}
      </AntModal>
    </>
  );
}
