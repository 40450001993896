import React, { useEffect } from "react";

import { getTagName } from "../../../utils";

import styles from "./Question.module.scss";

const Tags = ({ data, lng }) => {
  const { tags } = data;

  if (!tags || tags.length === 0) {
    return null;
  }

  const nameAttr = `name${lng.charAt(0).toUpperCase() + lng.slice(1)}`;

  let tagsComp = [];

  if (tags && tags.length > 0) {
    let sortedTags = tags;
    tagsComp = sortedTags.map((tag) => {
      let tagName = tag[nameAttr];

      if (!tagName || tagName.trim() === "") {
        tagName = getTagName(tag, nameAttr);
      }

      return (
        <span key={`tag-${tag.id}`} className={styles.tag}>
          {tagName}
        </span>
      );
    });
  }

  return (
    <>
      <h4 className={styles.subtitle}>Mots clés</h4>
      <div className={styles.tags}>{tagsComp}</div>
    </>
  );
};

export default Tags;
