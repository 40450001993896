import React from "react";

import Modal from "react-modal";
import ReactDropzone from "react-dropzone";

import { IconClose } from "../Icons/IconClose";
import IconUpload2 from "../Icons/IconUpload2";
import styles from "./AddMedia.module.scss";
import _ from "../../../i18n";
import { saveMedia } from "../../../api";

const AddMedia = ({
  isOpen,
  close,
  setUploading,
  setNewFile,
  token,
  community,
  medias,
  setMedias,
}) => {
  const onDrop = async (acceptedFiles, rejectedFiles) => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      setUploading(true);
      setNewFile(acceptedFiles[0]);
      close();
      try {
        const res = await saveMedia({
          token,
          community,
          file: acceptedFiles[0],
        });

        if (res) {
          setUploading(false);
          setMedias([{ ...res.data.data }, ...medias]);
        }
      } catch (error) {
        setUploading(false);
      }
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={close}
      className={{
        base: styles.modalContent,
        afterOpen: styles.modalContentAfterOpen,
        beforeClose: styles.modalContentBeforeClose,
      }}
      overlayClassName={{
        base: styles.modalOverlay,
        afterOpen: styles.modalOverlayAfterOpen,
        beforeClose: styles.modalOverlayBeforeClose,
      }}
      closeTimeoutMS={300}
      contentLabel="Add media"
    >
      <div className={styles.modal}>
        <div className={styles.close} onClick={close}>
          <IconClose size={17} />
        </div>
        <div className={styles.body}>
          <ReactDropzone
            onDrop={onDrop}
            accept={[
              "image/*",
              "video/*",
              "application/pdf",
              "application/vnd.ms-powerpoint",
              "application/vnd.ms-powerpoint.addin.macroEnabled.12",
              "application/vnd.ms-powerpoint.presentation.macroEnabled.12",
              "application/vnd.ms-powerpoint.slideshow.macroEnabled.12",
              "application/vnd.ms-powerpoint.template.macroEnabled.12",
              "application/vnd.openxmlformats-officedocument.presentationml.presentation",
              "application/vnd.openxmlformats-officedocument.presentationml.slideshow",
              "application/vnd.openxmlformats-officedocument.presentationml.template",
            ]}
            className={styles.dropzone}
            activeClassName={styles.dropzoneActive}
            rejectClassName={styles.dropzoneReject}
          >
            {({ getRootProps, getInputProps }) => (
              <div className={styles.dzContent} {...getRootProps()}>
                <IconUpload2 size={51} />

                <p className={styles.dropzoneTextLarge}>
                  {_(
                    "Drag and drop files here, or click to select the files to download."
                  )}
                </p>
                <input {...getInputProps()} />
                <button>{_("Select file")}</button>
              </div>
            )}
          </ReactDropzone>
        </div>
      </div>
    </Modal>
  );
};

export default AddMedia;
