import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import styles from "../Pages.module.scss";
import {
  fetchRubric,
  setBannerThemes,
  setCurrentRubric as setStoreCurrentRubric,
  updateFaqPreferences,
  updateHiddenFaqsLocalStorage,
} from "../../../store";
import { ReactComponent as ArrowDown } from "../../Home/assets/ArrowDown.svg";
import RubricSidebar from "../../Settings/Sidebar";

const LeftBanner = ({
  rubricList,
  setCurrentRubric,
  setCurrentPage,
  setCurrentTheme,
  themesList,
}) => {
  const auth = useSelector((state) => state.auth);
  const showAllStatus = useSelector((state) => state.faq.showAllStatus);
  const showFiduciaryStatus = useSelector(
    (state) => state.faq.showFiduciaryStatus
  );
  const hiddenFaqs = useSelector((state) => state.faq.hiddenFaqs);
  const lng = useSelector((state) => state.params.language);
  const domain = useSelector((state) => state.domains);
  const { items, currentDomain } = domain;
  const { domainID, themeID, pageID, rubricID } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [rubricSidebarOpened, setRubricSidebarOpened] = useState(false);
  const titleAttr = `title${lng.charAt(0).toUpperCase() + lng.slice(1)}`;
  const urlAttr = `url${lng.charAt(0).toUpperCase() + lng.slice(1)}`;

  useEffect(() => {
    if (themeID && themesList) {
      const theme = themesList.filter(
        (item) => item.id.toString() === themeID
      )[0];
      setCurrentTheme(theme);
      if (pageID && theme) {
        const pages = theme.pages.filter(
          (item) => item.id.toString() === pageID
        )[0];
        setCurrentPage(pages);
        if (rubricID && pages.rubrics) {
          const rub = pages.rubrics.filter(
            (item) => item.id.toString() === rubricID
          );
          if (rub.length > 0) {
            setCurrentRubric(rub[0]);
          }
        } else {
          setCurrentRubric(null);
        }
      } else {
        setCurrentPage(null);
      }
    }
  }, [domainID, themeID, pageID, rubricID, themesList]);

  const selectDomain = (domain) => {
    const el = document.getElementById("domain_list");
    el.classList.remove(`${styles.activeList}`);
    setCurrentRubric(null);
    setCurrentPage(null);
    dispatch(setStoreCurrentRubric(null));
    dispatch(setBannerThemes([]));
    // setCurrentTheme(themesList.filter((item) => item.id === 1)[0]);
    // navigate(`/${lng}/${id}/themes/1`);
    navigate(`/${lng}/${domain[urlAttr]}`);
  };
  const selectTheme = (id) => {
    setCurrentRubric(null);
    setCurrentPage(null);
    dispatch(setStoreCurrentRubric(null));
    navigate(`/${lng}/${domainID}/themes/${id}`);
  };
  const selectPage = (id) => {
    setCurrentRubric(null);
    dispatch(setStoreCurrentRubric(null));
    navigate(`/${lng}/${domainID}/themes/${themeID}/pages/${id}`);
  };
  const selectRubric = (id) => {
    navigate(
      `/${lng}/${domainID}/themes/${themeID}/pages/${pageID}/rubrics/${id}`
    );
  };

  const handleHideFaq = (e, key, id) => {
    e.stopPropagation();
    let tmpHiddenFaqs = JSON.parse(JSON.stringify(hiddenFaqs));
    if (tmpHiddenFaqs[key].includes(id)) {
      tmpHiddenFaqs[key] = tmpHiddenFaqs[key].filter((i) => i !== id);
    } else {
      tmpHiddenFaqs[key].push(id);
    }
    if (auth.navCommunity) {
      dispatch(
        updateFaqPreferences({
          hiddenFaqs: tmpHiddenFaqs,
          communityId: auth.navCommunity.id,
        })
      );
      dispatch(updateHiddenFaqsLocalStorage(tmpHiddenFaqs));
    }
  };

  if (!currentDomain) {
    return null;
  }

  return (
    <div
      className={`${styles.leftBanner} ${rubricList ? styles.questions : ""}`}
    >
      <div className={styles.domain}>
        <span
          onClick={() => {
            const el = document.getElementById("domain_list");
            el.classList.toggle(`${styles.activeList}`);
          }}
        >
          {currentDomain[titleAttr].length > 20
            ? currentDomain[titleAttr].substring(0, 20) + "..."
            : currentDomain[titleAttr]}
          <ArrowDown />
        </span>

        <ul id="domain_list">
          {items.map((item) => (
            <li
              key={item.id}
              onClick={() => selectDomain(item)}
              style={{
                display: item.id === currentDomain.id ? "none" : "block",
              }}
            >
              {item[titleAttr].length > 30
                ? item[titleAttr].substring(0, 30) + "..."
                : item[titleAttr]}
            </li>
          ))}
        </ul>
      </div>

      <ul className={styles.themesLinks}>
        {themesList &&
          themesList.map((item, i) => {
            if (!showFiduciaryStatus && hiddenFaqs.T.includes(item.id)) {
              return null;
            }

            return (
              <li
                className={`${styles.listItem} ${
                  item.id.toString() === themeID
                    ? styles.activeItem
                    : styles.unset
                } grid-x`}
                key={item.id}
              >
                <span className="cell small-1"></span>
                <p
                  className="cell small-11"
                  onClick={() => selectTheme(item.id)}
                >
                  {showFiduciaryStatus ? (
                    <p className={styles.eye_container}>
                      <span>{item[titleAttr]}</span>
                      <p
                        className={`${styles.status_eye} ${
                          hiddenFaqs.T.includes(item.id) && styles.hidden
                        } `}
                        onClick={(e) => handleHideFaq(e, "T", item.id)}
                      >
                        <i className="icon-ttp-eye"></i>
                      </p>
                    </p>
                  ) : (
                    item[titleAttr]
                  )}
                </p>

                {item.id.toString() === themeID && pageID && (
                  <ul className={styles.pagesLinks}>
                    {item.pages.map((page) => {
                      if (
                        !showFiduciaryStatus &&
                        hiddenFaqs.P.includes(page.id)
                      ) {
                        return null;
                      }

                      return (
                        <li
                          className={`${
                            page.id.toString() === pageID
                              ? styles.activeItem
                              : styles.unset
                          } grid-x`}
                          key={page.id}
                        >
                          <span className="cell small-1"></span>
                          <p
                            className="cell small-11"
                            onClick={() => selectPage(page.id)}
                          >
                            {showFiduciaryStatus ? (
                              <p className={styles.eye_container}>
                                <span>{page[titleAttr]}</span>
                                <p
                                  className={`${styles.status_eye} ${
                                    hiddenFaqs.P.includes(page.id) &&
                                    styles.hidden
                                  } `}
                                  onClick={(e) =>
                                    handleHideFaq(e, "P", page.id)
                                  }
                                >
                                  <i className="icon-ttp-eye"></i>
                                </p>
                              </p>
                            ) : (
                              page[titleAttr]
                            )}
                          </p>
                          {page.id.toString() === pageID && rubricID && (
                            <ul className={styles.rubricsLinks}>
                              {page.rubrics.map((rubric, i) => {
                                if (
                                  !showAllStatus &&
                                  rubric.status !== "PUBLISHED"
                                ) {
                                  return null;
                                }
                                if (
                                  !showFiduciaryStatus &&
                                  hiddenFaqs.R.includes(rubric.id)
                                ) {
                                  return null;
                                }

                                return (
                                  <li
                                    className={`${
                                      rubric.id.toString() === rubricID &&
                                      styles.activeItem
                                    } ${styles.rubricName} ${
                                      rubric.status !== "PUBLISHED" &&
                                      styles.draftRubric
                                    } grid-x`}
                                    key={rubric.id}
                                    style={{ marginLeft: "5%" }}
                                    onClick={() => selectRubric(rubric.id)}
                                  >
                                    <span className="cell small-1"></span>

                                    <p className="cell small-10">
                                      {rubric[titleAttr]}
                                    </p>
                                    <p className="cell small-1">
                                      {showAllStatus && (
                                        <span
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            dispatch(
                                              fetchRubric({
                                                id: rubric.id,
                                                community: auth.navCommunity.id,
                                              })
                                            );
                                            setRubricSidebarOpened(true);
                                          }}
                                        >
                                          <i className="icon-ttp-edit"></i>
                                        </span>
                                      )}
                                      {showFiduciaryStatus && (
                                        <p
                                          className={`${styles.status_eye} ${
                                            hiddenFaqs.R.includes(rubric.id) &&
                                            styles.hidden
                                          } `}
                                          onClick={(e) =>
                                            handleHideFaq(e, "R", rubric.id)
                                          }
                                        >
                                          <i className="icon-ttp-eye"></i>
                                        </p>
                                      )}
                                    </p>
                                  </li>
                                );
                              })}
                            </ul>
                          )}
                        </li>
                      );
                    })}
                  </ul>
                )}
              </li>
            );
          })}
      </ul>

      <RubricSidebar
        isOpened={rubricSidebarOpened}
        isNew={false}
        hideSidebar={() => {
          setRubricSidebarOpened(false);
        }}
        // afterSave={handleAfterSave}
      />
    </div>
  );
};

export default LeftBanner;
