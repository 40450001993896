import React, { useState } from "react";
import { ModalConfirm } from "tamtam-components";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import _ from "../../i18n";
import styles from "./Domains.module.scss";
import { removeDomain } from "../../api";

const DomainItem = ({ domain, showSidebar, afterDomainDelete }) => {
  const lng = useSelector((state) => state.params.language);
  const token = useSelector((state) => state.auth.token);
  const titleAttr = `title${lng.charAt(0).toUpperCase() + lng.slice(1)}`;
  const [isOpenThemeDeleteModal, setIsOpenThemeDeleteModal] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const handleThemeDelete = async () => {
    if (token) {
      try {
        setDeleting(true);
        await removeDomain(token, domain.id);
        if (afterDomainDelete) afterDomainDelete({ id: domain.id });
        setDeleting(false);
        setIsOpenThemeDeleteModal(false);
      } catch (error) {
        toast.error(error);
      }
    }
  };

  return (
    <div className={`${styles.itemCard} grid-x`}>
      <div className={`${styles.content} grid-x small-11`}>
        {domain.icon && (
          <i className={`webtool-${domain.icon.label} cell small-2`} />
        )}
        <span className="cell small-10">{domain[titleAttr]}</span>
      </div>

      <div className={`${styles.actions} cell small-1`}>
        <div className={styles.edit} onClick={() => showSidebar()}>
          <i className="icon-ttp-edit"></i>
        </div>
        <div
          className={styles.delete}
          onClick={() => setIsOpenThemeDeleteModal(true)}
        >
          <i className="icon-ttp-trash"></i>
        </div>

        <ModalConfirm
          type="delete"
          isOpen={isOpenThemeDeleteModal}
          onCancel={() => setIsOpenThemeDeleteModal(false)}
          onConfirm={() => handleThemeDelete()}
          inProcess={deleting}
          title={_("modal_delete_title")}
          text={_("modal_delete_text")}
          labelNo={_("no")}
          labelYes={_("yes")}
          labelError={_("error")}
        />
      </div>
    </div>
  );
};

export default DomainItem;
