import React from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { useQuery } from "react-query";

import styles from "../Search.module.scss";
import Card from "./Card";
import { getPage } from "../../../api";
import _ from "../../../i18n";
import PageLoader from "../../common/PageLoader";

const Rubrics = ({ setCurrentRubric, pageID }) => {
  const hiddenFaqs = useSelector((state) => state.faq.hiddenFaqs);
  const auth = useSelector((state) => state.auth);

  const { token } = auth;

  let { isLoading, data } = useQuery(["getPage", token, pageID], async () => {
    if (token && pageID) {
      const response = await getPage({
        token,
        pageId: [parseInt(pageID)],
        questions: true,
      });
      if (response.data.data && response.data.data[0]) {
        return response.data.data[0].rubrics;
      } else {
        return null;
      }
    }
  });
  return (
    <div className={`${styles.cardsList} grid-x `}>
      {data &&
        data.map((rubric) => {
          if (hiddenFaqs.R.includes(rubric.id)) {
            return null;
          }
          return (
            <div
              className={classNames(
                styles.cardCont,
                "cell small-12 medium-6 large-4"
              )}
            >
              <Card
                key={rubric.id}
                type={"rubric"}
                data={rubric}
                setCurrentRubric={setCurrentRubric}
              />
            </div>
          );
        })}
      {!isLoading && !data && (
        <div className={styles.no_data}>{_("page_has_no_rubrics")}</div>
      )}
      {isLoading && (
        <div style={{ margin: "4rem auto" }}>
          <PageLoader />
        </div>
      )}
    </div>
  );
};

export default Rubrics;
