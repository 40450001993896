import React, { useState } from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";

import styles from "../Search.module.scss";
import { ReactComponent as BlueRightArrow } from "../../Home/assets/BlueRightArrow.svg";

const Card = ({
  type,
  data,
  setCurrentDomain,
  setCurrentTheme,
  setCurrentPage,
  setCurrentRubric,
}) => {
  const lng = useSelector((state) => state.params.language);
  const titleAttr = `title${lng.charAt(0).toUpperCase() + lng.slice(1)}`;
  let countAuthor = 0;

  const handleClick = () => {
    if (setCurrentDomain) {
      window.localStorage.setItem("currentDomain", JSON.stringify(data));
      setCurrentDomain(data);
    }
    if (setCurrentTheme) {
      window.localStorage.setItem("currentTheme", JSON.stringify(data));
      setCurrentTheme(data);
    }
    if (setCurrentPage) {
      window.localStorage.setItem("currentPage", JSON.stringify(data));
      setCurrentPage(data);
    }
    if (setCurrentRubric && data.questions.length > 0) {
      window.localStorage.setItem("currentRubric", JSON.stringify(data));
      setCurrentRubric(data);
    }
  };

  const getCardText = () => {
    if (
      type === "theme" ||
      type === "page" ||
      type === "rubric" ||
      type === "domain"
    ) {
      return data[titleAttr].length > 36
        ? data[titleAttr].substring(0, 36) + " ..."
        : data[titleAttr];
    }
    return "INDÉPENDANTS & PROFESSIONS LIBÉRALES & PROFESSIONS LIBÉRALES "
      .length > 60 && type === "rubric"
      ? "INDÉPENDANTS & PROFESSIONS LIBÉRALES & PROFESSIONS LIBÉRALES".substring(
          0,
          60
        ) + " ..."
      : "INDÉPENDANTS & PROFESSIONS LIBÉRALES & PROFESSIONS LIBÉRALES ".length >
        36
      ? "INDÉPENDANTS & PROFESSIONS LIBÉRALES & PROFESSIONS LIBÉRALES".substring(
          0,
          36
        ) + " ..."
      : "INDÉPENDANTS & PROFESSIONS LIBÉRALES & PROFESSIONS LIBÉRALES";
  };

  return (
    <div
      className={classNames(styles.card, "grid-x")}
      onClick={() => handleClick()}
      style={{
        height: type === "rubric" ? "226px" : "142px",
      }}
    >
      <div
        className={classNames(
          styles.cardBody,
          `cell ${type === "rubric" ? "small-12" : "small-10"}`
        )}
      >
        <span>
          {type === "domain"
            ? "Je suis..."
            : type === "theme"
            ? "Thème"
            : type === "rubric"
            ? "Rubrique"
            : "Page"}
        </span>
        <h5>{getCardText()}</h5>
        {type === "rubric" && (
          <div className={styles.cardFooter}>
            {data.questions && (
              <p>
                <strong>{data.questions.length}</strong> questions
              </p>
            )}{" "}
            <div className={styles.info}>
              {data.chain.chain && (
                <div
                  className={styles.chainImg}
                  style={{
                    backgroundImage: `url(${data.chain.chain[0].mediaChain[0].avatarUrl})`,
                  }}
                ></div>
              )}
              <div className={styles.users}>
                {data.author &&
                  data.author.author &&
                  data.author.author.map((path, i) => {
                    if (!path.enableAvatar) {
                      return null;
                    }
                    countAuthor++;
                    if (countAuthor >= 4) {
                      return null;
                    }
                    return (
                      <div
                        className={styles.avatar}
                        style={{
                          backgroundImage: `url(${path.avatarUrl})`,
                        }}
                      ></div>
                    );
                  })}
                {countAuthor > 4 && (
                  <div className={styles.extraUsers}>+{countAuthor - 4}</div>
                )}
              </div>
              <BlueRightArrow />
            </div>
          </div>
        )}
      </div>

      {type !== "rubric" && (
        <div className={classNames(styles.icons, "cell small-2")}>
          {data.icon && <i className={`icon webtool-${data.icon.label}`}></i>}
          <BlueRightArrow />
        </div>
      )}
    </div>
  );
};

export default Card;
