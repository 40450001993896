import React from "react";

export const IconHandler = ({ size = 20 }) => (
  <svg
    width="7"
    height="24"
    viewBox="0 0 7 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="6" width="1" height="28" rx="0.5" fill="#B6BFC8" />
    <rect x="3" width="1" height="28" rx="0.5" fill="#B6BFC8" />
    <rect width="1" height="28" rx="0.5" fill="#B6BFC8" />
  </svg>
);
