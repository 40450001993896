import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import styles from "./RubricCard.module.scss";
import { ReactComponent as ArrowRight } from "../../Home/assets/ArrowRight.svg";
import { ReactComponent as BlueRightArrow } from "../../Home/assets/BlueRightArrow.svg";
import classNames from "classnames";

import {
  updateFaqPreferences,
  updateHiddenFaqsLocalStorage,
} from "../../../store";

const RubricCard = ({ data, chainImg, url, language }) => {
  const auth = useSelector((state) => state.auth);

  const showAllStatus = useSelector((state) => state.faq.showAllStatus);
  const showFiduciaryStatus = useSelector(
    (state) => state.faq.showFiduciaryStatus
  );
  const hiddenFaqs = useSelector((state) => state.faq.hiddenFaqs);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let count = 0;
  let countLength = 0;
  let countAuthor = 0;

  const titleAttr = `title${
    language.charAt(0).toUpperCase() + language.slice(1)
  }`;

  const handleHideFaq = (e, key, id) => {
    e.stopPropagation();
    let tmpHiddenFaqs = JSON.parse(JSON.stringify(hiddenFaqs));
    if (tmpHiddenFaqs[key].includes(id)) {
      tmpHiddenFaqs[key] = tmpHiddenFaqs[key].filter((i) => i !== id);
    } else {
      tmpHiddenFaqs[key].push(id);
    }
    if (auth.navCommunity) {
      dispatch(
        updateFaqPreferences({
          hiddenFaqs: tmpHiddenFaqs,
          communityId: auth.navCommunity.id,
        })
      );
      dispatch(updateHiddenFaqsLocalStorage(tmpHiddenFaqs));
    }
  };

  return (
    <div className={styles.card}>
      {showFiduciaryStatus && (
        <div
          className={`${styles.status_eye} ${styles.status_eye_absolute} ${
            hiddenFaqs.R.includes(data.id) && styles.hidden
          } `}
          onClick={(e) => handleHideFaq(e, "R", data.id)}
        >
          <i className="icon-ttp-eye"></i>
        </div>
      )}
      <div className={styles.title}>
        <h3
          onClick={() => {
            navigate(url);
          }}
        >
          {data[titleAttr]}
        </h3>
      </div>
      <ul className={styles.rubricContent}>
        {data.questions &&
          data.questions.map((item, i) => {
            if (item.objectType !== "QUESTION") return null;
            if (!showAllStatus && item.status !== "PUBLISHED") {
              return null;
            }
            if (!showFiduciaryStatus && hiddenFaqs.Q.includes(item.id)) {
              return null;
            }
            countLength++;
            if (count >= 4) {
              return null;
            }
            count++;
            return (
              <li
                className={classNames(
                  "grid-x",
                  item.search ? styles.active : ""
                )}
                key={i}
                onClick={() => navigate(`${url}?q=${item.id}`)}
              >
                <ArrowRight className="cell small-1" />
                <p className="cell small-10">{item[titleAttr]}</p>
                {showFiduciaryStatus && (
                  <div
                    className={`cell small-1 ${styles.status_eye} ${
                      hiddenFaqs.Q.includes(item.id) && styles.hidden
                    } `}
                    onClick={(e) => handleHideFaq(e, "Q", item.id)}
                  >
                    <i className="icon-ttp-eye"></i>
                  </div>
                )}
              </li>
            );
          })}
      </ul>
      {data.questions && countLength > 4 && (
        <div className={styles.nbPages}>
          <span>
            <strong>+ {countLength - 4}&nbsp;</strong> questions
          </span>
        </div>
      )}

      <div className={styles.authors}>
        {chainImg && (
          <div
            className={styles.image}
            style={{ backgroundImage: `url(${chainImg})` }}
          />
        )}
        {data.author && (
          <div className={styles.users}>
            {data.author.map((path, i) => {
              if (!path.enableAvatar) {
                return null;
              }
              countAuthor++;
              if (countAuthor >= 4) {
                return null;
              }
              return (
                <div
                  key={`avar${i}`}
                  className={styles.avatar}
                  style={{ backgroundImage: `url(${path.avatarUrl})` }}
                  alt=""
                ></div>
              );
            })}
            {countAuthor > 4 && (
              <div className={styles.extraUsers}>+{countAuthor - 4}</div>
            )}
          </div>
        )}
        <div
          className={styles.blueArrow}
          onClick={() => {
            navigate(url);
          }}
        >
          <BlueRightArrow />
        </div>
      </div>
    </div>
  );
};

export default RubricCard;
