import React, { useEffect } from "react";

import { attachmentIsPDF } from "../../../utils";
import { TTP_API_URL } from "../../../config";

// import PDFPreview from "./PDFPreview";
import styles from "./Question.module.scss";

const Attachments = ({ data }) => {
  const { mediaFaqQuestions } = data;

  if (!mediaFaqQuestions || mediaFaqQuestions.length === 0) {
    return null;
  }

  //   const attachments = mediaFaqQuestions.filter(
  //     (m) => m.isAttachment && !attachmentIsPDF(m)
  //   );

  //   const pdfs = mediaFaqQuestions.filter(
  //     (m) => m.isAttachment && attachmentIsPDF(m)
  //   );
  const attachments = mediaFaqQuestions.filter((m) => m.isAttachment);

  const attachmentsJsx = attachments.map((a) => {
    let iconClass = "";

    switch (a.type) {
      case "IMAGE":
        iconClass = "icon-ttp-medias";
        break;
      case "VIDEO":
        iconClass = "icon-ttp-film";
        break;
      case "AUDIO":
        iconClass = "icon-ttp-headphones";
        break;
      case "FILE":
        iconClass = "icon-ttp-copy";
        break;
      default:
        iconClass = "icon-ttp-copy";
    }

    return (
      <li key={a.id}>
        <a
          href={a.fullMediaUrl ? a.fullMediaUrl : `${TTP_API_URL}/${a.webPath}`}
          target="_blank"
          rel="noreferrer"
        >
          <i className={iconClass}></i>
          {a.name}
        </a>
      </li>
    );
  });

  //   const pdfsJsx = pdfs.map((a) => {
  //     return (
  //       <li key={a.id}>
  //         <a
  //           href={a.fullMediaUrl ? a.fullMediaUrl : `${TTP_API_URL}/${a.webPath}`}
  //           target="_blank"
  //           rel="noreferrer"
  //         >
  //           <i className="icon-ttp-copy"></i>
  //           {a.name}
  //         </a>
  //         <PDFPreview media={a} />
  //       </li>
  //     );
  //   });

  //   if (pdfs.length === 0 && attachments.length === 0) {
  //     return null;
  //   }

  return (
    <div className={styles.attachments}>
      <ul>{attachmentsJsx}</ul>
      {/* <ul className={styles.pdfsList}>{pdfsJsx}</ul> */}
    </div>
  );
};

export default Attachments;
