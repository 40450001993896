import React from "react";
import { useState } from "react";
import InputMask from "react-input-mask";
import { useSelector } from "react-redux";
import Select from "react-select";

import { SELECT_STYLES } from "../../../config";
import _ from "../../../i18n";
import { convertDateToUTC } from "../../../utils";
import Switch from "../../common/Switch/Switch";
import faqStyles from "./FAQ.module.scss";
import GroupsSelect from "./GroupsSelect";
import statusStyles from "./Status.module.scss";

const statusOptions = [
  { value: "DRAFT", label: _("draft") },
  { value: "PUBLISHED", label: _("published") },
];
const DATE_FORMAT = "DD-MM-YYYY HH:mm";
const API_DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";

function RenderPublishedAt(props) {
  const { setPublishedAt, publishedAt, currentRubric } = props;

  let label = _("rubric_published_on");

  let inputMaskProps = {
    mask: "99-99-9999 99:99",
    onChange: (e) => {
      setPublishedAt(e.target.value);
    },
    className: "published-at-input",
    autoComplete: "off",
    placeholder: _("Publication date (eg. 24-07-2021 12:00)"),
  };

  if (publishedAt) {
    inputMaskProps.defaultValue = publishedAt;
  } else if (currentRubric.publishedAt) {
    inputMaskProps.defaultValue = convertDateToUTC(
      currentRubric.publishedAt,
      API_DATE_FORMAT,
      DATE_FORMAT
    );
    setPublishedAt(inputMaskProps.defaultValue);
  } else {
    const date = new Date();
    const dateSTR = `${
      date.getDay() > 9 ? date.getDay() : "0" + date.getDay()
    }-${
      date.getMonth() > 9 ? date.getMonth() : "0" + date.getMonth()
    }-${date.getFullYear()} ${
      date.getHours() > 9 ? date.getHours() : "0" + date.getHours()
    }:${date.getMinutes() > 9 ? date.getMinutes() : "0" + date.getMinutes()}`;
    inputMaskProps.defaultValue = dateSTR;
    setPublishedAt(dateSTR);
  }

  return (
    <div className={statusStyles.publishRow}>
      <span className={statusStyles.publishLabel}>{label} :</span>
      <InputMask {...inputMaskProps} />
    </div>
  );
}
const StatusForm = ({
  status,
  setStatus,
  publishedAt,
  setPublishedAt,
  isPrivate,
  setIsPrivate,
  privateGroups,
  setPrivateGroups,
  auth,
}) => {
  const currentRubric = useSelector((state) => state.faq.currentRubric);
  const { navCommunity } = auth;
  return (
    <div>
      <div className={faqStyles.configRow}>
        <div className={faqStyles.configColumn}>
          <div className={faqStyles.formRow}>
            <label className={faqStyles.configLabel}>{_("faq_status")}</label>
            <Select
              styles={SELECT_STYLES}
              options={statusOptions}
              isSearchable={false}
              value={status}
              onChange={(e) => setStatus(e)}
              isDisabled={currentRubric.status === "DRAFT"}
            />
          </div>
        </div>
        {status.value === "PUBLISHED" && (
          <div className={faqStyles.configColumn}>
            <div className={faqStyles.formRow}>
              <label className={faqStyles.configLabel}>{_("faq_status")}</label>
              <RenderPublishedAt
                publishedAt={publishedAt}
                setPublishedAt={setPublishedAt}
                currentRubric={currentRubric}
                status={status}
              />
            </div>
          </div>
        )}
      </div>
      <div className={faqStyles.configRow}>
        <div className={faqStyles.configColumn}>
          <div className={faqStyles.switchRow}>
            <label className={faqStyles.configLabel}>{_("is_private")}</label>

            <Switch isChecked={isPrivate} onChange={(e) => setIsPrivate(e)} />
          </div>
        </div>
        {isPrivate && (
          <div className={faqStyles.configColumn}>
            <label className={faqStyles.configLabel}>
              {_("subscribers_groups")}
            </label>
            <GroupsSelect
              isMulti={true}
              selectedGroups={privateGroups}
              onChange={(groups) => setPrivateGroups(groups)}
              auth={auth}
              community={navCommunity?.id ? { value: navCommunity.id } : null}
              selectStyles={SELECT_STYLES}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default StatusForm;
