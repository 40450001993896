import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import classNames from "classnames";
import { useSelector } from "react-redux";

import styles from "./Search.module.scss";
import LeftBanner from "./components/LeftBanner";
import PageContent from "./components/PageContent";
import PageLoader from "../common/PageLoader";

import { getListThemes } from "../../api";

const Search = () => {
  const auth = useSelector((state) => state.auth);
  const { token, navCommunity } = auth;

  const [currentDomain, setCurrentDomain] = useState(null);
  const [currentTheme, setCurrentTheme] = useState(null);
  const [currentPage, setCurrentPage] = useState(null);
  const [currentRubric, setCurrentRubric] = useState(null);

  useEffect(() => {
    const domain = localStorage.getItem("currentDomain");
    const theme = localStorage.getItem("currentTheme");
    const page = localStorage.getItem("currentPage");
    const rubric = localStorage.getItem("currentRubric");

    if (domain) setCurrentDomain(JSON.parse(domain));
    if (theme) setCurrentTheme(JSON.parse(theme));
    if (page) setCurrentPage(JSON.parse(page));
    if (rubric) setCurrentRubric(JSON.parse(rubric));
  }, []);

  let { isLoading, data } = useQuery(
    ["listThemes", token, currentDomain],
    async () => {
      if (token && currentDomain) {
        const response = await getListThemes({
          token,
          community: navCommunity.id,
          domain: currentDomain.id,
          addQuestion: true,
          isEditable: currentDomain.isEditable,
        });

        return response.data.data;
      }
    }
  );

  return (
    <div className={classNames(styles.searchPage, "grid-x")}>
      <div className={classNames(styles.bannerCont, "cell small-0 large-3")}>
        <LeftBanner
          currentDomain={currentDomain}
          currentTheme={currentTheme}
          currentPage={currentPage}
          currentRubric={currentRubric}
        />
      </div>
      <div className="cell small-12 large-9">
        {isLoading ? (
          <div style={{ margin: "4rem auto" }}>
            <PageLoader />
          </div>
        ) : (
          <PageContent
            currentDomain={currentDomain}
            currentTheme={currentTheme}
            currentPage={currentPage}
            currentRubric={currentRubric}
            setCurrentDomain={setCurrentDomain}
            setCurrentTheme={setCurrentTheme}
            setCurrentPage={setCurrentPage}
            setCurrentRubric={setCurrentRubric}
            data={data}
          />
        )}
      </div>
    </div>
  );
};

export default Search;
