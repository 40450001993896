import React from "react";

export const IconChevronDown = () => (
  <svg
    width="12"
    height="8"
    viewBox="0 0 12 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.42831 7.12901L11.8229 1.73267C12.059 1.49588 12.059 1.11224 11.8229 0.874857C11.5867 0.638069 11.203 0.638069 10.9668 0.874857L6.00032 5.84317L1.0338 0.875454C0.797615 0.638666 0.413981 0.638666 0.177193 0.875454C-0.0589972 1.11224 -0.0589972 1.49647 0.177193 1.73326L5.57174 7.12961C5.80549 7.36281 6.19516 7.36281 6.42831 7.12901Z"
      fill="currentColor"
    />
  </svg>
);
