import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { useDispatch } from "react-redux";

import styles from "./Pages.module.scss";
import { ReactComponent as BlackLeftArrow } from "./assets/BlackLeftArrow.svg";
import LeftBanner from "./components/LeftBanner";
import PagesList from "./components/PagesList";
import RubricList from "./components/RubricList";
import QuestionList from "./components/QuestionList";
import Breadcrumb from "../common/Breadcrumb";
import PageLoader from "../common/PageLoader";
import _ from "../../i18n";
import { fetchBannerThemes, setCurrentDomain, fetchDomains } from "../../store";

const Pages = () => {
  const { domainID, themeID, pageID, rubricID } = useParams();
  const lng = useSelector((state) => state.params.language);
  const bannerThemes = useSelector((state) => state.themes.bannerThemes);
  const navigate = useNavigate();
  const [currentNavPage, setCurrentNavPage] = useState(null);
  const [currentTheme, setCurrentTheme] = useState(null);
  const [currentPage, setCurrentPage] = useState(null);
  const [selectedRubric, setSelectedRubric] = useState(null);
  const auth = useSelector((state) => state.auth);
  const currentDomain = useSelector((state) => state.domains.currentDomain);
  const domainsList = useSelector((state) => state.domains.items);
  const { token } = auth;
  const titleAttr = `title${lng.charAt(0).toUpperCase() + lng.slice(1)}`;
  const urlAttr = `url${lng.charAt(0).toUpperCase() + lng.slice(1)}`;
  const dispatch = useDispatch();

  useEffect(() => {
    if (auth.navCommunity) {
      if (domainsList.length === 0) {
        dispatch(fetchDomains({ communityId: auth.navCommunity.id }));
      }
    }
  }, [auth.navCommunity]);

  useEffect(() => {
    if (domainsList.length > 0) {
      let domain = domainsList.filter(
        (item) => item[urlAttr].toLowerCase() === domainID.toLowerCase()
      );
      if (!domain) {
        domain = domainsList[0];
      } else {
        domain = domain[0];
      }
      dispatch(setCurrentDomain(domain));
    }
  }, [domainID, domainsList]);

  useEffect(() => {
    if (rubricID) {
      setCurrentNavPage("RUBRIC");
    } else if (pageID) {
      setCurrentNavPage("PAGE");
    } else {
      setCurrentNavPage("THEME");
    }
  }, [rubricID, pageID]);

  useEffect(() => {
    if (token && currentDomain) {
      dispatch(
        fetchBannerThemes({
          domain: currentDomain.id,
          rubrics: true,
          isEditable: currentDomain.isEditable
            ? currentDomain.isEditable
            : false,
        })
      );
    }
  }, [token, currentDomain]);

  const handleBack = () => {
    if (rubricID) {
      navigate(`/${lng}/${domainID}/themes/${themeID}/pages/${pageID}`);
    } else if (pageID) {
      navigate(`/${lng}/${domainID}/themes/${themeID}`);
    } else {
      navigate(`/${lng}`);
    }
  };

  return (
    <div className={styles.pagesPage}>
      <div className={styles.backNav}>
        <button onClick={() => handleBack()}>
          <BlackLeftArrow />
          {_("back")}
        </button>
        <Breadcrumb
          items={[
            currentTheme && {
              label: currentTheme[titleAttr],
              url: `/${lng}/${domainID}/themes/${currentTheme.id}`,
            },
            currentPage &&
              currentTheme && {
                label: currentPage[titleAttr],
                url: `/${lng}/${domainID}/themes/${currentTheme.id}/pages/${currentPage.id}`,
              },
            selectedRubric &&
              currentPage &&
              currentTheme && {
                label: selectedRubric[titleAttr],
                url: `/${lng}/${domainID}/themes/${currentTheme.id}/pages/${currentPage.id}/rubrics/${selectedRubric.id}`,
              },
          ]}
        />
      </div>
      <div className={styles.pageContent}>
        <div className={classNames(styles.body, "grid-x")}>
          <div
            className={classNames(
              styles.bannerDiv,
              "cell medium-4 large-3 small-12"
            )}
          >
            <LeftBanner
              rubricList={currentTheme}
              setCurrentRubric={setSelectedRubric}
              setCurrentPage={setCurrentPage}
              setCurrentTheme={setCurrentTheme}
              themesList={bannerThemes}
            />
          </div>
          <div
            className={classNames(
              styles.listContent,
              "cell medium-8 large-9 small-12"
            )}
          >
            {currentNavPage === "RUBRIC" ? (
              <QuestionList rubricID={rubricID} />
            ) : currentNavPage === "PAGE" ? (
              <RubricList
                lng={lng}
                domainID={domainID}
                themeID={themeID}
                pageID={pageID}
                token={token}
              />
            ) : (
              <PagesList
                themes={bannerThemes}
                domainID={domainID}
                themeID={themeID}
                lng={lng}
                token={token}
              />
            )}
          </div>
        </div>
      </div>
      {/* {isLoading ? (
        <div style={{ marginTop: "4rem" }}>
          <PageLoader />
        </div>
      ) : (
      
      )} */}
    </div>
  );
};

export default Pages;
