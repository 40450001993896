import React, { useState } from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { useQuery } from "react-query";

import PageLoader from "../../common/PageLoader";
import Languages from "../../common/Languages";
import styles from "../Chaines.module.scss";
import LeftBanner from "./LeftBanner";
import _ from "../../../i18n";
import RubricCard from "../../common/RubricCard";
import { getAvatarsAndRubrics } from "../../../api";

const SingleChaine = ({ lng, chaineID }) => {
  const currentDomain = useSelector((state) => state.domains.currentDomain);
  const urlAttr = `url${lng.charAt(0).toUpperCase() + lng.slice(1)}`;
  const auth = useSelector((state) => state.auth);
  const { token, navCommunity } = auth;
  const [chain, setChain] = useState(null);
  const [rubrics, setRubrics] = useState([]);
  const [loading, setLoading] = useState(false);
  const [start, setStart] = useState(0);
  const [showBtn, setShowBtn] = useState(true);

  let { data } = useQuery(
    ["singleChain", navCommunity, token, chaineID, start],
    async () => {
      if (token) {
        try {
          setLoading(true);
          const response = await getAvatarsAndRubrics({
            token,
            organizationId: navCommunity.id,
            chaineID,
            rubricsStart: start,
            rubricsLimit: 18,
          });
          if (
            response.data.data[0] &&
            chain &&
            rubrics.length > 0 &&
            start > 0
          ) {
            setRubrics([...rubrics, ...response.data.data[0].rubrics]);
          } else if (response.data.data[0]) {
            setChain(response.data.data[0].chain);
            setRubrics(response.data.data[0].rubrics);
          }
          setLoading(false);
        } catch (error) {
          if (error.response.data && error.response.data.statusCode === 404) {
            setLoading(false);
            setShowBtn(false);
          }
        }
      }
    }
  );

  const chainAuthors = () => {
    const authors = [];
    if (rubrics && rubrics.length > 0) {
      rubrics.map((item) => {
        if (item.author) {
          item.author.map((auth) => {
            const author = {
              id: auth.id,
              firstName: auth.firstName,
              lastName: auth.lastName,
              avatarUrl: auth.avatarUrl,
              headlineEn: auth.authorDetails.headlineEn
                ? auth.authorDetails.headlineEn.title
                : null,
              headlineFr: auth.authorDetails.headlineFr
                ? auth.authorDetails.headlineFr.title
                : null,
              headlineNl: auth.authorDetails.headlineNl
                ? auth.authorDetails.headlineNl.title
                : null,
            };

            if (
              authors.filter((element) => element.id === author.id).length === 0
            ) {
              authors.push(author);
            }
          });
        }
      });
    }
    return authors;
  };

  if (!currentDomain) {
    return null;
  }
  if (loading && !chain && rubrics.length === 0) return <PageLoader />;
  return (
    <div className={`${styles.singleChaine} grid-x`}>
      <div
        className={classNames(
          styles.bannerDiv,
          "cell medium-5 large-3 small-12"
        )}
      >
        {chain && rubrics.length > 0 && (
          <LeftBanner
            chain={chain}
            chainAuthors={chainAuthors()}
            single={true}
            lng={lng}
          />
        )}
      </div>
      <div className={`${styles.rubricsList} cell medium-7 large-9 small-12`}>
        <div className={`${styles.contentTitle} grid-x`}>
          {chain && (
            <h4 className="cell small-12 large-10">
              {chain.rubricCounter}{" "}
              {_("solution rubrics published by this channel")}
            </h4>
          )}
          <div className={classNames(styles.lng, "cell small-2")}>
            <Languages />
          </div>
        </div>
        <div className={`${styles.rubrics} grid-x`}>
          {rubrics.length > 0 &&
            rubrics.map((rubric) => (
              <div className={styles.rubricCardCont}>
                <div style={{ width: "307px" }}>
                  <RubricCard
                    language={lng}
                    chainImg={chain.mediaChain[0].avatarUrl}
                    data={rubric}
                    url={`/${lng}/${currentDomain[urlAttr]}/themes/${rubric.theme.id}/pages/${rubric.pages[0].id}/rubrics/${rubric.id}`}
                  />
                </div>
              </div>
            ))}
        </div>
        {loading && chain && rubrics.length > 0 ? (
          <PageLoader />
        ) : (
          showBtn && (
            <div className={styles.showMore}>
              <div>
                <button onClick={() => setStart(start + 18)}>
                  {_("show_more_chaines")}
                  <i className="icon-ttp-chevron-down"></i>
                </button>
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default SingleChaine;
