import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";

import { DOMAIN_LIST } from "../../../config";
import _ from "../../../i18n";

export default function Domain(props) {
  const loadingThemes = useSelector((state) => state.themes.fetching);
  let themes = useSelector((state) => state.themes.items);
  const language = useSelector((state) => state.params.language);
  const [domainOptions, setDomainOptions] = useState([]);
  const { theme } = props;
  const titleAttr = `title${
    language.charAt(0).toUpperCase() + language.slice(1)
  }`;

  useEffect(() => {
    if (theme) {
      themes.map((t, index) => {
        if (t.id === theme.id && t.domains && t.domains.length > 0) {
          let tmp = [];
          t.domains.forEach((val) => {
            tmp.push({
              label: val[titleAttr],
              value: val.id,
            });
          });
          setDomainOptions(tmp);
          if (tmp.length === 1) {
            props.setDomain([tmp[0]]);
          }
        }
      });
    }
  }, [theme]);

  return (
    <Select
      styles={props.selectStyles}
      isLoading={loadingThemes}
      options={domainOptions}
      placeholder={_("select_domain")}
      value={props.domain}
      onChange={(e) => props.setDomain(e)}
      isClearable
      isMulti
    />
  );
}
