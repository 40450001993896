import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import { toast } from "react-toastify";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import { ModalConfirm } from "tamtam-components";
import InputMask from "react-input-mask";

import { APP_ENV, SELECT_STYLES } from "../../../config";
import {
  setCurrentQuestion,
  addQuestion,
  deleteQuestion,
} from "../../../store";
import {
  saveQuestion,
  removeQuestion,
  suggestAnswer,
  addAnswer,
  removeAnswer,
  getGroups,
} from "../../../api";
import { convertDateToUTC, getTagName } from "../../../utils";
import Button from "../../common/Button";
import Tabs from "../../common/Tabs";
import Loader from "../../common/Loader";
import Switch from "../../common/Switch/Switch";
import { IconTrash } from "../../common/Icons";
import _ from "../../../i18n";

import Tag from "./Tag";
import Attachments from "./Attachments";
import GroupsSelect from "./GroupsSelect";
import styles from "./FAQ.module.scss";
import statusStyles from "./Status.module.scss";
import IconBackArrow from "../../common/Icons/IconBackArrow";

const languages = [
  { value: "en", label: _("english") },
  { value: "fr", label: _("french") },
  { value: "nl", label: _("dutch") },
];

const statusOptions = [
  { value: "DRAFT", label: _("draft") },
  { value: "PUBLISHED", label: _("published") },
];

const DATE_FORMAT = "DD-MM-YYYY HH:mm";
const API_DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";

export function RenderPublishedAt(props) {
  const { setPublishedAt, publishedAt, currentRubric, status } = props;

  let label = _("rubric_published_on");

  let inputMaskProps = {
    mask: "99-99-9999 99:99",
    onChange: (e) => {
      setPublishedAt(e.target.value);
    },
    className: "published-at-input",
    autoComplete: "off",
    placeholder: _("Publication date (eg. 24-07-2021 12:00)"),
  };

  if (publishedAt) {
    inputMaskProps.defaultValue = publishedAt;
  } else if (currentRubric.publishedAt) {
    inputMaskProps.defaultValue = convertDateToUTC(
      currentRubric.publishedAt,
      API_DATE_FORMAT,
      DATE_FORMAT
    );
    setPublishedAt(inputMaskProps.defaultValue);
  } else {
    const date = new Date();
    const dateSTR = `${
      date.getDay() > 9 ? date.getDay() : "0" + date.getDay()
    }-${
      date.getMonth() > 9 ? date.getMonth() : "0" + date.getMonth()
    }-${date.getFullYear()} ${
      date.getHours() > 9 ? date.getHours() : "0" + date.getHours()
    }:${date.getMinutes() > 9 ? date.getMinutes() : "0" + date.getMinutes()}`;
    inputMaskProps.defaultValue = dateSTR;
    setPublishedAt(dateSTR);
  }

  return (
    <div className={statusStyles.publishRow}>
      <span className={statusStyles.publishLabel}>{label} :</span>
      <InputMask {...inputMaskProps} />
    </div>
  );
}

const FaqForm = (props) => {
  const currentQuestionStore = useSelector((state) => state.faq.current);
  const currentQuestion = props.suggest
    ? props.currentQuestion
    : currentQuestionStore;
  const currentRubric = useSelector((state) => state.faq.currentRubric);
  const auth = useSelector((state) => state.auth);
  const language = useSelector((state) => state.params.language);
  const [displayedLanguage, setDisplayedLanguage] = useState(language);
  const [title, setTitle] = useState({ en: "", fr: "", nl: "" });
  const [status, setStatus] = useState(statusOptions[0]);
  const [publishedAt, setPublishedAt] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [isPrivate, setIsPrivate] = useState(false);
  const [privateGroups, setPrivateGroups] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [tags, setTags] = useState([]);
  const [isResetAnswer, setIsResetAnswer] = useState(false);
  const { token, navCommunity } = auth;

  const dispatch = useDispatch();

  const [answers, setAnswers] = useState({ en: "", fr: "", nl: "" });
  const [answerFr, setAnswerFr] = useState("");
  const [answerNl, setAnswerNl] = useState("");
  const [answerEn, setAnswerEn] = useState("");

  const editor = useRef();
  const getSunEditorInstance = (sunEditor) => {
    editor.current = sunEditor;
  };
  useEffect(() => {
    if (props.status) setStatus(props.status);
    if (props.privateGroups) setPrivateGroups(props.privateGroups);
    if (props.publishedAt) setPublishedAt(props.publishedAt);
    if (props.isPrivate) setIsPrivate(props.isPrivate);
  }, [props]);
  // const initialContent =
  //   currentQuestion && currentQuestion.answers?.length > 0
  //     ? {
  //         en: props.suggestion
  //           ? props.suggestion.contentEn
  //           : currentQuestion.answers[0].contentEn,
  //         fr: props.suggestion
  //           ? props.suggestion.contentFr
  //           : currentQuestion.answers[0].contentFr,
  //         nl: props.suggestion
  //           ? props.suggestion.contentNl
  //           : currentQuestion.answers[0].contentNl,
  //         isMain: false,
  //       }
  //     : { en: "", fr: "", nl: "" };

  const nameAttr = `name${
    language.charAt(0).toUpperCase() + language.slice(1)
  }`;

  useEffect(() => {
    if (currentQuestion) {
      let answer = null;
      if (props.isCustomAnswer) {
        answer = auth.loggedIsAdmin
          ? props.customAnswer
          : props.suggest
          ? !props.suggestion
            ? ""
            : props.suggestion
          : "";
      } else if (props.suggestion) {
        answer = props.suggestion;
      } else {
        answer = currentQuestion.answers[0];
      }
      if (answer) {
        setAnswers({
          en: answer.contentEn,
          fr: answer.contentFr,
          nl: answer.contentNl,
          isMain: false,
        });
      }

      setTitle({
        fr: currentQuestion.titleFr,
        en: currentQuestion.titleEn,
        nl: currentQuestion.titleNl,
      });
      currentQuestion.status === statusOptions[0].value
        ? setStatus(statusOptions[0])
        : setStatus(statusOptions[1]);

      if (currentQuestion.isPrivate) {
        setIsPrivate(true);
        let customFilter = [
          {
            property: "id",
            value: currentQuestion.privateGroups,
            operator: "in",
          },
        ];
        getGroups({
          token: auth.token,
          clientId: currentRubric.organization
            ? currentRubric.organization.id
            : null,
          customFilter,
        }).then((result) => {
          setPrivateGroups(result.data.data);
        });
      } else {
        setIsPrivate(false);
        setPrivateGroups([]);
      }
      if (currentQuestion.publishedAt) {
        setPublishedAt(
          convertDateToUTC(
            currentQuestion.publishedAt,
            API_DATE_FORMAT,
            DATE_FORMAT
          )
        );
      }
      if (currentQuestion.mediaFaqQuestions) {
        setAttachments(currentQuestion.mediaFaqQuestions);
      }
      if (currentQuestion.tags) {
        let tabTags = currentQuestion.tags.map((tag) => {
          let label = getTagName(tag, language);
          let tmp = {
            label,
            name: tag[nameAttr],
            value: tag.id,
            tag: {
              id: tag.id,
              nameEn: tag.nameEn,
              nameFr: tag.nameFr,
              nameNl: tag.nameNl,
            },
          };
          return tmp;
        });
        setTags(tabTags);
      } else {
        setTags([]);
      }
    } else {
      resetForm();
      if (currentRubric && currentRubric.tags) {
        let tabTags = currentRubric.tags.map((tag) => {
          let label = getTagName(tag, language);
          let tmp = {
            label,
            name: tag[nameAttr],
            value: tag.id,
            tag: {
              id: tag.id,
              nameEn: tag.nameEn,
              nameFr: tag.nameFr,
              nameNl: tag.nameNl,
            },
          };
          return tmp;
        });
        setTags(tabTags);
      }
    }
  }, [currentQuestion]);

  // useEffect(() => {
  //   if (status.value === "PUBLISHED" && publishedAt === null) {
  //     if (currentRubric.publishedAt) {
  //       setPublishedAt(
  //         convertDateToUTC(
  //           currentQuestion.publishedAt,
  //           API_DATE_FORMAT,
  //           DATE_FORMAT
  //         )
  //       );
  //     } else {
  //       const date = new Date();
  //       const dateSTR = `${
  //         date.getDay() > 9 ? date.getDay() : "0" + date.getDay()
  //       }-${
  //         date.getMonth() > 9 ? date.getMonth() : "0" + date.getMonth()
  //       }-${date.getFullYear()} ${
  //         date.getHours() > 9 ? date.getHours() : "0" + date.getHours()
  //       }:${
  //         date.getMinutes() > 9 ? date.getMinutes() : "0" + date.getMinutes()
  //       }`;
  //       setPublishedAt(dateSTR);
  //     }
  //   } else {
  //     setPublishedAt(null);
  //   }
  // }, [status]);

  const resetForm = () => {
    setTitle({ en: "", fr: "", nl: "" });
    setStatus(statusOptions[0]);
    setAnswers({ en: "", fr: "", nl: "", isMain: false });
    setAnswerFr("");
    setAnswerNl("");
    setAnswerEn("");
    setAttachments([]);
    setIsPrivate(false);
    setPrivateGroups([]);
  };

  languages.forEach(function (item, i) {
    if (item.value === language) {
      languages.splice(i, 1);
      languages.unshift(item);
    }
  });

  const handleCancel = () => {
    if (props.onCancel) {
      props.onCancel();
    }
  };

  const handleDelete = () => {
    setIsDeleting(true);
    removeQuestion(token, currentQuestion.id)
      .then(() => {
        dispatch(deleteQuestion(currentQuestion.id));
        setIsDeleting(false);
        setIsOpenDeleteModal(false);
        props.onCancel();
      })
      .catch((err) => {
        setIsDeleting(false);
        setIsOpenDeleteModal(false);
        toast.error(_("rubric_delete_error"));
      });
  };

  const handleTitleChange = (e, lng) => {
    let tmp = { ...title };
    tmp[lng] = e.target.value;
    setTitle(tmp);
  };

  const handleChangeAnwser = (c, lang) => {
    eval("setAnswer" + lang.charAt(0).toUpperCase() + lang.slice(1))(c);
  };

  const handlePaste = (event, cleanData, maxCharCount, core) => {
    let content = cleanData.replace(/\s+style="[^"]*"/gi, "");
    return content.replace(/(<\/?h)([0-1])/gi, "$12");
  };

  const handleResetAnswer = (e) => {
    setIsResetAnswer(e);
    if (e) {
      if (currentQuestion.answers?.length > 0) {
        setAnswers({
          en: currentQuestion.answers[0].contentEn,
          fr: currentQuestion.answers[0].contentFr,
          nl: currentQuestion.answers[0].contentNl,
          isMain: false,
        });
        setAnswerFr(currentQuestion.answers[0].contentFr);
        setAnswerNl(currentQuestion.answers[0].contentNl);
        setAnswerEn(currentQuestion.answers[0].contentEn);
      }
    } else if (props.customAnswer) {
      setAnswers({
        en: props.customAnswer.contentEn,
        fr: props.customAnswer.contentFr,
        nl: props.customAnswer.contentNl,
        isMain: false,
      });
      setAnswerFr(props.customAnswer.contentFr);
      setAnswerNl(props.customAnswer.contentNl);
      setAnswerEn(props.customAnswer.contentEn);
    }
  };

  const validate = () => {
    let errors = [];

    if (title[language].trim().length === 0 && !props.suggest) {
      errors.push(_("validate_title"));
    }

    if (isPrivate && privateGroups.length === 0) {
      errors.push(_("validate_private_groups"));
    }

    if (tags.length > 0) {
      let emptyTagName = false;
      tags.forEach((tag) => {
        if (
          !tag["__isNew__"] &&
          (tag.tag.nameFr.length === 0 ||
            tag.tag.nameNl.length === 0 ||
            tag.tag.nameEn.length === 0)
        ) {
          emptyTagName = true;
        }
      });
      if (emptyTagName) {
        errors.push(_("validate_tag_name"));
      }
    }

    return errors;
  };

  const handleSave = async () => {
    let errors = validate();

    if (errors && errors.length > 0) {
      let ErrorsContainer = ({ errors }) => (
        <div>
          <span>{_("errors") + " :"}</span>
          <ul>
            {errors.map((e, i) => (
              <li key={i}>{e}</li>
            ))}
          </ul>
        </div>
      );
      toast.error(<ErrorsContainer errors={errors} />);
      return;
    }

    let tabPrivateGroups = privateGroups.map((item) => {
      return item.id;
    });

    const tmpAttachments = attachments.filter((a) => a.isTmp);

    let data = {
      title,
      community: navCommunity ? navCommunity.id : 9,
      status: status.value,
      answer: {
        fr: answerFr,
        nl: answerNl,
        en: answerEn,
      },
      rubric: currentRubric && currentRubric.id,
      isPrivate,
      privateGroups: tabPrivateGroups,
      publishedAt: publishedAt
        ? convertDateToUTC(publishedAt, DATE_FORMAT, API_DATE_FORMAT)
        : "",
      attachments,
      tmpAttachments,
      objectType: "QUESTION",
    };

    if (currentQuestion) {
      data.id = currentQuestion.id;
    }
    if (props.suggestion) {
      data.suggestion = props.suggestion.id;
    }

    if (!props.suggest) {
      const formatedTags = [];
      const nameAttr = `name${
        language.charAt(0).toUpperCase() + language.slice(1)
      }`;
      tags.forEach((tag) => {
        if (tag["__isNew__"]) {
          let newTag = {};
          newTag[nameAttr] = tag.value;
          formatedTags.push(newTag);
        } else {
          formatedTags.push({ id: tag.value });
        }
      });
      data.tags = formatedTags;
    }

    setIsSaving(true);
    try {
      if (props.suggest) {
        if (navCommunity?.id !== 9 && auth.loggedIsAdmin) {
          if (isResetAnswer) {
            const res = await removeAnswer(token, props.customAnswer.id);
          } else {
            if (props.isCustomAnswer) {
              data.answerId = props.customAnswer.id;
            }
            const res = await addAnswer(token, data);
          }
          setIsSaving(false);
          resetForm();
          toast.success(_("suggestion_success"));
          setTimeout(() => window.location.reload(), 800);
        } else {
          const res = await suggestAnswer(token, data);
          props.setSuggestion(res);
          setIsSaving(false);
          resetForm();
          toast.success(_("suggestion_success"));
        }
      } else {
        const response = await saveQuestion(token, data);
        setIsSaving(false);
        resetForm();
        if (!currentQuestion) {
          dispatch(addQuestion(response.data.data));
        }
        dispatch(setCurrentQuestion(response.data.data));
      }
      props.afterSave();
    } catch (e) {
      setIsSaving(false);
    }
  };

  if (!currentRubric && !props.suggest) {
    return null;
  }

  return (
    <div>
      {!props.suggest && !props.sideBar && (
        <div className={styles.configRow}>
          <div className={styles.configColumn}>
            <div className={styles.formRow}>
              <label className={styles.configLabel}>{_("faq_status")}</label>
              <Select
                styles={SELECT_STYLES}
                options={statusOptions}
                isSearchable={false}
                value={status}
                onChange={(e) => setStatus(e)}
                isDisabled={currentRubric.status === "DRAFT"}
              />
            </div>
          </div>
          {status.value === "PUBLISHED" && (
            <div className={styles.configColumn}>
              <div className={styles.formRow}>
                <label className={styles.configLabel}>{_("faq_status")}</label>
                <RenderPublishedAt
                  publishedAt={publishedAt}
                  setPublishedAt={setPublishedAt}
                  currentRubric={currentRubric}
                  status={status}
                />
              </div>
            </div>
          )}
        </div>
      )}

      {!props.suggest && !props.sideBar && (
        <div className={styles.configRow}>
          <div className={styles.configColumn}>
            <div className={styles.switchRow}>
              <label className={styles.configLabel}>{_("is_private")}</label>
              <Switch isChecked={isPrivate} onChange={(e) => setIsPrivate(e)} />
            </div>
          </div>
          {isPrivate && (
            <div className={styles.configColumn}>
              <label className={styles.configLabel}>
                {_("subscribers_groups")}
              </label>
              <GroupsSelect
                isMulti={true}
                selectedGroups={privateGroups}
                onChange={(groups) => setPrivateGroups(groups)}
                auth={auth}
                community={navCommunity?.id ? { value: navCommunity.id } : null}
                selectStyles={SELECT_STYLES}
              />
            </div>
          )}
        </div>
      )}
      <Tabs
        theme="wtheme"
        onClickTab={(e) => setDisplayedLanguage(languages[e].value)}
      >
        {languages.map((lng) => (
          <div
            key={`langtab${lng.value}`}
            label={lng.label}
            icon={`https://tamtam.s3-eu-west-1.amazonaws.com/cdn/widget/${APP_ENV}/img/flags/${lng.value.toUpperCase()}.jpg`}
          >
            <div
              className={`${styles.langTab} ${
                props.suggest ? styles.suggetModal : ""
              }`}
            >
              <div className={styles.formRow}>
                <label className={styles.configLabel}>
                  {_("rubric_form_title")} {lng.value}
                </label>
                <input
                  disabled={props.suggest}
                  className={styles.formInput}
                  value={title[lng.value]}
                  onChange={(e) => {
                    handleTitleChange(e, lng.value);
                  }}
                />
              </div>

              <div className={styles.formRow}>
                <label className={styles.configLabel}>
                  {_("faq_form_anwser")} {lng.value}
                </label>
                <div className={`ttp-helpeditor ${styles.editorContainer} `}>
                  <SunEditor
                    setOptions={{
                      attributesWhitelist: {
                        // all: "style",
                        input: "checked",
                      },
                      stickyToolbar: false,
                      height: "auto",
                      minHeight: "250px",
                      showPathLabel: false,
                      resizingBar: false,
                      imageUrlInput: false,
                      imageWidth: "75%",
                      formats: [
                        "p",
                        "blockquote",
                        "h2",
                        "h3",
                        "h4",
                        "h5",
                        "h6",
                      ],
                      buttonList: [
                        [
                          "undo",
                          "redo",
                          "formatBlock",
                          "bold",
                          "underline",
                          "italic",
                          "strike",
                          "outdent",
                          "indent",
                          "align",
                          "list",
                          "table",
                          "link",
                          "image",
                          "video",
                          "fullScreen",
                          "showBlocks",
                        ],
                      ],
                    }}
                    disable={isResetAnswer}
                    setContents={answers[lng.value]}
                    onChange={(c) => handleChangeAnwser(c, lng.value)}
                    onPaste={handlePaste}
                  />
                </div>
              </div>
            </div>
          </div>
        ))}
      </Tabs>

      {!props.suggest && (
        <div className={styles.tag}>
          <label className={styles.configLabel}>{_("tag")}</label>
          <Tag
            selectStyles={SELECT_STYLES}
            language={language}
            displayedLng={displayedLanguage}
            tags={tags}
            setTags={setTags}
          />
        </div>
      )}

      {props.isCustomAnswer && (
        <div className={styles.switchFlex}>
          <label className={styles.configLabel}>{_("reset_answer")}</label>
          <Switch
            isChecked={isResetAnswer}
            onChange={(e) => handleResetAnswer(e)}
          />
        </div>
      )}

      {!props.suggest && (
        <div className={styles.attachmentsRow}>
          <label className={styles.configLabel}>{_("attachments")}</label>
          <Attachments
            attachments={attachments}
            setAttachments={setAttachments}
          />
        </div>
      )}

      <div
        className={`${styles.formControls} ${
          props.suggest ? styles.suggetModalActions : ""
        }`}
      >
        <Button onClick={() => handleCancel()} variant="default">
          {_("cancel")}
        </Button>

        <div className={styles.actions}>
          {currentQuestion && !props.suggest && (
            <span onClick={() => setIsOpenDeleteModal(true)}>
              <IconTrash size={18} />
            </span>
          )}
          {isSaving ? (
            <Button
              variant="primary"
              style={{ paddingTop: "15px", paddingBottom: "15px" }}
              className={styles.okBtn}
            >
              <Loader
                style={{
                  height: "10px",
                }}
                color={"#fff"}
              />
            </Button>
          ) : (
            <Button
              onClick={() => handleSave()}
              variant="primary"
              className={styles.okBtn}
            >
              {_("save")}
            </Button>
          )}
        </div>
      </div>
      <ModalConfirm
        type="delete"
        isOpen={isOpenDeleteModal}
        inProcess={isDeleting}
        onCancel={() => setIsOpenDeleteModal(false)}
        onConfirm={handleDelete}
        title={_("modal_delete_title")}
        text={_("modal_delete_text")}
        labelNo={_("no")}
        labelYes={_("yes")}
      />
    </div>
  );
};
export default FaqForm;
